/* eslint-disable no-dupe-class-members */
// TODO: update to eslint >= 8 to support private class methods

import rp from 'request-promise';

export class AlertsService {
  _hostUrl;
  _rpOptions;

  constructor(headers, hostUrl) {
    this._hostUrl = hostUrl;

    // set default request options
    this._rpOptions = {
      headers: { ...headers },
      json: true,
    };
  }

  _getById(id) {
    const options = {
      ...this._rpOptions,
      method: 'GET',
      uri: `${this._hostUrl}/alerts/${id}`,
    };
    return rp(options);
  }

  _create(data) {
    const options = {
      ...this._rpOptions,
      method: 'POST',
      uri: `${this._hostUrl}/alerts`,
      body: {
        title: data.title,
        type: data.type,
        params: data.params,
        notificationConfig: data.notificationConfig,
      },
    };
    return rp(options);
  }

  _patch(id, data) {
    const options = {
      ...this._rpOptions,
      method: 'PATCH',
      uri: `${this._hostUrl}/alerts/${id}`,
      body: data,
    };
    return rp(options);
  }

  _delete(id) {
    const options = {
      ...this._rpOptions,
      method: 'DELETE',
      uri: `${this._hostUrl}/alerts/${id}`,
    };
    return rp(options);
  }

  _getAll() {
    const options = {
      ...this._rpOptions,
      method: 'GET',
      uri: `${this._hostUrl}/alerts`,
    };
    return rp(options);
  }

  _getSupportedFeatures() {
    const options = {
      ...this._rpOptions,
      method: 'GET',
      url: `${this._hostUrl}/alerts-supported-features`,
    };
    return rp(options);
  }

  getServices() {
    return {
      getById: this._getById.bind(this),
      create: this._create.bind(this),
      patch: this._patch.bind(this),
      delete: this._delete.bind(this),
      getAll: this._getAll.bind(this),
      getSupportedFeatures: this._getSupportedFeatures.bind(this),
    };
  }
}
