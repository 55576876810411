/**
 * This class is responsible for transforming forensic analysis data
 * from the API into data that the app can use.
 */

import { isEthAddress, isValidEthHash } from 'utils/crypto';
import { IAssetInfo } from 'typings/interfaces';

const AssetIconIndex = { btc: '฿', eth: '⧫' };
export default class ForensicAnalysis {
  #rawReportData: any;
  constructor(response) {
    this.#rawReportData = this.#adaptResponse(response);
  }

  get rawReportData() {
    return this.#rawReportData;
  }

  #adaptResponse = (parentAgentRes) => {
    const ids: string[] = [];
    const parentAgent = ForensicAnalysis.markAgentResponses(parentAgentRes);
    ids.push(parentAgent.meta._id);

    const reportData = ForensicAnalysis.normalizeReportData(parentAgent);

    return {
      meta: reportData.meta,
      graph: { nodes: reportData.nodes, edges: reportData.edges },
      reportParams: reportData.meta.reportParams,
      token: parentAgent.token, // this shouldn't do anything. try to remove
      ids,
      transactions: reportData.transactions,
    };
  };

  static markAgentResponses = (newChildAgent, chldRepID = false) => {
    if (!chldRepID) {
      chldRepID = newChildAgent.meta._id;
    }

    newChildAgent.edges.forEach((e) => {
      e.agentId = chldRepID;
      if (!e.source.includes('AGENT'))
        e.source = e.source + '_AGENT' + chldRepID;
      if (!e.target.includes('AGENT'))
        e.target = e.target + '_AGENT' + chldRepID;
    });

    newChildAgent.nodes.forEach((n) => {
      n.agentId = chldRepID;
      if (!n.id.includes('AGENT')) n.id = n.id + '_AGENT' + chldRepID;
    });

    return newChildAgent;
  };

  // TODO: all report data from 'API.getForensicAnalysis' needs to be normalized
  // this should be refactored so that normalization is guaranteed to
  // happen instead of requiring the dev to remember it
  static normalizeReportData(reportData) {
    const meta = reportData.meta
      ? ForensicAnalysis.normalizeReportMeta(reportData.meta)
      : undefined;
    const transactions = Array.isArray(reportData.transactions)
      ? reportData.transactions.reduce((acc, tx) => {
          acc[tx.id] = normalizeTransaction(tx);
          return acc;
        }, {})
      : reportData.transactions.map(normalizeTransaction);
    const edges = reportData.edges.map((edge: any) =>
      normalizeEdge(edge, transactions),
    );
    const nodes = reportData.nodes.map(normalizeNode);
    return {
      nodes,
      edges,
      transactions,
      meta,
    };
  }

  static normalizeReportMeta = (meta: any): any => {
    meta.assetInfo = getAssetInfo(meta);
    meta.created_at = new Date(meta.created_at);
    return meta;
  };
}

const normalizeTransaction = (transaction: any): any => {
  transaction.outamt = parseFloat(transaction.outamt);
  transaction.inamt = parseFloat(transaction.inamt);
  transaction.cpin.map(normalizeCounterParty);
  transaction.cpout.map(normalizeCounterParty);
  return transaction;
};

const normalizeCounterParty = (counterParty: any): any => {
  return counterParty;
};

const normalizeEdge = (
  edge: any,
  transactions: { [transactionId: string]: any },
): any => {
  const txIds: string[] = edge.tx_hashes;
  const txTimestamps = txIds.map((id) => transactions[id].timestamp);
  edge.txTimeStps = txTimestamps;
  edge.amts = edge.amts.map((amt: string) => parseFloat(amt));
  edge.expanded = !!edge.expanded;
  return edge;
};

const normalizeNode = (node: any): any => {
  node.balance = parseFloat(node.balance);
  node.cluster_id =
    node.clusterId === undefined ? node.cluster_id : node.clusterId;
  node.clustersize =
    node.clusterSize === undefined
      ? parseInt(node.clustersize)
      : node.clusterSize;
  // node.entity = node.entity;
  node.entitytype =
    node.entityType === undefined ? node.entitytype : node.entityType;
  // node.hops = node.hops;
  // node.id = node.id;
  node.is_source =
    node.isSource === undefined ? !!node.is_source : node.isSource;
  node.is_stop_cluster =
    node.isStopCluster === undefined
      ? !!node.is_stop_cluster
      : node.isStopCluster;
  node.max_num_incoming_edges =
    node.maxNumIncomingEdges === undefined
      ? parseInt(node.max_num_incoming_edges)
      : node.maxNumIncomingEdges;
  node.max_num_outgoing_edges =
    node.maxNumOutgoingEdges === undefined
      ? parseInt(node.max_num_outgoing_edges)
      : node.maxNumOutgoingEdges;
  node.num_incoming_txs =
    node.numIncomingTxs === undefined
      ? node.num_incoming_txs
      : node.numIncomingTxs;
  node.num_outgoing_txs =
    node.numOutgoingTxs === undefined
      ? node.num_outgoing_txs
      : node.numOutgoingTxs;
  node.sumscore = node.sumScore === undefined ? node.sumscore : node.sumScore;
  node.total_amt_received =
    node.totalAmtReceived === undefined
      ? parseFloat(node.total_amt_received)
      : parseFloat(node.totalAmtReceived);

  // TODO: check that it's safe to remove then remove
  // (see what's going on with this in ReportData.ts)
  node.has_incoming_txs = node.num_incoming_txs !== 0;
  node.has_outgoing_txs = node.num_outgoing_txs !== 0;
  return node;
};

const getAssetInfo = (meta: any): IAssetInfo => {
  const blockchain = getBlockchain(meta);
  const asset = getAsset(meta, blockchain);
  const symbol = getAssetSymbol(asset);
  const icon = getAssetIcon(symbol);
  return {
    blockchain,
    asset,
    symbol,
    icon,
  };
};

const getBlockchain = (meta: any): string => {
  let blockchain = 'bitcoin';
  if (
    (meta.target_addresses?.length &&
      meta.target_addresses.find((a) => isEthAddress(a))) ||
    (meta.target_txs?.length &&
      meta.target_txs.find((tx) => isValidEthHash(tx)))
  ) {
    blockchain = 'ethereum';
  }
  return blockchain;
};

const getAsset = (meta: any, blockchain: string): string => {
  let asset = 'btc';
  if (blockchain === 'bitcoin') {
    return asset;
  }

  asset = 'eth';
  if (meta.tokenSymbol) {
    asset = meta.tokenSymbol;
  } else if (meta.layout?.assetName) {
    asset = meta.layout?.assetName;
  } else if (meta.layout?.asset) {
    asset = meta.layout.asset;
  } else if (meta.reportParams?.crypto) {
    asset = meta.reportParams.crypto;
  }

  return asset.toLowerCase();
};

const getAssetSymbol = (asset: string): string => {
  // hack for symbol not being returned for newly created report
  if (asset.length > 5) {
    return '-';
  }
  return asset.toUpperCase();
};

const getAssetIcon = (asset: string): string | undefined => {
  if (!asset) {
    return undefined;
  }
  return AssetIconIndex[asset.toLowerCase()];
};
