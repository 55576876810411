import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import Radio from '@material-ui/core/Radio';
import { ModalContainer } from '../Graph/styles';
import { styleRefs as SR } from 'styles/styleRefs';

import React from 'react';

export const ParameterContainer = styled.div`
  display: flex;
  width: 75%;
  .field-title {
    width: 50%;
  }
`;

export const NewAppContainer = styled(ModalContainer)`
  .panel-body {
    padding: 30px;

    .MuiFormControl-root {
      width: 100%;

      .MuiFormLabel-root {
        color: rgba(255, 255, 255, 0.54);
      }

      .Mui-Focused {
        color: ${SR.colors.emphasis_lightPurple};
      }

      label {
        width: 90%;
      }

      .MuiInputBase-root {
        color: white;
        font-size: 16px;
      }

      .MuiFormHelperText-root {
        font-size: 1em;
      }
    }
    form {
      display: flex;
      flex-direction: column;

      input {
        color: white;
        font-size: 1em;
      }
      .MuiFilledInput-underline:after {
        border-bottom: 4px solid ${SR.colors.emphasis_lightPurple};
      }

      .address-input {
        margin-top: 2em;
        .MuiInputBase-root {
          height: 100%;
        }
      }

      .alert-container {
        color: #ff6663;
        border-radius: 40px;
        width: 80%;
        place-self: center;
        text-align: center;
      }
    }
    .load-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 2em;
      margin-bottom: 2em;
      height: 81px;

      .custom-file-upload {
        border: 1px solid #ccc;
        padding: 6px 12px;
        border-radius: 7px;
        transition: 0.2s;

        &:hover {
          cursor: pointer;
          color: ${SR.colors.emphasis_lightPurple};
          border: 1px solid ${SR.colors.emphasis_lightPurple};
        }
      }

      .random-btn {
        margin-top: ${SR.margin.half};
        font-size: ${SR.fontSize.standard};
      }
    }
  }

  .source_only_box {
    display: flex;
    justify-content: center;
    margin-left: 200px;
  }

  .submit-btn {
    width: 100%;
    margin-top: 25px;
    &:hover {
      cursor: pointer;
    }
  }

  .field-title {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 2px;
  }

  #slidercontainer {
    width: 80%;
  }

  .Cluster_Optionality_Radio {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
    }
    p {
      margin: auto 0;
    }
  }

  .flowtype-selection-radio {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
    }
    p {
      margin: auto 0;
    }
  }

  // move to button styles

  .button-toggle {
    float: right;
    margin-top: -10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;

    .btn-group {
      margin-bottom: 10px;
      margin: 5px;

      .left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;

export const PurpleRadio = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: SR.colors.emphasis_lightPurple,
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

export const PurpleCheckBox = styled.div`
  .label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    cursor: pointer;
    /* display: inline-flex; */
    align-items: center;
    /* margin-left: -11px; */
    /* margin-right: 16px; */
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    .input {
      top: 0;
      left: 0;
      width: 100%;
      cursor: inherit;
      height: 100%;
      margin: 0;
      opacity: 0;
      padding: 0;
      z-index: 1;
      position: absolute;
    }
    svg {
      fill: ${(props) => props.color};
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }
  }
`;

export const customSelectStyles = {
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: SR.colors.nuetral_DarkPurple,
      color: isSelected ? SR.colors.emphasis_lightPurple : 'white',
      marginTop: 0,
      fontWeight: isSelected ? 'bolder' : 'initial',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: SR.colors.emphasis_lightPurple,
        cursor: 'pointer',
        color: 'white',
      },
      textTransform: 'uppercase',
    };
  },
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderColor: SR.colors.nuetral_DarkPurple,
    boxShadow: '0 0 0 1px white',
    ':active': {
      ...styles[':active'],
      borderColor: SR.colors.emphasis_lightPurple,
    },
    ':hover': {
      ...styles[':hover'],
      cursor: 'pointer',
      color: 'white',
    },
  }),
  singleValue: (styles) => {
    return {
      ...styles,
      transition: 'color 300ms',
      color: 'white',
      borderColor: SR.colors.nuetral_DarkPurple,
      textTransform: 'uppercase',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      backgroundColor: SR.colors.nuetral_DarkPurple,
      borderColor: SR.colors.nuetral_DarkPurple,
    };
  },
};
// keeping this in here in the case we want to revert to the simpler approach
