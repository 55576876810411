import React, { useState } from 'react';
import id, { RowContainer } from './id';
import button from './button';
import other from './other';
import assetFormatter from './asset';
import { CellText, DarkCellText } from '../styles';
import { getBlockExplorerURL } from 'helpers/utils';
import { abbreviateNumber } from 'utils/displayData';

const renderDate = (date) => {
  return date.toLocaleString().split('.')[0].replace('T', ' ');
};

const dateTimeFormatter = (dateTime) => {
  try {
    return (
      <span className='table-dateTime'>
        <DarkCellText>{renderDate(dateTime)}</DarkCellText>
      </span>
    );
  } catch (e) {
    console.log('Date error: ', e);
    console.log('dateTime: ', dateTime);
    return (
      <span className='table-dateTime'>
        <CellText>-</CellText>
      </span>
    );
  }
};

const blockNbFormatter = (blockNb) => {
  return (
    <span className='table-blockNb'>
      <DarkCellText>{blockNb}</DarkCellText>
    </span>
  );
};

const AmountsContainer = ({ amounts, addShowAllListener }) => {
  const [showAll, setShowAll] = useState(false);
  addShowAllListener((showAll) => setShowAll(showAll));
  const visibleAmounts = showAll
    ? [...amounts]
    : amounts.slice(0, Math.min(amounts.length, 3));
  return (
    <>
      {visibleAmounts.map((amount, idx) => {
        return (
          <RowContainer key={idx}>
            <CellText>{amount}</CellText>
          </RowContainer>
        );
      })}
    </>
  );
};

const amounts = (amounts, row) => {
  return (
    <AmountsContainer
      amounts={amounts.map(abbreviateNumber)}
      addShowAllListener={row.addShowAllListener}
    />
  );
};

const fiatAmounts = (amounts, row) => {
  const displayAmounts = amounts.map((amount) => {
    let val;
    if (amount === null || amount !== undefined) {
      val = `$${abbreviateNumber(amount)}`;
    } else {
      val = 'unavailable';
    }
    return val;
  });
  return (
    <AmountsContainer
      amounts={displayAmounts}
      addShowAllListener={row.addShowAllListener}
    />
  );
};

const txIdFormatter = ({ asset, id }) => {
  return (
    <a
      className='table-txId'
      href={getBlockExplorerURL(asset, id, true)}
      target='_blank'
      rel='noopener noreferrer'
    >
      <CellText title='View transaction in block explorer'>{id}</CellText>
    </a>
  );
};

const formatters = {
  id,
  asset: assetFormatter,
  button,
  other,
  dateTime: dateTimeFormatter,
  blockNB: blockNbFormatter,
  amounts,
  txId: txIdFormatter,
  fiatAmounts,
};

export default formatters;
