const initSettings = () => {
  window.ZC.LICENSE = [
    'aaa7e9eefc495308daf69f6a0458546e',
    '1e242af6f7e4dd8c5c92b2070218bf92',
    '76eb7cbb4ac013ee486000eace87958f',
    'c7743f4ebc53de793a3daf8153ffe2f6',
    'eb87b8ab4f1fb4ea2595841d1ec74829',
    'lens.elementus.io',
    'flux.elementus.io',
    'radar.elementus.io',
    'pulse.elementus.io',
  ];
};

export default initSettings;
