import React from 'react';
import {
  hideContextMenu,
  hideItems,
  unhideItems,
  clearSelection,
  removeCustomLabels,
  set_showCustomLabelEdit,
} from 'hooks/useGraph/actions';
import { MenuItem } from 'rc-menu';
import { ContextMenuContainer } from './styles';
import 'rc-menu/assets/index.css';
import { useAuth0 } from '@auth0/auth0-react';
import ElementusAPIService from 'services/elementus_api.service';
import ClearIcon from './clearIcon';
import UnhideIcon from './unhideIcon';
import HideIcon from './hideIcon';
import ExpandIcon from './ExpandIcon';
import {
  idIsLink,
  idIsComboLink,
  idIsComboNode,
  idIsNode,
} from 'helpers/itemsHelper';
import { getBlockExplorerURL } from 'helpers/utils';
import { useGraph } from 'hooks/useGraph';

/**
 * ContextMenu
 *
 * Defines the right click menu for the chart component.
 * It is stateful in order to prevent default context menu.
 * */

const ContextMenu = ({ toggleNewReportModal }) => {
  const { state, dispatch: graphDispatch, expandNode } = useGraph();

  const { contextMenu, selection, ReportData } = state;
  let sourceIds = [];
  if (ReportData) {
    sourceIds = ReportData.sourceIds;
  }
  const { x, y } = contextMenu;
  const { getAccessTokenSilently } = useAuth0();

  const ifNone = !selection.size;

  let hasAtLeastOneValidSelection = false;

  if (!ifNone) {
    // FIXME may need to rework this
    const firstSelectedNode = Array.from(selection).filter(
      (id) => idIsNode(id) || idIsComboNode(id),
    )[0];
    const selectionData = state.viewableItems[firstSelectedNode];
    // TODO Handle case for Combo Node Click
    // The following conditional is a temp fix. For a more permanent fix use:
    // 'getSelectionBreakdown' from itemsHelper to make a determination on the item selected.
    if (selectionData) {
      const ifCluster = selectionData.data.is_cluster;
      hasAtLeastOneValidSelection = !ifCluster;
    }
    hasAtLeastOneValidSelection =
      !idIsLink(firstSelectedNode) && !idIsLink(firstSelectedNode);
  }

  const hasCombos = Array.from(selection).some(
    (id) => idIsComboLink(id) || idIsComboNode(id),
  );

  const ifMultiple = selection.size > 1;

  const commands = [];

  if (
    selection.size &&
    Array.from(selection).every((id) => {
      if (!idIsComboNode(id) && idIsNode(id)) return true;
      return idIsComboNode(id) && id.includes('_CC') && idIsNode(id);
    })
  ) {
    commands.push({
      text: `Add/Edit Custom Label`,
      actions: () => {
        graphDispatch(set_showCustomLabelEdit(true));
        graphDispatch(hideContextMenu());
      },
    });
  }

  if (
    Array.from(selection).some(
      (id) =>
        id.slice(-5) in state.customLabelsMap || id in state.customLabelsMap,
    )
  ) {
    commands.push({
      text: `Remove Custom Label`,
      actions: () => {
        graphDispatch(removeCustomLabels());
      },
    });
  }

  // TODO should have some waiting indicator and then a link to the new report
  if (Array.from(selection).some(idIsNode) && !hasCombos) {
    commands.push({
      text: 'Generate New Report (Address)',
      actions: () => {
        const presetItems = Array.from(state.selection)
          //  filters out edges
          .filter((x) => !x.includes('|') && !x.includes('combonode'))
          .map((x) => state.viewableItems[x].data.cluster_id)
          .map((x) => x.split('_')[0]);

        toggleNewReportModal(presetItems);
        graphDispatch(hideContextMenu());
      },
    });
  }
  if (Array.from(selection).some(idIsLink) && !hasCombos) {
    commands.push({
      text: 'Generate New Report (Transaction)',
      actions: () => {
        const selectedTxArrayOfArrays = Array.from(state.selection)
          //  filters out edges
          .filter((x) => x.includes('|') && !x.includes('combonode'))
          .map((x) => state.viewableItems[x].data.txHashs);

        const presetItems = [].concat(...selectedTxArrayOfArrays);

        toggleNewReportModal(presetItems);
        graphDispatch(hideContextMenu());
      },
    });
  }

  if (
    hasAtLeastOneValidSelection &&
    !ifMultiple &&
    !hasCombos &&
    idIsNode(Array.from(selection)[0])
  ) {
    const nodeId = Array.from(selection)[0];
    const nodeData = state.items[nodeId].data;
    const isEntityNode = nodeData.cluster_id === nodeData.entity;

    const uniqueAddress = nodeData['id'].split('_')[0];

    commands.push({
      text: `Copy ${isEntityNode ? 'Entity Name' : 'Address'}`,
      actions: () => {
        navigator.clipboard.writeText(uniqueAddress);
        graphDispatch(hideContextMenu());
      },
    });

    if (!isEntityNode)
      commands.push({
        text: 'Open in Block Explorer',
        actions: () => {
          const url = getBlockExplorerURL(
            state.ReportData.assetInfo.asset,
            uniqueAddress,
          );
          window.open(url, '_blank');
          graphDispatch(hideContextMenu());
        },
      });
  }

  // "clear selected" should not appear if nothing is selected
  if (selection.size) {
    commands.push({
      text: 'Clear Selected',
      icon: <ClearIcon />,
      actions: () => {
        graphDispatch(clearSelection());
        graphDispatch(hideContextMenu());
      },
    });
  }

  if (
    hasAtLeastOneValidSelection &&
    selection.size &&
    !Array.from(selection).some((id) => sourceIds.includes(id)) &&
    Array.from(selection).some((id) => idIsNode(id)) &&
    !hasCombos &&
    !state.expansionsRequested
  ) {
    commands.push({
      text: 'Hide Selected',
      icon: <HideIcon />,
      actions: async () => {
        const token = await getAccessTokenSilently();
        const api = new ElementusAPIService(token);

        graphDispatch(
          hideItems(
            new Set(Array.from(selection).filter((id) => idIsNode(id))),
            api,
          ),
        );
        graphDispatch(hideContextMenu());
      },
    });
  }

  if (
    Array.from(selection).some(
      (id) =>
        state &&
        state.items &&
        state.items[id] &&
        state.items[id].data &&
        state.items[id].data.canExpandOut,
    ) &&
    !hasCombos
  ) {
    commands.push({
      text: 'Expand Selected (Outgoing)',
      icon: <ExpandIcon />,
      actions: async () => {
        state.selection.forEach(async (id) => {
          if (idIsNode(id)) {
            if (state.items[id].data.canExpandOut) {
              await expandNode(id, 'outgoing');
            }
          }
        });

        graphDispatch(hideContextMenu());
      },
    });
  }

  if (
    Array.from(selection).some(
      (id) =>
        state &&
        state.items &&
        state.items[id] &&
        state.items[id].data &&
        state.items[id].data.canExpandIn,
    ) &&
    !hasCombos
  ) {
    commands.push({
      text: 'Expand Selected (Incoming)',
      icon: <ExpandIcon />,
      actions: async () => {
        state.selection.forEach((id) => {
          if (idIsNode(id)) {
            if (state.items[id].data.canExpandIn) {
              expandNode(id, 'incoming');
            }
          }
        });

        graphDispatch(hideContextMenu());
      },
    });
  }

  if (state.ReportData.hiddenIds.length)
    commands.push({
      text: 'Unhide All',
      icon: <UnhideIcon />,
      actions: async () => {
        const api = new ElementusAPIService(state.ReportData.token);
        graphDispatch(unhideItems(api));
        graphDispatch(hideContextMenu());
      },
    });

  return (
    <div>
      <ContextMenuContainer
        className='graph-context-menu'
        mode='vertical'
        defaultActiveFirst={true}
        x={x}
        y={y}
      >
        {commands.map((command) => (
          <MenuItem
            key={command.text}
            className='graph-context-menu-item'
            onClick={command.actions}
          >
            {command.icon}
            {command.text}
          </MenuItem>
        ))}
      </ContextMenuContainer>
    </div>
  );
};
export default ContextMenu;
