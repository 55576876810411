import React from 'react';
import { ViewContainer } from '../../styles';
import { InfoWindow } from '../Common/InfoWindow';
import NavBar from '../NavBar';

const Terms = () => {
  return (
    <ViewContainer>
      <NavBar view='list' />
      <InfoWindow>
        <article className='post'>
          <header className='post-header'>
            <h1 className='post-title'>Privacy Policy</h1>
          </header>

          <section className='post-content'>
            <h2>Introduction</h2>
            <p>
              This privacy policy (“Policy”) and this site’s Terms of Service
              (together the “Terms”) govern all use of{' '}
              <a href='https://elementus.io'>https://elementus.io</a> and that
              site’s services (together the “Site” or “Services”). The owners
              and contributors to the Site will be referred to as “we,” “us,” or
              “our” in this Policy. By using the Site or its Services, and/or by
              clicking anywhere on this Site to agree to the Terms and this
              Policy, you are deemed to be a “user” for purposes of this Policy.
              You and every other user (“you” or “User” as applicable) are
              subject to this Policy. You and each user also agree to the Terms
              by using the Services. In these Terms, the word “Site” includes
              the site referenced above, its owner(s), contributors, suppliers,
              licensors, and other related parties.
            </p>
            <p>
              We provide this privacy statement explaining our online
              information practices, so that you can decide whether and how to
              interact with the Site and the Services.
            </p>
            <p>
              We may release your information when we deem it appropriate to
              comply with the law, enforce our site policies, or protect ours or
              others’ rights, property, or safety.
            </p>
            <p>
              This online privacy policy applies only to information collected
              through our website and not to information collected offline.
            </p>
            <p>
              Please also review our Terms of Use section that governs the use
              and the users of the Site.
            </p>
            <p>By using our site, you consent to our privacy policy.</p>
            <p>
              If we decide to change our privacy policy, we will post those
              changes on this page. If we have your email address, we may also
              send an email notifying you of any changes.
            </p>
            <h2>Contact Data and Other Identifiable Information</h2>
            <p>
              This site collects certain user information, which may include a
              username and password, contact information, or any other data that
              you type in to the site. It may also identify your IP address to
              help identify you on future visits to the site. At our discretion,
              the Site may use this data to:
            </p>
            <ul>
              <li>Personalize the user experience and/or customer service</li>
              <li>Improve the site</li>
              <li>To process transactions</li>
              <li>
                Administer a contest, promotion, survey or other site feature or
                function
              </li>
              <li>Send email to users</li>
            </ul>
            <h2>Mobile Device Privacy</h2>
            <p>
              The following applies to our site, when viewed on a mobile device:
              <br />
              When accessed with a mobile deivce, our site may collect
              information automatically, such as the type of mobile device you
              have, device identifiers, and information about your use of the
              site. Regardless of the device you use to access the site, it will
              also collect information you provide, as well as information about
              your interaction with the site and its content.
              <br />
              If location services are activated on your mobile device, our site
              may collect information about the location of your device. Your
              mobile network service providers may collect device-specific
              information, such as a device identifier, when you use our website
              or one of our mobile applications. This information collected by
              your mobile network service will not be associated with your user
              account with us, or with your personally identifiable information.
            </p>
            <h2>Disputes</h2>
            <p>
              We are based in New York, NY and you are contracting to use our
              Site. This Policy and all matters arising from your use of the
              Site are governed by and will be construed according to the laws
              of New York, NY, without regard to any choice of laws rules of any
              jurisdiction. The federal courts and state courts that have
              geographical jurisdiction over disputes arising at our office
              location in New York, NY will be the only permissible venues for
              any and all disputes arising out of or in connection with this
              Policy or the Site and Service.
            </p>
            <h2>Terms Contact</h2>
            <p>
              If you have any questions about these Terms, please address them
              to admin@elementus.io.
            </p>
            <h2>Last Updated</h2>
            <p>
              These terms were last updated on <em>December 1, 2017</em>
            </p>
          </section>
        </article>
        <footer className='site-footer clearfix'>
          <section className='copyright'>
            <a href='https://elementus.io'>Elementus</a> © 2017
          </section>
        </footer>
      </InfoWindow>
    </ViewContainer>
  );
};

export default Terms;
