import jwtDecode from 'jwt-decode';
import { Product } from 'utils/enums';

export class User {
  constructor(authUser, authToken) {
    this._authToken = authToken;
    this._authUser = authUser;
    this._decodedToken = jwtDecode(authToken);
  }

  get picture() {
    return this._authUser.picture;
  }

  get email() {
    return this._authUser.email;
  }

  get id() {
    return this._authUser.sub.substr(6);
  }

  get name() {
    return this._authUser.nickname;
  }

  isAuthorized(productName) {
    const permission = {
      [Product.DASHBOARDS]: 'use:elementus_dashboards',
      [Product.FLUX]: 'use:elementus_pulse',
      [Product.RADIX]: 'use:elementus_radar',
      [Product.ENTITY_REPORTS]: 'use:elementus_entity_reports',
      [Product.RISK_API]: 'use:elementus_risk_api',
    }[productName];
    return this._decodedToken.permissions.includes(permission);
  }
}
