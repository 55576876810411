import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

export const LoadingBubbles = () => {
  return (
    <Loading>
      <ReactLoading type='bubbles' />
    </Loading>
  );
};

const Loading = styled.div`
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
  z-index: 9999;
  div {
    width: 140px !important;
    height: 140px !important;
    svg {
      fill: #3d3d3d;
    }
  }
`;
