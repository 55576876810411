import _ from 'lodash';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import { FlowType } from 'utils/enums';
import { styleRefs as SR } from 'styles/styleRefs';

import {
  addCombination,
  clearCombination,
  addCustomCombination,
  removeCombinationP1,
  removeCombinationP2,
  toggleLocalCombinations,
} from 'hooks/useGraph/actions';
import { useGraph } from 'hooks/useGraph/';

// const TitleComponent = (text) => <div style={{ fontSize: 12 }}> {text} </div>;

const Combinations = () => {
  const { state: graphState, dispatch } = useGraph();
  const [_localCombos, set_localCombos] = useState(
    graphState.localCombinations,
  );

  const comboTypes = getComboTypes(graphState.ReportData);

  return (
    <>
      {graphState.ReportData.reportIds.length > 1 && (
        <IconButton
          overlap='rectangular'
          aria-label='local-combinations'
          component='span'
          onClick={() => {
            dispatch(toggleLocalCombinations());
            set_localCombos((lC) => (lC ? false : true));
          }}
          style={{
            color: _localCombos ? 'white' : SR.colors.emphasis_lightPurple,
          }}
        >
          <Tooltip
            title={
              <div style={{ fontSize: SR.fontSize.standard }}>
                {' '}
                Apply Combos Globally{' '}
              </div>
            }
            aria-label='Apply Combos Globally'
          >
            <i className='fal fa-globe icon2x'></i>
          </Tooltip>
        </IconButton>
      )}

      <IconButton
        overlap='rectangular'
        aria-label='combine selected'
        component='span'
        onClick={() =>
          dispatch(addCustomCombination('selected', graphState.selection))
        }
        style={{
          color: graphState.combine.properties.some((p) =>
            p.startsWith('user-custom-combo-selected'),
          )
            ? SR.colors.emphasis_lightPurple
            : 'white',
        }}
      >
        <Tooltip
          title={
            <div style={{ fontSize: SR.fontSize.standard }}>
              Combine Selected
            </div>
          }
          aria-label='selected combinations'
        >
          <i className='fad fa-circle-notch icon2x'></i>
        </Tooltip>
      </IconButton>
      {comboTypes.map(({ id, icon, name }) => (
        <IconButton
          overlap='rectangular'
          aria-label={id}
          key={id}
          component='span'
          value={id}
          onClick={() => {
            if (_.indexOf(graphState.combine.properties, id) + 1 === 0) {
              dispatch(addCombination(id));
            } else {
              const currCombined = graphState.combine.properties.filter(
                (i) => i !== id,
              );
              // We have two dispatches to allow for
              // removing when clicking the same combo type
              dispatch(removeCombinationP1());
              dispatch(removeCombinationP2(currCombined));
            }
            return;
          }}
          style={{
            color:
              _.indexOf(graphState.combine.properties, id) + 1
                ? SR.colors.emphasis_lightPurple
                : 'white',
          }}
        >
          <Badge
            badgeContent={_.indexOf(graphState.combine.properties, id) + 1}
            color='secondary'
            overlap='rectangular'
            invisible={_.indexOf(graphState.combine.properties, id) + 1 === 0}
          >
            <Tooltip
              title={
                <div style={{ fontSize: SR.fontSize.standard }}>{name}</div>
              }
              aria-label={id}
            >
              <i className={`${icon} icon2x`} />
            </Tooltip>
          </Badge>
        </IconButton>
      ))}

      <IconButton
        overlap='rectangular'
        aria-label='clear-combinations'
        component='span'
        onClick={() => dispatch(clearCombination())}
        style={{
          color: _.size(graphState.combine.properties) ? '#ff0008' : '#f3f2f7',
        }}
      >
        <Tooltip
          title={
            <div style={{ fontSize: SR.fontSize.standard }}>
              Clear Combinations
            </div>
          }
          aria-label='clear combinations'
        >
          <i className='fad fa-empty-set icon2x'></i>
        </Tooltip>
      </IconButton>
    </>
  );
};

export default Combinations;

const getComboTypes = (reportData) => {
  const comboTypes = [];
  COMBO_TYPES.forEach((comboType) => {
    // for entity reports only
    if (reportData.flowType === FlowType.Entity) {
      if (
        reportData.assetInfo.asset === 'btc' &&
        comboType.id === 'COMBO_entitytype'
      ) {
        comboTypes.push(comboType);
      }
      return;
    }
    // only show wallet combo for btc
    if (
      comboType.id === 'COMBO_wallet' &&
      reportData.assetInfo.asset !== 'btc'
    ) {
      return;
    }
    comboTypes.push(comboType);
  });
  return comboTypes;
};

let COMBO_TYPES = [
  {
    id: 'COMBO_wallet',
    icon: 'fas fa-stop-circle',
    name: 'Combine Wallets',
    label: null,
  },
  {
    id: 'COMBO_unattributed',
    icon: 'fas fa-question-circle',
    name: 'Condense Unknowns',
    label: 'Unknowns',
  },
  {
    id: 'COMBO_source',
    icon: 'fas fa-dot-circle',
    name: 'Combine Sources',
    label: 'Sources',
  },
  {
    id: 'COMBO_entitytype',
    icon: 'fas fa-info-circle',
    name: 'Group By Type',
    label: null,
  },
  {
    id: 'COMBO_entity',
    icon: 'fas fa-user-circle',
    name: 'Group By Entity',
    label: null,
  },
];
