export const SET_ACTIVE_TRANSACTION_IDS = 'SET_ACTIVE_TRANSACTION_IDS';
export const SET_SEARCH = 'SET_TRANSACTION_SEARCH';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';

export const setActiveTxIds = (activeTxIds) => ({
  type: SET_ACTIVE_TRANSACTION_IDS,
  activeTxIds,
});

export const setSearch = (search) => ({
  type: SET_SEARCH,
  search: search,
});

export const setTxs = (txs) => ({
  type: SET_TRANSACTIONS,
  txs,
});
