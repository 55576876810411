import Stat from './Stat/stat';
import { Row } from 'react-bootstrap';
import React from 'react';
import { useGraph } from 'hooks/useGraph';
import DenominatedBalance from 'models/other/balance';

import * as d3 from 'd3';
var formatInt = d3.format(',.0f');

export default function SummaryFLUX({ ReportData }) {
  const asset = ReportData.assetInfo.symbol;

  const throughputText = `THROUGHPUT (${asset})`;
  const inputText = `INPUT (${asset})`;

  // NOTE DO NOT REMOVE instantiating useGraph is required for calculation of throughput values. A bit hacky--find a better solution.
  // eslint-disable-next-line no-unused-vars
  const { state: graphState } = useGraph();

  const formatThroughput = (symbol, throughput) => {
    const denominatedBalance = new DenominatedBalance(symbol, throughput);

    return denominatedBalance.amt;
  };

  const throughputCryptoOut = formatThroughput('', ReportData.throughputCrypto);
  const throughputCryptoIn = formatThroughput('', ReportData.inputCrypto);

  const throughputUSDOut = formatThroughput('$', ReportData.throughputUSD);
  const throughputUSDIn = formatThroughput('$', ReportData.inputUSD);

  return (
    <>
      <Row>
        <Stat
          statNumber={ReportData.reportType}
          statText='REPORT RUN TYPE'
          statWidth='12'
          isReportType={true}
        />
      </Row>

      <Row>
        <Stat
          statNumber={ReportData.sourceType}
          statText='SOURCE TYPE'
          statWidth='12'
          isReportType={true}
        />
      </Row>

      <Row>
        <Stat
          statNumber={formatInt(ReportData.nCount)}
          statText='NODES'
          statWidth='12'
        />
      </Row>

      <Row>
        <Stat
          statNumber={formatInt(ReportData.txCount || 0)}
          statText='TRANSACTIONS'
          statWidth='12'
        />
      </Row>

      <Row>
        <Stat
          statNumber={throughputCryptoOut}
          statText={throughputText}
          statWidth='12'
        />
      </Row>

      {ReportData.throughputUSD !== undefined &&
      ReportData.throughputUSD !== null &&
      !isNaN(ReportData.throughputUSD) ? (
        <Row>
          <Stat
            statNumber={throughputUSDOut}
            statText={`THROUGHPUT (USD)`}
            statWidth='12'
          />
        </Row>
      ) : (
        <></>
      )}

      <Row>
        <Stat
          statNumber={throughputCryptoIn}
          statText={inputText}
          statWidth='12'
        />
      </Row>

      {ReportData.inputUSD !== undefined &&
      ReportData.inputUSD !== null &&
      !isNaN(ReportData.inputUSD) ? (
        <Row>
          <Stat
            statNumber={throughputUSDIn}
            statText={`INPUT (USD)`}
            statWidth='12'
          />
        </Row>
      ) : (
        <></>
      )}
    </>
  );
}
