import _ from 'lodash';
import {
  idIsNode,
  idIsComboNode,
  idIsLink,
  idIsComboLink,
} from 'helpers/itemsHelper';
import { initialState } from './initialState';
import CommaNumber from 'comma-number';
import { findIdFromComboLabel } from 'helpers/utils';
import { FlowType } from 'utils/enums';

export const graphReducer = (draft, action) => {
  const { type, payload } = action;
  let inComboMode;
  let idsToPing = [];

  const edgeDuplicates = (draft) => {
    const dupeEdges = draft.localCombinations
      ? [].concat(...Object.values(draft.ReportData.dupeEdges.byAgent))
      : draft.ReportData.dupeEdges.global;

    const edgesToAdd = dupeEdges.filter(
      (dupEdge) => !draft.ReportData.dupeEdges.removed.has(dupEdge),
    );
    const edgesToRemove = draft.ReportData.dupeEdges.removed;

    const addDupes = (d) => {
      const edgeIdArr = d.split('|');
      const source = edgeIdArr[0];
      const target = edgeIdArr[1];
      draft.viewableItems[d] = draft.items[d];
      draft.viewableItems[source] = draft.items[source];
      draft.viewableItems[target] = draft.items[target];
    };

    const removeDupes = (d) => {
      const edgeIdArr = d.split('|');
      const source = edgeIdArr[0];
      const target = edgeIdArr[1];
      delete draft.viewableItems[d];
      delete draft.viewableItems[source];
      delete draft.viewableItems[target];
    };

    edgesToAdd.forEach(addDupes);
    edgesToRemove.forEach(removeDupes);
  };

  const removeComboLabels = () => {
    Object.keys(draft.customLabelsMap).forEach((id) => {
      if (id.includes('CC')) {
        delete draft.customLabelsMap[id];
      }
    });
  };

  const accountForFlows = (draft, newViewableItems) => {
    // keep flows consistent
    Object.entries(newViewableItems).forEach(([id, item]) => {
      if (idIsLink(id)) {
        item.flow = draft.flowToggle;
        item.end2 = {
          arrow: !draft.flowToggle,
          color: item.end2.color,
        };
      }
    });
  };
  const pullTxIdsFromRelatedEdges = (s, tx_hashes) => {
    // to be replaced by pagination api call

    const subSelection = draft.viewableItems[s];

    const updateTxIdsBasedOn = (edgeIds) => {
      if (edgeIds && edgeIds.length) {
        edgeIds.forEach((e) => {
          let edge_tx_hashes;
          if (draft.viewableItems[e]) {
            edge_tx_hashes = draft.viewableItems[e].data.tx_hashes;
          }

          if (edge_tx_hashes) {
            edge_tx_hashes.forEach((txHash) => {
              tx_hashes.add(txHash);
            });
          }
        });
      }
    };

    if (idIsLink(s)) {
      if (subSelection) updateTxIdsBasedOn([s]);
    } else if (idIsComboLink(s)) {
      // TODO the links should be edge ids, not edges.
      const edges = action.comboLinks[s].links;
      updateTxIdsBasedOn(edges);
    } else if (idIsComboNode(s)) {
      const combinedNodes = action.comboNodes[s].nodes;
      const edgesIds = [];
      Object.keys(combinedNodes).forEach((nid) => {
        const n = combinedNodes[nid];
        edgesIds.push(...n.data.incomingEdges);
        edgesIds.push(...n.data.outgoingEdges);
      });
      updateTxIdsBasedOn(edgesIds);
    } else if (subSelection) {
      const outgoingEdges = subSelection.data.outgoingEdges
        ? subSelection.data.outgoingEdges
        : [];
      const edgesIds = [...subSelection.data.incomingEdges, ...outgoingEdges];
      updateTxIdsBasedOn(edgesIds);
    }
  };

  const clearSelection = () => {
    stopSelectionPing(draft);
    draft.selection = new Set();
    draft.viewableTxIds = new Set(draft.initialViewableTxIds);
    _.keys(draft.viewableItems).forEach((id) => {
      if (draft.viewableItems[id]) {
        draft.viewableItems[id]['fade'] = false;
      }
    });
  };

  const precedingEdgeType = draft.ReportData.isSourceflow
    ? 'incomingEdges'
    : 'outgoingEdges';

  const nextEdgeType = draft.ReportData.isSourceflow
    ? 'outgoingEdges'
    : 'incomingEdges';

  const nextNodeLabel = draft.ReportData.isSourceflow ? 'target' : 'source';
  const prevNodeLabel = draft.ReportData.isSourceflow ? 'source' : 'target';

  const applyCustomCombos = (action, existingComboId) => {
    const newViewableItems = _.cloneDeep(draft.viewableItems);

    const addCustomCombo = () => {
      const dataId = existingComboId
        ? existingComboId
        : `user-custom-combo-${action.kind}-${_.size(draft.customCombosMap)}`;

      // TODO remove existing related combo ids
      const newCombosArr = [];

      action.ids.forEach((id) => {
        if (
          idIsNode(id) && // Check if this is a ¬
          newViewableItems[id] // Check if node wasn't filtered out
        ) {
          newCombosArr.push(id);
        }
      });
      const setIdsOnNodes = (ids) => {
        ids.forEach((id) => {
          if (!idIsComboNode(id) && newViewableItems[id]) {
            const newData = _.cloneDeep(newViewableItems[id]);
            newData.data[dataId] = findIdFromComboLabel(dataId);
            newViewableItems[id] = _.cloneDeep(newData);
          }
        });
      };
      setIdsOnNodes(action.ids);

      if (!existingComboId) {
        const arr2 = [...draft.combine.properties, dataId];
        draft.combine.properties = arr2;
        draft.combine.level = arr2.length;
      }

      draft.customCombosMap[dataId] = newCombosArr;
      draft.viewableItems = newViewableItems;
    };

    const clearCustomCombination = () => {
      Object.entries(draft.customCombosMap).forEach(
        ([comboLabel, comboIds]) => {
          delete draft.viewableItems[comboLabel];
          draft.combine.properties = draft.combine.properties.filter(
            (c) => !c.split('-').includes('selected'),
          );
          draft.level = draft.combine.properties.length;
          comboIds.forEach((id) => (draft.viewableItems[id] = draft.items[id]));
        },
      );

      draft.customCombosMap = {};
      removeComboLabels();
      // FIXME opening up combo deselects and fades all
    };

    if (action.kind === 'selected' && action.ids.size) {
      addCustomCombo();
    } else {
      clearCustomCombination();
    }
    clearSelection();
  };

  const ping = (obj, chartRef, color) => {
    if (obj && chartRef && chartRef.current && chartRef.current.ping) {
      chartRef.current.ping(obj, {
        haloRadius: 60,
        haloWidth: 30,
        linkWidth: 30,
        time: 1000,
        repeat: 1,
        color: color,
      });
    }
  };

  const pingSelection = (draft, selection, chartRef, color) => {
    // reset all existing pings to have them in sync
    stopSelectionPing(draft);

    const obj = Array.from(selection).reduce((acc, id) => {
      acc[id] = true;
      if (draft.viewableItems[id]) draft.viewableItems[id]['fade'] = false;
      return acc;
    }, {});

    ping(obj, chartRef, color);
    draft.intervalPing = setInterval(function () {
      ping(obj, chartRef, color);
    }, 1000);
  };

  const stopSelectionPing = (draft) => {
    clearInterval(draft.intervalPing);
  };

  const fadeNonSelected = (
    draft,
    selection,
    comboNodes = false,
    comboLinks = false,
  ) => {
    let selectionWithCombos = [...selection];

    if (comboNodes)
      selection.forEach((sId) => {
        if (idIsComboNode(sId) && !_.isEmpty(comboNodes)) {
          selectionWithCombos.push(...Object.keys(comboNodes[sId].nodes));
        } else if (idIsComboLink(sId) && !_.isEmpty(comboLinks)) {
          selectionWithCombos.push(...Object.keys(comboLinks[sId].links));
        }
      });

    _.keys(draft.viewableItems).forEach((id) => {
      if (![...selectionWithCombos].includes(id) && draft.viewableItems[id]) {
        draft.viewableItems[id]['fade'] = true;
      }
    });
  };

  const triggerReformatInHistoryWithSavedState = () => {
    const newPositionState = {};
    newPositionState.customLabelsMap = draft.customLabelsMap;
    newPositionState.combine = draft.combine;
    newPositionState.customCombosMap = draft.customCombosMap;
    newPositionState.layout = draft.layout;

    if (draft.histPointer < Object.keys(draft.positions).length)
      draft.histPointer++;
    draft.positions[draft.histPointer] = {};
    draft.positions[draft.histPointer].state = newPositionState;
  };

  const createEdgeLabel = (e, endLabel) => {
    const outgoingSumScore =
      draft.viewableItems[
        e[draft.ReportData.meta.flowType > 1 ? 'target' : 'source']
      ].data.sumscore;

    const dilutedCrypto = CommaNumber(
      (parseFloat(outgoingSumScore) * e.txSum).toFixed(5),
    );
    const undilutedCrypto = CommaNumber(e.txSum.toFixed(5));
    const undilutedUSD = CommaNumber(e.txSum_in_USD.toFixed(2));
    const assetSymbol = draft.ReportData.assetInfo.icon ?? '';
    const labelTextMid = `
${assetSymbol}${undilutedCrypto}
${e.txSum_in_USD ? '$' + undilutedUSD : ''}
`;
    const throughputORinput =
      draft.ReportData.flowType > 0 ? 'inputCrypto' : 'throughputCrypto';

    const fromORto = draft.ReportData.flowType > 0 ? 'to' : 'from';

    const labelTextStopCluster = `
${((dilutedCrypto / draft.ReportData[throughputORinput]) * 100).toFixed(4)}%
${fromORto} sources
`;

    return {
      text: !endLabel ? labelTextMid : labelTextStopCluster,
      fontSize: 8,
      fontFamily: 'sans-serif',
      color: endLabel
        ? `rgb(255, ${parseInt(150 * (1 - parseFloat(outgoingSumScore)))}, 0)`
        : 'rgba(255, 255, 255, 0.6)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      bold: true,
      textAlign: 'right',
    };
  };

  switch (type) {
    case 'INITIALIZE': {
      if (action.ReportData.initialGraph.nodes.length > 100)
        draft.flowToggle = false;

      const persistedMetaState = action.ReportData.meta;
      if (persistedMetaState.layout) {
        if (persistedMetaState.layout.base)
          draft.layout = persistedMetaState.layout.base;
        if (persistedMetaState.layout.combinations) {
          const comboProps = persistedMetaState.layout.combinations.properties;
          draft.combine = {
            properties: comboProps,
            level: comboProps.length,
            combineLabelsSet: false,
          };
        }

        if (
          typeof persistedMetaState.layout.combinations.localCombinations !==
          'undefined'
        )
          draft.localCombinations =
            persistedMetaState.layout.combinations.localCombinations;

        // apply custom labels
        draft.customLabelsMap = persistedMetaState.layout.customLabelsMap
          ? persistedMetaState.layout.customLabelsMap
          : {};

        if (persistedMetaState.layout.customCombosMap) {
          draft.customCombosMap = persistedMetaState.layout.customCombosMap;
        }
      }

      // failsafe for old reports manipulated while node hiding was buggy
      if (
        persistedMetaState.hidden_nodes.length >
        action.ReportData.initialGraph.nodes.length
      ) {
        action.ReportData.hiddenIds = [];
        action.api.patchHiddenNodes(
          action.ReportData.reportIds[0], // save hidden node data to parent report
          action.ReportData.hiddenIds,
        );
      }
      draft.loading = false;
      draft.items = action.ReportData.items;
      draft.viewableItems = draft.items;
      draft.layout.top = action.ReportData.sourceIds;

      draft.ReportData = action.ReportData;

      Object.entries(draft.customLabelsMap).forEach(([id, label]) => {
        // handles filtering case
        if (draft.items[id]) {
          draft.items[id].data.custom_label = label;

          // redundant, clean up
          draft.items[id].glyphs = draft.ReportData.createGlyphs(
            draft.items[id].data,
          );
        }
      });

      // handle radix defaults
      if (
        action.ReportData.flowType === 2 &&
        action.ReportData.initialGraph.nodes.length > 1 &&
        !(
          action.ReportData.intermediaryNodes &&
          action.ReportData.intermediaryNodes.length
        )
      ) {
        const setRadixDefaultCombos = (comboName) => {
          if (!_.includes(draft.combine.properties, comboName)) {
            const arr = [...draft.combine.properties, comboName];
            draft.combine.properties = arr;
            draft.combine.level = arr.length;
            draft.layout = { ...draft.layout };
          }
        };

        // combine wallets first
        if (draft.ReportData.assetInfo.asset === 'btc') {
          setRadixDefaultCombos('COMBO_wallet');
        }

        // then combine unknowns
        setRadixDefaultCombos('COMBO_unattributed');

        // set flows = true
        draft.flowToggle = true;

        // default to right-to-left sequence layout
        draft.layout.name = 'sequential';
        draft.layout.orientation = 'right';
      }

      return;
    }
    case 'KEY_DOWN':
      draft.keysPressed[action.key] = true;
      return;
    case 'KEY_UP':
      draft.keysPressed[action.key] = false;
      return;

    case 'SHOW_MESSAGE':
      if (action.id === 'remove') {
        draft.viewableItems[Object.keys(draft.glyphMessageId)[0]].glyphs =
          draft.viewableItems[
            Object.keys(draft.glyphMessageId)[0]
          ].glyphs.filter((g) => {
            return !(
              ['LIMITED', 'ERROR', 'LOADING'].includes(g.labelType) &&
              !_.isEmpty(g.label)
            );
          });
        draft.glyphMessageId = {};
      } else if (draft.viewableItems[action.id].glyphMessage) {
        draft.viewableItems[action.id].glyphs.push(
          draft.items[action.id].glyphMessage,
        );
        draft.glyphMessageId[action.id] = true;
      }

      return;
    case 'EXPANSION_REQUESTED': {
      // TODO remove item reference in action
      if (action.direction === 'outgoing') {
        draft.items[action.id].data.canExpandOut = false;
      } else if (action.direction === 'incoming') {
        draft.items[action.id].data.canExpandIn = false;
      }
      draft.expansionsRequested += 1;
      draft.items[action.id].data.EXPANSION_progress = 1;

      const iconLookUp = {
        LOADING: 'fas fa-dot-circle',
        LIMITED: 'fas fa-adjust',
        ERROR: 'fas fa-exclamation',
      };

      const messageLookUp = {
        LOADING: 'data being fetched',
        LIMITED: '500 most recent Txs',
        ERROR: 'error while expanding',
      };

      const colorLookUp = {
        LOADING: '#753bf2',
        LIMITED: 'orange',
        ERROR: 'red',
      };

      draft.items[action.id].glyphMessage = {
        labelType: action.glyphType,
        color: colorLookUp[action.glyphType],
        angle: 45,
        radius: 50,
        label: { text: messageLookUp[action.glyphType] },
      };

      draft.items[action.id].glyphs = draft.items[action.id].glyphs.filter(
        (g) => g.labelType !== action.direction && g.labelType !== 'LOADING',
      );

      draft.items[action.id].glyphs.push({
        labelType: action.glyphType,
        color: colorLookUp[action.glyphType],
        fontIcon: { text: iconLookUp[action.glyphType], color: 'white' },
        angle: 45,
        radius: 30,
        size: action.glyphType === 'LOADING' ? 1.75 : 1,
        blink: action.glyphType === 'LOADING' && true,
      });

      draft.viewableItems[action.id] = draft.items[action.id];

      clearSelection();
      return;
    }
    case 'TIME_FILTER': {
      clearSelection();
      const itemsToViewCloned = _.cloneDeep(action.itemsToView);
      accountForFlows(draft, itemsToViewCloned);

      draft.viewableItems = itemsToViewCloned;

      for (const customComboId in draft.customCombosMap) {
        const viewableIds = draft.customCombosMap[customComboId].reduce(
          (acc, id) => {
            if (_.includes(Object.keys(itemsToViewCloned), id)) {
              acc[id] = true;
            }
            return acc;
          },
          {},
        );

        if (_.keys(viewableIds).length) {
          applyCustomCombos(
            { kind: 'selected', ids: new Set(_.keys(viewableIds)) },
            customComboId,
          );
        }
      }

      draft.labelsToggle = initialState.labelsToggle;

      // We adjust the range to GTM time because
      // the timebar displays transactions in UTC time
      // but outputs range in local time
      draft.range.start = action.range.start;
      draft.range.end = action.range.end;

      if (
        Object.keys(draft.viewableItems).filter((item) => idIsNode(item))
          .length !==
        Object.keys(draft.items).filter((item) => idIsNode(item)).length -
          draft.ReportData.hiddenIds.length
      )
        draft.timeBarOptions.sliders.color = 'rgba(44, 34, 73, 0.45)';
      else draft.timeBarOptions.sliders.color = 'rgba(44, 34, 73, 0.95)';

      draft.range.reset = false;

      return;
    }
    case 'TOGGLE_COMBO':
      draft.combine = { ...draft.combine };
      return;
    case 'DEAL_WITH_DUPES':
      edgeDuplicates(draft);
      return;
    case 'ADD_COMBINATION': {
      // Prevent double addition.
      if (_.includes(draft.combine.properties, action.value)) return;
      const arr = [...draft.combine.properties, action.value];
      draft.combine.properties = arr;
      draft.combine.level = arr.length;
      draft.layout = { ...draft.layout };

      triggerReformatInHistoryWithSavedState();

      return;
    }
    case 'ADD_CUSTOM_COMBINATION':
      applyCustomCombos(action);

      triggerReformatInHistoryWithSavedState();
      return;
    case 'TOGGLE_GLOBAL_COMBOS':
      // falsy strings are used in order to make the combo label text easier to adapt
      draft.localCombinations = !draft.localCombinations;
      draft.ReportData.localCombinations = draft.localCombinations;

      Object.entries(draft.viewableItems).forEach(([id, item]) => {
        if (idIsNode(id))
          item.data = {
            ...item.data,
            ...draft.ReportData.setCombos(item.data, draft.localCombinations),
          };
      });
      return;
    case 'REMOVE_COMBINATION_P1':
      draft.combine = { properties: [], level: 0 };
      draft.layout = { ...draft.layout };

      return;
    case 'REMOVE_COMBINATION_P2':
      draft.combine.properties = action.value;
      draft.combine.level = action.value.length;
      draft.layout = { ...draft.layout };

      draft.ReportData.dupeEdges['removed'] = new Set();
      clearSelection();

      triggerReformatInHistoryWithSavedState();
      return;
    case 'CLEAR_COMBINATION':
      draft.combine = { properties: [], level: 0 };
      for (const id of _.keys(draft.customCombosMap)) {
        for (const viewableId of draft.customCombosMap[id]) {
          if (viewableId in draft.viewableItems) {
            delete draft.viewableItems[viewableId]['data'][id];
          }
        }
      }

      draft.ReportData.dupeEdges['removed'] = new Set();

      draft.customCombosMap = {};
      draft.layout = { ...draft.layout };
      removeComboLabels();

      triggerReformatInHistoryWithSavedState();
      return;
    // Don't delete to increase speed, just set to false
    case 'ROTATE': {
      const next = new Map();
      next.set('down', 'right');
      next.set('right', 'up');
      next.set('up', 'left');
      next.set('left', 'down');
      draft.layout.orientation = next.get(draft.layout.orientation);

      draft.layout = { ...draft.layout };

      return;
    }
    case 'TOGGLE_LABELS': {
      draft.labelsToggle = !draft.labelsToggle;
      const viewableItemIds = Object.keys(draft.viewableItems);
      if (draft.labelsToggle === true) {
        for (const viewID of viewableItemIds) {
          draft.viewableItems[viewID]['label'] = draft.items[viewID].label;
          if (idIsNode(viewID))
            draft.viewableItems[viewID].glyphs = [
              ...draft.items[viewID].glyphs,
            ];
        }
      } else if (draft.labelsToggle === false) {
        for (const viewID of viewableItemIds) {
          draft.viewableItems[viewID].label = {};
          if (idIsNode(viewID)) draft.viewableItems[viewID].glyphs = [];
        }
      }
      draft.combine = { ...draft.combine };
      return;
    }

    case 'TOGGLE_EXPOSURE': {
      // Handle Flow Toggle
      draft.exposureToggle = !draft.exposureToggle;

      const endForExposureIndicator =
        draft.ReportData.flowType > 0 ? 'end1' : 'end2';

      // will probably have to remove directly
      Object.entries(draft.viewableItems).forEach(([id, item]) => {
        if (idIsLink(id)) {
          const isStopCluster =
            draft.viewableItems[
              item.data[
                draft.ReportData.meta.flowType > 1 ? 'source' : 'target'
              ]
            ].data.is_stop_cluster;

          const isExpanded = item.data.expanded;

          if (
            !isExpanded &&
            draft.exposureToggle &&
            isStopCluster &&
            draft.ReportData.throughputCrypto > 0
          ) {
            draft.viewableItems[id][endForExposureIndicator].label =
              createEdgeLabel(item.data, true);
          } else {
            draft.viewableItems[id][endForExposureIndicator].label = {
              // needed to ensure no blip happens when toggled
              // animations require a shift from one state to another
              text: '',
              fontSize: 8,
              fontFamily: 'sans-serif',
              color: 'rgba(255, 255, 255, 0.6)',
              backgroundColor: 'rgba(0, 0, 0, 0)',
            };
          }
        }
      });

      // draft.combine = { ...draft.combine };
      return;
    }
    case 'TOGGLE_FLOW':
      // Handle Flow Toggle
      draft.flowToggle = !draft.flowToggle;
      Object.entries(draft.viewableItems).forEach(([id, item]) => {
        if (idIsLink(id)) {
          draft.viewableItems[id].flow = draft.flowToggle;
        }
      });

      draft.combine = { ...draft.combine };
      return;
    case 'TRIGGER_LAYOUT':
      draft.layout = { ...draft.layout };
      return;

    case 'APPLY_SEARCH':
      if (!draft.selection.size > 0) {
        clearSelection();
        pingSelection(draft, action.idsToPing, action.chartRef, 'white');
        fadeNonSelected(draft, action.idsToPing);
      }
      return;

    case 'ADD_SELECTION': {
      idsToPing = [];

      const pullTxIds = (id) => {
        if (idIsComboNode(id) && !_.isEmpty(action.comboNodes)) {
          draft.selection.add(id);
          for (let nId of _.keys(action.comboNodes[id].nodes)) {
            if (draft.viewableItems[nId]) idsToPing.push(nId);
            pullTxIdsFromRelatedEdges(nId, draft.viewableTxIds);
          }
        } else if (idIsComboLink(id) && !_.isEmpty(action.comboLinks)) {
          draft.selection.add(id);
          for (let lId of _.keys(action.comboLinks[id].links)) {
            if (draft.viewableItems[lId]) idsToPing.push(lId);
            pullTxIdsFromRelatedEdges(lId, draft.viewableTxIds);
          }
        } else if (idIsLink(id)) {
          draft.selection.add(id);
          if (draft.viewableItems[id]) {
            idsToPing.push(id);
          }
          pullTxIdsFromRelatedEdges(id, draft.viewableTxIds);
        } else if (idIsNode(id)) {
          draft.selection.add(id);
          if (draft.viewableItems[id]) {
            idsToPing.push(id);
          }
          pullTxIdsFromRelatedEdges(id, draft.viewableTxIds);
        }
      };

      draft.viewableTxIds =
        draft.viewableTxIds.size === draft.initialViewableTxIds.size // will need a new basis for pagination
          ? new Set()
          : draft.viewableTxIds;

      action.selection.forEach((s) => {
        if (
          !draft.ReportData.hiddenIds.includes(s) ||
          idIsLink(s) ||
          idIsComboLink(s)
        ) {
          if (
            !s.includes('AGENT') &&
            !s.includes('_combonode_') &&
            !s.includes('_combolink_')
          ) {
            draft.ReportData.reportIds.forEach((id) => {
              pullTxIds(s + '_AGENT' + id);
            });
          } else {
            pullTxIds(s);
          }
        }
      });
      pingSelection(draft, idsToPing, action.chartRef, '#ffff00');
      fadeNonSelected(
        draft,
        draft.selection,
        action.comboNodes,
        action.comboLinks,
      );

      draft.contextMenu.show = false;

      return;
    }
    case 'REMOVE_SELECTION': {
      const removeFromSelection = (unSid) => {
        if (idIsComboNode(unSid) && !_.isEmpty(action.comboNodes)) {
          for (let nId of _.keys(action.comboNodes[unSid].nodes)) {
            draft.selection.delete(nId);
          }
        } else {
          draft.selection.delete(unSid);
        }

        // if deselecting the only selected node
        if (draft.selection.size === 1) {
          for (let id of _.keys(draft.viewableItems)) {
            if (idIsComboNode(id) && !_.isEmpty(action.comboNodes)) {
              for (let nId of _.keys(action.comboNodes[id].nodes)) {
                draft.viewableItems[nId]['fade'] = true;
              }
            }
            draft.viewableItems[id]['fade'] = true;
          }
        } else {
          if (idIsComboNode(unSid) && !_.isEmpty(action.comboNodes)) {
            for (let nId of _.keys(action.comboNodes[unSid].nodes)) {
              draft.viewableItems[nId]['fade'] = true;
            }
          }
          if (draft.viewableItems[unSid]) {
            draft.viewableItems[unSid]['fade'] = true;
          }
        }
      };

      removeFromSelection(action.selection);
      if (!draft.selection.size) {
        draft.viewableTxIds = new Set(draft.initialviewableTxIds);
      } else {
        draft.viewableTxIds = new Set();
        draft.selection.forEach((s) => {
          pullTxIdsFromRelatedEdges(s, draft.viewableTxIds);
        });
      }

      pingSelection(draft, draft.selection, action.chartRef, '#ffff00');

      if (!draft.selection.size) {
        _.keys(draft.viewableItems).forEach((id) => {
          draft.viewableItems[id]['fade'] = false;
        });
      }
      draft.contextMenu.show = false;

      return;
    }
    case 'CLEAR_SELECTION':
      clearSelection();
      return;
    case 'CHANGE_BASELAYOUT':
      draft.layout.name = action.name;
      triggerReformatInHistoryWithSavedState();
      return;
    case 'CHART_HOVER':
      draft.chartHover['x'] = action.x;
      draft.chartHover['y'] = action.y;
      return;
    case 'ADD_ITEMS':
      if (action.id) {
        if (action.direction === 'outgoing') {
          draft.items[action.id].data.canExpandOut = false;
        } else if (action.direction === 'incoming') {
          draft.items[action.id].data.canExpandIn = false;
        }

        draft.items[action.id].color = action.color;
        draft.items[action.id].glyphs = draft.ReportData.createGlyphs(
          draft.items[action.id].data,
        );
        draft.viewableItems[action.id] = draft.items[action.id];
        draft.expansionsRequested -= 1;
      }

      // Add new items both item lists
      _.entries(action.newItems).forEach(([id, item]) => {
        draft.items[id] = item;
        draft.viewableItems[id] = item;

        if (item.data.is_source) {
          draft.layout.top.push(id);
        }

        if (idIsLink(id)) {
          if (draft.flowToggle) {
            draft.viewableItems[id].flow = true;
            draft.viewableItems[id].end2 = {
              arrow: false,
              color: draft.viewableItems[id].end2.color,
            };
          }

          item.data.tx_hashes.forEach((hash) => {
            draft.viewableTxIds.add(hash);
            draft.initialViewableTxIds.add(hash);
          });
        }
      });
      // if info is within two days (within buffer) the timeline resets / fits
      if (draft.ReportData.max_ts - draft.ReportData.min_ts < 172800000) {
        draft.range.reset = true;
      } else {
        draft.range.start =
          action.t_min === draft.range.start.getTime() / 1000
            ? draft.range.start
            : new Date(action.t_min * 1000 - 100000000); //adds buffer (100000) to be inclusive
        draft.range.end =
          action.t_max === draft.range.end.getTime() / 1000
            ? draft.range.end
            : new Date(action.t_max * 1000 + 100000000);
      }

      clearSelection();

      return;

    case 'HIDE_ITEMS': {
      const hiddenNodeIds = [];
      const hiddenEdges = [];

      const showUnHide = {
        color: '#753bf2',
        fontIcon: { text: 'fas fa-eye-slash', color: 'white' },
        angle: 298,
        radius: 30,
      };

      const setToUnhidable = (nId) => {
        draft.items[nId].glyphs.push(showUnHide);
        draft.items[nId].data.unhidable = true;
        if (!_.isEmpty(draft.viewableItems[nId])) {
          draft.viewableItems[nId].glyphs.push(showUnHide);
          draft.viewableItems[nId].data.unhidable = true;
        }
      };

      const recurse = (nId) => {
        if (nId in hiddenNodeIds) return;

        const removeNode = () => {
          delete draft.viewableItems[nId];
          draft.items[nId].data.hidden = true;
          hiddenNodeIds.push(nId);
        };
        const removeEdge = (e) => {
          if (!(e in hiddenEdges)) {
            delete draft.viewableItems[e];
            draft.items[e].data.hidden = true;
            hiddenEdges.push(e);
          }
        };

        const removeChildNodesIfHopsLowerThanHiddenNode = () => {
          const nodeHops = parseFloat(draft.items[nId].data.hops);
          childNodes.forEach((cN) => {
            const childHops = draft.items[cN].data.hops;
            if (childHops > nodeHops) recurse(cN);
          });
        };
        if (draft.items[nId].data.is_source) {
          setToUnhidable(nId);
          return;
        }
        const edges = draft.items[nId].data[precedingEdgeType].concat(
          draft.items[nId].data[nextEdgeType],
        );
        const childNodes = [];
        edges.forEach((e) => {
          try {
            childNodes.push(draft.items[e].data.source);
            childNodes.push(draft.items[e].data.target);
            removeEdge(e);
          } catch (error) {
            console.log(`edge not found`, error);
          }
        });

        removeNode(nId);
        removeChildNodesIfHopsLowerThanHiddenNode();
      };

      action.selection.forEach((s) => {
        draft.items[s].data[precedingEdgeType].forEach((edge) =>
          setToUnhidable(draft.items[edge].data[prevNodeLabel]),
        );
        recurse(s);
      });

      draft.ReportData.hiddenIds = Array.from(
        new Set(draft.ReportData.hiddenIds.concat(hiddenNodeIds)),
      );

      action.api.patchHiddenNodes(
        draft.ReportData.reportIds[0],
        draft.ReportData.hiddenIds,
      );

      clearSelection();
      draft.ReportData.updateBasedOnTimeFilter(
        draft.viewableItems,
        draft.viewableTxIds,
      );

      return;
    }
    case 'ADD_OR_EDIT_CUSTOM_LABELS':
      // this check needed because selecting a combined node
      // also selects all nodes in that combination
      inComboMode = false;
      draft.selection.forEach((id) => {
        if (idIsComboNode(id)) {
          inComboMode = true;
          draft.customLabelsMap[id.slice(-5)] = action.newLabel;
          return;
        }

        if (!inComboMode) {
          draft.customLabelsMap[id] = action.newLabel;
          draft.items[id].data.custom_label = action.newLabel;
          draft.items[id].glyphs = draft.ReportData.createGlyphs(
            draft.items[id].data,
          );
          draft.viewableItems[id] = draft.items[id];
        }
      });

      if (inComboMode)
        draft.combine.combineLabelsSet = !draft.combine.combineLabelsSet;

      clearSelection();

      return;

    case 'SET_SHOW_CUSTOM_LABEL_EDIT':
      draft.showCustomLabelEdit = action.isOpen;
      return;

    case 'SET_SHOW_RISK_BRK_DWN':
      draft.showRiskBrkDwn = action.isOpen;
      return;
    case 'REMOVE_CUSTOM_LABELS':
      inComboMode = false;

      draft.selection.forEach((id) => {
        if (idIsComboNode(id)) {
          inComboMode = true;
          delete draft.customLabelsMap[id.slice(-5)];
          return;
        }
        if (!inComboMode) {
          draft.items[id].data.custom_label = '';
          delete draft.customLabelsMap[id];

          const glyphs = draft.ReportData.createGlyphs(draft.items[id].data);
          draft.viewableItems[id].glyphs = draft.items[id].glyphs = glyphs;
        }
      });
      if (inComboMode)
        draft.combine.combineLabelsSet = !draft.combine.combineLabelsSet;

      return;

    case 'UNHIDE_ITEMS': {
      const unhiddenNodes = [];

      const recurseThroughNextEdges = (id) => {
        // this logic should be recyclable for specific nodes
        draft.items[id].data.hidden = false;
        draft.items[id].data.unhidable = false;
        draft.items[id].flow = draft.flowToggle;
        draft.items[id].glyphs = draft.ReportData.createGlyphs(
          draft.items[id].data,
        );

        unhiddenNodes.push(id);

        const runThroughNextEdges = (nextEdges, direction) => {
          const unhideEdge = (nextEdge) => {
            // stop if non hidden edge found when not unhide all
            if (action.items && !draft.items[nextEdge].data.hidden) {
              return;
            }

            draft.items[nextEdge].data.hidden = false;
            draft.viewableItems[nextEdge] = draft.items[nextEdge];

            // to avoid loops?
            const edgeNodes = nextEdge.split('|');
            if (edgeNodes[0].split('_')[0] === edgeNodes[1].split('_')[0])
              return;

            const NodeLabel =
              direction === nextEdgeType ? nextNodeLabel : prevNodeLabel;

            const nxt = draft.items[nextEdge].data[NodeLabel];

            if (!unhiddenNodes.includes(nxt)) {
              recurseThroughNextEdges(nxt, direction);
            }
          };

          nextEdges.forEach((nextEdge) => {
            try {
              unhideEdge(nextEdge);
            } catch (error) {
              console.log(`edgeNotFound`, error);
            }
          });
        };

        const precedingEdges = draft.items[id].data[precedingEdgeType] || [];
        const nextEdges = draft.items[id].data[nextEdgeType] || [];
        runThroughNextEdges(precedingEdges, precedingEdgeType);
        runThroughNextEdges(nextEdges, nextEdgeType);
      };

      if (!action.items)
        draft.ReportData.sourceIds.forEach((id) => {
          recurseThroughNextEdges(id);
        });
      else
        action.items.forEach((id) => {
          recurseThroughNextEdges(id);
        });

      draft.ReportData.hiddenIds = draft.ReportData.hiddenIds.filter(
        (id) => !unhiddenNodes.includes(id),
      );

      action.api.patchHiddenNodes(
        draft.ReportData.reportIds[0],
        draft.ReportData.hiddenIds,
      );

      clearSelection();
      draft.ReportData.updateBasedOnTimeFilter(
        draft.viewableItems,
        draft.viewableTxIds,
      );

      return;
    }
    case 'SHOW_CONTEXT_MENU':
      draft.contextMenu.show = true;
      draft.contextMenu.x = action.x;
      draft.contextMenu.y = action.y;
      draft.contextMenu.id = action.id;
      return;

    case 'HIDE_CONTEXT_MENU':
      draft.contextMenu.show = false;
      return;

    case 'FILTER_EDGES': {
      draft.ReportData.updateBasedOnTimeFilter(draft.viewableItems);

      const getTX = (viewableItems) => {
        let viewableTxIds = new Set();

        Object.entries(viewableItems).forEach(([id, item]) => {
          if (idIsLink(id)) {
            const setAmountsAndVisibleTxBasedOnTimeRange = () => {
              let txSum = 0;
              let txSum_in_USD = 0;

              item.data.tx_hashes.forEach((hash, i) => {
                const ts = item.data.txTimeStps[i];
                const amtUSD = item.data.amts_USD[i];
                const amt = item.data.amts[i];

                if (
                  draft.range.start <= ts.time &&
                  ts.time <= draft.range.end
                ) {
                  txSum += amt;
                  txSum_in_USD += amtUSD;
                  viewableTxIds.add(hash);
                }
              });

              // (2022-03-10) Temp workaround for showing sums in entity reports
              if (draft.ReportData.flowType !== FlowType.Entity) {
                item.data.txSum = txSum;
              }
              item.data.txSum_in_USD = txSum_in_USD;
            };

            setAmountsAndVisibleTxBasedOnTimeRange();

            item.label = createEdgeLabel(item.data);

            const endForExposureIndicator =
              draft.ReportData.flowType > 0 ? 'end1' : 'end2';

            const isStopCluster =
              draft.viewableItems[
                item.data[
                  draft.ReportData.meta.flowType > 1 ? 'source' : 'target'
                ]
              ].data.is_stop_cluster;

            const isExpanded = item.data.expanded;

            if (
              !isExpanded &&
              draft.exposureToggle &&
              isStopCluster &&
              draft.ReportData.throughputCrypto > 0
            )
              item[endForExposureIndicator].label = createEdgeLabel(
                item.data,
                true,
              );

            draft.items[id].label = item.label;
          }
        });

        return viewableTxIds;
      };
      let viewableTxIds = getTX(draft.viewableItems);
      draft.viewableTxIds = viewableTxIds;
      draft.ReportData.txCount = viewableTxIds.size;

      // sets the base view for the clearSelection function
      draft.initialViewableTxIds = new Set(draft.viewableTxIds);

      //ensures source ids are never removed by accident ---
      draft.ReportData.sourceIds.forEach(
        (id) => (draft.viewableItems[id] = draft.items[id]),
      );
      return;
    }
    case 'UPDATE_POSITIONS': {
      const relayout =
        draft.histPointer > 0 &&
        typeof draft.positions[draft.histPointer] === 'object' &&
        !draft.positions[draft.histPointer].state.step &&
        draft.positions[draft.histPointer].state.combine;

      const backTrackAfterRelayout = () => {
        draft.histPointer--;
      };

      const removeAllFollowingSteps = () => {
        if (draft.histPointer < Object.keys(draft.positions).length) {
          draft.histPointer++;
        }

        const steps = Object.keys(draft.positions).map((n) => parseInt(n));
        if (draft.histPointer <= Math.max(...steps) && !relayout) {
          Object.keys(draft.positions).forEach((changeNum) => {
            if (parseInt(changeNum) > draft.histPointer)
              delete draft.positions[changeNum];
          });
        }
      };

      const logNextStep = () => {
        const positionState = {};
        positionState.combine = draft.combine;
        positionState.customLabelsMap = draft.customLabelsMap;
        positionState.layout = draft.layout;
        positionState.step = draft.histPointer;
        positionState.customCombosMap = draft.customCombosMap;

        action.positions.state = positionState;
      };

      if (relayout) backTrackAfterRelayout();

      removeAllFollowingSteps();

      draft.positions[draft.histPointer] = action.positions;

      logNextStep();

      draft.histPointer++;
      return;
    }

    case 'REVERT_POSITIONS': {
      const lastStep = Object.keys(draft.positions).length;
      if (lastStep === 1) return;

      const adaptLayouts = (lastPositionState) => {
        const nextLayout = draft.positions[draft.histPointer].state.layout;
        if (
          lastPositionState.layout.name !== nextLayout.name ||
          lastPositionState.layout.orientation !== nextLayout.orientation
        )
          draft.layout = nextLayout;
      };

      const removeLastCustomCombos = (lastPositionState) => {
        Object.entries(lastPositionState.customCombosMap).forEach(
          ([comboName, combinedNodes]) => {
            combinedNodes.forEach((cN) => {
              delete draft.viewableItems[cN].data[comboName];
              delete draft.items[cN].data[comboName];
            });
          },
        );
      };

      const addCurrentCustomCombos = () => {
        Object.entries(
          draft.positions[draft.histPointer].state.customCombosMap,
        ).forEach(([comboName, combinedNodes]) => {
          combinedNodes.forEach((cN) => {
            draft.items[cN].data[comboName] = findIdFromComboLabel(comboName);
            draft.viewableItems[cN].data[comboName] =
              findIdFromComboLabel(comboName);
          });
        });
      };

      const redo = () => {
        draft.histPointer++;

        if (draft.histPointer < lastStep) {
          const lastPositionState =
            draft.positions[draft.histPointer - 1].state;
          adaptLayouts(lastPositionState);
          draft.combine = draft.positions[draft.histPointer].state.combine;
          draft.customLabelsMap =
            draft.positions[draft.histPointer].state.customLabelsMap;
          draft.customCombosMap =
            draft.positions[draft.histPointer].state.customCombosMap;
          removeLastCustomCombos(lastPositionState);
          addCurrentCustomCombos();
        } else {
          draft.histPointer--;
        }
      };
      const undo = () => {
        draft.histPointer--;

        if (draft.histPointer >= 0) {
          if (draft.histPointer + 1 === lastStep) {
            // because the pointer is always a step ahead when at max to
            // ensure the graph receiveds a null position state,
            // the pointer neecds to jump back twice when undoing from the history head
            draft.histPointer--;
          }
          const lastPositionState =
            draft.positions[draft.histPointer + 1].state;

          adaptLayouts(lastPositionState);
          draft.customLabelsMap =
            draft.positions[draft.histPointer].state.customLabelsMap;
          draft.combine = draft.positions[draft.histPointer].state.combine;
          draft.customCombosMap =
            draft.positions[draft.histPointer].state.customCombosMap;
          removeLastCustomCombos(lastPositionState);
          addCurrentCustomCombos();
        } else {
          draft.histPointer++;
        }
      };

      if (action.direction === 'forward') redo();
      else undo();
      return;
    }
    case 'RERENDER':
      return;

    default:
      draft[type] = payload;
      return;
  }
};
