const markdown = {
  authentication_flow: `
<h1 id="authentication_flow"> ---Authentication Flow--- </h1>

Before you can start using the Elementus API, you must authenticate:

* Based on your \`EMAIL\` and \`PASSWORD\`, we provide you an account and an associated \`CLIENT_ID\` and \`CLIENT_SECRET\` 

* Run the following command to receive your \`ACCESS_TOKEN\`
~~~bash
curl 
  --request POST 
  --url 'https://elementus.auth0.com/oauth/token' 
  --header 'content-type: application/x-www-form-urlencoded' 
  --data grant_type=password 
  --data username=<EMAIL> 
  --data password=<PASSWORD> 
  --data audience=https://risk-api.elementus.io 
  --data client_id=<CLIENT_ID> 
  --data client_secret=<CLIENT_SECRET>
~~~

* The response of this request contains an \`access_token\`, which you need to include as an \`Authorization\` header in all subsequent requests. The following sections contain demonstrations of this usage.

* When the token expires, repeat the authentication request to retrieve a new \`ACCESS_TOKEN\`.
`,

  risk_provenance: `
<h1 id="risk_provenance">  ---Risk Provenance---</h1>

For a given Bitcoin/Ethereum address and crypto asset of interest (BTC, ETC, or ERC20 token), this retrieves the risk profile based on the provenance of funds the address has received. 

**URL** : \`https://risk-api.elementus.io/risk-scoring/address-risk-provenance\`

**Method** : \`POST\`

**Auth required** : YES

**Body:**

Name | Type | Required | Description
------------ | ------------ | ------------ | ------------
address | STRING | Yes | Bitcoin or Ethereum address
token_contract_address | STRING | NO | for ERC-20 tokens, please supply the smart contract address

<br/>

**Returns:** 

Name | Type | Description
------------ | ------------ | ------------
blockchain | OBJECT | address blockchain
blockchain[direction] | OBJECT | fund flow direction analyzed
blockchain[direction].riskScore | STRING | quantitative risk assessment, ranges from 0 to 1
blockchain[direction].riskScoreBreakdown | OBJECT | a breakdown of risk attributed to each category
blockchain[direction].riskSources | OBJECT | each entry is an address that contributed to the risk score
blockchain[direction].riskSources[address] | OBJECT | the address's risk category, entity type, and entity
blockchain[direction].clusters | OBJECT | each entry contains risk scores and category breakdowns for clusters contributing risky funds

<br/>

<br/>

**Example request:**

~~~bash
curl 
  --request POST 'https://risk-api.elementus.io/risk-scoring/address-risk-provenance'
  --header 'Authorization: Bearer <ACCESS_TOKEN>'
  --header 'Content-Type: application/json'
  --data-raw '{
      "address": "0xd90e2f925da726b50c4ed8d0fb90ad053324f31b"
  }'
~~~

<br/>

**Example response:**

~~~json
{
    "eth": {
        "INCOMING": {
            "riskContribution": {
                "0xd90e2f925da726b50c4ed8d0fb90ad053324f31b": "1"
            },
            "riskScore": "1",
            "riskScoreBreakdown": {
                "cybercrime": "0",
                "high risk jurisdiction": "0",
                "darknet commerce": "0",
                "gambling": "0",
                "terrorism finance": "0",
                "mining": "0",
                "sanctions": "0",
                "obfuscation": "1"
            },
            "riskSources": {
                "0xd90e2f925da726b50c4ed8d0fb90ad053324f31b": {
                    "riskCategory": "obfuscation",
                    "entityType": "mixer",
                    "entity": "tornado.cash"
                }
            },
            "clusters": {
                "0xd90e2f925da726b50c4ed8d0fb90ad053324f31b": {
                    "riskContribution": {
                        "0xd90e2f925da726b50c4ed8d0fb90ad053324f31b": "1"
                    },
                    "riskScore": "1",
                    "riskScoreBreakdown": {
                        "cybercrime": "0",
                        "high risk jurisdiction": "0",
                        "darknet commerce": "0",
                        "gambling": "0",
                        "terrorism finance": "0",
                        "mining": "0",
                        "sanctions": "0",
                        "obfuscation": "1"
                    }
                }
            }
        }
    },
    "*scoreMinThreshold": 0.005
}
~~~`,
  risk_provenance_flag: `
<h1 id="risk_provenance_flag">  ---Risk Provenance Flag---</h1>

This retrieves an abbreviated version of the __Address Risk Provenance__ response data.

**URL** : \`https://risk-api.elementus.io/risk-scoring/address-risk-provenance-flag\`

**Method** : \`POST\`

**Auth required** : YES

**Body:**

Name | Type | Required | Description
------------ | ------------ | ------------ | ------------
address | STRING | Yes | Bitcoin or Ethereum address
token_contract_address | STRING | NO | for ERC-20 tokens, please supply the smart contract address

<br/>

**Returns:**

Name | Type | Description
------------ | ------------ | ------------
blockchain | OBJECT | address blockchain
blockchain[direction] | OBJECT | fund flow direction analyzed
blockchain[direction].riskScore | STRING | quantitative risk assessment, ranges from 0 to 1
blockchain[direction].riskScoreBreakdown | OBJECT | a breakdown of risk attributed to each category
blockchain[direction].clusters | OBJECT | each entry contains risk scores and category breakdowns for clusters contributing risky funds

<br/>

**Example request:**

~~~bash
curl 
  --request POST 'https://risk-api.elementus.io/risk-scoring/address-risk-provenance-flag'
  --header 'Authorization: Bearer <ACCESS_TOKEN>'
  --header 'Content-Type: application/json'
  --data-raw '{
      "address": "0xd90e2f925da726b50c4ed8d0fb90ad053324f31b"
  }'
~~~

<br/>

**Example response:**

~~~json
{
    "eth": {
        "INCOMING": {
            "riskScore": "1",
            "riskScoreBreakdown": {
                "cybercrime": "0",
                "high risk jurisdiction": "0",
                "darknet commerce": "0",
                "gambling": "0",
                "terrorism finance": "0",
                "mining": "0",
                "sanctions": "0",
                "obfuscation": "1"
            },
            "clusters": {
                "0xd90e2f925da726b50c4ed8d0fb90ad053324f31b": {
                    "riskScore": "1",
                    "riskScoreBreakdown": {
                        "cybercrime": "0",
                        "high risk jurisdiction": "0",
                        "darknet commerce": "0",
                        "gambling": "0",
                        "terrorism finance": "0",
                        "mining": "0",
                        "sanctions": "0",
                        "obfuscation": "1"
                    }
                }
            }
        }
    },
    "*scoreMinThreshold": 0.005
}
~~~`,
  risk_profile: `
<h1 id="risk_profile"> ---Risk Profile--- </h1>

This retrieves direct risk associations of a Bitcoin or Ethereum address.

**URL** : \`https://risk-api.elementus.io/risk-scoring/address-risk-profile\`

**Method** : \`POST\`

**Auth required** : YES

**Body:**

Name | Type | Required | Description
------------ | ------------ | ------------ | ------------
address | STRING | Yes | Bitcoin or Ethereum address

<br/>

**Returns:** 

Name | Type | Description
------------ | ------------ | ------------
address | STRING | input address
asset | STRING | input address blockchain
riskCategory | STRING | risk category (cybercrime, obfuscation, etc.)
entity | STRING | named entity that controls the address
entityType | STRING | type of entity that controls the address
beneficialOwner | STRING | effective address owner
beneficialOwnerAccountType | STRING | account type of effective address owner

<br/>

**Example request:**

~~~bash
curl 
  --request POST 'https://risk-api.elementus.io/risk-scoring/address-risk-profile'
  --header 'Authorization: Bearer <ACCESS_TOKEN>'
  --header 'Content-Type: application/json'
  --data-raw '{
      "address": "38NCGnEXis2v7AJyB6kNrairs8jk6TyKBz"
  }'
~~~

<br/>

**Example response:**

~~~json
{
    "address": "38NCGnEXis2v7AJyB6kNrairs8jk6TyKBz",
    "asset": "btc",
    "riskCategory": "Uncategorized",
    "entity": "okex.com",
    "entityType": "exchange",
    "beneficialOwner": "pickcoin.pro",
    "beneficialOwnerAccountType": "exchange"
}
~~~`,
  attributions: `
<h1 id="attributions"> ---Attributions--- </h1>

This retrieves attribution data for a given address.

**URL** : \`https://risk-api.elementus.io/attributions/<ADDRESS>\`

**Method** : \`GET\`

**Auth required** : YES

**Returns:**

Name | Type | Description
------------ | ------------ | ------------
metadata | OBJECT | contains info about the response
metadata.address | STRING | the address associated with the attribution data
data | OBJECT | attributions data
data.enttiy | STRING / NULL | the entity associated with the address
data.entity_type | STRING / NULL | the entity's classification
data.address_type | STRING / NULL | the address's type

<br/>

**Example request:**

~~~bash
curl
  --request GET 'https://risk-api.elementus.io/attributions/3MN8nYo1tt5hLxMwMbxDkXWd7Xu522hb9P'
  --header 'Authorization: Bearer <ACCESS_TOKEN>'
~~~

<br/>

**Example response:**

~~~json
{
  "metadata": {
      "address": "3MN8nYo1tt5hLxMwMbxDkXWd7Xu522hb9P",
      "version": "0.1"
  },
  "data": {
      "entity": "Li Jiadong",
      "entity_type": "ofac sanctions",
      "address_type": "P2SH-P2WPKH"
  }
}
~~~`,
  Risk_Categories: `
<h1 id="risk_provenance_flag">  ---Risk Categories---</h1>
<br/>
 <h3>In general, we classify risk categories by the functional type of each entity as follows: </h3>
<br/>

Entity Type | Risk Category
------------ | ------------
cybercrime | cybercrime
csam | cybercrime
ransomware | cybercrime
darknet commerce | darknet commerce
gambling | gambling
investment scheme | gambling
miner | mining
mining pool | mining
mixer | obfuscation
changer | obfuscation
ofac sanctions | sanctions
non-us sanctions | sanctions
terrorism finance | terrorism finance
terrorism | terrorism finance
cloud mining | gambling
mining hardware | mining
hyip | gambling

<br/>
<br/>
<h3> However, we classify an entity under the "sanctions" risk category as long as it is associated with sanctioned actors like the Iranian government, even when the entity is playing broader function such as mining.</h3>
<br/>

Entity | Risk Category
------------ | ------------
aryana.io | sanctions
arzpaya.com | sanctions
bitcoin.ir | sanctions
coinex.ir | sanctions
coin-hash.plus | sanctions
excoino.com | sanctions
exir.io | sanctions
exnovin.io | sanctions
farhadexchange.net | sanctions
farhadmarket.com | sanctions
iminer.net | sanctions
iranicard.com | sanctions
jeeb.io | sanctions
jibitex.com | sanctions
keepchange.io | sanctions
nobitex.ir | sanctions
payment24.ir | sanctions
paywm.net | sanctions
ramzinex.com | sanctions
wallex.ir | sanctions
...
    `,
};

export default markdown;
