import _, { isEmpty } from 'lodash';
import React, {
  useRef,
  useEffect,
  useState,
  useReducer,
  useContext,
  createContext,
  useCallback,
  useMemo,
} from 'react';
import CommaNumber from 'comma-number';
import { createContextStore } from 'easy-peasy';
import produce from 'immer';
import {
  initialize,
  addItems,
  expansionRequested,
  unhideItems,
  addSelection,
  clearSelection,
  toggleCombo,
  timeFilter,
  hideContextMenu,
  keyDown,
  keyUp,
  showContextMenu,
  showMessage,
  revertPositions,
  dealWithDupes,
} from './actions';

import * as d3 from 'd3';

import ForensicAnalysis from '../../models/forensicAnalysis';
import { initialState, initialContext } from './initialState';
import { graphReducer } from './reducer';
import { idIsNode, idIsComboNode } from 'helpers/itemsHelper';
import Color from 'color';
import ElementusAPIService from 'services/elementus_api.service';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from 'ReportData/ReportDataStore';
import { updatePositionSequence } from './chartLogic';
import { FlowType } from 'utils/enums';

const curriedGraphReducer = produce(graphReducer);
const GraphContext = createContextStore(initialContext);
const StateContext = createContext();
const DispatchContext = createContext();

const GraphProvider = (props) => {
  const [state, dispatch] = useReducer(curriedGraphReducer, initialState);
  const { state: globalState } = useContext(store);
  const { getAccessTokenSilently } = useAuth0();
  const [_childrenLoading, set_childrenLoading] = useState(false);
  const chartRef = useRef({});
  const timeBarRef = useRef({});

  const [_expandedNodes, set_expandedNodes] = useState({});

  const api = useMemo(
    () => new ElementusAPIService(props.ReportData.token),
    [props.ReportData.token],
  );

  const comboNodesRef = useRef({});
  const comboLinksRef = useRef({});
  let comboNodes = comboNodesRef.current;
  let comboLinks = comboLinksRef.current;

  useEffect(() => {
    (async () => {
      try {
        dispatch(initialize(props.ReportData, api));
      } catch (e) {
        console.log('error initializing', e);
      }
    })();
  }, [props.ReportData, api]);

  useEffect(() => {
    // this looks like it would cause a memory leak
    comboLinks.current = comboLinks;
    comboNodes.current = comboNodes;
  }, [comboLinks, comboNodes]);

  useEffect(() => {
    if (state.range.start && state.range.end) {
      dispatch({ type: 'FILTER_EDGES' });
    }

    const childAgentsNotLoaded =
      state.ReportData &&
      state.ReportData.reportIds &&
      state.ReportData.meta.child_agents &&
      state.ReportData.reportIds.length - 1 <
        state.ReportData.meta.child_agents.length;

    // load after initial time ranges are set, and only load once based on childrenLoading var
    if (childAgentsNotLoaded && !_childrenLoading) {
      set_childrenLoading(true);
      const ReportData = state.ReportData;
      const child_agents = ReportData.meta.child_agents;

      if (child_agents) {
        for (let i = 0; i < child_agents.length; i++) {
          (async () => {
            const cR = child_agents[i];
            const childRepRes = await api.getForensicAnalysis(
              cR,
              globalState.userParameters,
            );
            const normalizedReportData =
              ForensicAnalysis.normalizeReportData(childRepRes);
            const childAgent =
              ForensicAnalysis.markAgentResponses(normalizedReportData);
            ReportData.reportIds.push(childAgent.meta._id);
            await updateGraph(childAgent);
          })();
        }
      }
    } else if (!childAgentsNotLoaded) set_childrenLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.range.start,
    state.range.end,
    state.sourceLookup,
    _childrenLoading,
    state.ReportData,
    api,
    // globalState.userParameters, // adding this causes unit tests to fail
  ]);

  // using state in any of these functions will create a hit on performance
  const onKeyDown = useCallback(
    (e) => {
      // Don't use this for 'keys' that can repeat.
      dispatch(keyDown(e.key));

      if (
        (e.ctrlKey && e.key === 'z') ||
        (!e.shiftKey && e.metaKey && e.key === 'z')
      ) {
        dispatch(revertPositions('back'));
      }
      if (
        (e.shiftKey && e.ctrlKey && e.key === 'Z') ||
        (e.shiftKey && e.metaKey && e.key === 'Z') ||
        (e.shiftKey && e.metaKey && e.key === 'z') ||
        (e.ctrlKey && e.key === 'y') ||
        (e.metaKey && e.key === 'y')
      ) {
        dispatch(revertPositions('forward'));
      }
    },
    [dispatch],
  );

  const onKeyUp = useCallback(
    (e) => {
      // Don't use this for keys that can repeat
      dispatch(keyUp(e.key));
    },
    [dispatch],
  );

  const onHover = useCallback(
    ({ id, sub, subItem }) => {
      if (sub == null && !isEmpty(state.glyphMessageId)) {
        dispatch(showMessage('remove'));
      }
      if (subItem && state.items[id] && state.items[id].glyphMessage) {
        dispatch(showMessage(id, subItem));
      }
    },
    [state],
  );

  const onChange = useCallback(
    ({ positions, selection, why }) => {
      if (positions) updatePositionSequence(positions, state, dispatch, why);

      // handle area cursor drag
      if (!_.isEmpty(selection)) {
        dispatch(
          addSelection(
            Object.keys(selection),
            comboNodes,
            comboLinks,
            chartRef,
          ),
        );
      }
      return false;
    },
    [dispatch, comboNodes, comboLinks, chartRef, state],
  );

  // Used for situations when a user either clicks or touches the chart.
  const onClick = useCallback(
    ({ id }) => {
      if (id && !state.selection.has(id)) {
        dispatch(addSelection([id], comboNodes, comboLinks, chartRef));
      } else if (!id) {
        dispatch(clearSelection());
      }
    },
    [dispatch, state.selection, comboNodes, comboLinks],
  );

  const onDragMove = useCallback(() => {}, []);

  const onPointerDown = useCallback(
    ({ button, id }) => {
      if (
        !id &&
        button === 0 &&
        !state.keysPressed.Control &&
        state.contextMenu.show
      ) {
        dispatch(hideContextMenu());
        return false;
      }
    },
    [dispatch, state.keysPressed.Control, state.contextMenu.show],
  );

  const onPointerUp = useCallback(() => {}, []);

  const onPointerMove = useCallback(() => {}, []);

  const onContextMenu = useCallback(
    ({ id, x, y, subItem }) => {
      if (id) {
        dispatch(addSelection([id], comboNodes, comboLinks, chartRef));
        dispatch(showContextMenu(id, x, y, subItem));
        return true;
      } else if (state.selection) {
        dispatch(showContextMenu(id, x, y, subItem));
        return true;
      }
    },
    [dispatch, comboNodes, comboLinks, state.selection],
  );

  const onDoubleClick = async ({ id }) => {
    if (idIsNode(id) && state.items[id].data.unhidable) {
      dispatch(unhideItems(api, [id]));
    } else if (idIsComboNode(id) && comboNodes[id] && !state.items[id]) {
      comboNodes[id].open = !comboNodes[id].open;
      dispatch(toggleCombo());
      dispatch(clearSelection());
    }
  };

  const onTimeBarChange = useCallback(
    (change) => {
      set_expandedNodes({});
      const bypass = state.ReportData.flowType === FlowType.Entity;
      dispatch(timeFilter(change, state, bypass));
      return false;
    },
    [state],
  );

  const onCombineNodes = useCallback(
    ({ nodes, combo, id, setStyle }) => {
      const node = state.items[_.keys(nodes)[0]];
      let glyphs = [];
      const combinationExpandableGlyph = {
        color: '#753bf2',
        fontIcon: { text: 'fas fa-expand-arrows-alt', color: 'white' },
        angle: 90,
        radius: 30,
      };

      const nodeArray = Object.values(nodes);
      if (nodeArray.length) {
        let isCommonEntity = true;
        let entity = nodeArray[0].data.entity;
        for (let i = 1; i < nodeArray.length; i++) {
          if (nodeArray[i].data.entity !== entity) {
            isCommonEntity = false;
            break;
          }
        }
        if (entity && isCommonEntity) {
          glyphs.push({
            color: 'grey',
            size: 1,
            label: {
              text: entity,
            },
            angle: 0,
          });
        }
      }

      const nodeColor =
        node.data.id === 'intermediary' ? '#bbafa2' : node.color;

      let color;
      const open =
        comboNodes[id] && comboNodes[id].open ? comboNodes[id].open : false;
      if (open) {
        color = Color(nodeColor).alpha(0.1).rgb().toString();
      } else {
        color = nodeColor;
        if (state.labelsToggle) {
          glyphs.push(combinationExpandableGlyph);
        }
      }

      let labelTextWithoutAgentId;

      const comboIsCustom = id.includes('_CC');

      if (comboIsCustom) {
        const CCID = id.slice(-1);
        labelTextWithoutAgentId = `custom_group_CC-${CCID}`;
      } else {
        const labelTextWithoutSuffix = id.split('_')[id.split('_').length - 1];
        // not relevant for custom combos
        labelTextWithoutAgentId =
          labelTextWithoutSuffix.split(/-l-|-lfixed-/)[0];
      }

      const firstInstanceOfType = Object.values(nodes)[0].data;

      let stylingPerCombo = {
        open,
        size: Math.sqrt(Math.sqrt(Object.keys(nodes).length)),
        color,
        shape: node.shape,
        glyphs,
        arrange: 'lens',
      };

      stylingPerCombo['label'] = {
        text: labelTextWithoutAgentId,
        fontSize: 10,
        fontFamily: 'sans-serif',
        color: 'white',
        center: false,
        backgroundColor: 'rgba(0,0,0,0.0)',
      };

      stylingPerCombo['fontIcon'] =
        node.data.id === 'intermediary'
          ? state.ReportData.createIcons('intermediary')
          : state.ReportData.createIcons(
              firstInstanceOfType.entitytype.toLowerCase(),
            );

      comboNodes[id] = {
        id,
        nodes,
        combo,
        stylingPerCombo,
        open,
      };
      setStyle(stylingPerCombo);
    },
    [state.items, state.labelsToggle, comboNodes, state.ReportData],
  );

  const removedDupeEdgesExist =
    state.ReportData.dupeEdges && state.ReportData.dupeEdges['removed'].size;

  useEffect(() => {
    // manage all logic in the same place for dupes
    if (
      state.ReportData.dupeEdges &&
      Object.values(state.ReportData.dupeEdges.byAgent)[0]
    )
      dispatch(dealWithDupes());
    return () => {};
  }, [removedDupeEdgesExist, state.ReportData.dupeEdges]);

  const onCombineLinks = useCallback(
    ({ id, id1, id2, links, setStyle }) => {
      if (isEmpty(links)) return { summary: false };
      if (!comboLinks[id]) comboLinks[id] = { id, id1, id2, links };

      const removeDupes = () => {
        const dupeEdges = state.localCombinations
          ? [].concat(...Object.values(state.ReportData.dupeEdges.byAgent))
          : state.ReportData.dupeEdges['global'];

        Object.keys(links).forEach((lId) => {
          if (dupeEdges.includes(lId)) {
            delete links[lId];
            // condition deletion based on type and global flag
            state.ReportData.dupeEdges['removed'].add(lId);
          }
        });
      };

      const sumAmtsOnEdges = () => {
        let sum = 0.0;
        let sumUSD = 0;
        let sumScored = 0;
        let linkIds = _.keys(links);
        const linkItems = {};

        for (let linkId of linkIds) {
          const link = links[linkId];
          linkItems[linkId] = link;

          const outgoingSumScore =
            state.viewableItems[
              link.data[state.ReportData.flowType > 1 ? 'target' : 'source']
            ].data.sumscore;

          const amountsToAdd = link.data.amts
            ? link.data.amts.reduce((sum, amt) => sum + amt, 0)
            : 0.0;

          sum += amountsToAdd;
          sumScored += amountsToAdd * outgoingSumScore;
          if (link.data.amts_USD) {
            sumUSD += link.data.amts_USD
              ? link.data.amts_USD.reduce((sum, amt) => sum + amt, 0)
              : 0;
          }
        }
        return { sum, sumUSD, sumScored };
      };

      removeDupes();

      const { sum, sumUSD } = sumAmtsOnEdges();

      const scale = d3
        .scaleLog()
        .domain([state.ReportData.min_tx_amt, state.ReportData.max_tx_amt])
        .range([1, 50]);

      const styleToBeSet = {
        end1: {
          color: 'grey',
        },
        end2: {
          color: 'grey',
          arrow: true,
        },
        label: state.labelsToggle
          ? {
              text: ` ${state.ReportData.assetInfo?.icon ?? ''}${CommaNumber(
                sum.toFixed(5),
              )}${
                sumUSD
                  ? ' ($' + CommaNumber(parseFloat(sumUSD).toFixed(2)) + ')  '
                  : ''
              }`,
              fontFamily: 'sans-serif',
              fontSize: 10,
              color: 'rgba(255, 255, 255, 0.6)',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              bold: true,
            }
          : '',
        width: scale(sum),
        flow: state.flowToggle,
      };

      const relevantEnd = state.ReportData.flowType > 0 ? 'end1' : 'end2';
      styleToBeSet[relevantEnd].label = {};
      setStyle(styleToBeSet);
    },
    [
      state.flowToggle,
      state.labelsToggle,
      comboLinks,
      state.ReportData.assetInfo?.icon,
      state.ReportData.dupeEdges,
      state.ReportData.flowType,
      state.ReportData.max_tx_amt,
      state.ReportData.min_tx_amt,
      state.localCombinations,
      state.viewableItems,
    ],
  );

  useEffect(() => {
    try {
      const anAsyncer = async (
        id,
        layout,
        combine,
        customLabelsMap,
        customCombosMap,
        asset,
      ) => {
        combine.localCombinations = state.localCombinations;
        try {
          delete layout.top;
          await api.patchLayout(id, {
            base: layout,
            combinations: combine,
            customLabelsMap,
            customCombosMap,
            asset,
          });
        } catch (e) {
          console.error(e);
        }
      };
      anAsyncer(
        props.id,
        _.cloneDeep(state.layout),
        _.cloneDeep(state.combine),
        _.cloneDeep(state.customLabelsMap),
        _.cloneDeep(state.customCombosMap),
        state.ReportData.assetInfo?.asset,
      );
    } catch (e) {
      console.error(e);
    }
  }, [
    state.layout,
    state.combine,
    state.customLabelsMap,
    state.localCombinations,
    state.range,
    getAccessTokenSilently,
    props.id,
    api,
    state.ReportData.assetInfo?.asset,
    state.customCombosMap,
  ]);

  const updateGraph = useCallback(
    (res, nodeId = false, direction = false) => {
      let color = false;
      if (nodeId) {
        color = Color(state.items[nodeId].data.color).alpha(1).rgb().toString();
      }

      state.ReportData.colorSourceIdsByAgent();
      // update to tx
      const { newNodes, newEdges } = state.ReportData.updateReportData(
        nodeId ? [...res.nodes, { ...state.items[nodeId].data }] : res.nodes,
        res.edges,
        res.transactions ? res.transactions : res.transfers,
      );

      const newItems = state.ReportData.applyTransformationsForRegraph(
        newNodes,
        newEdges,
      );

      const accountForTimeStampsofExpandedNodes = () => {
        let timestampsFromExpandedEdges = [];
        res.edges.forEach((e) => {
          timestampsFromExpandedEdges = [
            ...timestampsFromExpandedEdges,
            ...e['txTimeStps'],
          ];
        });

        const expTxTimeMin = Math.min(
          ...timestampsFromExpandedEdges,
          state.ReportData.initialGraph.nodes.length - res.nodes.length === 1
            ? state.ReportData.min_ts
            : state.range.start.getTime() / 1000,
        );
        const expTxTimeMax = Math.max(
          ...timestampsFromExpandedEdges,
          state.ReportData.initialGraph.nodes.length - res.nodes.length === 1
            ? state.ReportData.max_ts
            : state.range.end.getTime() / 1000,
        );
        return { expTxTimeMin, expTxTimeMax };
      };

      const { expTxTimeMin, expTxTimeMax } =
        accountForTimeStampsofExpandedNodes();
      dispatch(
        addItems(
          nodeId,
          color,
          newItems,
          expTxTimeMin,
          expTxTimeMax,
          direction,
        ),
      );

      set_expandedNodes((eN) => (eN = { ...eN, ...newItems }));
      // only adapts timeline to new items, not 'all'
      timeBarRef.current.fit({
        ...state.viewableItems,
        ..._expandedNodes,
        ...newItems,
      });
    },
    [state, timeBarRef, _expandedNodes],
  );

  const expandNode = useCallback(
    async (nodeId, direction) => {
      let res = '';

      dispatch(expansionRequested(nodeId, 'LOADING', direction));
      try {
        // hack to avoid having a pipe in the string for the api's bash script...
        // uses a non-special character
        const buildClusterEdge = (x) => {
          const sourceClst = x.split('|')[0].split('_')[0];
          const targetClst = x.split('|')[1].split('_')[0];
          return `${sourceClst}^${targetClst}`;
        };

        const existing_edge_ids = state.items[nodeId].data.incomingEdges
          .map(buildClusterEdge)
          .concat(state.items[nodeId].data.outgoingEdges.map(buildClusterEdge))
          .join();

        res = await api.getNodeNextHop(
          state.items[nodeId].data.agentId,
          nodeId,
          state.items[nodeId].data.sumscore,
          direction,
          existing_edge_ids,
          state.items[nodeId].data.hops,
        );

        if (res.statusCode === 500) {
          console.log('We actually never get here as an error.');
        } else {
          if (res.edges.length === 0 || res.edges[0].expansion_limit_reached) {
            dispatch(expansionRequested(nodeId, 'LIMITED', direction));
          } else {
            const normalizedReportData =
              ForensicAnalysis.normalizeReportData(res);
            const respMarked = ForensicAnalysis.markAgentResponses(
              normalizedReportData,
              state.items[nodeId].data.agentId,
            );
            updateGraph(respMarked, nodeId, direction);
          }
        }
      } catch (e) {
        // API error for expansion.
        console.log('e', e);
        dispatch(expansionRequested(nodeId, 'ERROR', direction));
      }
    },
    [state, api, updateGraph],
  );

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider
        value={{
          state,
          chartRef,
          timeBarRef,
          comboNodes,
          onChange,
          onDragMove,
          onContextMenu,
          onCombineNodes,
          onHover,
          onCombineLinks,
          onTimeBarChange,
          onClick,
          onPointerDown,
          onPointerUp,
          onPointerMove,
          onDoubleClick,
          onKeyDown,
          onKeyUp,
          expandNode,
          updateGraph,
        }}
      >
        <GraphContext.Provider {...props} />
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useGraph = () => {
  const dispatch = useContext(DispatchContext);
  const {
    state,
    chartRef,
    timeBarRef,
    comboNodes,
    onChange,
    onDragMove,
    onContextMenu,
    onHover,
    onCombineNodes,
    onCombineLinks,
    onTimeBarChange,
    onClick,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onDoubleClick,
    onKeyDown,
    onKeyUp,
    expandNode,
    updateGraph,
  } = useContext(StateContext);
  return {
    state,
    chartRef,
    timeBarRef,
    comboNodes,
    dispatch,
    onChange,
    onDragMove,
    onContextMenu,
    onHover,
    onCombineNodes,
    onCombineLinks,
    onTimeBarChange,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onClick,
    onDoubleClick,
    onKeyDown,
    onKeyUp,
    expandNode,
    updateGraph,
  };
};

export { GraphProvider, GraphContext };
