const COLOR_DEFAULT = '#333';
const COLOR_FEATURED = '#aaa';

const createSeries = (data, featured) => {
  return Object.entries(data).map((entry) => {
    const color = entry[0] === featured ? COLOR_FEATURED : COLOR_DEFAULT;
    return {
      text: entry[0],
      values: entry[1],
      ...addStylingToSeriesItem(color),
    };
  });
};

const addStylingToSeriesItem = (color) => {
  return {
    'line-color': color,
    'legend-item': {
      'background-color': color,
      borderRadius: 5,
      'font-color': 'white',
    },
    'legend-marker': {
      visible: false,
    },
    marker: {
      'background-color': color,
      shadow: 0,
      border: 'none',
    },
    'highlight-marker': {
      size: 6,
      'background-color': color,
    },
  };
};

const getCrosshairXSettings = () => {
  return {
    'line-color': '#efefef',
    'plot-label': {
      'border-radius': '5px',
      'border-width': '1px',
      'border-color': '#f6f7f8',
      padding: '10px',
      'font-weight': 'bold',
    },
    'scale-label': {
      'font-color': '#000',
      'background-color': '#f6f7f8',
      'border-radius': '5px',
    },
  };
};

const getPlotSettings = () => {
  return {
    'tooltip-text': '%t views: %v<br>%k',
    shadow: 0,
    'line-width': '2px',
  };
};

const legendSettings = () => {
  return {
    layout: 'float',
    'background-color': 'none',
    'border-width': 0,
    shadow: 0,
    align: 'center',
    'adjust-layout': true,
    'toggle-action': 'remove',
    item: {
      padding: 7,
      marginRight: 17,
      cursor: 'hand',
    },
  };
};

export const lineChartStyles = {
  palette: {
    line: [
      ['#6A36DC', '#00BAF2', '#00BAF2', '#00a7d9'],
      ['#753BF2', '#E80C60', '#E80C60', '#d00a56'],
      ['#7D72DA', '#9B26AF', '#9B26AF', '#8b229d'],
      ['#CCC9E7', '#E2D51A', '#E2D51A', '#E2D51A'],
      ['#2B2147', '#FB301E', '#FB301E', '#e12b1b'],
      ['#330036', '#00AE4D', '#00AE4D', '#00AE4D'],
    ],
  },
  graph: {
    backgroundColor: '#2a2146',
  },
};

export const createTimeSeriesData = (title, data, featured) => {
  return {
    type: 'line',
    utc: true,
    title: {
      text: title,
      'font-size': '24px',
      'adjust-layout': true,
      'font-color': 'white',
    },
    plotarea: {
      margin: 'dynamic 45 60 dynamic',
    },
    legend: legendSettings(),
    'scale-x': {
      transform: {
        type: 'date',
        all: '%Y',
      },
    },
    'crosshair-x': getCrosshairXSettings(),
    tooltip: {
      visible: false,
    },
    plot: getPlotSettings(),
    series: createSeries(data, featured),
  };
};
