import _ from 'lodash';
import { handleInternalAttributions } from 'helpers/utils';
import Gradient from 'javascript-color-gradient';
import { ReportType } from 'utils/enums';
import { colors } from './colors';
import {
  clusterOf,
  getAssetConversion,
  getReportSourceType,
  getReportType,
  isSourceflow,
} from 'helpers/reportDataTransform';
import Transaction from 'models/transaction';
import { floatToBigNumNotation } from 'utils/format';
import Counterparty from 'models/counterparty';
import { IAssetInfo } from 'typings/interfaces';

export default class ReportDataClass {
  colorMap: any;
  meta: any;
  flowType: any;
  localCombinations: any;
  reportType: any;
  sourceType: any;
  entityName: any;
  token: any;
  reportIds: any;
  activeNodes: any;
  nodesWithBal: any;
  intermediaryNodes: any;
  sourceIds: any;
  unqTransferEnumerator: any;
  walletEnumerator: any;
  baseDenomination: any;
  initialGraph: any;
  transactions: any;
  isCPDataAvailable: any;
  hiddenIds: any;
  wallets: any;
  singleAddresses: any;
  unknownType: any;
  clusterLookUp: any;
  edgeClusterLookUp: any;
  dupeEdges: any;
  UniqueIDLookUp: any;
  filterBounds: any;
  max_ts: any;
  min_ts: any;
  max_tx_amt: any;
  min_tx_amt: any;
  isSourceflow: any;
  userParameters: any;
  sourceColorsByAgent: any;
  riskSourcesAddresses: any;
  riskSourcesUnqId: any;
  txCount: number = 0;
  assetInfo: IAssetInfo;

  // class intended for initial report transformations
  constructor(data) {
    this.assetInfo = data.meta.assetInfo;
    this.colorMap = colors;
    this.meta = data.meta;
    this.flowType = data.meta.flow_type;

    this.localCombinations =
      data.meta.layout.combinations.localCombinations !== undefined
        ? data.meta.layout.combinations.localCombinations
        : true;

    this.reportType = getReportType(data.meta);

    this.sourceType = getReportSourceType(this.meta, this.assetInfo.asset);
    if (data.meta.entity) {
      this.entityName = data.meta.entity.canonicalName;
    }

    if (this.reportType === ReportType.Compliance) {
      this.#buildComplianceData(this.meta.scores);
    }

    this.token = data.token;
    this.reportIds = data.ids;

    this.activeNodes = [];
    this.nodesWithBal = [];
    this.intermediaryNodes = [];
    this.sourceIds = [];
    this.unqTransferEnumerator = 0;
    this.walletEnumerator = 0;

    this.baseDenomination = getAssetConversion(this.assetInfo.asset) || 1;

    this.initialGraph = data.graph;
    this.transactions = data.transactions; // this should be removed for pagination

    this.isCPDataAvailable =
      this.transactions &&
      Object.values(this.transactions).length !== 0 &&
      Object.values<any>(this.transactions)[0].inamt;

    this.hiddenIds = this.meta.hidden_nodes ? this.meta.hidden_nodes : [];
    this.wallets = [];
    this.singleAddresses = [];
    // this.entityTypes = [];

    this.unknownType = [];

    this.clusterLookUp = {};
    this.edgeClusterLookUp = {};
    this.dupeEdges = {};
    this.dupeEdges.global = [];
    this.dupeEdges.removed = new Set();
    this.dupeEdges.byAgent = {};

    // this.transferLookUp = {};
    this.UniqueIDLookUp = {};
    this.filterBounds = data.filterBounds;
    this.max_ts = -Infinity;
    this.min_ts = Infinity;

    this.max_tx_amt = -Infinity;
    this.min_tx_amt = Infinity;

    this.isSourceflow = isSourceflow(data.meta); // not being used.
    this.userParameters = data.reportParams;
    this.sourceColorsByAgent = {};
    this.colorSourceIdsByAgent();

    this.#applyInitialTransformations(
      this.initialGraph.nodes,
      this.initialGraph.edges,
      this.transactions,
    );
  }

  #normalizeEntityStatisticsObject(obj) {
    const result = {
      entityName: obj.entity_name ?? obj.entity,
      addressCount: floatToBigNumNotation(obj.address_count),
      incomingTxCount: floatToBigNumNotation(obj.incoming_tx_count),
      incomingVolume: floatToBigNumNotation(obj.incoming_volume),
      outgoingTxCount: floatToBigNumNotation(obj.outgoing_tx_count),
      outgoingVolume: floatToBigNumNotation(obj.outgoing_volume),
      riskScore: obj.riskScore
        ? `${Number(obj.riskScore * 100).toFixed(2)}%`
        : undefined,
      firstOnChainTx: obj.first_seen
        ? `${Number(obj.first_seen).toFixed(0)}`
        : undefined,
    };

    return result;
  }

  getEntityStatistics() {
    let stats: any = {};
    if (this.meta && this.meta.entity && this.meta.entity.statistics) {
      stats = this.meta.entity.statistics;
    }
    if (this.meta.scores) {
      stats.riskScore = this.meta.scores.riskScore;
    }
    if (this.meta.entity && this.meta.entity.canonicalName) {
      stats.entity = this.meta.entity.canonicalName;
    }
    return this.#normalizeEntityStatisticsObject(stats);
  }

  #hasBenchmarkData() {
    if (
      this.meta &&
      this.meta.entity &&
      this.meta.entity.benchmarkData &&
      this.meta.entity.benchmarkData.length
    ) {
      return true;
    }

    return false;
  }

  getBenchmarkData() {
    if (!this.#hasBenchmarkData()) {
      return [];
    }

    const { benchmarkData } = this.meta.entity;
    const result = benchmarkData.map((obj) =>
      this.#normalizeEntityStatisticsObject(obj),
    );

    return result;
  }

  #buildComplianceData(scores) {
    this.riskSourcesAddresses = scores.riskSources.map((rS) => rS.rootAddress);

    this.riskSourcesUnqId = [];
  }

  #applyInitialTransformations(nodes, edges, txs) {
    this.#buildClusterLookUpForNodes(nodes);
    this.#parseEdges(edges);
    this.#parseNodes1(nodes);
    this.#parseNodes2(nodes);
    this.#transformTransactions(txs);
    this.#setIsExpansionOrHidePossible(nodes, edges);
    addTransactionDataToNodes(nodes, this.transactions, this.reportType);
  }

  updateReportData(newNodes, newEdges, newTxs) {
    this.#applyInitialTransformations(newNodes, newEdges, newTxs);
    this.initialGraph.nodes = [...this.initialGraph.nodes, ...newNodes];
    this.initialGraph.edges = [...this.initialGraph.edges, ...newEdges];
    this.transactions = { ...this.transactions, ...newTxs };
    return { newNodes, newEdges, newTxs };
  }

  // TODO: determine why this is being called with domain Transaction objs
  #transformTransactions(apiTxs) {
    for (const tx in apiTxs) {
      if (!(apiTxs[tx] instanceof Transaction)) {
        apiTxs[tx] = new Transaction(apiTxs[tx]);
      }
    }
  }

  #buildClusterLookUpForNodes(nodes) {
    nodes.forEach((node) => {
      const BaseNodeId = clusterOf(node.id);
      if (BaseNodeId in this.clusterLookUp) {
        const nodeCopy = { ...this.clusterLookUp[BaseNodeId] };
        this.clusterLookUp[BaseNodeId] = nodeCopy;
        nodeCopy.count += 1;
        // NOTE had to do this instead of .push because for some reason, .push was failing on expansions
        this.clusterLookUp[BaseNodeId].unqIds = [
          ...this.clusterLookUp[BaseNodeId].unqIds,
          node.id,
        ];
      } else {
        const nodeCopy = { ...node };
        this.walletEnumerator += 1;
        nodeCopy.wallet = this.walletEnumerator;
        this.clusterLookUp[BaseNodeId] = nodeCopy;
        this.clusterLookUp[BaseNodeId].count = 1;
        this.clusterLookUp[BaseNodeId].unqIds = [nodeCopy.id];
      }
    });
  }

  #parseEdges(edges) {
    const determineTxCount = (edge) => {
      this.txCount += Object.keys(edge.tx_hashes).length;
    };

    const transformTimestampsToLocal = (edge) => {
      edge.txTimeStps = edge.txTimeStps.map((ts) => {
        const localTime = ts * 1000 - new Date(ts * 1000).getTimezoneOffset();
        return new Date(localTime);
      });
    };

    edges.forEach((edge) => {
      this.#setUniqueIDLookupForEdge(edge);
      this.#setIfLimitedFrom(edge);
      determineTxCount(edge);
      transformTimestampsToLocal(edge);

      // set min max of amts
      this.min_tx_amt = Math.min(this.min_tx_amt, edge.txSum);
      this.max_tx_amt = Math.max(this.max_tx_amt, edge.txSum);

      const edgeIdByCluster = `${clusterOf(edge.source)}|${clusterOf(
        edge.target,
      )}`;
      const edgeId = `${edge.source}|${edge.target}`;
      edge.edgeId = edgeId;

      if (edgeIdByCluster in this.edgeClusterLookUp) {
        // required for modifying the lookup after the reducer initiates the data
        this.edgeClusterLookUp[edgeIdByCluster] = _.cloneDeep(
          this.edgeClusterLookUp[edgeIdByCluster],
        );

        // tracks unq ids for highlighting from table
        const edgeIdLookUp = this.edgeClusterLookUp[edgeIdByCluster];
        edgeIdLookUp.unqIds.push(edgeId);
      } else {
        this.edgeClusterLookUp[edgeIdByCluster] = edge;
        this.edgeClusterLookUp[edgeIdByCluster].unqIds = [edgeId];
        this.edgeClusterLookUp[edgeIdByCluster].agentIds = [edge.agentId];
      }
    });
  }

  #parseNodes1(nodes) {
    nodes.forEach((n) => {
      if (n) {
        if (this.hiddenIds.includes(n.id)) {
          n.hidden = true;
        }
        if (n.id.split('_')[0] === 'intermediary') {
          this.intermediaryNodes.push(n.id);
        }

        n.is_cluster = n.clustersize > 1;
        n.isLrgClster = n.clustersize >= 20;

        if (n.is_cluster) this.wallets.push(n.id);
        else this.singleAddresses.push(n.id);
        n.expansion_limited_directions = [];

        n.has_balance = n.balance > 0 && !n.is_stop_cluster;

        if (n.is_source) {
          n.entitytype = 'source';
          const newSourceIds = Object.assign([], this.sourceIds);
          newSourceIds.push(n.id);
          this.sourceIds = Array.from(new Set(newSourceIds));
        }

        if (
          this.reportType === ReportType.Compliance &&
          this.riskSourcesAddresses
        ) {
          n.isRiskSource = this.riskSourcesAddresses.includes(n.cluster_id);
          if (n.isRiskSource) this.riskSourcesUnqId.push(n.id);
        }

        n.incomingEdges = n.incomingEdges || [];
        n.outgoingEdges = n.outgoingEdges || [];

        // set old has_*_txs flags based on newer max_num_*_edges values
        n.has_incoming_txs = n.max_num_incoming_edges > 0;
        n.has_outgoing_txs = n.max_num_outgoing_edges > 0;

        n.entity = handleInternalAttributions(n.entity);
        if (isNodeUnattributed(n)) this.unknownType.push(n.id);

        n.sourceFundsRecevied = 0;
        // hack for nodes with confusing "wallet" label
        n.entitytype =
          n.entitytype && n.entitytype.toLowerCase() === 'wallet'
            ? 'wallet manager'
            : n.entitytype;

        this.#addMissingEntityColorsForNode(n);
        this.#setEdgeDataForNode(n);
        this.#setNodeBalIdsAndConvertDenominationForNode(n);
      }
    });
  }

  #parseNodes2(nodes) {
    // trim Risk Sources
    if (this.reportType === ReportType.Compliance)
      this.riskSourcesAddresses = this.riskSourcesAddresses.filter(
        (rs) => rs in this.clusterLookUp,
      );

    nodes.forEach((n) => {
      if (!(n.id in this.UniqueIDLookUp)) {
        this.UniqueIDLookUp[n.id] = n;
      }
      n._id = this.UniqueIDLookUp[n.id].id;

      if (n) {
        n.wallet = this.clusterLookUp[n.id.split('_')[0]].wallet; // is this used for anything?
      }
    });
  }

  #setUniqueIDLookupForEdge(e) {
    const edgeId = `${e.source}|${e.target}`;

    if (!this.UniqueIDLookUp[e.source]) this.UniqueIDLookUp[e.source] = {};
    if (!this.UniqueIDLookUp[e.target]) this.UniqueIDLookUp[e.target] = {};

    this.UniqueIDLookUp[e.source].id = e._from;
    if (this.UniqueIDLookUp[e.source].outgoingEdges)
      this.UniqueIDLookUp[e.source].outgoingEdges = [
        ...this.UniqueIDLookUp[e.source].outgoingEdges,
        edgeId,
      ];
    else {
      this.UniqueIDLookUp[e.source].outgoingEdges = [edgeId];
      if (!this.UniqueIDLookUp[e.source].expansion_limited_directions)
        this.UniqueIDLookUp[e.source].expansion_limited_directions = [];
    }

    this.UniqueIDLookUp[e.target].id = e._to;
    if (this.UniqueIDLookUp[e.target].incomingEdges)
      this.UniqueIDLookUp[e.target].incomingEdges = [
        ...this.UniqueIDLookUp[e.target].incomingEdges,
        edgeId,
      ];
    else {
      this.UniqueIDLookUp[e.target].incomingEdges = [edgeId];
      if (!this.UniqueIDLookUp[e.target].expansion_limited_directions)
        this.UniqueIDLookUp[e.target].expansion_limited_directions = [];
    }
  }

  #setIfLimitedFrom(e) {
    if (e.expansion_limit_reached) {
      const expandedNode =
        e.expansion_direction === 'outgoing' ? 'source' : 'target';
      this.UniqueIDLookUp[e[expandedNode]].expansion_limit_reached =
        e.expansion_limit_reached;
      this.UniqueIDLookUp[e[expandedNode]].expansion_limited_directions = [
        ...this.UniqueIDLookUp[e[expandedNode]].expansion_limited_directions,
        e.expansion_direction,
      ];
    }
  }

  #setEdgeDataForNode(n) {
    const setIsExpandedOut = (n) => {
      if (n && this.UniqueIDLookUp[n.id]) {
        if (this.UniqueIDLookUp[n.id].expansion_limit_reached) {
          n.expansion_limit_reached =
            this.UniqueIDLookUp[n.id].expansion_limit_reached;

          n['expansion_limited_directions'] =
            this.UniqueIDLookUp[n.id].expansion_limited_directions;
        }

        if (this.UniqueIDLookUp[n.id].outgoingEdges) {
          n.outgoingEdges = [
            ...n.outgoingEdges,
            ...this.UniqueIDLookUp[n.id].outgoingEdges,
          ];
        }

        if (this.UniqueIDLookUp[n.id].incomingEdges) {
          n.incomingEdges = Array.from(
            new Set([
              ...n.incomingEdges,
              ...this.UniqueIDLookUp[n.id].incomingEdges,
            ]),
          );
        }
      }
    };

    setIsExpandedOut(n);
  }

  #setIsExpansionOrHidePossible(nodes, edges) {
    const targetsHidden: any = [];

    const hideEdges = (edges) => {
      const hideEdges = (e) => {
        for (let [_beginFlow, _endFlow] of [
          ['source', 'target'],
          ['target', 'source'],
        ]) {
          if (this.hiddenIds.includes(e[_endFlow])) {
            e.hidden = true;
          }
          // also set if node has hidden nodes as targets
          if (this.hiddenIds.includes(e[_endFlow])) {
            targetsHidden.push(e[_beginFlow]);
          }
        }
      };

      edges.forEach((e) => {
        hideEdges(e);
      });
    };
    hideEdges(edges);

    nodes.forEach((n) => {
      if (targetsHidden.includes(n.id)) {
        n.unhidable = true;
      }

      if (
        this.reportType === ReportType.Compliance ||
        (n.isLrgClster && !n.is_source)
      ) {
        n.canExpandOut = false;
        n.canExpandIn = false;
        return;
      }

      if (n.max_num_incoming_edges != null) {
        // hack for potentially incorrect numbers for source node
        if (
          n.is_source &&
          (!this.meta.tx_id_restrictions ||
            !Object.keys(this.meta.tx_id_restrictions).length)
        ) {
          n.canExpandOut = !n.outgoingEdges.length && n.has_outgoing_txs;
          n.canExpandIn = !n.incomingEdges.length && n.has_incoming_txs;
          return;
        }
        // not source nodes
        n.canExpandOut =
          n.outgoingEdges.length < n.max_num_outgoing_edges &&
          !n.expansion_limited_directions.includes('outgoing');

        n.canExpandIn =
          n.incomingEdges.length < n.max_num_incoming_edges &&
          !n.expansion_limited_directions.includes('incoming');
      }
    });
  }

  #setNodeBalIdsAndConvertDenominationForNode(n) {
    if (
      n.balance > (cryptoThresholds[this.assetInfo.asset] || 0.000154) &&
      !(n.entitytype.length > 1) &&
      n.entitytype.toLowerCase() !== 'source'
    )
      this.nodesWithBal.push(n.cluster_id);
    n.parsedBalance = n.balance;
  }

  #getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  colorSourceIdsByAgent() {
    const colorGradient: any = new Gradient();
    const keyColorExtremes = ['#FFFC19', '#ff0000'];
    colorGradient.setGradient(...keyColorExtremes);
    colorGradient.setMidpoint(this.reportIds.length);
    const colorArr = colorGradient.getArray().reverse();

    this.reportIds.forEach((repId, i) => {
      this.sourceColorsByAgent[repId] = colorArr[i];
    });
  }

  #addMissingEntityColorsForNode(n) {
    const isSource = n.is_source;
    const entitytype = n.entitytype.toLowerCase();

    if (isSource) {
      n.color = this.sourceColorsByAgent[n.agentId];
    } else if (entitytype.length <= 1) {
      n.color = '#c2c2c2';
    } else if (!isSource && entitytype && this.colorMap[entitytype]) {
      n.color = this.colorMap[entitytype].color;
    } else if (entitytype && entitytype !== '-') {
      n.color = this.#getRandomColor();

      if (this.colorMap[entitytype]) {
        this.colorMap[entitytype].color = n.color;
      } else {
        this.colorMap[entitytype] = { color: n.color };
      }
    } else {
      n.color = '#c2c2c2';
    }
  }
}

const addTransactionDataToNodes = (
  nodes,
  transactions: { [transactionId: string]: Transaction },
  reportType,
) => {
  const clusterIdToBeneficialOwner = {};
  const copyCounterpartyData = (
    counterparty: Counterparty,
    destination: { [key: string]: string },
  ) => {
    // only add beneficial owner info to pulse reports
    if (
      reportType === ReportType.Incoming ||
      reportType === ReportType.Outgoing
    ) {
      if (
        !(counterparty.cluster in destination) &&
        counterparty.beneficialOwner
      ) {
        destination[counterparty.cluster] = counterparty.beneficialOwner;
      }
    }
  };
  Object.values(transactions).forEach((transaction) => {
    transaction.counterpartiesFrom.forEach((counterparty) =>
      copyCounterpartyData(counterparty, clusterIdToBeneficialOwner),
    );
    transaction.counterpartiesTo.forEach((counterparty) =>
      copyCounterpartyData(counterparty, clusterIdToBeneficialOwner),
    );
  });
  nodes.forEach((node) => {
    if (node.cluster_id in clusterIdToBeneficialOwner) {
      node.beneficialOwner = clusterIdToBeneficialOwner[node.cluster_id];
    }
  });
};

const isNodeUnattributed = (n) =>
  !n.entitytype ||
  n.entitytype.toLowerCase() === 'other' ||
  n.entitytype.toLowerCase() === 'unknown' ||
  n.entitytype.toLowerCase() === 'unattributed';

const cryptoThresholds = { btc: 0.00011, eth: 0.0027 };
