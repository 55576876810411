import React, { useState, useEffect, useContext } from 'react';
import * as RA from 'ramda-adjunct';
import _ from 'lodash';
import 'zingchart/es6';
import ZingChart from 'zingchart-react';
import 'zingchart/modules-es6/zingchart-treemap.min.js';

import { ModButton } from './styles';
import { clearSelection, addSelection } from 'hooks/useGraph/actions';
import { useGraph } from 'hooks/useGraph/';
import { store } from 'ReportData/ReportDataStore';

export default function Treemap() {
  const { state: graphState, dispatch, chartRef } = useGraph();
  const { state: globalState } = useContext(store);

  const [chartJSON, setChartJSON] = useState({});
  const [selectToggled, setSelectToggled] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(graphState.viewableItems)) {
      let chartData = globalState.ReportData.getEgressData(
        graphState.viewableItems,
      );
      chartData = formatTreemapChartData(chartData);
      setChartJSON(chartData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphState.ReportData.egressEntities]);

  useEffect(() => {
    const exit_entryNodes = globalState.ReportData.exit_entryNodes;
    if (
      exit_entryNodes &&
      !exit_entryNodes.every((id) => graphState.selection.has(id))
    )
      setSelectToggled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphState.selection]);

  const handleClickSelectAll = () => {
    // Handles the 'Select Exited / Entered' button
    const exit_entryNodes = globalState.ReportData.exit_entryNodes;
    if (!exit_entryNodes.length) return;

    if (selectToggled) {
      dispatch(clearSelection());
      setSelectToggled(false);
    } else {
      dispatch(addSelection(exit_entryNodes, null, null, chartRef));
      setSelectToggled(true);
    }
  };

  const handleEgressClick = (e) => {
    // deselect/select
    if (!e['data-uid']) return;
    const splitTextBySpace = e['data-uid'].split(' ');
    let thisEntity =
      splitTextBySpace.length > 1 ? splitTextBySpace[1] : splitTextBySpace[0];
    if (
      thisEntity &&
      thisEntity.length > 1 &&
      thisEntity.substr(0, 1) !== '['
    ) {
      const egressIds = graphState.ReportData.egressEntities[thisEntity].ids;
      // check if all the viewableItems are already selected.
      if (egressIds.every((id) => graphState.selection.has(id))) {
        // If all viewableItems controlled by this Egress are selected, we will deselect.
        dispatch(clearSelection());
      } else {
        // Else we will ensure all viewableItems are selected by this Egress Click.
        dispatch(addSelection(egressIds, null, null, chartRef));
      }
    }
  };

  const egressLabel = graphState.ReportData.isSourceflow
    ? 'Exit Points'
    : 'Entry Points';

  // NOTE handles corner case of all zero tainted amounts (likely points to a larger issue)
  if (
    !chartJSON ||
    !chartJSON.series ||
    !chartJSON.series.length ||
    !chartJSON.series.some((elem) => elem.children && elem.children.length)
  ) {
    return (
      <div
        id='treemapcontainer'
        style={{ width: '100%', height: '100%' }}
      ></div>
    );
  } else {
    return (
      <>
        <div className='sectiontitle'>
          {/* TODO change logic to only count exited for now */}
          <h5 id='egresstitle'>{egressLabel}</h5>
        </div>
        <div id='treemapcontainer' style={{ width: '100%', height: '100%' }}>
          <ZingChart
            id='chart1'
            data={chartJSON}
            width='100%'
            height='100%'
            node_click={handleEgressClick}
          />
          <ModButton
            variant='contained'
            color='primary'
            onClick={handleClickSelectAll}
            className='statbutton'
            id='allexitbutton'
            title='Select all points of exit'
          >
            {selectToggled ? 'Deselect' : 'Select'}
          </ModButton>
        </div>
      </>
    );
  }
}

function formatTreemapChartData(chart_data) {
  if (RA.isNilOrEmpty(chart_data) || RA.isNilOrEmpty(chart_data.series)) {
    return chart_data;
  }

  chart_data.options = {
    ...chart_data.options,
    'tooltip-box': {
      text: '%text',
      padding: '10%',
      fontFamily: 'Lato',
      'border-radius': '5px',
      'font-size': '9px',
      sticky: true,
      'text-align': 'right',
    },
    box: {
      borderColor: '#fff',
      fontFamily: 'Lato',
      fontSize: '9px',
    },
  };

  // For single exit point graphs, make treemap a single color - https://elementus.atlassian.net/browse/PRD-377
  if (chart_data.series.length === 1) {
    chart_data.options = {
      ...chart_data.options,
      aspectType: 'transition',
      colorStart: '#3c63af',
      colorEnd: '#578efa',
    };
  }

  return chart_data;
}
