import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import Root from './Root';
import App from './Router';
import { Auth0Provider } from '@auth0/auth0-react';
import { CookiesProvider } from 'react-cookie';
import history from 'utils/history';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { AnalyticsProvider } from 'use-analytics';
import { GlobalStyles } from 'styles/GlobalStyles';
import { UserProvider } from 'contexts/user';

const analytics = Analytics({
  app: 'elementus-lens',
  plugins: [
    googleAnalytics({
      trackingId: 'G-DY7KL4NWX4',
      customDimensions: {
        userid: 'dimension1',
      },
    }),
  ],
});

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const handleAuth0CallbackErrors = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');
  if (!error) {
    return;
  }

  const errorDesc = urlParams.get('error_description');
  if (error === 'unauthorized') {
    // error and description added to url just for debugging posssible auth0 errors
    window.location.href = `${window.location.origin}/unathorized?auth_response=${error}-${errorDesc}`;
  }
};

const main = () => {
  handleAuth0CallbackErrors();

  ReactDOM.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <UserProvider>
        <Router history={history}>
          <CookiesProvider>
            <Root>
              <GlobalStyles />
              <AnalyticsProvider instance={analytics}>
                <App />
              </AnalyticsProvider>
            </Root>
          </CookiesProvider>
        </Router>
      </UserProvider>
    </Auth0Provider>,
    document.getElementById('root'),
  );
};

main();
