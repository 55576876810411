import { updatePositions } from './actions';
import _ from 'lodash';

export const updatePositionSequence = (positions, state, dispatch, why) => {
  const differenceInPositionsObserved = (p1, p2) => {
    // this check is primarily to avoid the updating of position data
    // that is sent back from combinations that is identical to the position data received before the combination
    if (!p1) return false;
    if (_.isEmpty(p2)) return true;
    const sumsOfXYs = [];
    const positionsArray = [p1, p2];
    positionsArray.forEach((positions) => {
      sumsOfXYs.push(
        Object.keys(positions)
          .filter(
            (p) =>
              // exclusion necessary for sum
              // it is important that any key added to the position state
              // be excluded in this calculation
              !p.startsWith('_combonode_') && !p.startsWith('state'),
          )
          .reduce(
            (acc, p) =>
              acc +
              Math.round(positions[p]['x']) +
              Math.round(positions[p]['y']),
            0,
          ),
      );
    });
    return sumsOfXYs[0] !== sumsOfXYs[1];
  };
  const endPointer = Object.keys(state.positions).length;
  const islessThanLastStep = state.histPointer < endPointer;
  const lastPositions = islessThanLastStep
    ? state.positions[state.histPointer]
    : state.positions[state.histPointer - 1];

  const updateReceivedPositions =
    why === 'user' ||
    endPointer <= 1 ||
    (lastPositions && differenceInPositionsObserved(positions, lastPositions));

  if (updateReceivedPositions) dispatch(updatePositions(positions));

  // ensure focus on graph to enable undoing after changes/combos
  document.querySelector('#report-frame-left canvas').focus();
};
