const buildEnv = process.env.NODE_ENV;

// Ensure passed in features aren't visible in production
const ensureNotProd = (val) => buildEnv !== 'production' && val;

// Experimental features, if enabled, can be seen in staging/dev but not in production
export const EXPERIMENTAL_FEATURES = {
  alertsFeature: ensureNotProd(true),
  riskMonitor: ensureNotProd(true),
  entityReports: {
    entityStats: true,
    exchangeBenchmarks: true,
  },
  redash: ensureNotProd(true),
};
