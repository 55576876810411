import React, { useState, useEffect, useRef, useContext } from 'react';
import * as R from 'ramda';
import { Col, Form, FormGroup, Button, Panel, Overlay } from 'react-bootstrap';
import BarLoader from 'react-spinners/BarLoader';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { FlowType, Product } from '../../../utils/enums';
import InfoIcon from 'components/Common/InfoIcon';
import CloseButton from 'components/Common/CloseButton';
import {
  AssetOption,
  DefaultAssetTypes,
  guessAssetFromInput,
  isValidAddress,
  isValidTXHash,
} from 'utils/crypto';
import { trimLeadingTrailingWhitespace } from 'helpers/utils';
import { PurpleRadio, NewAppContainer, customSelectStyles } from './styles';
import Config from 'utils/config';
import { styleRefs as SR } from 'styles/styleRefs';
import { UserContext } from 'contexts/user';
import { AddressAutocompleteTooltip } from 'components/Common/AutoComplete';

const ReportsNew = (props) => {
  const { user } = useContext(UserContext);
  const isEntityReportEnabled = user.isAuthorized(Product.ENTITY_REPORTS);
  const { apiClient } = useContext(UserContext);
  const addressInputRef = useRef(null);

  const [report_name, set_report_name] = useState('');
  const [report_addresses_list, set_report_addresses_list] = useState(
    props.presetItems || '',
  );
  const [entityName, setEntityName] = useState(props.entityName || '');
  const [apiError, setApiError] = useState(null);
  const [is_requesting, set_is_requesting] = useState(false);
  const [flowType, setFlowType] = useState(FlowType.Radix);
  const [autocompleteObj, setAutocompleteObj] = useState({});
  const [curAddressPrefix, setCurAddressPrefix] = useState(null);
  const [entityPrefix, setEntityPrefix] = useState('');
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(AssetOption.Btc);
  const [selectedEntityAsset, setSelectedEntityAsset] = useState(
    AssetOption.Btc,
  );
  const [reportNameErr, setReportNameErr] = useState('');
  const [addressListErr, setAddressListErr] = useState('');
  const [assetsList, setAssetsList] = useState(DefaultAssetTypes);

  const clearErrors = () => {
    setAddressListErr('');
    setReportNameErr('');
    setApiError('');
  };

  useEffect(() => {
    const getAutocompleteList = async (addressPrefix) => {
      // skip API call if memoized;
      // only make API call if user has input >= 4 characters
      if (curAddressPrefix && !(curAddressPrefix in autocompleteObj)) {
        const newAutocompleteObj = await apiClient
          .getAddressAutocompletion(addressPrefix, false)
          .then((autocompletes) => {
            // maybe if there is only one result, no dropdown

            autocompletes.sort();
            const ac = R.clone(autocompleteObj);
            ac[addressPrefix] = autocompletes;
            setAutocompleteObj(ac);
            return ac;
          });

        // call was successful and there's a match in the DB
        // and addressPrefix matches the only address in the returned list
        // then autoselect
        if (
          curAddressPrefix in newAutocompleteObj &&
          newAutocompleteObj[curAddressPrefix].length === 1 &&
          curAddressPrefix === newAutocompleteObj[curAddressPrefix][0]
        ) {
          handleDropdownSelect(curAddressPrefix, false);
        }
      }
    };

    getAutocompleteList(curAddressPrefix);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_addresses_list]);

  useEffect(() => {
    // allow spaces, but don't query api with leading/trailing spaces
    const entityPrefixTrimmed = trimLeadingTrailingWhitespace(entityPrefix);
    if (entityPrefixTrimmed.length < 4) {
      return;
    }

    const getEntitySuggestions = async (prefix) => {
      try {
        const completions = await apiClient.getEntityAutocompletion(prefix);
        setAutocompleteObj({
          ...autocompleteObj,
          [prefix]: completions,
        });
      } catch (error) {
        console.error('getEntityAutocompletion. error: ', error);
      }
    };

    getEntitySuggestions(entityPrefix);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityPrefix]);

  useEffect(() => {
    let show = !!(curAddressPrefix && curAddressPrefix.length);
    if (flowType === FlowType.Entity) {
      show = entityPrefix.length >= 4;
    }
    setShowAutocomplete(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curAddressPrefix, entityPrefix]);

  const handleDropdownSelect = (item) => {
    if (flowType === FlowType.Entity) {
      const entityName = item;
      set_report_addresses_list([entityName]);
      setEntityName(entityName);
      setAutocompleteObj({});
      return;
    }

    const truncatedList = report_addresses_list.slice(0, -1);
    truncatedList.push(item);
    set_report_addresses_list(truncatedList);
    setCurAddressPrefix(null);
    // clear memoization once selected
    setAutocompleteObj({});
    addressInputRef.current.focus();
  };

  const handleAddressListChange = (event) => {
    setAddressListErr('');

    // handle direct call with a list of addresses
    const newAddresses = event.target ? event.target.value : event;

    // handle comma and space-delimited
    const newAddressListCommaSplit = newAddresses.split(',');
    const newAddressListNewlineSplit = newAddressListCommaSplit.reduce(
      (acc, elem) => {
        const splt = elem.split('\n');
        return [...acc, ...splt];
      },
      [],
    );

    const newAddressListUntrimmed = newAddressListNewlineSplit.reduce(
      (acc, elem) => {
        const splt = elem.split(' ');
        return [...acc, ...splt];
      },
      [],
    );

    // handle adding comma and space between elements
    let newAddressList = newAddressListUntrimmed
      .filter(
        (x, idx) => !(x === '' && idx !== newAddressListUntrimmed.length - 1),
      )
      .map((x, idx) => (idx === 0 || x === '' ? x : ' ' + x));

    // handle adding comma and space between elements
    const blankIdx = newAddressList.indexOf('');
    if (blankIdx >= 0) {
      newAddressList = newAddressList.slice(0, blankIdx + 1);
      newAddressList[blankIdx] = ' ';
    }

    set_report_addresses_list(newAddressList);
    setEntityName(newAddresses);

    let addressPrefix;
    if (newAddressList.length) {
      addressPrefix = newAddressList[newAddressList.length - 1];
      addressPrefix = trimLeadingTrailingWhitespace(addressPrefix);
    } else {
      addressPrefix = '';
    }

    // try to guess asset from address/tx hash
    const address = newAddressList[0]?.trim();
    if (!address || address.length < 2) {
      setSelectedAsset(AssetOption.Btc);
      setAssetsList(DefaultAssetTypes);
    } else {
      const asset = guessAssetFromInput(address);
      if (asset === AssetOption.Eth.value) {
        setSelectedAsset(AssetOption.Eth);
        setAssetsList([AssetOption.Eth]);
      } else {
        setSelectedAsset(AssetOption.Btc);
        setAssetsList([AssetOption.Btc]);
      }
    }

    if (addressPrefix.length && addressPrefix.length >= 4) {
      setCurAddressPrefix(addressPrefix);
    } else {
      // get rid of dropdown if input is not long enough
      setCurAddressPrefix(null);
    }
  };

  const handleEntityNameChange = (event) => {
    // handle direct call with a list of addresses
    const eventEntityName = event.target ? event.target.value : event;

    setEntityName(eventEntityName);
    setEntityPrefix(eventEntityName);
    setAddressListErr('');
  };

  const handleReportNameChange = (event) => {
    const target = event.target;
    set_report_name(target.value);
    setReportNameErr('');
  };

  const chooseRandomAddress = () => {
    const address =
      EXAMPLE_ADDRESSES[Math.floor(Math.random() * EXAMPLE_ADDRESSES.length)];
    handleAddressListChange(address);
  };

  const handleRadixSubmit = (event) => {
    // stops full reload
    event.preventDefault();

    if (!report_name || !report_name.trim().length) {
      setReportNameErr('Please enter a name for this report.');
      return;
    }

    if (!report_addresses_list || !report_addresses_list[0].trim()) {
      setAddressListErr('Please enter an address, transaction hash or entity.');
      return;
    }

    // filter empty strings from address/tx array
    const inputs = report_addresses_list.reduce((acc, addr) => {
      if (addr && addr.trim().length) {
        acc.push(addr.trim());
      }
      return acc;
    }, []);

    if (inputs.length > 1) {
      setAddressListErr(
        'Input must contain a single BTC/ETH address or transaction hash.',
      );
      return;
    }

    // check asset is valid
    if (selectedAsset == null || selectedAsset.value === '-') {
      setAddressListErr('Please select a valid asset type.');
      return;
    }

    // check address or tx hash is valid
    const addrOrTxHash = inputs[0];
    if (!isValidAddress(addrOrTxHash) && !isValidTXHash(addrOrTxHash)) {
      setAddressListErr('Found an invalid address or transaction hash.');
      return;
    }

    // See if asset matches address/tx hash
    if (
      !isValidAddress(addrOrTxHash, selectedAsset.value) &&
      !isValidTXHash(addrOrTxHash, selectedAsset.value)
    ) {
      setAddressListErr('The selected asset does not match address format.');
      return;
    }

    commonSubmit(selectedAsset, [addrOrTxHash]);
  };

  const handleEntitySubmit = (event) => {
    // stops full reload
    event.preventDefault();

    if (!report_name || !report_name.length || !report_name.trim().length) {
      setReportNameErr('Please enter a name for this report.');
      return;
    }

    if (!entityName || !entityName.length || !entityName.trim().length) {
      setAddressListErr('Please input a valid entity name');
      return;
    }

    const multiEntityString = entityName.replaceAll(/[,\n ]/g, ',').split(',');
    if (multiEntityString.length > 1) {
      setAddressListErr('Only one entity name per report allowed');
      return;
    }

    commonSubmit(selectedEntityAsset, [], entityName);
  };

  const commonSubmit = (asset, addresses, entityName) => {
    set_is_requesting(true);
    clearErrors();

    const entity = entityName ? { name: entityName } : undefined;

    // keeping params in here in case we want to filter or apply params in some future state
    const reportParams = {
      dilution: { current: Config.reportParams.concentrationMin },
      hops: { current: '10+' },
      minTransferSize: { current: 0 }, //in base denomination
      crypto: asset.value,
      maxNode: 10000,
    };

    apiClient
      .createForensicAnalysis(
        trimLeadingTrailingWhitespace(report_name),
        addresses,
        false,
        reportParams,
        flowType,
        asset.value,
        false,
        entity,
      )
      .then((res) => {
        set_is_requesting(false);
        props.toggleModal();

        // add pending report to report list immediately before reloading with results
        props.addNewReport(res);
        props.reloadList();
        return null;
      })
      .catch((error_res) => {
        const errorMsg = error_res.error.message
          .toLowerCase()
          .includes('failed to fetch')
          ? 'Network too large. Please contact us for more info.'
          : error_res.error.message;

        set_is_requesting(false);
        setApiError(errorMsg);
      });
  };

  const AutocompleteTooltip = (props) => {
    // TODO: use single var to track input, only change labels
    let autocompleteList = autocompleteObj[curAddressPrefix];
    let query = curAddressPrefix;

    if (flowType === FlowType.Entity) {
      query = entityPrefix;
      autocompleteList = autocompleteObj[entityPrefix].map(
        (entry) => entry.name,
      );
    }

    return (
      <AddressAutocompleteTooltip
        props={props}
        autocompletelist={autocompleteList}
        onClickHandler={handleDropdownSelect}
        query={query}
        noMatchingResultsLabel='No addresses matching this prefix...'
      ></AddressAutocompleteTooltip>
    );
  };

  const createReportInput = () => {
    let inputTitle = 'Source Address or Transaction Hash';
    let changeHandler = handleAddressListChange;
    let inputValue = report_addresses_list;
    let inputPlaceholder =
      'Enter an address or transaction hash to trace the flow of funds. Autocomplete starts after 4 characters.';

    if (flowType === FlowType.Entity) {
      inputTitle = 'Entity';
      changeHandler = handleEntityNameChange;
      inputValue = entityName;
      inputPlaceholder = 'Enter an entity name';
    }

    let showAutocompleteTooltip =
      curAddressPrefix &&
      autocompleteObj[curAddressPrefix] &&
      autocompleteObj[curAddressPrefix].length;

    if (flowType === FlowType.Entity) {
      showAutocompleteTooltip =
        entityPrefix &&
        autocompleteObj[entityPrefix] &&
        autocompleteObj[entityPrefix].length;
    }

    return (
      <div className='text-field-container address-input'>
        <div className='field-title'>{inputTitle}</div>

        <TextField
          error={!!addressListErr}
          helperText={addressListErr}
          style={{ height: '75px' }}
          inputRef={addressInputRef}
          inputProps={{
            classes: { input: { minHeight: '75px' } },
          }}
          InputLabelProps={{ style: { fontSize: 12 } }}
          id='addresslist'
          name='report_addresses_list'
          value={inputValue}
          onChange={changeHandler}
          variant='filled'
          placeholder={inputPlaceholder}
          multiline
          minRows='2'
        />

        {showAutocompleteTooltip ? (
          <Overlay
            key='right'
            placement='bottom'
            target={addressInputRef.current}
            show={showAutocomplete}
            rootClose={true}
            onHide={() => setShowAutocomplete(false)}
          >
            <AutocompleteTooltip />
          </Overlay>
        ) : (
          <> </>
        )}
      </div>
    );
  };

  const createExampleAddressButton = () => {
    const isVisible = flowType === FlowType.Radix ? 'visible' : 'hidden';
    return (
      <Button
        onClick={() => chooseRandomAddress()}
        className='flux-btn flux-btn-bg random-btn'
        style={{ visibility: isVisible }}
      >
        Example Address
      </Button>
    );
  };

  const createAssetSelectionInput = () => {
    let selection = selectedAsset;
    let setAsset = setSelectedAsset;
    let options = assetsList;

    if (flowType === FlowType.Entity) {
      selection = selectedEntityAsset;
      setAsset = setSelectedEntityAsset;
    }

    return (
      <Select
        className=''
        value={selection}
        onChange={(x) => {
          setAsset(x);
          clearErrors();
        }}
        options={options}
        styles={customSelectStyles}
      />
    );
  };

  let handleSubmit = handleRadixSubmit;
  let isAssetSelected = selectedAsset && selectedAsset.value !== '-';
  if (flowType === FlowType.Entity) {
    handleSubmit = handleEntitySubmit;
    isAssetSelected = !!selectedEntityAsset;
  }

  return (
    <NewAppContainer>
      <section className='modal-heading'>
        <h1 className='modal-title'>New Radar Report</h1>
        <InfoIcon
          infoText={`
                  Elementus Radar identifies and graphs crypto inflows from known nefarious
                  entities to an address or transaction of interest.`}
        />
        <CloseButton onClose={() => props.toggleModal()} />
      </section>
      <Panel.Body>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormGroup controlId='formBasicText'>
            <div className='text-field-container'>
              <div className='field-title'>Report Name</div>
              <TextField
                autoFocus
                error={!!reportNameErr}
                helperText={reportNameErr}
                inputProps={{ maxLength: 255 }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                name='report_name'
                value={report_name}
                onChange={handleReportNameChange}
                variant='filled'
                placeholder='Enter a name for your report.'
              />
            </div>
            {createReportInput()}
            <div className='load-buttons'>
              <Col>
                <div className='flowtype-selection-radio'>
                  <div>
                    <PurpleRadio
                      checked={flowType === FlowType.Radix}
                      onChange={() => {
                        setFlowType(FlowType.Radix);
                        set_report_addresses_list([entityName]);
                        clearErrors();
                      }}
                      value='radix'
                      color='primary'
                      name='radio-button-demo'
                    />
                    <p>Addresses or Hashes</p>
                  </div>
                  {isEntityReportEnabled && (
                    <div>
                      <PurpleRadio
                        checked={flowType === FlowType.Entity}
                        onChange={() => {
                          clearErrors();
                          setFlowType(FlowType.Entity);
                          const address =
                            report_addresses_list && report_addresses_list.pop
                              ? report_addresses_list.pop()
                              : '';
                          setEntityName(address);
                        }}
                        value='entity'
                        name='radio-button-demo'
                      />
                      <p>Entity</p>
                    </div>
                  )}
                </div>
              </Col>
              <Col>
                {createAssetSelectionInput()}
                {createExampleAddressButton()}
              </Col>
            </div>
          </FormGroup>

          <>
            {apiError ? (
              <p
                className='error'
                style={{ color: '#f00', textAlign: 'center' }}
              >
                {apiError}
              </p>
            ) : null}

            {is_requesting ? (
              <BarLoader
                css='
                width: 540px;
                margin-top: 50px;
                '
                size={20}
                color={SR.colors.nuetral_DarkPurple}
              />
            ) : (
              <Button
                title='submit'
                type='submit'
                className='flux-btn flux-btn-bg submit-btn'
                disabled={!isAssetSelected}
              >
                Submit
              </Button>
            )}
          </>
        </Form>
      </Panel.Body>
    </NewAppContainer>
  );
};

ReportsNew.propTypes = {};

const EXAMPLE_ADDRESSES = [
  '1E6QtbekDM4sJMPUZ9bLfo11LvFDWvdNm9',
  '13UCB7W5dDgvVhQujdGmT4nU9L42vMGspj',
  '164H5khmYzrU21QdvQRTRnETYEPq8tQMpq',
  '1JUco13gGDgJZZ3mFd9pJSmKqHbcurButS',
];

export default ReportsNew;
