import styled from 'styled-components';
import { Window, PanelBody, EmphasisButton, TitleText } from '../../styles/';

export const FAQWindow = styled(Window)`
  background: none;
  height: 80%;
  width: 80%;
`;

export const FAQButtonSection = styled.section`
  width: 250px;
  height: 200px;
  display: flex;
  flex-direction: column;
  place-content: space-evenly;
`;

// https://github.com/styled-components/styled-components/issues/1816
export const FAQTitleText = styled(TitleText)`
  color: white;
  text-align: center;
  font-size: 30px;
`;

export const SectionButton = styled(EmphasisButton)`
  width: 190px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`;

export const FAQPanelBody = styled(PanelBody)`
  flex-direction: row;
  display: flex;
  padding-top: 0px;
`;

export const ExplanatoryTextSection = styled.section`
  margin: 30px;
  width: 100%;
  border-radius: 4px;
  color: #f3f2f7;
  padding: 4px 20px 20px 20px;
  overflow: scroll;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const ExplanatoryTextQuestion = styled.p`
  font-size: 30px;
  font-weight: bold;
  border-bottom: 0.5px dashed #9e9e9e;
  cursor: pointer;
  &:hover {
    color: grey;
  }
`;

export const ExplanatoryTextResponse = styled.p`
  margin-left: 20px;
  font-size: 20px;
`;
