import React from 'react';
import { FlowType } from '../../../utils/enums';
import { getReportSourceType } from 'helpers/reportDataTransform';
import { getRunType } from 'helpers/utils';
import { IntercomText } from './styles';

// popup text
const StatusCodeTranslation = {
  404: {
    type: 'error',
    name: 'Not Found',
    description: 'report failed to build',
    message: 'Info required to build report not found.',
  },
  408: {
    type: 'error',
    name: 'Timeout',
    description: 'graph took longer than 8 mins to build',
    message:
      'If you want to expand from single source nodes, create the report using manual expansion',
  },
  420: {
    type: 'error',
    name: 'Empty Reprt',
    description: 'no transactions associated with source addresses',
    message: null,
  },
  421: {
    type: 'error',
    name: 'Invalid Targets',
    description: 'unconfirmed or invalid addresses/transactions',
    message: null,
  },
  500: {
    type: 'error',
    name: 'Error',
    description: 'report failed to build',
    message: null,
  },
  502: {
    type: 'error',
    name: 'Error',
    description: 'report failed to build',
    message: null,
  },
  413: {
    type: 'warning',
    name: 'Large Graph',
    description: 'graph involves more than 10,000 transfers',
    message: 'This report has been trimmed or set to manual expansion',
  },
};

const getReportSummary = (report) => {
  let summary = null;
  const statusType = getReportStatusType(report);
  const isRadixRiskZero =
    report.flow_type &&
    report.flow_type === FlowType.Radix &&
    report.scores &&
    report.scores.riskScore === 0;

  const isRadixRisk100 =
    report.flow_type &&
    report.flow_type === FlowType.Radix &&
    report.scores &&
    report.scores.riskSources.length !== 0 &&
    report.scores.riskSources[0] &&
    report.scores.address === report.scores.riskSources[0].rootAddress;

  const riskType = isRadixRisk100
    ? Object.entries(report.scores.riskScoreBreakdown).reduce(
        (cats100, [cat, val]) => {
          if (val >= 1) cats100.push(cat);
          return cats100;
        },
        [],
      )
    : null;
  const entity =
    report.scores &&
    report.scores.riskSources &&
    report.scores.riskSources.length
      ? report.scores.riskSources[0].entity
      : null;
  if (statusType === 'initialized') {
    summary = 'Building';
  } else if (statusType === 'error') {
    summary = 'Error';
  } else if (isRadixRiskZero) {
    summary = '0% Risk Score';
  } else if (isRadixRisk100) {
    summary = `identified as a ${riskType} entity - (${entity})`;
  }
  return summary;
};

const getReportStatusType = (report) => {
  const statusType = {
    404: 'error',
    408: 'error',
    420: 'error',
    421: 'error',
    500: 'error',
    502: 'error',
    413: 'success',
    202: 'initialized',
    201: 'success',
  }[report.status];
  return statusType;
};

const getReportTargets = (report, includeEntity = true) => {
  const targets = [];
  if (includeEntity && report.flow_type === FlowType.Entity) {
    if (report.entity) {
      targets.push(report.entity.canonicalName);
    }
  } else {
    const targetAddresses = report.target_addresses;
    const targetsToUse =
      targetAddresses && targetAddresses.length
        ? targetAddresses
        : report.target_txs
        ? report.target_txs
        : [];
    targets.push(...targetsToUse);
  }
  return targets;
};

const getReportTargetTypes = (report) => {
  const targetTypes = [];
  if (report.target_addresses && report.target_addresses.length) {
    targetTypes.push('address');
  }
  if (report.target_txs && report.target_txs.length) {
    targetTypes.push('transaction');
  }
  return targetTypes;
};

const getReportRunType = (report) => getRunType(report.flow_type);

const getReportCreatedTimestamp = (report) => report.created_at;

const getReportSharedTimestamp = (report) => report.created_at;

const getReportPopInfo = (report) => {
  let popInfo = { type: null, title: null, content: null };
  const translation = StatusCodeTranslation[report.status];
  if (!translation) {
    return popInfo;
  }
  popInfo.type = translation.type;
  popInfo.title = translation.name;

  popInfo.content = (
    <>
      <p>
        <code>{translation.description}</code>
      </p>
      {translation.message && <p>{translation.message}</p>}
      <p>
        If you need to request support about this error, go{' '}
        <IntercomText id='intercom' className='intercom-text'>
          here
        </IntercomText>
        .
      </p>
    </>
  );
  return popInfo;
};

const getReportResult = (report) => {
  const statusType = getReportStatusType(report);
  const summary = getReportSummary(report);
  const link = `/report/${report._id}`;
  let popInfo = getReportPopInfo(report);
  if (statusType === 'success' && summary) {
    popInfo = {};
  }
  return {
    summary,
    link,
    statusType,
    popContentType: popInfo.type,
    popContentTitle: popInfo.title,
    popContent: popInfo.content,
  };
};

export const createReportRow = (report) => {
  const row = {
    // unique row id
    id: report._id,
    // column cell values
    name: report.name,
    targets: getReportTargets(report),
    targetsList: getReportTargets(report, false),
    targetTypes: getReportTargetTypes(report),
    sourceType: getReportSourceType(report),
    runType: getReportRunType(report),
    asset: report.assetInfo?.symbol ?? '-',
    createdTimestamp: getReportCreatedTimestamp(report),
    sharedTimestamp: getReportSharedTimestamp(report),
    result: getReportResult(report),
    // additional data
    isEditable: !report.is_shared_with_me,
    isDeleteAllowed: !report.is_shared_with_me,
    report,
  };
  return row;
};
