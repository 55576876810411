import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ErrorIcon from '@material-ui/icons/Error';
const shapeshiftIcon = `${process.env.PUBLIC_URL}/img/shapeshift.png`;

export default function otherFormatter(data, row) {
  const {
    fromIsFlagged,
    hasShapeshift,
    toIsFlagged,
    fromFlaggedCategory,
    fromFlaggedDescription,
    fromFlaggedSource,
    toFlaggedCategory,
    toFlaggedDescription,
    toFlaggedSource,
  } = data;

  // TODO: pass in popover content. too much business logic here
  const flaggedPopover = (
    <Popover id='popover-info' title=''>
      {fromIsFlagged && (
        <div>
          <strong>&quot;From&quot; Address is flagged:</strong>
          <br />
          <br />
          Category: {fromFlaggedCategory || 'Unknown'} <br />
          Description: {fromFlaggedDescription || 'Unknown'} <br />
          Source: {fromFlaggedSource || 'Unknown'} <br /> <br />
        </div>
      )}
      {toIsFlagged && (
        <div>
          <strong>&quot;To&quot; Address is flagged:</strong>
          <br />
          <br />
          Category: {toFlaggedCategory || 'Unknown'} <br />
          Description: {toFlaggedDescription || 'Unknown'} <br />
          Source: {toFlaggedSource || 'Unknown'}
        </div>
      )}
    </Popover>
  );

  const shapeshiftPopover = (
    <Popover id='popover-info' title=''>
      <strong>
        &quot;To&quot; Address has at least 1 associated ShapeShift transaction,
        the most recent of which (at the time of this report) is:
      </strong>
      <br />
      <br />
      Withdraw Tx ID: {row['shapeshift_withdraw_txid'] || 'Unknown'} <br />
      Withdraw Coin: {row['shapeshift_withdraw_coin'] || 'Unknown'} <br />
      Withdraw Address: {row['shapeshift_withdraw_address'] || 'Unknown'}
    </Popover>
  );

  return (
    <div className='table-id-parent row inline abrow'>
      {(fromIsFlagged || toIsFlagged) && (
        <OverlayTrigger
          rootClose
          trigger='click'
          placement='top'
          overlay={flaggedPopover}
          className='flagged-shapeshift-overlay'
        >
          <ErrorIcon title='flagged' className='flagged-icon' />
        </OverlayTrigger>
      )}

      {hasShapeshift && (
        <OverlayTrigger
          rootClose
          trigger='click'
          placement='top'
          overlay={shapeshiftPopover}
          className='flagged-shapeshift-overlay'
        >
          <img
            src={shapeshiftIcon}
            alt='ShapeShift'
            width='20'
            height='20'
            className='shapeshift-icon'
          ></img>
        </OverlayTrigger>
      )}
    </div>
  );
}
