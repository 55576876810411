import { useEffect, useReducer, useCallback, useState, useRef } from 'react';
import { useGraph } from '../useGraph';
import transactionReducer from './reducer';
import { setTxs, setActiveTxIds, setSearch } from './actions';
import {
  clearSelection,
  removeSelection,
  addSelection,
} from 'hooks/useGraph/actions';

const INITIAL_STATE = {
  search: null,
  txs: {},
  activeTxIds: [],
  filteredTxs: [],
};

const useTransactions = () => {
  const [state, dispatch] = useReducer(transactionReducer, INITIAL_STATE);
  const { state: graphState, dispatch: graphDispatch, chartRef } = useGraph();
  const [attentionIntervals, setAttentionIntervals] = useState([]);
  const clusterColorCache = useRef({});

  useEffect(() => {
    dispatch(setTxs(graphState.ReportData.transactions));
  }, [graphState.ReportData.transactions]);

  useEffect(() => {
    const viewableTxIds = [...graphState.viewableTxIds];
    dispatch(setActiveTxIds(viewableTxIds));
  }, [graphState.viewableTxIds]);

  const setTransactionSearch = useCallback(
    (search) => {
      dispatch(setSearch(search));
    },
    [dispatch],
  );

  const setSelectedClusterIds = useCallback(
    (ids) => {
      if (!ids.length) {
        return;
      }
      // ported from https://github.com/elementus-io/elementus-forensics-webapp/blob/fffee2f1c272bf5d491dc8608ae336be3a1a8bdb/src/components/Report/Table/formatters/button.js#L57
      // previous implementation only supported isolating
      // one node from the transaction table
      const id = ids[0];
      if (graphState.selection.has(id)) {
        if (graphState.combine.properties.length > 0) {
          graphDispatch(clearSelection());
        } else {
          graphDispatch(removeSelection(id, null, null, null, chartRef));
        }
      } else {
        graphDispatch(addSelection([id], null, null, chartRef));
      }
    },
    [
      graphDispatch,
      graphState.combine.properties.length,
      graphState.selection,
      chartRef,
    ],
  );

  const setAttentionClusterIds = useCallback(
    (ids) => {
      // HACK: the useTransactions hook should not be controlling the graph UI
      const attentionPing = (obj, chart) => {
        if (obj && chart && chart.ping) {
          chart.ping(obj, {
            haloRadius: 100,
            haloWidth: 50,
            linkWidth: 20,
            time: 800,
            repeat: 1,
            color: 'white',
          });
        }
      };
      if (!chartRef || !chartRef.current) {
        return;
      }
      const chart = chartRef.current;

      if (ids !== null && ids.length) {
        const newIntervals = [];
        ids.forEach((clusterId) => {
          if (clusterId in graphState.ReportData.clusterLookUp) {
            graphState.ReportData.clusterLookUp[clusterId].unqIds.forEach(
              (uniqueId) => {
                attentionPing(uniqueId, chart);
                const intervalPing = setInterval(() => {
                  attentionPing(uniqueId, chart);
                }, 1000);
                newIntervals.push(intervalPing);
              },
            );
          }
        });
        setAttentionIntervals((oldVal) => [...oldVal, ...newIntervals]);
      } else {
        attentionIntervals.forEach((interval) => clearInterval(interval));
        setAttentionIntervals([]);
      }
    },
    [attentionIntervals, chartRef, graphState.ReportData.clusterLookUp],
  );

  const getClusterColor = useCallback(
    (clusterId) => {
      if (clusterId in clusterColorCache.current) {
        return clusterColorCache.current[clusterId];
      }
      const graphItemIds = Object.keys(graphState.ReportData.items);
      for (let i = 0; i < graphItemIds.length; i++) {
        const graphItemId = graphItemIds[i];
        if (graphItemId.includes(clusterId) && !graphItemId.includes('|')) {
          const item = graphState.ReportData.items[graphItemId];
          if (item) {
            const { color } = item;
            clusterColorCache.current[clusterId] = color;
            return color;
          }
        }
      }
    },
    [graphState.ReportData.items],
  );

  return {
    transactions: state.filteredTxs,
    setTransactionSearch,
    setSelectedClusterIds,
    setAttentionClusterIds,
    getClusterColor,
  };
};

export default useTransactions;
