import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faCrosshairs,
} from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '../styles';

const ActionType = {
  blockExplorer: {
    title: 'Open in external block explorer',
    icon: faExternalLinkAlt,
  },
  isolate: {
    title: 'Isolate transaction',
    icon: faCrosshairs,
  },
};

const buttonFormatter = (cell, row, rowIdx, extraData) => {
  const { onClick } = extraData.customEvents;
  const { title, icon } = ActionType[cell.actionType];
  const { hidden } = cell;
  const createButton = () => {
    return (
      <>
        <div className='table-id-parent row inline abrow'>
          <div className='col-md-2 no-margin addrbtn'>
            <IconButton
              type='button'
              title={title}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick(cell, row, rowIdx, extraData);
              }}
              disabled={!cell.isEnabled}
            >
              <FontAwesomeIcon icon={icon} color='white' />
            </IconButton>
          </div>
        </div>
      </>
    );
  };
  return hidden ? null : createButton();
};

export default buttonFormatter;
