import styled from 'styled-components';
import Modal from 'react-modal';
import { styleRefs as SR } from 'styles/styleRefs';

export const DropdownContainer = styled.div`
  text-align: center;
  ul {
    min-width: auto;
  }

  .MuiFormControl-root {
    width: 100%;
    max-height: 140px;
    overflow-y: auto;
    height: auto;
  }
`;

export const ModalWithNoBackground = styled(Modal)`
  background: none !important;
  outline: none;
`;

export const UserInputContainer = styled.div`
  top: 8%;
  right: 0%;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .filterDiv {
    display: flex;
    justify-content: flex-end;
  }
`;

export const UserInputModal = styled.div`
  height: 450px;

  .user-input-button {
    fill: 'white';
    -moz-transition: 0.8s ease-in-out; /* Firefox */
    -webkit-transition: 0.8s ease-in-out; /* WebKit */
    -o-transition: 0.8s ease-in-out; /* Opera */
    transition: 0.8s ease-in-out; /* Standard */

    &:hover {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .user-input-panel {
    height: 100%;
    width: 100%;

    &.panel {
      border-color: transparent;
      background: ${SR.colors.nuetral_DarkPurple};

      .panel-heading {
        background: ${SR.colors.nuetral_DarkPurple} !important;
        color: white !important;
        display: inline-flex;
        justify-content: center;
        width: 100%;

        .info-icon-new-report {
          margin-left: 5px;
          margin-top: 3px;
        }

        .modal-title {
          color: white;
        }
      }

      .panel-body {
        color: white;

        .user-input-boxes {
          display: inline-flex;
          justify-content: space-around;
          width: 100%;
          margin-top: 25px;

          .MuiFormHelperText-root {
            color: red;
            font-size: 10px;
          }

          .center-field {
            margin-left: 90px;
            margin-right: 90px;
          }
        }

        .user-input-button-row {
          margin-left: 5px;

          button {
            font-size: 12px;
          }
          .update-graph-btn {
            margin-left: 50px;
            position: absolute;
            bottom: 5%;
            right: 5%;
          }

          .cancel-update-btn {
            background: #ff8080 !important;
            position: absolute;
            bottom: 5%;
            left: 5%;
            &:hover,
            &:focus {
              background: red !important;
            }
          }
        }

        .text-field-container {
          margin-top: 50px;
        }

        #slidercontainer {
          z-index: 20;
          position: relative;
          margin-top: 25px;

          span.MuiSlider-track,
          span.MuiSlider-thumb,
          span.PrivateValueLabel-circle-173 {
            background-color: ${SR.colors.emphasis_lightPurple};
          }
          span.MuiSlider-rail {
            background-color: white;
          }
        }
      }
    }

    .field-title {
      margin-bottom: 15px;
      font-size: 14px;
    }

    // not sure these are being used
    .info-icon-report {
      margin-bottom: -6px;
      margin-left: 3px;
    }

    .info-icon-edit {
      fill: ${SR.colors.mutedWhitePurple} !important;
      margin-bottom: -4px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const NewAgentForm = styled.form`
  .bounce {
    animation: bounce 2s;
    animation-iteration-count: infinite;
  }

  @keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-6px);
    }
  }
`;

export const NewAgentInput = styled.input`
  width: ${(props) => (props.clicked ? '200px' : 0)};
  visibility: ${(props) => (props.clicked ? 'visible' : 'hidden')};
  transition: 0.8s ease-in-out;
`;
