const floatToBigNumNotation = (val, decimalPrecision = 1) => {
  const number = parseFloat(val);

  if (Number.isNaN(number)) {
    return undefined;
  }

  let denomination = '';
  let orderOfMagnitude = 1;
  let wholePart = Math.trunc(number);

  // Only add remainder if it makes sense to
  const setRemainder = (val) => {
    if (val == null || val.length === 0) return '';
    if (val === '0' || val === '00') return '';
    if (val[1] === '0') return '';

    return '.' + val;
  };

  const buildLabel = (wholePart, remainder, denomination) =>
    `${Math.trunc(wholePart)}${remainder}${denomination}`;

  // for numbers < 1000 use 2 digits of precision
  if (wholePart < 1e3) {
    decimalPrecision = 2;
    let remainder = setRemainder(
      getDecimalPart(number).toString().substring(0, decimalPrecision),
    );

    return buildLabel(wholePart, remainder, denomination);
  }

  if (wholePart < 1e6) {
    orderOfMagnitude = 1e3;
    denomination = 'k';
  } else if (wholePart < 1e9) {
    orderOfMagnitude = 1e6;
    denomination = 'm';
  } else if (wholePart < 1e12) {
    orderOfMagnitude = 1e9;
    denomination = 'b';
  } else if (wholePart < 1e15) {
    orderOfMagnitude = 1e12;
    denomination = 't';
  }

  wholePart = wholePart / orderOfMagnitude;

  let remainder = setRemainder(
    getDecimalPart(wholePart).toString().substring(0, decimalPrecision),
  );

  return buildLabel(wholePart, remainder, denomination);
};

const bigNumNotationToFloat = (string) => {
  if (typeof string !== 'string' || Number.isNaN(string)) {
    return string;
  }

  let denomination = string.slice(-1);
  let orderOfMagnitude;

  switch (denomination) {
    case 'k':
      denomination = 'k';
      orderOfMagnitude = 1e3;
      break;
    case 'm':
      denomination = 'm';
      orderOfMagnitude = 1e6;
      break;
    case 'b':
      denomination = 'b';
      orderOfMagnitude = 1e9;
      break;
    case 't':
      denomination = 't';
      orderOfMagnitude = 1e12;
      break;
    default:
      denomination = '';
      orderOfMagnitude = 1;
  }

  // mult whole part by order mag
  const number = Number(string.replace(denomination, ''));
  const wholePart = getWholePart(number) * orderOfMagnitude;
  const decimalPart = getDecimalPart(number) * (orderOfMagnitude / 10);

  return wholePart + decimalPart;
};

const getWholePart = (val) => {
  const number = parseFloat(val);
  if (Number.isNaN(number)) {
    return val;
  }

  return String(number).split('.')[0];
};

const getDecimalPart = (val) => {
  if (Number.isInteger(val)) {
    return 0;
  }

  const decimalStr = val.toString().split('.')[1];
  return Number(decimalStr);
};

export { floatToBigNumNotation, bigNumNotationToFloat };
