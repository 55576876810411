import React from 'react';

const hiddenIcon = () => {
  return (
    <div className='context-icons'>
      <svg id='Layer_1' x='0px' y='0px' height='12px' viewBox='0 0 36 36'>
        <g>
          <path
            d='M6.6,30c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2L30.3,7.4c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0L6.6,28.8
		C6.2,29.1,6.2,29.7,6.6,30z'
          />
          <path
            d='M18,13.2c0.6,0,1.2,0.1,1.8,0.3c0.4,0.2,0.9,0,1.1-0.4c0.2-0.4,0-0.9-0.4-1.1c-0.8-0.3-1.6-0.4-2.4-0.4
		c-3.6,0-6.5,2.9-6.5,6.5c0,0.8,0.2,1.6,0.4,2.4c0.1,0.3,0.4,0.5,0.8,0.5c0.1,0,0.2,0,0.3,0c0.4-0.2,0.6-0.7,0.4-1.1
		c-0.2-0.6-0.3-1.2-0.3-1.8C13.1,15.4,15.3,13.2,18,13.2z'
          />
          <path
            d='M18,23c-0.4,0-0.8,0-1.2-0.2c-0.4-0.1-0.9,0.2-1,0.6c-0.1,0.4,0.2,0.9,0.6,1c0.6,0.2,1.1,0.2,1.6,0.2
		c3.6,0,6.5-2.9,6.5-6.5c0-0.5-0.1-1.1-0.2-1.6c-0.1-0.4-0.6-0.7-1-0.6c-0.4,0.1-0.7,0.6-0.6,1c0.1,0.4,0.2,0.8,0.2,1.2
		C22.9,20.8,20.7,23,18,23z'
          />
          <path
            d='M9.1,24.6c0.3,0,0.6-0.2,0.7-0.4c0.2-0.4,0.1-0.9-0.3-1.1c-2-1.2-4.1-2.8-6.3-4.8c7.2-6.9,12.9-9,18.9-7.1
		c0.4,0.1,0.9-0.1,1-0.5c0.1-0.4-0.1-0.9-0.5-1c-6.7-2.1-13.2,0.3-21.1,8c-0.2,0.2-0.2,0.4-0.2,0.6v0c0,0.2,0.1,0.4,0.2,0.6
		c2.5,2.4,4.8,4.2,7.2,5.6C8.8,24.6,8.9,24.6,9.1,24.6z'
          />
          <path
            d='M34.8,18.4L34.8,18.4c0-0.2-0.1-0.5-0.2-0.6c-2.4-2.3-4.6-4.1-6.7-5.5c-0.4-0.2-0.9-0.1-1.1,0.2c-0.2,0.4-0.1,0.9,0.2,1.1
		c1.9,1.2,3.8,2.8,5.9,4.7c-6.5,6.2-12.4,8.4-18.4,6.9c-0.4-0.1-0.9,0.2-1,0.6c-0.1,0.4,0.2,0.9,0.6,1c1.3,0.3,2.6,0.5,3.9,0.5
		c5.4,0,10.8-2.7,16.5-8.3C34.7,18.8,34.8,18.6,34.8,18.4z'
          />
        </g>
      </svg>
    </div>
  );
};
export default hiddenIcon;
