import React, { useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import ChipInput from 'material-ui-chip-input';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CloseButton from 'components/Common/CloseButton';
import { PurpleActiveButton } from 'styles/';
import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';
import {
  useTrapFocus,
  useBodyScrollLock,
  useCloseOnEsc,
} from '@weahead/react-customizable-modal';
import { UserContext } from 'contexts/user';

/** Email Check */
const regex =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

export default function Share({ onClose, report }) {
  const [viewers, setViewers] = useState(report.share_users);
  const { apiClient } = useContext(UserContext);
  useBodyScrollLock();
  useCloseOnEsc(() => onClose(viewers, false, false));

  const modalRef = useTrapFocus();

  const overlayProps = useSpring({
    backgroundColor: 'rgba(0,0,0,0.6)',
    from: { backgroundColor: 'rgba(0,0,0,0)' },
  });

  const modalProps = useSpring({
    transform: 'scale(1)',
    from: { transform: 'scale(0)' },
    config: { mass: 1, tension: 280, friction: 25 },
  });

  const handleChange = (chips) => {
    setViewers(chips);
  };

  return (
    <ModalStyle>
      <animated.div
        style={{
          ...overlayProps,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <animated.div
          ref={modalRef}
          style={{
            ...modalProps,
            width: 500,
            backgroundColor: '#e2deeb',
            padding: 20,
            position: 'relative',
            borderRadius: '10px',
          }}
        >
          <CloseButton onClose={() => onClose(viewers, false, false)} />

          <center>
            <Typography variant='h4'>Share Report</Typography>
          </center>

          <div style={{ padding: '0px 0px 30px 0px' }}>
            <Typography variant='h5'>Report Owner</Typography>
            <Chip
              icon={<FaceIcon />}
              label={report.user_email}
              className='chip-report-owner'
            />
          </div>

          <div style={{ padding: '0px 0px 30px 0px' }}>
            {!report.is_shared_with_me && (
              <div style={{ padding: '0px 0px 0px 0px' }}>
                <Typography variant='h5'>Current Viewers</Typography>
                <ChipInput
                  fullWidth={true}
                  variant={'filled'}
                  newChipKeyCodes={[188, 186, 9, 13, 32]}
                  defaultValue={viewers}
                  helperText={
                    <>Enter emails of users you would like to share to.</>
                  }
                  onBeforeAdd={(val) => regex.test(val)}
                  onChange={(chips) => handleChange(chips)}
                  style={{
                    fontSize: '1em',
                  }}
                />
              </div>
            )}
          </div>

          {/** If I am the owner of this report. */}
          {!report.is_shared_with_me && (
            <center style={{ padding: 30 }}>
              <PurpleActiveButton
                onClick={() => {
                  apiClient
                    .patchReportsSharing(report._id, viewers)
                    .catch(console.error);
                  return onClose(viewers, true, false);
                }}
              >
                <SaveIcon />
                <p style={{ fontSize: SR.fontSize.med }}>Save</p>
              </PurpleActiveButton>
            </center>
          )}

          {/** If this report is shared with me but I am not the owner */}
          {report.is_shared_with_me && (
            <center style={{ padding: '0px 0px 0px 0px' }}>
              <PurpleActiveButton
                onClick={() => {
                  apiClient
                    .deleteReportSharing(report._id)
                    .catch(console.error);
                  return onClose(viewers, false, true);
                }}
              >
                <RemoveCircleOutlineIcon />
                <p style={{ fontSize: SR.fontSize.med }}> Unfollow Report</p>
              </PurpleActiveButton>
            </center>
          )}
        </animated.div>
      </animated.div>
    </ModalStyle>
  );
}

export const ModalStyle = styled.div`
  .MuiChip-root,
  .MuiInputBase-root {
    font-size: 1em;
  }
  .MuiChip-root {
    font-size: 1em;
    margin-top: ${SR.margin.half};
    background-color: #753bf2;
    color: #fff;
    svg {
      fill: #fff;
    }
    &:hover {
      background-color: #753bf2;
    }
  }
  .MuiFormHelperText-root {
    font-size: 0.8em;
  }
  .MuiChip-colorPrimary {
    background-color: '#753bf2';
  }
  .chip-report-owner {
    background-color: #4c3086;
  }
`;
