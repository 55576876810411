import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { clearSelection, addSelection } from 'hooks/useGraph/actions';
import { useGraph } from 'hooks/useGraph/';
import { styleRefs as SR } from 'styles/styleRefs';

const Stat = ({
  statUnits,
  statDetail,
  statWidth,
  statData,
  statText,
  statNumber,
  isReportType,
}) => {
  const { state, dispatch, chartRef } = useGraph();
  const [selectToggled, setSelectToggled] = useState(false);
  const [anchorEl] = React.useState(null);

  //First lets handle outside effects from state.
  useEffect(() => {
    // We toggle 'selectToggle' to true if the items selected are from another component.
    if (!_.isEmpty(state.selection) && statData && statData.length) {
      let cnt = 0;
      for (const id of statData) if (state.selection.has(id)) cnt++;
      if (cnt === statData.length) setSelectToggled(true);
    } else {
      setSelectToggled(false);
    }
  }, [state.selection, statData, state.viewableItems]);
  const handleClick = () => {
    if (!statData) return;
    if (!statData.length) return;
    if (selectToggled) {
      dispatch(clearSelection());
      setSelectToggled(false);
    } else {
      dispatch(addSelection(statData, null, null, chartRef));
      setSelectToggled(true);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  var outerClass = 'statcontainer center col-xs-4';
  if (statWidth) outerClass = 'statcontainer col-xs-' + statWidth;
  if (statDetail) outerClass = outerClass + ' stdetail';

  var statUnitsLocal = (
    <span>
      <center></center>
    </span>
  );
  if (statUnits)
    statUnitsLocal = <span className='statunits'>{statUnits}</span>;

  if (!statDetail) {
    return (
      <div
        className={outerClass}
        style={{
          alignContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <div className={isReportType ? 'stattoptext' : 'statnumber'}>
          {statNumber} {statUnitsLocal}
        </div>
        <div className='stattext'>{statText}</div>
      </div>
    );
  } else {
    return (
      <center>
        <div className={outerClass}>
          <div className='statnumber'>
            {statNumber} {statUnitsLocal}
          </div>
          <div className='stattext'>{statText}</div>
          <ModButton
            aria-describedby={id}
            variant='contained'
            color='primary'
            onClick={handleClick}
            className='statbutton flux-btn-mod'
          >
            {selectToggled ? 'Deselect' : 'Select'}
          </ModButton>
        </div>
      </center>
    );
  }
};

export default Stat;
// We've styled this button soooo many times
const ModButton = withStyles({
  root: {
    backgroundColor: SR.colors.emphasis_lightPurple,
    borderColor: SR.colors.emphasis_lightPurple,
    fontSize: SR.fontSize.sml,
    height: '24.5px',
    '&:hover': {
      backgroundColor: SR.colors.mutedGreyPurple,
      borderColor: SR.colors.emphasis_lightPurple,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: SR.colors.emphasis_lightPurple,
      borderColor: SR.colors.nearBlackPurple,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    '.MuiButton-label': {
      position: 'relative',
      top: '-1px',
    },
  },
})(Button);
