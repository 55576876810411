import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Panel } from 'components/Common/Panel';
import { NotificationsTableConfig } from './table.config';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

export const NotificationsList = ({ data }) => {
  return (
    <Panel className='active-alerts'>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>Notifications List</Panel.Title>
      </Panel.Heading>

      <Panel.Body className='notifications'>
        <BootstrapTable
          keyField='_id'
          data={data}
          columns={NotificationsTableConfig}
          bordered={false}
          defaultSorted={[{ dataField: 'data', order: 'asc' }]}
          noDataIndication='No notifications have been sent.'
          pagination={paginationFactory()}
        />
      </Panel.Body>
    </Panel>
  );
};
