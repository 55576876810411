import { createGlobalStyle } from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export const GlobalStyles = createGlobalStyle`
  @font-face {
  font-family: 'bitcoinregular';
  src: url('bitcoin-webfont.eot');
  src: url('bitcoin-webfont.eot?#iefix') format('embedded-opentype'),
       url('bitcoin-webfont.woff2') format('woff2'),
       url('bitcoin-webfont.woff') format('woff'),
       url('bitcoin-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Verlag A', 'Verlag B';
  background-color: transparent !important;
  overflow-x: hidden;
}

html {
  background-color: ${SR.colors.nearBlackPurple};
}

#Avatar {
  width: 30px;
  height: 30px;
  margin: 5px;
}

text {
  font-family: 'Verlag A', 'Verlag B';
}

area:hover {
  cursor: pointer !important;
}

div.abrow {
  margin-left: -8px;
  justify-content: start;
}

.intercom-text {
  color: #0000ee;
  cursor: pointer;
  font-weight: bold;
}

span.MuiTypography-root {
  font-size: 1.3rem;
}

div.MuiListItemText-root {
  color: white;
}

header.MuiAppBar-root {
  display: none;
}

div.MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.makeStyles-root-188 {
  margin-top: 10px;
}

// TODO wrap styles of flux-btn with button and import it
// has to remain a global style because of csv link
.flux-btn,
button.flux-btn,
a.flux-btn {
  outline: none !important;
  outline-offset: none !important;
  -webkit-font-smoothing: antialiased;
  overflow: visible;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 1px 16px 0 16px;
  font-size: ${SR.fontSize.sml};
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  /*line-height: 1.75;*/
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: white;
  background-color: ${SR.colors.emphasis_lightPurple};

  line-height: 3em;

  &.passive {
    background-color: ${SR.colors.nuetral_DarkPurple};
  }
}

.flux-btn:hover,
.flux-btn:focus {
  background-color: ${SR.colors.mutedGreyPurple} !important;
  color: white !important;
  text-decoration: none !important;
}

.flux-btn>span,
button.flux-btn>span,
a.flux-btn>span {
  font-size: ${SR.fontSize.sml};
}

.flux-btn.flux-btn-bg {
  font-size: ${SR.fontSize.standard};
}

div>ul.pagination>li.page-item.active>a {
  background-color: rgb(117, 59, 242);
  border-color: rgb(117, 59, 242);
}

div>ul.pagination>li.page-item.active>a:hover {
  background-color: rgb(117, 59, 242);
  border-color: rgb(117, 59, 242);
  opacity: 1;
}

// React bootstrap table

.react-bootstrap-table {
  td {
    vertical-align: middle;
  }
}

.is-disabled {
  opacity: 0.8;
  pointer-events: none;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ul {
    margin: 0;
  }
}

// Modals

#tooltip-autocomplete {
  opacity: 1;
  width: 550px;

  .tooltip-arrow {
    display: none;
  }

  .tooltip-inner {
    max-width: 600px;
    background-color: ${SR.colors.mediumPurple};
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    padding: 0;

    .autocomplete-items {
      display: flex;
      flex-direction: column;
      text-align: left;

      .autocomplete-item {
        padding: 2px 8px;
        font-size: 15px;
        color: ${SR.colors.mutedWhite};
        background-color: ${SR.colors.mediumPurple};
        text-align: left;

        &:hover {
          cursor: pointer;
          color: ${SR.colors.emphasis_lightPurple};
          background-color: ${SR.colors.mutedWhite};
        }
      }
    }
  }
}

.ReactModal__Content {
  background: transparent !important;
}

.ReactModal__Body--open {
  overflow-y: hidden !important;
}

.addresspopover {
  background-color: rgba(255, 255, 255, 0.92);
  padding: 16px 20px 20px 20px;

  .metakey {
    font-family: 'Verlag A', 'Verlag B', 'arial';
    font-weight: 700;
  }

  .metaval {
    font-family: 'HelveticaNeue-Thin', 'HelveticaNeueThin',
      'Helvetica Neue Thin', 'HelveticaNeueLight', 'HelveticaNeue-Light',
      'Helvetica Neue Light', 'HelveticaNeue', 'Helvetica Neue',
      'TeXGyreHerosRegular', 'Helvetica', 'Tahoma', 'Geneva', 'Arial',
      sans-serif;
    font-size: 12px;
    letter-spacing: 0.01071em;
    padding-left: 20px;
    font-weight: 500;
  }

  #metaaddresses {
    max-height: 60vh;
    overflow-y: scroll;
    border: 1px solid white;
    background-color: white;
    box-sizing: content-box;
    border-radius: 2px;
  }

  #metaaddresses div {
    border-bottom: 1px solid white;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    letter-spacing: 0.01071em;
    border-spacing: 0;
    border-collapse: collapse;
    white-space: nowrap;
    line-height: 1.5;
    text-align: left;
    box-sizing: border-box;
    background-color: transparent;
    opacity: 1;
    font-family: 'HelveticaNeue-Thin', 'HelveticaNeueThin',
      'Helvetica Neue Thin', 'HelveticaNeueLight', 'HelveticaNeue-Light',
      'Helvetica Neue Light', 'HelveticaNeue', 'Helvetica Neue',
      'TeXGyreHerosRegular', 'Helvetica', 'Tahoma', 'Geneva', 'Arial',
      sans-serif;
    font-weight: 200;
    font-size: 12px;
    outline: 0;
    padding: 2px 6px;
    color: black;
  }
}

#background-layer {
  // styles mobile disclaimer
  background-color: ${SR.colors.nuetral_DarkPurple};
  height: 100vh;
  width: 100wv;
}

.scrollable-wrapper {
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;

  .scrollable-div {
    overflow-y: auto;
  }
}

.collapsable-item {
  width: 100%;
  padding: ${SR.padding.half};
  height: auto;
  max-height: 470px;

  .collapsable-header {
    font-family: 'Verlag A', 'Verlag B';
    font-size: ${SR.fontSize.lrg};
    font-weight: bold;
    height: 50px;
    display: flex;
    color: #fff;

    span {
      margin: auto;
    }

    &.benchmark-table {
      max-height: 700px;
    }
  }
}

// Make bootstrap inputs:focus glow match our app default purple
input.form-control:focus {
  border-color: ${SR.colors.emphasis_brightPurple};
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(128 44 251 / 60%)
}

// Utils

.flex-col {
  display: flex;
  flex-direction: column;
}

.full-width {
  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.position-absolute {
  position: absolute;
}

.margin-right {
  margin-right: ${SR.margin.std} !important;
}
`;
