import styled from 'styled-components';

export const TableStyling = styled.div`
  table.table {
    table-layout: auto;
    .breakdown {
      text-align: left;
    }

    ul {
      padding-inline-start: 15px;
    }
  }
`;
