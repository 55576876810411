import React, { useState, useEffect, useContext, useCallback } from 'react';
import _ from 'lodash';
import { Panel } from 'react-bootstrap';

import formatters from './formatters.js';
import NewReportModal from '../NewReport/NewReportModal.js';
import { createReportRow } from './listHelper.js';
import { ReportContainer } from '../../styles';

import { MyReports } from './MyReports';
import { SharedReports } from './SharedReports';
import { UserContext } from 'contexts/user';
import { Product } from 'utils/enums.js';
import { ShareReportModal } from 'components/Modals/ShareReport.js';
import { DeleteReportModal } from 'components/Modals/DeleteReport.js';
import {
  CreatePulseReportButton,
  CreateRadarReportButton,
} from 'components/Buttons/generics.js';
import ForensicAnalysis from 'models/forensicAnalysis';

const ReportsList = () => {
  const { user, apiClient } = useContext(UserContext);
  const [ownedReports, setOwnedReports] = useState(null);
  const [sharedReports, setSharedReports] = useState(null);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [ifComplianceModal, set_ifComplianceModal] = useState(false);

  const [openModal, setopenModal] = useState(false);
  const toggleModal = () => setopenModal(!openModal);

  const reloadReportsOwned = useCallback(
    (isOwned) => {
      const getReports = async () => {
        apiClient.getForensicAnalysisList(isOwned).then((reports) => {
          reports = reports
            .sort((a, b) => {
              let aa = new Date(a.created_at);
              let bb = new Date(b.created_at);
              return bb.getTime() - aa.getTime();
            })
            .filter(
              (analysis) => analysis.deleted !== true && !analysis.parentId,
            );
          reports = reports.map(ForensicAnalysis.normalizeReportMeta);
          if (isOwned) {
            setOwnedReports(reports);
          } else {
            setSharedReports(reports);
          }
          return reports;
        });
      };
      getReports();
    },
    [apiClient],
  );

  const reloadReports = useCallback(() => {
    reloadReportsOwned(false);
    reloadReportsOwned(true);
  }, [reloadReportsOwned]);

  useEffect(() => {
    reloadReports();
  }, [apiClient, reloadReports]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        ownedReports &&
        ownedReports.slice(0, 8).some((r) => r.status === '202')
      ) {
        reloadReports();
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [ownedReports, reloadReports]);

  const addNewReport = (rep) => {
    const newReps = _.cloneDeep(ownedReports);
    newReps.unshift(rep);
    setOwnedReports(newReps);
  };

  const saveReportNameChange = async (name, reportId) => {
    try {
      await apiClient.patchNameForForensicAnalysis(reportId, name);
      const report = ownedReports.find((r) => r._id === reportId);
      if (report) {
        report.name = name;
      }
    } catch (err) {
      console.error('failed to save report name change');
      throw err;
    }
  };

  const beforeSaveCell = async (_oldValue, newValue, row, column, done) => {
    if (column.dataField === 'name') {
      saveReportNameChange(newValue, row.id)
        .then(done)
        .catch(() => done(false));
      return { async: true };
    } else {
      console.error(`Editing not supported for "${column.text}"`);
    }
  };

  const columns = (isShared = false) => [
    {
      dataField: 'name',
      text: 'Report Name',
      formatter: formatters.nameFormatter,
      headerStyle: () => ({ width: '16%' }),
      sort: true,
    },
    {
      dataField: 'targets',
      text: 'Addresses or Transactions | Entity',
      formatter: formatters.targetFormatter,
      editable: false,
      headerStyle: () => ({ width: '32%' }),
      sort: true,
    },
    {
      dataField: 'targetsList',
      text: '',
      formatter: formatters.targetsListFormatter,
      editable: false,
      headerStyle: () => ({ width: '4%' }),
    },
    {
      dataField: 'sourceType',
      text: 'Src Type',
      editable: false,
      headerStyle: () => ({ width: '12%' }),
      sort: true,
    },
    {
      dataField: 'runType',
      text: 'Run Type',
      headerAlign: 'left',
      dataAlign: 'center',
      headerStyle: () => ({ width: '12%' }),
      editable: false,
      sort: true,
    },
    {
      dataField: 'asset',
      text: 'Asset',
      formatter: formatters.assetFormatter,
      headerAlign: 'left',
      dataAlign: 'center',
      headerStyle: () => ({ width: '9%' }),
      editable: false,
      sort: true,
    },
    {
      dataField: 'createdTimestamp',
      text: 'Created On',
      formatter: formatters.timestampFormatter,
      editable: false,
      headerStyle: () => ({ width: '13%' }),
      sort: true,
    },
    {
      dataField: 'sharedTimestamp',
      hidden: isShared ? false : true,
      text: 'Shared On',
      formatter: formatters.timestampFormatter,
      editable: false,
      headerStyle: () => ({ width: '13%' }),
      sort: true,
    },
    {
      dataField: 'result',
      text: '',
      formatter: formatters.resultFormatter,
      editable: false,
      headerAlign: 'left',
      dataAlign: 'center',
      headerStyle: () => ({ width: '12%' }),
    },
    {
      dataField: 'share',
      isDummyField: true,
      text: '',
      formatter: (cell, row) =>
        formatters.shareReportFormatter({
          row,
          setIsShareOpen,
          setSelectedReport,
        }),
      editable: false,
      headerStyle: () => ({ width: '45px' }),
      headerAlign: 'left',
      dataAlign: 'center',
    },
    {
      dataField: 'delete',
      isDummyField: true,
      text: '',
      formatter: (cell, row) =>
        formatters.deleteReportFormatter({
          row,
          setIsDeleteOpen,
          setSelectedReport,
        }),
      editable: false,
      headerStyle: () => ({ width: '45px' }),
      headerAlign: 'left',
      dataAlign: 'center',
    },
  ];

  const ownedRows = ownedReports ? ownedReports.map(createReportRow) : [];
  const sharedRows = sharedReports ? sharedReports.map(createReportRow) : [];
  const isRadixEnabled = user.isAuthorized(Product.RADIX);
  const isFluxEnabled = user.isAuthorized(Product.FLUX);

  return (
    <ReportContainer>
      <div id='report-frame-list' className='report-frame-new-list light-col'>
        <div data-testid='panel' className='panel-container'>
          <Panel bsStyle='primary' data-testid='panel2'>
            <Panel.Heading
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderColor: 'transparent',
                background: 'transparent',
              }}
            >
              <Panel.Title componentClass='h1'>Report List</Panel.Title>

              <div
                className='report-creation-btn-wrapper'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CreatePulseReportButton
                  show={isFluxEnabled}
                  toggleModal={toggleModal}
                  set_ifComplianceModal={set_ifComplianceModal}
                />

                <CreateRadarReportButton
                  show={isRadixEnabled}
                  toggleModal={toggleModal}
                  set_ifComplianceModal={set_ifComplianceModal}
                />

                <NewReportModal
                  reloadList={reloadReports}
                  ifComplianceModal={ifComplianceModal}
                  addNewReport={addNewReport}
                  toggleModal={toggleModal}
                  openModal={openModal}
                />
              </div>

              {/** Modal : Share Report */}
              <ShareReportModal
                show={isShareOpen}
                setIsShareOpen={setIsShareOpen}
                ownedReports={ownedReports}
                setOwnedReports={setOwnedReports}
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
                sharedReports={sharedReports}
                setSharedReports={setSharedReports}
              />

              {/** Modal : Confirm Delete */}
              <DeleteReportModal
                show={isDeleteOpen}
                setIsDeleteOpen={setIsDeleteOpen}
                ownedReports={ownedReports}
                setOwnedReports={setOwnedReports}
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
              />
            </Panel.Heading>

            <Panel.Body>
              <MyReports
                beforeSaveCell={beforeSaveCell}
                columns={columns}
                ownedReports={ownedReports}
                ownedRows={ownedRows}
              />
              <hr></hr>
              <SharedReports
                columns={columns}
                sharedRows={sharedRows}
                sharedReports={sharedReports}
              />
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </ReportContainer>
  );
};

export default ReportsList;
