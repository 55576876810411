import React from 'react';
import { arc } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { format } from 'd3-format';
import { GaugeContainer } from '../styles';
import { styleRefs as SR } from 'styles/styleRefs';

const colorRange = {
  LOW: SR.colors.emphasis_lightPurple,
  MODERATE: SR.colors.offWhite,
  HIGH: SR.colors.contrasting_YellowOrange,
  'VERY HIGH': SR.colors.brightRed,
};

const colorBinPoints = [0.0, 0.1, 0.3, 0.6, 1.0];

const Gauge = ({ value, min, max }) => {
  const innerRadius = 0.85;

  const backgroundArc = arc()
    .innerRadius(innerRadius)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(1)();

  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);

  // arbitrarily cap the risk score at 100%. Real fix will happen in Neo4j.
  const percent = percentScale(Math.min(value * 100, 100));

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);

  const angle = angleScale(percent);

  const filledArc = arc()
    .innerRadius(innerRadius)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(1)();

  let demarcation = {};
  let designation;
  const colorRangePoints = [];

  Object.keys(colorRange).forEach((range, i) => {
    let min;
    let max;
    if (i === 0) {
      min = colorBinPoints[i];
      max = colorBinPoints[i + 1];
    } else if (i === Object.keys(colorRange).length - 1) {
      min = colorBinPoints[i];
      max = colorBinPoints[i + 1];
    } else {
      min = colorBinPoints[i];
      max = colorBinPoints[i + 1];
    }
    if (min <= value && value <= max) designation = Object.keys(colorRange)[i];
    colorRangePoints.push([min + (max - min) / 2]);

    demarcation[range] = [min, max];
  });

  const colorScale = scaleLinear()
    .domain(colorRangePoints)
    .range(Object.values(colorRange));

  const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value));
  const markerLocation = getCoordsOnArc(angle, 1 - (1 - innerRadius) / 2);

  return (
    <GaugeContainer className='risk-gauge' id='risk-gauge'>
      <svg width='13em' height='10em' viewBox={[-1, -1, 2, 1].join(' ')}>
        <defs>
          <linearGradient
            id='Gauge__gradient'
            gradientUnits='userSpaceOnUse'
            x1='-1'
            x2='1'
            y2='0'
          >
            {gradientSteps.map((color, index) => (
              <stop
                key={color}
                stopColor={color}
                offset={`${index / (gradientSteps.length - 1)}`}
              />
            ))}
          </linearGradient>
        </defs>
        <path d={backgroundArc} fill='url(#Gauge__gradient)' opacity={0.45} />
        <path d={filledArc} fill='url(#Gauge__gradient)' />

        {/* <line
          y1='-1'
          y2='-0.65'
          stroke='white'
          strokeWidth='0.027'
        /> */}

        <circle
          cx={markerLocation[0]}
          cy={markerLocation[1]}
          r='0.1'
          stroke='#2a2146'
          strokeWidth='0.01'
          fill={colorScale(percent)}
        />
        <path
          d='M0.136364 0.0290102C0.158279 -0.0096701 0.219156 -0.00967009 0.241071 0.0290102C0.297078 0.120023 0.375 0.263367 0.375 0.324801C0.375 0.422639 0.292208 0.5 0.1875 0.5C0.0852272 0.5 -1.8346e-08 0.422639 -9.79274e-09 0.324801C0.00243506 0.263367 0.0803571 0.120023 0.136364 0.0290102ZM0.1875 0.381684C0.221591 0.381684 0.248377 0.356655 0.248377 0.324801C0.248377 0.292947 0.221591 0.267918 0.1875 0.267918C0.153409 0.267918 0.126623 0.292947 0.126623 0.324801C0.126623 0.356655 0.155844 0.381684 0.1875 0.381684Z'
          transform={`rotate(${
            angle * (180 / Math.PI)
          }) translate(-0.2, -0.33)`}
          fill='#6a6a85'
        />
      </svg>

      <div className='info'>
        <figure className='valueTitle'>RISK SCORE: </figure>
        <figure className='value' style={{ color: colorScale(percent) }}>
          {format(',.02%')(value)}
        </figure>
        <figure className='riskLabels'>
          {Object.entries(colorRange).map(([label, color]) => (
            <div
              key={label}
              className='labelContainer'
              style={{ opacity: designation !== label ? 0.5 : 1 }}
            >
              <div className='labelBox' style={{ backgroundColor: color }} />
              <p>{label} </p>
            </div>
          ))}
        </figure>
      </div>
    </GaugeContainer>
  );
};
export default Gauge;

const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
];
