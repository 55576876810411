import React from 'react';
import { styleRefs as SR } from 'styles/styleRefs';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { AddressColumnStyle } from './styles';

const formatAsPercentage = (decimal) => {
  let percentage = (decimal * 100).toFixed(2);

  // prefer showing 0% instead of 0.00%
  if (percentage === '0.00') {
    return '0%';
  }

  return `${percentage}%`;
};

export const breakdownFormatter = (breakdown = {}) => {
  return (
    <ul className='breakdown'>
      {Object.entries(breakdown).map(([key, value]) => {
        return (
          <li key={key}>
            {key}: {formatAsPercentage(value)}
          </li>
        );
      })}
    </ul>
  );
};

export const changeFormatter = (value) => {
  let sentimentColor = '';
  let change = '-';
  if (!isNaN(value)) {
    sentimentColor =
      value === 0
        ? SR.colors.grey
        : value < 0
        ? SR.colors.emphasis_brightPurple
        : SR.colors.brightRed;
    change = formatAsPercentage(value);
  }
  return <p style={{ color: sentimentColor }}>{change}</p>;
};

export const riskScoreFormatter = (riskScore) => {
  const { score, date } = riskScore;
  const formattedScore = isNaN(score) ? '-' : formatAsPercentage(score);
  const title = date ? date : null;
  return <p title={title}>{formattedScore}</p>;
};

export const addressColumnFormatter = (cell, row, rowIndex, removeAddress) => {
  const truncatedAddress = `${cell.substring(0, 4)}...${cell.slice(-6)}`;

  return (
    <AddressColumnStyle>
      <div className='address'>{truncatedAddress}</div>
      <div className='toolbar'>
        <IconButton
          className='btn-copy'
          component='span'
          onClick={() => {
            navigator.clipboard.writeText(cell);
          }}
        >
          <FileCopyIcon style={{ color: SR.colors.mutedGreyPurple }} />
        </IconButton>
        <IconButton
          className='btn-delete'
          component='span'
          onClick={() => {
            removeAddress(cell);
          }}
        >
          <DeleteOutlineRoundedIcon
            style={{ color: SR.colors.contrasting_YellowOrange }}
          />
        </IconButton>
      </div>
    </AddressColumnStyle>
  );
};
