import format from 'format-number';
import CommaNumber from 'comma-number';
import { scientificToDecimal } from 'components/Report/Table/formatters/sci_to_dec.js';

/*** DYNAMIC STRING FORMATTING ***/
// First we add a new function to the String prototype,
// this will be used to get the length of current string according
// to its font-family and font-size
// eslint-disable-next-line
String.prototype.textWidth = function (fontFamily, fontSize) {
  let container = document.createElement('div');
  container.style.visibility = 'hidden';
  container.style.fontFamily = fontFamily;
  container.style.fontSize = fontSize + 'px';
  container.style.display = 'inline';
  document.body.appendChild(container);
  container.innerHTML = this;
  const pxLength = container.offsetWidth;
  container.parentNode.removeChild(container);
  return pxLength;
};

/*** DYNAMIC FONT SIZE BASED ON STRING LENGTH ***/
// adjustFontSize is the function that will resize our text if it's too long
// classNameTarget (String) = the className of the element we need to resize
// maxWidth (int) = the max width (in px) of your final string
// fontFamily (String) = the family currently used by your string(wrong one might lead
//    to wrong result!)
// fontSize (int) = the initial font-size of your string
export function adjustFontSize(
  classNameTarget,
  maxWidth,
  fontFamily,
  fontSize,
) {
  maxWidth = maxWidth || 100;
  // first we get all targets
  let containers = document.getElementsByClassName(classNameTarget);
  for (let i = 0; i < containers.length; i++) {
    // for each of them we fetch their current length
    let length = containers[i].innerHTML.textWidth(fontFamily, fontSize);
    if (length > maxWidth) {
      // if the current length is bigger then we resize it by using a span styling with
      // the new font-size
      // containers[i].innerHTML = "<span style=\"font-size:" + Math.floor(parseInt(fontSize) / (length / maxWidth)) + "px;\">" + containers[i].innerHTML + "</span>";
      containers[i].style =
        'font-size:' +
        Math.floor(parseInt(fontSize) / (length / maxWidth)) +
        'px;';
    }
  }
}

export function adjustFontSizeByClassName(
  classNameTarget,
  maxWidth,
  fontFamily,
  fontSize,
) {
  maxWidth = maxWidth || 100;
  // first we get all targets
  let containers = document.getElementsByClassName(classNameTarget);
  let maxLength = 0;
  let length;
  for (let i = 0; i < containers.length; i++) {
    length = containers[i].innerHTML.textWidth(fontFamily, fontSize);
    if (length > maxLength) {
      maxLength = length;
    }
  }

  if (maxLength > maxWidth) {
    let newFontSize = Math.floor(parseInt(fontSize) / (maxLength / maxWidth));

    for (let i = 0; i < containers.length; i++) {
      containers[i].style = 'font-size:' + newFontSize + 'px;';
    }
  }
}

export function adjustFontSizeById(targetId, maxWidth, fontFamily, fontSize) {
  maxWidth = maxWidth || 100;
  // first we get all targets
  let container = document.getElementById(targetId);
  let length = container.innerHTML.textWidth(fontFamily, fontSize);
  if (length > maxWidth) {
    // if the current length is bigger then we resize it by using a span styling with
    // the new font-size
    // containers[i].innerHTML = "<span style=\"font-size:" + Math.floor(parseInt(fontSize) / (length / maxWidth)) + "px;\">" + containers[i].innerHTML + "</span>";
    container.style =
      'font-size:' +
      Math.floor(parseInt(fontSize) / (length / maxWidth)) +
      'px;';
  }
}

export function adjustFinancialsBalanceFontSize(
  targetIds,
  maxWidth,
  fontFamily,
  maxFontSizeInt,
  maxFontSizeDec,
) {
  maxWidth = maxWidth || 100;
  // first we get all targets
  let intContainer = document.getElementById(targetIds[0]);
  let decContainer = document.getElementById(targetIds[1]);

  let lengthInt = intContainer.innerHTML.textWidth(fontFamily, maxFontSizeInt);
  let lengthDec = decContainer.innerHTML.textWidth(fontFamily, maxFontSizeDec);
  let length = lengthInt + lengthDec;
  let maxWidthInt = (lengthInt / length) * maxWidth;
  let maxWidthDec = (lengthDec / length) * maxWidth;

  if (length > maxWidth) {
    // if the current length is bigger then we resize it by using a span styling with
    // the new font-size
    // containers[i].innerHTML = "<span style=\"font-size:" + Math.floor(parseInt(fontSize) / (length / maxWidth)) + "px;\">" + containers[i].innerHTML + "</span>";
    intContainer.style =
      'font-size:' +
      Math.floor(parseInt(maxFontSizeInt) / (lengthInt / maxWidthInt)) +
      'px;';
    decContainer.style =
      'font-size:' +
      Math.floor(parseInt(maxFontSizeDec) / (lengthDec / maxWidthDec)) +
      'px;';
  }
}

// EXAMPLE: we want our cell to have text no longer than 75px while having them starting at 50px font-size in arial
// testWidth("firstname", 75, "arial", 50);
// testWidth("lastname", 75, "arial", 50);
/*** TRUNCATE STRING BASED ON STRING LENGTH ***/
// truncate is the function that will resize our text if it's too long
// classNameTarget (String) = the className of the element we need to resize
// maxWidth (int) = the max width (in px) of your final string
// fontFamily (String) = the family currently used by your string(wrong one might lead
//    to wrong result!)
// fontSize (int) = the initial font-size of your string
export function truncate(classNameTarget, maxWidth, fontFamily, fontSize) {
  maxWidth = maxWidth || 100;
  // first we get all targets
  let containers = document.getElementsByClassName(classNameTarget);
  for (let i = 0; i < containers.length; i++) {
    // for each of them we fetch their current length
    let length = containers[i].innerHTML.textWidth(fontFamily, fontSize);
    if (length > maxWidth) {
      while (length > maxWidth) {
        // if the current length is bigger then we resize it by using a span styling
        containers[i].innerHTML =
          '<span style="font-size:' +
          'px;">' +
          containers[i].textContent.slice(
            0,
            containers[i].textContent.length - 4,
          ) +
          '</span>';
        length = containers[i].innerHTML.textWidth(fontFamily, fontSize);
        containers[i].textContent += '...';
      }
    }
  }
}

export function truncateTextContent(
  classNameTarget,
  maxWidth,
  fontFamily,
  fontSize,
) {
  maxWidth = maxWidth || 100;
  // first we get all targets
  let containers = document.getElementsByClassName(classNameTarget);
  for (let i = 0; i < containers.length; i++) {
    containers[i].textContent = containers[i].id;
    // for each of them we fetch their current length
    let length = containers[i].innerHTML.textWidth(fontFamily, fontSize);
    if (length > maxWidth) {
      let index = 0;
      while (length > maxWidth && index < 100) {
        // if the current length is bigger then we resize it by using a span styling
        containers[i].textContent = containers[i].textContent.slice(
          0,
          containers[i].textContent.length - 4,
        );
        length = containers[i].innerHTML.textWidth(fontFamily, fontSize);
        containers[i].textContent += '...';
        index += 1;
      }
    }
  }
}

// EXAMPLE: we want our cell to have text no longer than 75px while having them starting at 50px font-size in arial
// testWidth("firstname", 75, "arial", 50);
// testWidth("lastname", 75, "arial", 50);
/*** NUMBERS FORMATTING WITHOUT EXPONENT ***/
export function numberToStringWithoutExponent(num) {
  let numStr = num.toString();

  if (Math.abs(num) < 1.0) {
    let e = parseInt(num.toString().split('e-')[1]);
    if (e) {
      let negative = num < 0;
      if (negative) num *= -1;
      num *= Math.pow(10, e - 1);
      numStr = '0.' + new Array(e).join('0') + num.toString().substring(2);
      if (negative) numStr = '-' + numStr;
    }
  } else {
    let e = parseInt(numStr.split('+')[1]);
    if (e) {
      let ePos = numStr.indexOf('e');
      numStr = numStr.slice(0, ePos);
      let dotPos = numStr.indexOf('.');
      let zeroesToAdd = e - numStr.slice(dotPos + 1).length;
      numStr =
        numStr.slice(0, dotPos) +
        numStr.slice(dotPos + 1) +
        new Array(zeroesToAdd + 1).join('0');
    }
  }
  return numStr;
}

/*** NUMBERS FORMATTING WITH EXPONENT ***/
export function numberToStringWithExponent(num, roundPosition) {
  const numFormat = format({ round: roundPosition });

  let numStr;

  let pos = num.toString().indexOf('e');

  if (Math.abs(num) > 0) {
    if (pos >= 0) {
      let numStrBody = numFormat(parseFloat(num.toString().slice(0, pos)));
      let numStrExponent = num.toString().slice(pos, num.toString().length);
      numStr = numStrBody + numStrExponent;
    } else {
      if (num < 1) {
        let exp = 0;
        let index = 0;
        while (num < 1 && index < 100) {
          num *= 10;
          exp += 1;
          index += 1;
        }

        if (index === 100) {
          numStr = 'NaN';
        } else {
          numStr = numFormat(num) + 'e-' + exp.toString();
        }
      }
    }
  }
  return numStr;
}

/*** ARRAY SORTING ***/
export function sortByColumnArrayOfArrays(columnIndex) {
  return (a, b) => {
    if (a[columnIndex] === b[columnIndex]) {
      return 0;
    } else {
      return a[columnIndex] > b[columnIndex] ? -1 : 1;
    }
  };
}

export function sortByKeyArrayOfJSONs(key) {
  return (a, b) => {
    if (a[key] === b[key]) {
      return 0;
    } else {
      return a[key] > b[key] ? -1 : 1;
    }
  };
}

/*** FORMAT NAME ***/
export function formatName(nameString) {
  nameString = nameString.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });

  nameString = nameString.replace(/_/gi, ' ');

  return nameString;
}

export function formatAmountByAsset(
  baseDenomination,
  amt,
  toString = true,
  reverse = false,
) {
  let coefficient = 1;

  coefficient = reverse ? 1 / baseDenomination : baseDenomination;
  return toString
    ? CommaNumber(scientificToDecimal((amt / coefficient).toFixed(5) * 1))
    : amt / coefficient;
}

export function trimLeadingTrailingWhitespace(str) {
  return str.replace(/^\s+|\s+$/gm, '');
}

export function handleInternalAttributions(attr) {
  if (!attr) return '';
  const lowercaseEntity = attr.toLowerCase();
  if (lowercaseEntity.startsWith('_') || lowercaseEntity.startsWith('manual')) {
    return '';
  }
  return attr;
}

export function dateTimeFormatter(dateTime) {
  try {
    return `${new Date(parseInt(dateTime) * 1000)
      .toISOString()
      .split('.')[0]
      .replace('T', ' ')} UTC`;
  } catch (e) {
    console.log('Date error: ', e);
    console.log('dateTime: ', dateTime);
  }
}

export function getBlockExplorerURL(asset, hash, isTx = false) {
  return (
    (asset.toLowerCase() === 'btc'
      ? `https://blockchain.com/btc/${isTx ? 'tx' : 'address'}/`
      : `https://etherscan.io/${isTx ? 'tx' : 'address'}/`) + hash
  );
}

export const getRunType = (type) => {
  switch (type) {
    case -1:
      return 'Outgoing';

    case 1:
      return 'Incoming';

    case 2:
      return 'Compliance';
    default:
  }
};

export const findIdFromComboLabel = (comboLabel) => {
  return `CC-${comboLabel.split('-').slice(-1)[0]}`;
};
