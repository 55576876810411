import request from 'request-promise';
import { IDashboard } from '../typings/interfaces';

export class DashboardService {
  _hostUrl;
  _rpOptions;

  constructor(headers: {}, hostUrl: string) {
    this._hostUrl = hostUrl;
    this._rpOptions = {
      headers: { ...headers },
      json: true,
    };
  }

  async getAll() {
    return this._request<IDashboard[]>('GET', '/dashboards');
  }
  async find(id: number) {
    return this._request<IDashboard>('GET', `/dashboards/${id}`);
  }
  async _request<T>(method: string, path: string, data?: any): Promise<T> {
    const options = {
      ...this._rpOptions,
      method,
      uri: `${this._hostUrl}${path}`,
      body: data,
    };

    return request(options);
  }
}
