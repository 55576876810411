import React, { useContext } from 'react';
import { StoreProvider, store } from 'ReportData/ReportDataStore';
import Graph from './Graph/Graph';
import SummaryPanel from './SummaryPanel/summaryPanel';
import { useParams, Redirect, withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { GraphProvider } from 'hooks/useGraph';
import { useAuth0 } from '@auth0/auth0-react';
import { ReportFrame } from './styles';
import { getRunType } from 'helpers/utils';
import NavBar from '../NavBar';
import { FlowType, ReportType } from 'utils/enums';
import { EntityReport } from './EntityReport';

const ReportView = () => {
  const { id } = useParams();
  const { state: globalState } = useContext(store);
  const { getAccessTokenSilently } = useAuth0();
  const { ReportData } = globalState;

  // Split view for entity reports (also need risk source data otherwise no need for 50/50)
  const isEntityRadarRep =
    ReportData.flowType === FlowType.Entity &&
    ReportData.reportType === ReportType.Compliance;

  const rightColWidth = isEntityRadarRep
    ? '45%'
    : ReportData.ReportType === ReportType.Compliance
    ? '35%'
    : '350px';

  const leftColWidth = isEntityRadarRep
    ? '55%'
    : ReportData.ReportType === ReportType.Compliance
    ? '65%'
    : '75%';

  const hasEntityStats =
    ReportData.meta &&
    ReportData.meta.entity &&
    ReportData.meta.entity.statistics;
  const hasRiskSources =
    ReportData.meta &&
    ReportData.meta.scores &&
    ReportData.meta.scores.riskSources &&
    ReportData.meta.scores.riskSources.length;

  const maxWidthRight =
    ReportData.reportType !== ReportType.Compliance
      ? 'auto'
      : hasEntityStats || hasRiskSources
      ? '650px'
      : '350px';

  const isEntityReport = ReportData.flowType === FlowType.Entity;

  return (
    <>
      {isEntityReport ? (
        <EntityReport />
      ) : (
        <>
          {!globalState.loading && !globalState.error && (
            <NavBar view={getRunType(globalState.ReportData.meta.flow_type)} />
          )}
          <ReportFrame
            maxWidthRight={maxWidthRight}
            widthRight={rightColWidth}
            widthLeft={leftColWidth}
          >
            {!globalState.loading && !globalState.error && (
              <>
                <GraphProvider
                  id={id}
                  token={getAccessTokenSilently}
                  ReportData={globalState.ReportData}
                >
                  <div id='report-frame-left'>
                    <Graph />
                  </div>
                  <div id='report-frame-right'>
                    <SummaryPanel />
                  </div>
                </GraphProvider>
              </>
            )}
            {globalState.loading && !globalState.error && (
              <ReactLoading
                type='bubbles'
                color='gray'
                height={'10%'}
                width={'10%'}
                id='loading-animation'
              />
            )}
            {globalState.error && <Redirect to='/not_found'>404</Redirect>}
          </ReportFrame>
        </>
      )}
    </>
  );
};

/**
 * Report
 */
const Report = () => {
  const { id } = useParams();
  return (
    <div>
      {id && (
        <StoreProvider id={id}>
          <ReportView />
        </StoreProvider>
      )}
      {/** This should never occur **/}
      {!id && (
        <div id='report-frame'>
          <Redirect to='/not_found'>404</Redirect>
        </div>
      )}
    </div>
  );
};

// Wrapped 'withRouter' because 'useParams' depends on 'withRouter'
// TODO: Make 'id' a prop requirement for Report to remove this wrapper.
export default withRouter(Report);
