import * as RA from 'ramda-adjunct';

class DenominatedBalance {
  constructor(amtRepStart, amt, short) {
    this.amt = this.findDenom(amtRepStart, amt, short);
  }

  findDenom(amtRep, amt, short) {
    const amtFloat = parseFloat(amt);
    if (RA.isNilOrEmpty(amtFloat) || isNaN(amtFloat)) return '';

    if (amtFloat === 0) {
      amtRep = '0';
    } else if (amtFloat < 0.0001) {
      amtRep = `< ${amtRep} 0.0001`;
    } else if (amtFloat > 1e15) {
      amtRep = `> ${amtRep} 1000t`;
    } else {
      const setDecimalPlaces = (amtFloat) => {
        const howManyPlacesOverDecimal = amtFloat
          .toString()
          .split('.')[0].length;

        if (howManyPlacesOverDecimal > 5) {
          return amtFloat.toFixed(0);
        } else if (howManyPlacesOverDecimal === 4) {
          return amtFloat.toFixed(1);
        } else if (howManyPlacesOverDecimal === 3) {
          return amtFloat.toFixed(2);
        } else {
          return amtFloat.toFixed(3);
        }
      };

      const setDenomination = (amtRep, amtFloat, short) => {
        let denom = '';
        let amtTruncated;
        if (amtFloat < 1e3) {
          amtTruncated = amtFloat;
          denom = '';
        } else if (amtFloat < 1e6) {
          amtTruncated = amtFloat / 1e3;
          denom = 'k';
        } else if (amtFloat < 1e9) {
          amtTruncated = amtFloat / 1e6;
          denom = 'm';
        } else if (amtFloat < 1e12) {
          amtTruncated = amtFloat / 1e9;
          denom = 'b';
        } else if (amtFloat < 1e15) {
          amtTruncated = amtFloat / 1e12;
          denom = 't';
        }

        let amtDecRep = setDecimalPlaces(amtTruncated);

        // if flagged, 1 decimal place for millions/2 for thousands (ie: 10.1m , 50.25k)
        if (short) {
          if (denom === 'm') {
            const tmp = amtDecRep.split('.');
            if (tmp.length === 2)
              amtDecRep = tmp[0] + '.' + tmp[1].substring(0, 1);
          }
          if (denom === 'k') {
            const tmp = amtDecRep.split('.');
            if (tmp.length === 2)
              amtDecRep = tmp[0] + '.' + tmp[1].substring(0, 2);
          }
        }

        amtRep += ' ' + amtDecRep + denom;

        return amtRep;
      };

      const amtFloat = parseFloat(amt);
      amtRep = setDenomination(amtRep, amtFloat, short);
    }

    return amtRep;
  }
}

export default DenominatedBalance;
