import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

export const CellText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 12px;
  font-weight: ${(props) => props.bold};

  &:hover {
    color: ${(props) => props.hovercolor};
    font-weight: bold;
  }
`;

export const DarkCellText = styled(CellText)`
  opacity: 0.8;
  font-weight: 200;
`;

export const IconButton = styled.button`
  background-color: transparent;
  border-radius: 20px;
  padding: 0;
  font-size: 17px;
  height: 20px;
  margin-left: 4px;
  border: 1px solid transparent;
  > * {
    margin-bottom: 10px;
  }
`;

export const TableContainer = styled.div`
  overflow: scroll;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: ${SR.colors.nuetral_DarkPurple};
  overflow-x: hidden;
  color: white;
  table {
    border: none;
    background-color: transparent;
  }
  .headerClass {
    th {
      border: none;
      position: sticky;
      top: 53px;
      background: rgba(44, 34, 73, 0.95);
      z-index: 1;
      white-space: nowrap;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .rowClass {
    td {
      border: 1px solid rgba(29, 20, 46, 0.3);
      vertical-align: top;
    }
    tr {
      vertical-align: middle;
    }
  }
  .shapeshift-icon {
    cursor: pointer;
    margin-left: 3px;
  }

  .flagged-icon {
    fill: ${SR.colors.contrasting_RedishOrange}!important;
    margin-left: 3px;
  }
`;

export const TableHeader = styled.div`
  position: sticky;
  top: 0px;
  z-index: 1;
  background: ${SR.colors.nuetral_DarkPurple};
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  padding-top: 10px;
  width: 100%;
  font-family: 'Verlag A', 'Verlag B';
  .searchBar {
    color: white;
    background-color: transparent;
  }
  h5 {
    font-size: 20px;
    opacity: 1;
    font-weight: 500;
  }

  label {
    color: white;
    background-color: transparent;
    width: 300px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 28px;
    box-shadow: inset -2px -2px 6px 2px hsl(253deg, 35%, 25%),
      inset 2px 2px 6px 3px hsl(254deg, 35%, 13%);

    &:focus {
      border-color: #753bf2;
    }
  }
`;
