import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as d3 from 'd3';
import { useGraph } from 'hooks/useGraph';
import { addSelection, clearSelection } from 'hooks/useGraph/actions';
import { styleRefs as SR } from 'styles/styleRefs';

export default function Legend() {
  const { state, chartRef, dispatch } = useGraph();

  const [what2Collapse, set_what2Collapse] = useState([]);

  useEffect(() => {
    const legData = buildLegendMetaData(state.ReportData, what2Collapse);

    buildLegend(
      legData,
      chartRef,
      dispatch,
      state.ReportData,
      set_what2Collapse,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.viewableItems,
    state.ReportData.egressEntities,
    what2Collapse.length,
  ]);

  return (
    <>
      <svg id='legendsvg'></svg>
    </>
  );
}

const buildLegendMetaData = (ReportData, what2Collapse) => {
  /** LEGEND METADATA **/

  const entityObj = {};
  const legData = {};

  for (let e of Object.values(ReportData.egressEntities)) {
    entityObj[e.entitytype] = [e.color, e.entitytype, 'symbolCircle'];
  }

  /**
   * Defining Legend Items In Order
   * Each legend item follows the format [color, text, shape, outlineColor]
   *
   * color = 'rgb(255,255,255)' || false
   * text = String || ''
   * shape = 'circle'|| 'square' || 'custom' || false
   * (Optional) outline = 'rgb(255,255,255)'
   */

  legData['LEGEND'] = [false, 'LEGEND ' + String.fromCharCode('8675'), false];

  if (what2Collapse.includes('LEG')) {
    legData['LEGEND'][1] = 'LEGEND ' + String.fromCharCode('8673');
    return legData;
  }

  legData['Shape'] = [false, 'Shape' + String.fromCharCode('8673'), false];
  if (!what2Collapse.includes('Sha')) {
    legData['Shape'][1] = 'Shape ' + String.fromCharCode('8675');

    if (ReportData.assetInfo.asset === 'btc')
      legData['Wallet'] = [
        'rgb(192,192,192)',
        '(' + ReportData.walletsFiltered.length + ')- Wallets',
        'symbolSquare',
      ];

    legData['Single Address'] = [
      'rgb(192,192,192)',
      '(' + ReportData.singleAddressesFiltered.length + ')- Single Addresses',
      'symbolCircle',
    ];
  }

  legData['Type'] = [false, 'Type' + String.fromCharCode('8673'), false];
  if (!what2Collapse.includes('Typ')) {
    legData['Type'][1] = 'Type ' + String.fromCharCode('8675');

    const totalSourceAgents = ReportData.sourceIds.length;
    if (totalSourceAgents !== 0) {
      legData[`Source_Agents`] = [
        '#ff0000',
        `(${totalSourceAgents})- Source- Agent`,
        'symbolCircle',
      ];
    }

    Object.values(entityObj).forEach((leg) => {
      // hack for capitalization
      const entityType = leg[1].toLowerCase();
      let text = '';
      if (_.includes(Object.keys(NONSTANDARD_CAPS), entityType)) {
        text = NONSTANDARD_CAPS[entityType];
      } else {
        text = entityType
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      }

      if (ReportData.entityTypesByAddress[entityType]) {
        legData[entityType] = [
          leg[0],
          '(' +
            ReportData.entityTypesByAddress[entityType].length +
            ')- ' +
            text,
          leg[2],
        ];
      } else {
        console.error(`"${entityType}" not in ReportData.entityTypesByAddress`);
      }
    });

    legData['unknown'] = [
      'rgb(192,192,192)',
      '(' + ReportData.unknownTypeFiltered.length + ')- Uncategorized/Unknown',
      'symbolCircle',
    ];
  }
  legData['Modifier'] = [
    false,
    'Modifier' + String.fromCharCode('8673'),
    false,
  ];
  if (!what2Collapse.includes('Mod')) {
    legData['Modifier'][1] = 'Modifier ' + String.fromCharCode('8675');

    legData['Non-Zero'] = [
      '#c2c2c2',
      '(' + ReportData.nodesWithBal.length + ')- Non-Zero Balance',
      'symbolCircle',
      'outline',
      '#3B9441',
      3,
    ];
    //would be good to shift number of days
    legData['Active Nodes'] = [
      '#c2c2c2',
      '(' +
        ReportData.activeNodes.length +
        ')- Active Nodes In The Last 14 Days',
      'symbolCircle',
      'outline',
      SR.colors.emphasis_lightPurple,
      4,
    ];

    if (ReportData.intermediaryNodes.length)
      legData['Intermediary'] = [
        '#1c142c',
        '(' + ReportData.intermediaryNodes.length + ')- Intermediary',
        'symbolCircle',
      ];

    if (ReportData.riskSourcesAddresses)
      legData['Flagged Nodes'] = [
        '#c2c2c2',
        '(' + ReportData.riskSourcesUnqId.length + ')- Flagged Nodes',
        'symbolCircle',
      ];
  }

  return legData;
};

const buildLegend = (
  legData,
  chartRef,
  dispatch,
  ReportData,
  set_what2Collapse,
) => {
  /** LEGEND **/
  d3.select('#legendsvg').select('g').remove();
  let Legend = d3.select('#legendsvg').append('g');
  Legend.attr('transform', 'translate(0,90) scale(0.8)');

  /** Why does the rbg have a missing ')' ?? */
  const legLines = ['red', 'blue', 'rgb(255,0,255'];

  const lineSpace = 26;
  /* LEGEND UP SECTION WITH LINES */

  const linelegend = Legend.append('g')
    .attr('transform', 'translate(200,195)')
    .attr('class', 'linelegend');
  linelegend
    .selectAll('legline')
    .data(legLines)
    .enter()
    .append('line')
    .attr('x1', 6)
    .attr('x2', 26)
    .attr('y1', function (d, i) {
      return 8 + lineSpace * i;
    })
    .attr('y2', function (d, i) {
      return 8 + lineSpace * i;
    })
    .style('stroke', function (d) {
      return d;
    })
    .style('stroke-width', 4);
  /*
            LEGEND
            section size: font/circles size
        */
  const legR = 10;
  /*
            LEGEND
            section position
        */
  const circleAdjDown = 40 + (9 - Object.keys(legData).length) * legR * 2.5;

  /**
   * Now we are taking the legData we pushed in from above and actually
   * adding that data to the graph. We take the variables settings
   * and make assumptions on those regarding text placement
   */
  const symbolGenerator = d3.symbol().size(400);
  Legend.selectAll('legcircle')
    .data(Object.values(legData))
    .enter()
    .append('path')
    .attr(
      'transform',
      (d, i) =>
        // Defines vertical spacing between shapes
        'translate(20, ' + (legR + 2 + i * legR * 2.5 + circleAdjDown) + ')',
    )
    .attr('d', function (d) {
      if (!d[2]) return;
      symbolGenerator.type(d3[d[2]]);
      return symbolGenerator();
    })
    .style('fill', (d) => (d[0] ? d[0] : ''))
    .style('stroke', (d) => (d.length > 4 ? d[4] : ''))
    .style('stroke-width', (d) => (d.length > 4 ? d[5] : 0))
    .on('click', (d) => {
      const filteredAddresses = filterAddressesByType(d[1], ReportData);
      if (filteredAddresses && filteredAddresses.length) {
        dispatch(addSelection(filteredAddresses, null, null, chartRef));
      }
    });
  let legItem;

  Legend.selectAll('text')
    .data(Object.values(legData))
    .enter()
    .append('text')
    .attr('font-family', 'Font Awesome 5 Pro Solid')
    .attr('class', 'fas fa-bomb')
    .text(function (d) {
      if (d[0]) {
        legItem = d[1].split(`- `)[1].toLowerCase();
        if (
          ReportData.colorMap[legItem] &&
          Object.keys(ReportData.colorMap[legItem]).includes('code')
        )
          return ReportData.colorMap[legItem].code;
      }
    })
    .attr('transform', (d, i) => {
      // Defines vertical spacing between shapes
      if (d[0]) legItem = d[1].split(`- `)[1].toLowerCase();
      return (
        `translate(${legItem === 'flagged nodes' ? 2 : 20},` +
        (legR + 2 + i * legR * 2.5 + circleAdjDown) +
        ')'
      );
    })
    .attr('fill', (d) => {
      if (d[0]) legItem = d[1].split(`- `)[1].toLowerCase();
      if (legItem === 'flagged nodes') return 'rgb(255,0,0)';
      if (legItem === 'intermediary') return 'rgb(192,192,192)';
      else return SR.colors.nearBlackPurple;
    })
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central');

  Legend.selectAll('legtext')
    .data(Object.values(legData))
    .enter()
    .append('text')
    .style('font-size', 16)
    .attr('x', (d) => (!d[0] ? 0 : 36))
    .attr('y', (d, i) => legR + 2 + i * legR * 2.5 + circleAdjDown + 5)
    .text((d) => d[1])
    .on('click', (d) => {
      if (!d[0])
        set_what2Collapse((w2c) => {
          const first3LettersOflabel = d[1].slice(0, 3);
          if (w2c.includes(first3LettersOflabel))
            w2c = w2c.filter((li) => li !== first3LettersOflabel);
          else w2c.push(first3LettersOflabel);
          return [...w2c];
        });
      else {
        dispatch(clearSelection());
        const filteredAddresses = filterAddressesByType(d[1], ReportData);
        if (filteredAddresses && filteredAddresses.length) {
          dispatch(addSelection(filteredAddresses, null, null, chartRef));
        }
      }
    });
};

const NONSTANDARD_CAPS = {
  hyip: 'HYIP',
  'p2p exchange': 'P2P Exchange',
  ico: 'ICO',
  'otc desk': 'OTC Desk',
  'ofac sanctions': 'OFAC Sanctions',
};

const filterAddressesByType = (legendItem, ReportData) => {
  const type = legendItem.split(`- `)[1];
  switch (type) {
    case 'Intermediary':
      return ReportData.intermediaryNodes;

    case 'Non-Zero Balance':
      return ReportData.nodesWithBal;

    case 'Active Nodes In The Last 14 Days':
      return ReportData.activeNodes;

    case 'Flagged Nodes':
      return ReportData.riskSourcesUnqId;

    case 'Wallets':
      return ReportData.walletsFiltered;

    case 'Single Addresses':
      return ReportData.singleAddressesFiltered;

    case 'Uncategorized/Unknown':
      return ReportData.unknownTypeFiltered;

    default:
      return ReportData.entityTypesFiltered[type.toLowerCase()];
  }
};
