import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import rp from 'request-promise';
import { AlertsService } from './alertsService';
import { NotificationsService } from './notificationsService';
import { DashboardService } from './dashboardService';

const ELEMENTUS_API_HOST = process.env.REACT_APP_ELEMENTUS_API_HOST;

export class ElementusAPIService {
  constructor(token) {
    this.HOST_URL = ELEMENTUS_API_HOST;
    this.HEADERS = { Authorization: `Bearer ${token}` };
    this.authenticated = false;
    this.alertsService = new AlertsService(this.HEADERS, this.HOST_URL);
    this.notificationsService = new NotificationsService(
      this.HEADERS,
      this.HOST_URL,
    );
    this.dashboardService = new DashboardService(this.HEADERS, this.HOST_URL);
  }

  getHeaders() {
    const headers = this.HEADERS;
    return headers;
  }

  getAddressAutocompletion(addressPrefix, isSourceflow) {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/address-autocompletion/${addressPrefix}`,
      headers: this.getHeaders(),
      qs: { isSourceflow },
      json: true,
    };

    return rp(options);
  }

  getEntityAutocompletion(entityPrefix) {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/entity-autocompletion/${entityPrefix}`,
      headers: this.getHeaders(),
      json: true,
    };
    return rp(options);
  }

  getEthAssets(addressList, isSourceflow) {
    const options = {
      method: 'POST',
      uri: `${this.HOST_URL}/eth-assets`,
      headers: this.getHeaders(),
      body: {
        addressList: addressList.map((a) => a.toLowerCase()),
        isSourceflow,
      },
      json: true,
    };

    return rp(options);
  }

  getForensicAnalysisList(isOwned) {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/forensic-analysis`,
      headers: this.getHeaders(),
      body: {},
      qs: { isOwned },
      json: true,
    };

    return rp(options);
  }

  createForensicAnalysis(
    name,
    addresses,
    is_from_clusters,
    reportParams,
    flow_type,
    asset,
    parentId = false,
    entity = undefined,
  ) {
    // TODO body.strategy = 'jwt' not needed
    const options = {
      method: 'POST',
      uri: `${this.HOST_URL}/forensic-analysis`,
      headers: this.getHeaders(),
      body: {
        name,
        addresses,
        is_from_clusters,
        reportParams: {
          ...reportParams,
          hops: { current: `${reportParams.hops.current}` },
        },
        flow_type,
        asset,
        parentId,
        entity,
      },
      json: true,
    };

    return rp(options);
  }

  deleteForensicAnalysis(id) {
    const options = {
      method: 'DELETE',
      uri: `${this.HOST_URL}/forensic-analysis/${id}`,
      headers: this.getHeaders(),
      body: {},
      json: true,
    };
    return rp(options);
  }

  patchNameForForensicAnalysis(id, newName) {
    const options = {
      method: 'PATCH',
      uri: `${this.HOST_URL}/forensic-analysis/${id}`,
      headers: this.getHeaders(),
      body: {
        newName,
      },
      json: true,
    };
    return rp(options);
  }

  getForensicAnalysis(id, reportParams) {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/forensic-analysis/${id}`,
      headers: this.getHeaders(),
      qs: { reportParams },
      json: true,
    };

    return rp(options).then((res) => {
      if (RA.isNilOrEmpty(res)) {
        return null;
      }

      return R.head(res);
    });
  }

  getGraphBalances(addressesArray, asset) {
    const options = {
      method: 'POST',
      uri: `${this.HOST_URL}/graphbalances`,
      headers: this.HEADERS,
      body: {
        strategy: 'jwt',
        asset: asset,
        addresses: addressesArray,
      },
      json: true,
    };

    return rp(options)
      .then((balances) => {
        if (balances) {
          return balances;
        } else {
          return null;
        }
      })
      .catch((err) => {
        console.warn(
          'Error: Elementus API getGraphBalances returned and error',
          err,
        );
        return null;
      });
  }

  patchLayout(analysis_id, layout) {
    const options = {
      method: 'PATCH',
      uri: `${this.HOST_URL}/layout`,
      headers: this.getHeaders(),
      body: {
        analysis_id,
        layout,
      },
      json: true,
    };
    return rp(options);
  }

  patchHiddenNodes(analysis_id, hiddenNodeIds) {
    const options = {
      method: 'PATCH',
      uri: `${this.HOST_URL}/node_hiding`,
      headers: this.getHeaders(),
      body: {
        analysis_id,
        hiddenNodeIds,
      },
      json: true,
    };
    return rp(options);
  }

  patchReportsSharing(analysis_id, emails_list) {
    const options = {
      method: 'PATCH',
      uri: `${this.HOST_URL}/reports-sharing`,
      headers: this.getHeaders(),
      body: {
        analysis_id,
        emails_list,
      },
      json: true,
    };

    return rp(options);
  }

  deleteReportSharing(analysis_id) {
    const options = {
      method: 'DELETE',
      uri: `${this.HOST_URL}/reports-sharing/${analysis_id}`,
      headers: this.getHeaders(),
      body: {},
      json: true,
    };
    return rp(options);
  }

  getNodeNextHop(
    analysis_id,
    uniq_node_id,
    sumscore,
    direction,
    existing_edge_ids,
    num_hops,
  ) {
    const options = {
      method: 'POST',
      uri: `${this.HOST_URL}/node-expansion`,
      headers: this.getHeaders(),
      body: {
        analysis_id,
        uniq_node_id,
        sumscore,
        direction,
        existing_edge_ids:
          existing_edge_ids.length === 0 ? '0' : existing_edge_ids,
        num_hops,
      },
      json: true,
    };

    return rp(options);
  }

  getETHBalance(address) {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/balance?address=${address}`,
      headers: this.HEADERS,
      body: {
        strategy: 'jwt',
      },
      json: true,
    };

    return rp(options)
      .then((balance) => {
        if (balance) {
          return balance;
        } else {
          return { address, balance: 'NaN' };
        }
      })
      .catch((err) => {
        console.warn(
          'Error: Elementus API /internal/balance returned and error',
          err,
        );
        return { address, balance: 'NaN' };
      });
  }

  getETHUSDPrice() {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/usdprices`,
      headers: this.HEADERS,
      body: {
        strategy: 'jwt',
      },
      json: true,
    };

    return rp(options)
      .then((ETHUSDprice) => {
        if (ETHUSDprice) {
          return ETHUSDprice;
        } else {
          return 200.0;
        }
      })
      .catch((err) => {
        console.warn(
          'Error: Elementus API /internal/usdprices returned and error',
          err,
        );
        return 200.0;
      });
  }

  getAMLScores(address) {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/amlscores?address=${address}`,
      headers: this.HEADERS,
      body: {
        strategy: 'jwt',
      },
      json: true,
    };

    return rp(options)
      .then((AMLScores) => {
        if (AMLScores) {
          return AMLScores;
        } else {
          return {
            address,
            amlScores: {
              address: address,
              score1: 0,
              score2: 0,
              score3: 0,
              score4: 0,
              score5: 0,
              amt_received: 0,
              type: '',
            },
          };
        }
      })
      .catch((err) => {
        console.warn(
          'Error: Elementus API /internal/amlscores returned and error',
          err,
        );
        return {
          address,
          amlScores: {
            address: address,
            score1: 0,
            score2: 0,
            score3: 0,
            score4: 0,
            score5: 0,
            amt_received: 0,
            type: '',
          },
        };
      });
  }

  createRiskMonitorConfig() {
    const options = {
      method: 'POST',
      uri: `${this.HOST_URL}/risk-monitor`,
      headers: this.getHeaders(),
      json: true,
    };

    return rp(options);
  }

  getRiskMonitorConfig() {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/risk-monitor`,
      headers: this.getHeaders(),
      json: true,
    };

    return rp(options);
  }

  updateRiskMonitorConfig(data) {
    const options = {
      method: 'PATCH',
      uri: `${this.HOST_URL}/risk-monitor/${data.id}`,
      headers: this.getHeaders(),
      json: true,
      body: data,
    };

    return rp(options);
  }

  getRiskScores() {
    const options = {
      method: 'GET',
      uri: `${this.HOST_URL}/risk-scores`,
      headers: this.getHeaders(),
      json: true,
    };
    return rp(options);
  }

  createRiskScore(address) {
    const options = {
      method: 'POST',
      uri: `${this.HOST_URL}/risk-scores`,
      headers: this.getHeaders(),
      json: true,
      body: { address },
    };
    return rp(options);
  }

  deleteRiskScore(id) {
    const options = {
      method: 'DELETE',
      uri: `${this.HOST_URL}/risk-scores/${id}`,
      headers: this.getHeaders(),
      json: true,
    };
    return rp(options);
  }

  getHistoricalData(metricId, selectorIds, filters = {}, options = {}) {
    const req = {
      method: 'POST',
      uri: `${this.HOST_URL}/historical-data`,
      headers: this.getHeaders(),
      body: {
        metricId,
        selectorIds,
        filters,
        options,
      },
      json: true,
    };

    return rp(req);
  }

  get alerts() {
    return this.alertsService.getServices();
  }

  get notifications() {
    return this.notificationsService.getServices();
  }

  get dashboards() {
    return this.dashboardService;
  }
}

export default ElementusAPIService;
