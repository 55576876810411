import {
  SET_ACTIVE_TRANSACTION_IDS,
  SET_SEARCH,
  SET_TRANSACTIONS,
} from './actions';

const filterTxs = (txs, activeTxIds, search) => {
  const filteredTxs = [];
  if (txs === null || activeTxIds === null) {
    return filteredTxs;
  }
  activeTxIds.forEach((txId) => {
    const tx = txs[txId];
    if (!tx) {
      return;
    }
    if (search !== null) {
      if (tx.isSearchMatch(search)) {
        filteredTxs.push(tx);
      }
    } else {
      filteredTxs.push(tx);
    }
  });
  return filteredTxs;
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_TRANSACTION_IDS: {
      const activeTxIds = action.activeTxIds;
      const filteredTxs = filterTxs(state.txs, activeTxIds, state.search);
      return {
        ...state,
        activeTxIds,
        filteredTxs,
      };
    }
    case SET_SEARCH: {
      const search = action.search;
      const filteredTxs = filterTxs(state.txs, state.activeTxIds, search);
      return {
        ...state,
        search,
        filteredTxs,
      };
    }
    case SET_TRANSACTIONS: {
      const txs = action.txs;
      const filteredTxs = filterTxs(txs, state.activeTxIds, state.search);
      return {
        ...state,
        txs,
        filteredTxs,
      };
    }
    default: {
      throw new Error('unsupported action');
    }
  }
};

export default reducer;
