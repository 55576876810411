import { colors } from 'ReportData/colors';
import { styleRefs as SR } from 'styles/styleRefs';

const UA = navigator.userAgent;
const isFirefox = /firefox/gim.test(UA);

const imageAlignment = {};
Object.values(colors).forEach((c) => {
  if ('icon' in c) {
    imageAlignment[c.icon] = {
      size: c.icon === 'fas fa-cloud' ? 2 : 0.75,
      dx: c.icon === 'fas fa-play' ? 15 : 0,
    };
  }
});

export const initialState = {
  ReportData: { isSourceflow: true },
  items: {},
  viewableItems: {},
  layout: {
    name: 'organic',
    consistent: true,
    tightness: 5,
    orientation: 'down',
    top: [''],
    packing: 'aligned',
    stacking: {
      arrange: 'grid',
    },
    stretch: 1,
  },
  animation: { animate: true, time: 1000 },
  externalNodeSelection: {},
  walletLookup: {}, //TODO Convert to Set
  selection: new Set(),
  combine: { properties: [], level: 0, combineLabelsSet: false },
  customLabelsMap: {},
  localCombinations: true,
  intervalPing: null,

  viewableTxIds: new Set(),
  initialViewableTxIds: new Set(),

  flowToggle: true,
  exposureToggle: false,
  range: {
    start: new Date(),
    end: new Date(),
    reset: false,
  },
  labelsToggle: true,
  showLayouts: false,
  foreground: {},
  positions: {},
  histPointer: 0,
  timeBarOptions: {
    backgroundColor: 'rgba(0,0,0,0)',
    // input distinct hover color when there is functionality on click
    style: { color: 'white', hoverColor: 'white' },
    sliders: { color: 'rgba(44, 34, 73, 0.95)' },
    locale: { h12: false },
    scale: { hoverColor: '#333' },
    labels: {
      fontColor: '#E0E0E0',
      fontSize: 10,
    },
  },
  chartOptions: {
    navigation: { visible: true, position: 'se' },
    backgroundColor: SR.colors.nearBlackPurple,
    selection: { color: 'white', labelColor: 'black' },
    iconFontFamily: 'Font Awesome 5 Pro',
    labels: {
      fontFamily: 'bitcoinregular, arial, sans-serif',
    },
    imageAlignment: imageAlignment,
    fadeOpacity: 0.5,
    overview: {
      visible: false,
      showIcon: false,
    },
    minZoom: 0.01,
    watermark: {
      // https://app.asana.com/0/1202511539436161/1202393012573649/f
      image: !isFirefox ? '/img/base-logo-watermark.svg' : '',
    },
  },
  customCombosMap: {},
  showCustomLabelEdit: false,
  glyphMessageId: {},
  loading: true,
  asset: 'btc',
  userParameters: {},
  sourceLookup: new Set(),
  chartHover: {},
  expansionsRequested: 0,
  contextMenu: {
    x: 0,
    y: 0,
    id: null,
    show: false,
  },
  keysPressed: {
    Control: false,
  },
};

const stub = () => {
  throw new Error('You forgot to wrap your component in <GraphProvider>.');
};

export const initialContext = {
  ...initialState,
  initialize: stub,
  addItems: stub,
  hideItems: stub,
  addSelection: stub,
  clearSelection: stub,
  removeSelection: stub,
  addCombination: stub,
  clearCombination: stub,
  removeCombinationP1: stub,
  removeCombinationP2: stub,
  addCustomCombination: stub,
  triggerLayout: stub,
  toggleCombo: stub,
  toggleLabels: stub,
  toggleFlow: stub,
  rotate: stub,
  change: stub,
  changeBaselayout: stub,
  timeFilter: stub,
};
