import * as R from 'ramda';
import Counterparty from 'models/counterparty';
import DenominatedBalance from 'models/other/balance';

/**
 * This class encapsulates an API transaction object. It contains all
 * logic needed to access values in the object and makes explicit which
 * pieces of transaction data are being used in the app. It exposes read-only
 * properties and methods.
 */
class Transaction {
  constructor(apiTx) {
    if (apiTx === undefined || apiTx === null || typeof apiTx !== 'object') {
      throw new Error(`cannot construct Transaction from "${apiTx}"`);
    }

    this._id = apiTx.id || apiTx.tx_hash;
    this._timestamp = new Date(apiTx.timestamp * 1000);
    this._amountIn = apiTx.inamt;
    this._amountInUsd = apiTx.inamt_USD;
    this._amountOut = apiTx.outamt;
    this._amountOutUsd = apiTx.outamt_USD;
    this._inputCnt = apiTx.cpin.length;
    this._outputCnt = apiTx.cpout.length;
    this._cpFrom = apiTx.cpin.map((cpData) => new Counterparty(cpData));
    this._cpTo = apiTx.cpout.map((cpData) => new Counterparty(cpData));
    this._block = apiTx.block;
    this._asset = apiTx.asset;
    this._notes = {};
    Object.freeze(this);
  }

  get amount() {
    return this._amountOut;
  }

  get amountWithDenom() {
    const bal = new DenominatedBalance(``, this._amountOut);
    return bal.amt;
  }

  get amountOut() {
    return this._amountOut;
  }

  get amountOutUSDWithDenom() {
    const bal = new DenominatedBalance(`$`, this._amountOutUsd);
    return bal.amt;
  }

  get amountIn() {
    return this._amountInUsd;
  }

  get amountInUSDWithDenom() {
    const bal = new DenominatedBalance(`$`, this._amountInUsd);
    return bal.amt;
  }

  get addressesFrom() {
    return this._cpFrom.map((cp) => cp.address);
  }

  get addressesTo() {
    return this._cpTo.map((cp) => cp.address);
  }

  get asset() {
    return this._asset;
  }

  get block() {
    return this._block;
  }

  get clustersFrom() {
    return this._cpFrom.map((cp) => cp.cluster);
  }

  get clustersTo() {
    return this._cpTo.map((cp) => cp.cluster);
  }

  get addrFrom() {
    return this._cpFrom.map((cp) => cp.address);
  }

  get addrTo() {
    return this._cpTo.map((cp) => cp.address);
  }

  get counterpartiesFrom() {
    return this._cpFrom;
  }

  get counterpartiesTo() {
    return this._cpTo;
  }

  get entitiesFrom() {
    return this._cpFrom.map((cp) => cp.entity).filter((e) => !!e);
  }

  get entitiesTo() {
    return this._cpTo.map((cp) => cp.entity).filter((e) => !!e);
  }

  get id() {
    return this._id;
  }

  get notes() {
    return this._notes;
  }

  get timestamp() {
    return this._timestamp;
  }

  isSearchMatch(search) {
    if (typeof search !== 'string') throw new Error('search must be string');
    search = search.toLowerCase();

    if (this._id.toLowerCase().includes(search)) {
      return true;
    }
    if (this.addrFrom.find((id = '') => id.toLowerCase().includes(search))) {
      return true;
    }
    if (this.addrTo.find((id = '') => id.toLowerCase().includes(search))) {
      return true;
    }
    return false;
  }

  getTransfers() {
    // TODO migrate this to a Transfer model
    // Transaction -> Transfer
    // A transfer is a "flatten" version of a transaction,
    // combining all inputs with all outputs distributing its amt received
    const txData = {
      timestamp: this._timestamp,
      block: this._block,
      txHash: this._id,
      asset: this._asset,
    };

    const transfers = R.map((cpin) => {
      const cpinData = {
        from: cpin.address,
        fromCluster: cpin.cluster || '',
        fromEntity: cpin.entity || '',
      };

      return R.map((cpout) => {
        const cpoutData = {
          to: cpout.address,
          toCluster: cpout.cluster || '',
          toEntity: cpout.entity || '',
          amount: cpout.amount / this._inputCnt,
          amountUSD: cpout.amountUSD / this._inputCnt,
        };

        return { ...txData, ...cpinData, ...cpoutData };
      })(this._cpTo);
    })(this._cpFrom);

    return R.flatten(transfers);
  }
}

export default Transaction;
