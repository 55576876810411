import React from 'react';

const Root = (props) => {
  return (
    <div>
      <div>
        <title>Elementus</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <script src='https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js'></script>
        <link
          rel='stylesheet'
          href='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
        ></link>
        <script src='https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js'></script>
        <script src='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js'></script>
        <script src='https://npmcdn.com/react-bootstrap-table/dist/react-bootstrap-table.min.js' />
        <link
          rel='stylesheet'
          href='https://npmcdn.com/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
        ></link>
        <link
          href='https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'
          rel='stylesheet'
        />
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default Root;
