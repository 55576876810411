/**
 * Stateless functions that provide or convert data for display
 */
export const abbreviateNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) return '';
  let abbr = ``;
  if (number < 5 * 1e-6) {
    abbr = `< ${abbr}0.00001`;
  } else if (number < 1) {
    abbr += number.toFixed(5);
  } else if (number < 1e1) {
    abbr += number.toFixed(4);
  } else if (number < 1e2) {
    abbr += number.toFixed(3);
  } else if (number < 1e3) {
    abbr += number.toFixed(2);
  } else if (number < 1e6) {
    abbr += (number / 1e3).toFixed(2) + 'k';
  } else if (number < 1e9) {
    abbr += (number / 1e6).toFixed(2) + 'm';
  } else if (number < 1e12) {
    abbr += (number / 1e9).toFixed(2) + 'b';
  } else if (number < 1e15) {
    abbr += (number / 1e12).toFixed(2) + 't';
  } else if (number > 1e15) {
    abbr = `> ${number} 1000t`;
  }
  return abbr;
};

/**
 * @param {string} a
 * @param {string} b
 * @returns {number} number greater than 0 if 'b' is greater than 'a'
 */
export const comparePercentageStrings = (a, b) => {
  return parseFloat(b) - parseFloat(a);
};
