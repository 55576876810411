import CommaNumber from 'comma-number';
import _ from 'lodash';
import ReportData from './reportData';
import { isInternalOnlyEntity } from 'utils/elementusData';
import DenominatedBalance from 'models/other/balance';
import { styleRefs as SR } from 'styles/styleRefs';

class ReportDataWithRegraph extends ReportData {
  state: any;
  nCount: any;
  transformedNodes: any;
  transformedEdges: any;
  items: any;
  throughputCrypto: any;
  throughputUSD: any;
  inputCrypto: any;
  inputUSD: any;
  entityTypesByAddress: any;
  egressEntities: any;
  sourceIdsFiltered: any;
  walletsFiltered: any;
  singleAddressesFiltered: any;
  unknownTypeFiltered: any;
  entityTypesFiltered: any;

  constructor(data) {
    super(data);
    this.state = { data };
    this.nCount = 0;
    this.nodesWithBal = [];
    this.transformedNodes = {};
    this.transformedEdges = {};
    this.items = {};

    // TODO dynamic functions put in separate class
    this.throughputCrypto = 0;
    this.throughputUSD = 0;
    this.inputCrypto = 0;
    this.inputUSD = 0;
    this.entityTypesByAddress = {};
    this.egressEntities = {};

    this.sourceIdsFiltered = [];
    this.walletsFiltered = [];
    this.singleAddressesFiltered = [];
    this.unknownTypeFiltered = [];
    this.entityTypesFiltered = {};

    this.applyTransformationsForRegraph(
      this.initialGraph.nodes,
      this.initialGraph.edges,
    );
  }

  applyTransformationsForRegraph(nodes, edges) {
    const transformedNodes = this.#transformNodes(nodes);
    this.transformedNodes = { ...this.transformedNodes, ...transformedNodes };
    const transformedEdges = this.#transformEdges(edges);
    this.transformedEdges = { ...this.transformedEdges, ...transformedEdges };
    this.items = {
      ...this.items,
      ...this.transformedNodes,
      ...this.transformedEdges,
    };
    return { ...transformedNodes, ...transformedEdges };
  }

  updateBasedOnTimeFilter(viewableItems, viewableTx) {
    this.#setThroughput(viewableItems);
    this.setEgressEntities(viewableItems);
    if (viewableTx) this.txCount = viewableTx.size;
    this.getNodesWithBalance(viewableItems);
    this.getNodesActiveFromDays(viewableItems);
    this.calculateNumOfNodes(viewableItems);
    this.#setNodeSubsectionsBasedOnTimerFilter(viewableItems);
  }

  #setNodeSubsectionsBasedOnTimerFilter(viewableItems) {
    this.sourceIdsFiltered = {};
    this.walletsFiltered = [];
    this.singleAddressesFiltered = [];
    this.unknownTypeFiltered = [];
    this.entityTypesFiltered = { source: this.sourceIds };

    Object.keys(viewableItems).forEach((addr) => {
      if (idIsNode(addr)) {
        if (this.sourceIds.includes(addr)) {
          let agentId = viewableItems[addr].data.agentId;
          if (this.sourceIdsFiltered[agentId])
            this.sourceIdsFiltered[agentId].push(addr);
          else this.sourceIdsFiltered[agentId] = [addr];
        }
        if (this.wallets.includes(addr)) this.walletsFiltered.push(addr);
        if (this.singleAddresses.includes(addr))
          this.singleAddressesFiltered.push(addr);

        if (this.unknownType.includes(addr))
          this.unknownTypeFiltered.push(addr);

        Object.keys(this.entityTypesByAddress).forEach((et) => {
          et = et.toLowerCase();
          if (et !== 'source' && et !== 'unknown') {
            if (this.entityTypesByAddress[et].includes(addr))
              if (et in this.entityTypesFiltered)
                this.entityTypesFiltered[et].push(addr);
              else this.entityTypesFiltered[et] = [addr];
          }
        });
      }
    });
  }

  #returnCustomLabel(n) {
    const customLabel = n.custom_label;
    if (customLabel.charAt(25)) {
      let firstLabel = customLabel.slice(0, 25);
      let secondLabel = customLabel.slice(25, customLabel.length);

      const firstLabelWords = firstLabel.split(' ');
      const lastCharOfFirstLabel = firstLabel.charAt(firstLabel.length);
      let lastWordOfFirstLabel = firstLabelWords.pop();
      const secondLabelWords = secondLabel.split(' ');

      if (lastCharOfFirstLabel !== ' ') {
        let firstWordOfSecondLabel = secondLabelWords.shift();
        lastWordOfFirstLabel = lastWordOfFirstLabel + firstWordOfSecondLabel;
      }
      secondLabelWords.unshift(lastWordOfFirstLabel);
      firstLabel = firstLabelWords.join(' ');
      secondLabel = secondLabelWords.join(' ');

      return [firstLabel, secondLabel].map((label, i) => {
        if (!label.length) return false;

        return {
          color: 'white',
          size: 1,
          label: {
            text: label,
            color: '#753bf2',
          },
          angle: idIsLink(n.id || n.edgeId) ? 290 + i * 15 : 110 + i * 15,
          radius: idIsLink(n.id || n.edgeId) ? 60 + i * 8 : 50 + i * 8,
        };
      });
    } else
      return [
        {
          color: 'white',
          size: 1,
          label: {
            text: customLabel,
            color: '#753bf2',
          },
          angle: idIsLink(n.id || n.edgeId) ? 180 : 295,
          radius: idIsLink(n.id || n.edgeId) ? 20 : 45,
        },
      ];
  }

  createGlyphs(n) {
    let glyphs: any[] = [];
    const createBalanceGlyph = (n) => {
      n['border'] = { color: '#3B9441' };

      const assetIcon = this.assetInfo.icon ?? '';
      let labelText = createBalanceGlyphText(
        n.balance,
        n.balance_in_USD,
        assetIcon,
      );

      return {
        color: '#3B9441',
        size: 0.9,
        label: {
          text: labelText,
        },
        angle: 180,
      };
    };

    const createEntityLabel = (n) => ({
      color: 'grey',
      size: 1,
      label: {
        text: n.entity === 'intermediary' ? 'Intermediate Addresses' : n.entity,
      },
      angle: 0,
    });

    const createWalletLabel = (n) => ({
      color: 'grey',
      size: 1,
      label: {
        text: `wallet ${n.wallet}`,
      },
      angle: 180,
    });

    const returnExpandableLabel = (direction, hasBoth) => ({
      labelType: direction,
      color: direction === 'outgoing' ? '#753bf2' : 'white',
      fontIcon: {
        text:
          direction === 'outgoing' ? 'fas fa-angle-up' : 'fas fa-angle-down',
        color: direction === 'outgoing' ? 'white' : '#753bf2',
      },
      border: {
        color: '#753bf2',
      },
      angle: direction === 'outgoing' || !hasBoth ? 62 : 30,
      radius: direction === 'outgoing' || !hasBoth ? 32 : 17,
    });

    const returnErrorLabel = () => ({
      labelType: 'ERROR',
      color: '#753bf2',
      fontIcon: { text: 'fas fa-times-circle', color: 'white' },
      angle: 298,
      radius: 30,
    });

    const returnLoadingLabel = () => ({
      labelType: 'LOADING',
      color: '#753bf2',
      fontIcon: { text: 'fas fa-wifi-1', color: 'white' },
      angle: 298,
      radius: 30,
    });

    const returnLimitedLabel = () => ({
      color: 'orange',
      labelType: 'LIMITED',
      fontIcon: { text: 'fas fa-adjust', color: 'white' },
      angle: 298,
      radius: 30,
    });

    const showUnHide = () => ({
      color: '#753bf2',
      fontIcon: { text: 'fas fa-eye-slash', color: 'white' },
      angle: 298,
      radius: 30,
    });

    if (n.isRiskSource)
      glyphs.push(
        {
          angle: 0,
          radius: 50,
          fontIcon: { text: 'fas fa-chevron-down', color: 'red' },
          color: null,
          size: 1,
        },
        {
          angle: 90,
          radius: 50,
          fontIcon: { text: 'fas fa-chevron-left', color: 'red' },
          color: null,
          size: 1,
        },
        {
          angle: 180,
          radius: 50,
          fontIcon: { text: 'fas fa-chevron-up', color: 'red' },
          color: null,
          size: 1,
        },
        {
          angle: 270,
          radius: 50,
          fontIcon: { text: 'fas fa-chevron-right', color: 'red' },
          color: null,
          size: 1,
        },
      );

    const createBeneficialOwnerGlyph = (n, isAlsoEntity) => ({
      color: '#bfc20f',
      size: 1,
      label: {
        text: n.beneficialOwner,
      },
      angle: 0,
      radius: isAlsoEntity ? 50 : undefined,
    });

    if (n.beneficialOwner) {
      glyphs.push(createBeneficialOwnerGlyph(n, n.entity && n.entity.length));
    }

    if (n.has_balance) glyphs.push(createBalanceGlyph(n));
    if (n.entity) glyphs.push(createEntityLabel(n));
    if (n.is_cluster) glyphs.push(createWalletLabel(n));
    if (n.custom_label) glyphs = glyphs.concat(this.#returnCustomLabel(n));
    if (n.expansion_limit_reached) glyphs.push(returnLimitedLabel());
    if (n.unhidable) glyphs.push(showUnHide());
    else {
      if (n.canExpandOut || n.canExpandIn) {
        if (n.EXPANSION_errored === true) glyphs.push(returnErrorLabel());
        else if (n.EXPANSION_loading === true) {
          glyphs.push(returnLoadingLabel());
        } else {
          const hasBoth = n.canExpandOut && n.canExpandIn;
          if (n.canExpandOut) {
            glyphs.push(returnExpandableLabel('outgoing', hasBoth));
          }
          if (n.canExpandIn) {
            glyphs.push(returnExpandableLabel('incoming', hasBoth));
          }
        }
      }
    }
    return glyphs;
  }

  createIcons(entitytype) {
    return {
      text: this.colorMap[entitytype] ? this.colorMap[entitytype].icon : '',
      color:
        entitytype === 'intermediary' ? '#bbafa2' : SR.colors.nearBlackPurple,
      size: 10,
    };
  }

  #transformNodes(nodes) {
    const createNodeLabel = (n) => {
      const id = n.id.split('_')[0];
      const addrIdLbl =
        id === 'intermediary'
          ? ''
          : id.length <= 12
          ? id
          : `${id.substring(0, 4)}...${id.substring(id.length - 4, id.length)}`;

      const labelText =
        n.clusterid === 'intermediary'
          ? 'Intermediary'
          : n.is_source
          ? addrIdLbl
          : n.id
          ? addrIdLbl
          : '';

      return {
        text: labelText,
        fontSize: 10,
        fontFamily: 'sans-serif',
        color: 'white',
        center: false,
        backgroundColor: 'rgba(0,0,0,0.0)',
      };
    };

    const returnLimitedGlyphMessage = () => ({
      color: 'orange',
      labelType: 'LIMITED',
      angle: 45,
      radius: 50,
      label: { text: '500 most recent Txs' },
    });

    // PURE API DATA CLEANING + PURE ITEM CONVERSION ONLY
    const transformedNodes = nodes
      .map((n) => {
        if (isInternalOnlyEntity(n.entity)) {
          n.entity = '';
        }
        if (this.activeNodes.includes(n.id)) {
          n['halos'] = [{ color: '#753bf2', radius: 30, width: 10 }];
        }
        return {
          ...n,
          ...this.setCombos(n),
        };
      })
      .reduce(
        (ac, n) => ({
          ...ac,
          [n.id]: {
            MISSING_DATA: false,
            fontIcon:
              n.id === 'intermediary'
                ? this.createIcons('intermediary')
                : this.createIcons(n.entitytype.toLowerCase()),
            glyphs: this.createGlyphs(n),
            shape: n.is_cluster ? 'box' : 'circle',
            color:
              n.id === 'intermediary' || n.entitytype === 'intermediary'
                ? 'rgba(0, 0, 0, 0)'
                : n.color,
            fade: false,
            border: n.border ? n.border : {},
            halos: n.halos ? n.halos : [],
            glyphMessage: n.expansion_limit_reached
              ? returnLimitedGlyphMessage()
              : '',
            times: [],
            label: n.is_cluster ? '' : createNodeLabel(n),
            data: { ...n },
            size: n.size ? n.size : 1,
          },
        }),
        {},
      );
    return transformedNodes;
  }

  #transformEdges(edges) {
    const createEdgeLabel = (e) => {
      const labelText = ` ${this.assetInfo.icon ?? ''}${CommaNumber(
        e.txSum.toFixed(5).toString(),
      )}
      ${
        e.txSum_in_USD && !isNaN(e.txSum_in_USD)
          ? ' ($' + CommaNumber(e.txSum_in_USD.toFixed(2).toString()) + ')  '
          : ''
      }`;

      return {
        text: labelText,
        fontSize: 10,
        fontFamily: 'sans-serif',
        color: 'rgba(255, 255, 255, 0.6)',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      };
    };

    // FIXME should conversion be happening again below?
    const transformedEdges = edges
      .map((e) => {
        // this is dynamically built with time filtering, so these calculations are not used
        return {
          ...e,
          txSum: e.txSum ? e.txSum : 0,
          txSum_in_USD: e.txSum_in_USD ? e.txSum_in_USD : 0,
          txTimeStps: e.txTimeStps
            ? e.txTimeStps.map((v) => {
                // the value is not being used
                return {
                  // this is the structure needed for the time bar to work properly
                  time: v,

                  value: 1,
                };
              })
            : [],
        };
      })
      .reduce((ac, e) => {
        let end1Color = false;
        let end2Color = false;

        try {
          end1Color = this.transformedNodes[e.source].data.color;
          end2Color = this.transformedNodes[e.target].data.color;
        } catch {
          console.log(`transformedNodes`, this.transformedNodes);
          console.log('edge data not in node data -source', e.source);
          console.log('edge data not in node data -target', e.target);
          return ac;
        }

        return {
          ...ac,
          [`${e.source + '|' + e.target}`]: {
            id1: e.source,
            id2: e.target,
            end1: {
              color: end1Color ? end1Color : 'grey',
            },
            end2: {
              color: end2Color ? end2Color : 'grey',
              arrow: false,
            },
            flow: false,
            data: { ...e },
            fade: false,
            glyphs: e.custom_label ? this.#returnCustomLabel(e) : [],
            times: e.txTimeStps,
            label: createEdgeLabel(e),
          },
        };
      }, {});

    return transformedEdges;
  }

  setCombos(n, localCombinations = this.localCombinations) {
    // a falsey string ensures that the text labels concatenate with RepIds as expected
    const alwaysLocal = '-l-' + n.agentId;
    const possiblyGlobal = localCombinations ? '-l-' + n.agentId : '';

    const isMultiple = this.clusterLookUp[n.cluster_id].count > 1;

    return {
      // '-lfixed-' ensures sources are not combined globally by wallet
      ...(n.is_source && {
        COMBO_source: 'Source' + alwaysLocal,
      }),
      ...(n.id !== 'intermediary' &&
        isMultiple && {
          COMBO_wallet: n.is_source
            ? `wallet ${this.clusterLookUp[n.id.split('_')[0]].wallet}` +
              alwaysLocal
            : `wallet ${this.clusterLookUp[n.id.split('_')[0]].wallet}` +
              possiblyGlobal,
        }),
      ...(!n.entity &&
        !n.is_source && {
          COMBO_unattributed: '' + possiblyGlobal,
        }),
      ...(n.id !== 'intermediary' && {
        COMBO_entitytype: n.is_source
          ? 'Source' + alwaysLocal
          : !n.entitytype ||
            n.entitytype.toLowerCase() === 'other' ||
            n.entitytype.toLowerCase() === 'unknown' ||
            n.entitytype.toLowerCase() === 'unattributed'
          ? alwaysLocal
          : n.entitytype + possiblyGlobal,
      }),
      ...(n.id !== 'intermediary' && {
        COMBO_entity: n.is_source
          ? 'Source-lfixed-' + n.agentId
          : !n.entity ||
            n.entity.toLowerCase() === 'other' ||
            n.entity.toLowerCase() === 'unknown' ||
            n.entity.toLowerCase() === 'unattributed'
          ? alwaysLocal
          : n.entity + possiblyGlobal,
      }),
    };
  }

  #setThroughput(items) {
    let throughputCrypto = 0;
    let throughputUSD = 0;
    let inputCrypto = 0;
    let inputUSD = 0;

    // FIXME throughput is wrong due to negation on expanded sourcenodes
    Object.keys(items).forEach((id) => {
      if (idIsNode(id)) return;
      const item = items[id];
      const data = item.data;

      // FIXME experiencing integer overflow with massive ETH amounts
      if ('source' in data) {
        if (this.sourceIds.includes(data.source)) {
          throughputCrypto += data.amts.reduce((sum, amt) => sum + amt, 0);
          throughputUSD += data.amts_USD.reduce((sum, amt) => sum + amt, 0);
        }
      }
      if ('target' in data) {
        if (this.sourceIds.includes(data.target)) {
          inputCrypto += data.amts.reduce((sum, amt) => sum + amt, 0);
          inputUSD += data.amts_USD.reduce((sum, amt) => sum + amt, 0);
        }
      }
    });

    this.throughputCrypto = throughputCrypto;
    this.throughputUSD = throughputUSD;
    this.inputCrypto = inputCrypto;
    this.inputUSD = inputUSD;
  }

  setEgressEntities(viewableItems) {
    const viewableNodes = _.entries<any>(viewableItems)
      .filter(([id]) => idIsNode(id))
      .map(([, obj]) => obj.data);

    const egressEntities = {};
    const entityTypesByAddress = {};
    const flowType = this.flowType;
    const UniqueIDLookUp = this.UniqueIDLookUp;

    viewableNodes.forEach((n) => {
      if (n.is_stop_cluster && !n.is_source) {
        const incomingORoutgoing =
          flowType === -1 ? 'incomingEdges' : 'outgoingEdges';
        const relevantEdges = UniqueIDLookUp[n.id][incomingORoutgoing]
          ? UniqueIDLookUp[n.id][incomingORoutgoing]
          : [];
        const throughputCrypto = this.throughputCrypto;
        const inputCrypto = this.inputCrypto;

        const itemLookUp = this.items;

        let entitytype = n.entitytype;
        let entity = n.entity;

        if (entitytype === '') {
          entitytype = 'unknown';
        }
        entitytype = entitytype.toLowerCase();

        if (entity === '') {
          entity = 'unknown';
        }

        if (!(entity in egressEntities)) {
          egressEntities[entity] = {
            color: n.color,
            entitytype: entitytype,
            proportion: {
              sum: 0,
              sumUSD: 0,
              percent: 0.0,
            },
            ids: [],
          };
        }

        if (!(entitytype in entityTypesByAddress)) {
          entityTypesByAddress[entitytype] = [];
        }

        const findSumInEdges = (proportion, edgesIds) => {
          edgesIds.forEach((eId) => {
            const edge = itemLookUp[eId].data;
            const graphDirection = flowType === -1 ? 'source' : 'target';
            const relevantScore =
              itemLookUp[edge[graphDirection]].data.sumscore;
            proportion.sum += edge.amts.reduce(
              (acc, amt) => acc + amt * relevantScore,
              0,
            );
            proportion.sumUSD += edge.amts_USD.reduce(
              (acc, amt) => acc + amt * relevantScore,
              0,
            );
          });

          let throughputorInputCrypto =
            flowType === -1 ? throughputCrypto : inputCrypto;

          return {
            sum: proportion.sum,
            sumUSD: proportion.sumUSD,
            percent: (proportion.sum / throughputorInputCrypto) * 100, // this should be throughput
          };
        };
        egressEntities[entity].proportion = findSumInEdges(
          egressEntities[entity].proportion,
          relevantEdges,
        );
        egressEntities[entity].ids.push(n.id);
        entityTypesByAddress[entitytype].push(n.id);
      }
    });
    this.egressEntities = egressEntities;
    this.entityTypesByAddress = entityTypesByAddress;
  }

  getNodesWithBalance(items) {
    const ids = new Set();
    for (const id of _.keys(items)) {
      if (
        idIsNode(id) &&
        id in items &&
        items[id] &&
        'data' in items[id] &&
        'has_balance' in items[id].data &&
        items[id].data.has_balance
      )
        ids.add(id);
    }
    this.nodesWithBal = Array.from(ids);
  }

  getNodesActiveFromDays(items) {
    // The logic for this is different then previous.
    // Time data is in the links... So lets utilize that.
    const ids = new Set();
    const fourteendaysago = new Date().getTime() - 1209600000;
    for (const id in items) {
      if (idIsLink(id)) {
        // Base case checks.. They should never trigger;
        if (_.isEmpty(items[id].times)) continue;
        // If a time stamp on an edge is > then 14 days,
        // we return return a non empty array.
        if (
          items[id].times.filter((time) => time.time > fourteendaysago).length
        ) {
          // Lets add the items the edge refers too.
          // A link connects two nodes, so we include both nodes per id.
          ids.add(items[id].id1);
          ids.add(items[id].id2);
        }
      }
    }
    this.activeNodes = Array.from(ids);
    this.activeNodes.forEach((aN) => {
      if (items[aN]) {
        items[aN].halos = [{ color: '#753bf2', radius: 30, width: 10 }];
      }
    });
  }

  calculateNumOfNodes(items) {
    this.nCount = _.keys(items).filter((id) => idIsNode(id)).length;
  }

  getEgressData() {
    const ifInputorThroughput = this.flowType === -1 ? 'out' : 'in';

    const chartData: any = {
      type: 'treemap',
      options: {
        'split-type': 'balancedv2', // check these options
        'aspect-type': 'palette',
        palette: [],
      },
      plotarea: {
        margin: '0 0 0 0',
      },
      series: [],
    };

    const entityTypes = new Set(Object.keys(this.entityTypesByAddress));
    const seriesMapping = {};
    entityTypes.forEach(
      (entity) =>
        (seriesMapping[entity] = { text: entity, color: '', children: [] }),
    );

    Object.entries<any>(this.egressEntities).forEach(([entity, eData]) => {
      const { color, entitytype, proportion } = eData;
      if (entitytype === 'unknown') return;
      seriesMapping[entitytype].color = color;
      if (proportion.sum) {
        const cryptoBalance = new DenominatedBalance('', proportion.sum);
        const usdBalance = new DenominatedBalance('$', proportion.sumUSD);
        seriesMapping[entitytype].children.push({
          text: ` ${entity} <br> ${cryptoBalance.amt} ${
            this.assetInfo.symbol
          } ${proportion.sumUSD ? '<br>' + usdBalance.amt : ''}
${parseFloat(proportion.percent).toFixed(2)}% ${ifInputorThroughput} `,
          'data-uid': entity,
          value: proportion.sum,
        });
      }
    });

    const series = _.values(seriesMapping);
    // To ensure we are accurately taking into account the ordering of the colors.
    // Lets just map over the final series output to get the colors in order.
    // We want to make sure the ordering is preserved.
    const palette = _.map<any>(series, (o) => o.color);
    chartData.options.palette = palette;
    chartData.series = series;
    /** Congrats, we just rebuilt 'buildChartJSON' without having caused a single side effect due to the use of 'var'*/

    return chartData;
  }
}

export function createBalanceGlyphText(balance, balanceUsd, symbol) {
  // max chars returned: 11 for "< B 0.00001"
  const balanceRep = new DenominatedBalance(symbol, balance);
  const usdBalanceRep = new DenominatedBalance('$', balanceUsd);

  let glyphText = ` ${balanceRep.amt} `;
  if (usdBalanceRep) {
    glyphText += `${usdBalanceRep.amt}`;
  }
  return glyphText;
}

export default ReportDataWithRegraph;

// TODO put a flag in the item data
const idIsComboNode = (id: string): boolean => {
  return !!id && _.startsWith(id, '_combonode_');
};

const idIsComboLink = (id: string): boolean => {
  return !!id && _.startsWith(id, '_combolink_');
};

const idIsCombo = (id: string): boolean => {
  return idIsComboNode(id) || idIsComboLink(id);
};

const idIsLink = (id: string): boolean => {
  return !!id && _.includes(id, '|') && !idIsCombo(id);
};

const idIsNode = (id: string): boolean => {
  return !!id && !idIsLink(id) && !idIsCombo(id);
};
