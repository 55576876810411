import React from 'react';

import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

const SliderBar = ({
  value,
  onChange,
  max,
  min,
  steps,
  isRight,
  isDilution = false,
  allIs100 = false,
  isMinTrx = false,
}) => {
  const stepToUse = steps ? steps : (max - min) / 100;
  value = parseFloat(value);
  value = value < min ? min : value;
  const logStep = Math.log2(max + 1) / 100;

  const scale = (linearStep) => {
    return 2 ** (logStep * linearStep) - 1;
  };

  const convertBackToLinStep = (logVal) => {
    return Math.log2(logVal + 1) / logStep;
  };

  const valueLabelFormat = (_value) => {
    if (max === 10 && value >= 10) {
      return ['10+', isRight]; // hops
    }
    if (isDilution) {
      return [`${value.toFixed(0)}%`, isRight];
    }
    if (max < 1) return [value.toFixed(4), isRight]; // for small tx amts
    if (steps === 1) return [value, isRight]; // hops
    return [value.toFixed(2), isRight];
  };

  return allIs100 ? (
    <>
      <p id='dilutionDisclaimerText'>Concentration is 1 </p>
      <p id='dilutionDisclaimerText'>for all nodes.</p>
    </>
  ) : (
    <div id='slidercontainer'>
      <Slider
        value={isMinTrx ? convertBackToLinStep(value) : value}
        onChange={(e, val) => {
          return onChange(e, val, scale(val));
        }}
        scale={isMinTrx ? (linStep) => scale(linStep) : (val) => val}
        valueLabelDisplay='auto'
        aria-labelledby='discrete-slider-small-steps'
        valueLabelFormat={valueLabelFormat}
        step={isMinTrx ? 1 : stepToUse}
        max={isMinTrx ? 100 : max}
        min={min}
        ValueLabelComponent={ValueLabelComponent}
      />
    </div>
  );
};

export default SliderBar;

const ValueLabelComponent = (props) => {
  const { children, open, value } = props;
  const [val, isRight] = value;
  return (
    <LightTooltip
      open={open}
      enterTouchDelay={0}
      placement={isRight ? 'right' : 'bottom'}
      title={val}
    >
      {children}
    </LightTooltip>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#e2deeb',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontFamily: 'Roboto, Arial, sans-serif',
  },
}))(Tooltip);
