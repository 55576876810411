import React from 'react';
import { Panel } from 'react-bootstrap';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import NavBar from 'components/NavBar';

import { ReportContainer } from './styles';
import { styleRefs as SR } from 'styles/styleRefs';

const Unauthorized = () => {
  return (
    <ReportContainer>
      <NavBar view='list' />
      <div>
        <div id='report-frame-list' className='report-frame-new-list light-col'>
          <div className='panel-container'>
            <Panel bsStyle='primary'>
              <Panel.Heading
                style={{
                  borderColor: 'transparent',
                  background: 'transparent',
                }}
              >
                <Panel.Title>
                  <LocationOffIcon style={{ color: 'black', fill: 'black' }} />
                  401 - Unauthorized
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <p>
                  Your account is locked. Please contact{' '}
                  <a
                    style={{ color: SR.colors.emphasis_lightPurple }}
                    href='mailto:support@elementus.io'
                  >
                    support@elementus.io
                  </a>{' '}
                  for details.
                </p>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
    </ReportContainer>
  );
};

export default Unauthorized;
