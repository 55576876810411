import styled from 'styled-components';

export const MonitoredAddressesContainer = styled.div`
  .ma-wrapper {
    display: flex;
    flex-direction: row;

    .ma-form {
      padding-right: 1em;

      .rma-input {
        margin-bottom: 1em;

        input {
          background-color: #fff;
          font-size: 14px;
          display: flex;
          margin: auto 0;
          width: 300px;
        }
      }

      .btn-ma {
        height: 54px;
        margin: auto 0 auto 1em;
      }
    }
  }
`;
