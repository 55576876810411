import React from 'react';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import Popover from '@material-ui/core/Popover';
import { AddressListWrapper } from './styles';

const AddressList = (props) => {
  const items = props.items || [];
  const isAddresses = props.isAddresses;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let itemArr = [];
  items.forEach(function (a) {
    itemArr.push(<div key={a}>{a}</div>);
  });

  const suffix = isAddresses
    ? ` address${items.length === 1 ? '' : 'es'}`
    : ` transaction${items.length === 1 ? '' : 's'}`;
  let itemCnt = items.length + suffix;

  return (
    <AddressListWrapper>
      <div title='View all'>
        <ListAltRoundedIcon
          className='addressdataicon'
          onClick={handleClick}
        ></ListAltRoundedIcon>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='addresspopover'>
          <div className='metakey'>{itemCnt}</div>
          <div id='metaaddresses'>{itemArr}</div>
        </div>
      </Popover>
    </AddressListWrapper>
  );
};

export default AddressList;
