import React, { useState } from 'react';
import { LayoutButtonContainer, ActivePurpleButton } from './styles';
import Switch from '@material-ui/core/Switch';
import { FlowType } from 'utils/enums';

import {
  toggleFlow,
  toggleLabels,
  toggleExposure,
  changeBaselayout,
  rotate,
} from 'hooks/useGraph/actions';
import * as Toolbar from 'components/Common/IconButtons';

// Cleaned up
const layoutOptions = [
  {
    id: 'organic',
    name: 'Organic',
    icon: 'icon-spread',
    description:
      'Organic is a force-directed layout, making links similar lengths and reducing node and edge overlaps as they distribute items evenly across the chart. Good for any type or size of data, being particularly useful for finding patterns and symmetries.',
  },
  {
    id: 'lens',
    name: 'Lens',
    icon: 'icon-data_science',
    description:
      'Lens layout pushes highly-connected nodes into the center, and forces less connected nodes outwards. Due to its circular arrangement, the lens layout makes good use of the available space and generally creates networks that are denser than other layouts.',
  },
  {
    id: 'radial',
    name: 'Radial',
    icon: 'icon-layout_radial',
    description:
      'Radial layout arranges nodes in concentric circles around a selected subject in a radial tree. Each ‘generation’ of nodes becomes a new ring surrounding the previous generations. Generally, this layout is the best option when dealing with networks with a large number of child nodes compared to the number of parents.',
  },
  {
    id: 'structural',
    name: 'Structural',
    icon: 'icon-link_3_nodes',
    description:
      'Structural layout functions in a similar manner to the organic layout, but nodes with the same neighboring nodes are grouped together. This makes it easier to see the general organization of a network.',
  },
  {
    id: 'sequential',
    name: 'Sequential',
    icon: 'icon-layout_hierarchical',
    description:
      'The sequential layout is useful for displaying data with a clear sequence of links between distinct levels of nodes. It takes multiple components into account, minimizing link crossings and making efficient use of the available screen space.',
  },
];

export default function Layouts({ dispatch, graphState }) {
  const [showLayouts, setShowLayouts] = useState(false);
  const isRadarRep = graphState.ReportData.flowType >= FlowType.Radix;

  return (
    <>
      <div
        id='layout-buttons'
        style={{
          zIndex: 15,
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          left: '5px',
          top: '5px',
          backgroundColor: 'rgba(28,20,45,0)',
        }}
      >
        <LayoutButtonContainer>
          <ActivePurpleButton
            className='layout-button'
            key={graphState.layout.name}
            height='25px'
            value={graphState.layout.name}
            onClick={() => setShowLayouts((b) => !b)}
          >
            Layout: {graphState.layout.name}
          </ActivePurpleButton>

          {showLayouts &&
            layoutOptions.map((o) =>
              isRadarRep && o.id === 'sequential' ? (
                <div key={o.id} /> // map requires a return value, and list items need a key
              ) : (
                <ActivePurpleButton
                  className='layout-option'
                  key={o.id}
                  height={graphState.layout.name === o.id ? '30px' : '20px'}
                  value={o.name}
                  title={o.description}
                  onClick={() => {
                    dispatch(changeBaselayout(o.id));
                    setShowLayouts((b) => !b);
                  }}
                >
                  {o.name}
                </ActivePurpleButton>
              ),
            )}
        </LayoutButtonContainer>
        {!showLayouts && graphState.layout.name === 'sequential' && (
          <Toolbar.Rotate
            onClick={() => dispatch(rotate())}
            orientation={graphState.layout.orientation}
            tooltip={'Rotate'}
          />
        )}
      </div>
      <div>
        <div
          id='toggles-buttons'
          style={{
            zIndex: 15,
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: '5px',
            top: '50px',
            backgroundColor: 'rgba(28,20,45,0)',
          }}
        >
          <Switch
            size='small'
            checked={graphState.flowToggle}
            onChange={() => dispatch(toggleFlow())}
            value='flow'
            color='primary'
          />
          <b
            style={{
              paddingTop: '4px',
              fontFamily: 'Verlag A, Verlag B',
              fontSize: '11px',
              color: 'white',
            }}
          >
            Flow
          </b>
          <Switch
            size='small'
            checked={graphState.labelsToggle}
            onChange={() => dispatch(toggleLabels())}
            value='labels'
            color='primary'
          />
          <b
            style={{
              paddingTop: '4px',
              fontFamily: 'Verlag A, Verlag B',
              fontSize: '11px',
              color: 'white',
            }}
          >
            Labels
          </b>
          {isRadarRep ? (
            <></>
          ) : (
            <>
              <Switch
                size='small'
                checked={graphState.exposureToggle}
                onChange={() => dispatch(toggleExposure())}
                value='labels'
                color='primary'
              />
              <b
                style={{
                  paddingTop: '4px',
                  fontFamily: 'Verlag A, Verlag B',
                  fontSize: '11px',
                  color: 'white',
                }}
              >
                Exposure
              </b>
            </>
          )}
        </div>
      </div>
    </>
  );
}
