import React, { useContext, useState, useEffect } from 'react';

import ForensicAnalysis from 'models/forensicAnalysis';

import ElementusAPIService from 'services/elementus_api.service';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { NewAgentInput, NewAgentForm } from './styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
  cleanAddrETH,
  cleanAddrBTC,
  cleanTxBTC,
  cleanTxETH,
} from 'utils/crypto';
import { trimLeadingTrailingWhitespace } from 'helpers/utils';
import { store } from 'ReportData/ReportDataStore';

import { useGraph } from 'hooks/useGraph';
import { useInterval } from 'hooks';

const defaultButtonMessage = 'input new address(es) or hash(es)';

export default function NewAgent() {
  const { state: graphState, updateGraph } = useGraph();
  const { state: globalState } = useContext(store);
  const [_newAgentAddress, set_newAgentAddress] = useState('');
  const [newAgentInputVisible, set_newAgentInputVisible] = useState(false);
  const [_buttonMessage, set_buttonMessage] = useState(defaultButtonMessage);
  const [_isError, set_isError] = useState(false);
  const [_childAgentID, set_childAgentID] = useState(null);

  const [_loading, set_loading] = useState(false);

  const [_childAgentsNotLoaded, set_childAgentsNotLoaded] = useState(true);

  useEffect(() => {
    const childAgentsNotLoaded =
      graphState.ReportData &&
      graphState.ReportData.reportIds &&
      graphState.ReportData.meta.child_agents &&
      graphState.ReportData.reportIds.length - 1 <
        graphState.ReportData.meta.child_agents.length;
    set_childAgentsNotLoaded(childAgentsNotLoaded);

    if (childAgentsNotLoaded) set_buttonMessage('loading child agents');
    else set_buttonMessage(defaultButtonMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphState.items]);

  const API = new ElementusAPIService(graphState.ReportData.token);

  const openNewAgentInput = () => {
    set_newAgentInputVisible(!newAgentInputVisible);
    set_buttonMessage(defaultButtonMessage);
    set_childAgentID(null);
    set_newAgentAddress('');
    set_isError(false);
  };

  const handleSubmit = () => {
    // close and clear input
    set_newAgentInputVisible(false);
    set_buttonMessage('loading');

    // handle invalid addresses and duplicate addresses
    const addressesSplit = _newAgentAddress
      .split(',')
      .map((addr) => trimLeadingTrailingWhitespace(addr));

    const cleanAddressesBTC = cleanAddrBTC(addressesSplit);
    const cleanAddressesETH = cleanAddrETH(addressesSplit);
    const cleanTransactionsBTC = cleanTxBTC(addressesSplit);
    const cleanTransactionsETH = cleanTxETH(addressesSplit);

    const improperlyFormatted =
      parseInt(cleanAddressesBTC.length > 0) +
        parseInt(cleanAddressesETH.length > 0) +
        parseInt(cleanTransactionsBTC.length > 0) +
        parseInt(cleanTransactionsETH.length > 0) >
      1;

    // NOTE making reports single-asset for now
    if (improperlyFormatted) {
      const errorMsg = ' Inproperly formatted address or transaction hash';
      set_buttonMessage(errorMsg);
      set_isError(true);
      return null;
    }

    API.createForensicAnalysis(
      graphState.ReportData.meta.name + '_child',
      addressesSplit,
      true, // is full cluster - set with button
      graphState.ReportData.meta.reportParams,
      globalState.ReportData.meta.flow_type,
      graphState.ReportData.assetInfo.asset,
      globalState.ReportData.reportIds[0], // parentId
    )
      .then(async (res) => {
        set_childAgentID(res._id); // instigates loading interval
      })
      .catch((error_res) => {
        const errorMsg = error_res.error.message
          .toLowerCase()
          .includes('failed to fetch')
          ? 'Network too large. Please contact us for more info.'
          : error_res.error.message;
        set_buttonMessage(errorMsg);
        set_isError(true);
      });
  };

  const handleNewAgent = (event) => {
    // stops full reload
    event.preventDefault();

    if (_childAgentsNotLoaded) {
      return;
    }

    if (_newAgentAddress.length) {
      return handleSubmit(event);
    }

    return openNewAgentInput(event);
  };

  const checkStatusOfChildRep = async () => {
    if (!_loading && _childAgentID.length) {
      set_loading(true);
      try {
        const newChildAgentRes = await API.getForensicAnalysis(
          _childAgentID,
          graphState.userParameters,
        );
        // TODO: make report status checks separate from report data fetching
        // from the point of view of web app code outside of the API client
        if (newChildAgentRes.status === 'still_building') {
          set_loading(false);
        } else {
          set_childAgentID(null);
          set_newAgentAddress('');
          set_loading(false);
          const normalizedReportData =
            ForensicAnalysis.normalizeReportData(newChildAgentRes);
          const childAgent =
            ForensicAnalysis.markAgentResponses(normalizedReportData);
          graphState.ReportData.reportIds.push(childAgent.meta._id);
          updateGraph(childAgent);
        }
      } catch (resp) {
        set_buttonMessage('report failed to build');
        set_isError(true);
        set_childAgentID(null);
        set_newAgentAddress('');
        set_loading(false); // this defaults loading to false
      }
    }
  };

  useInterval(checkStatusOfChildRep, _childAgentID ? 1000 : null);

  return (
    <NewAgentForm onSubmit={handleNewAgent}>
      <NewAgentInput
        clicked={newAgentInputVisible}
        onChange={(e) => {
          if (e.target.value.length) set_buttonMessage('submit');
          set_newAgentAddress(e.target.value);
        }}
        value={_newAgentAddress}
      />

      <IconButton
        className='user-input-button'
        disable='true'
        onClick={handleNewAgent}
      >
        <Tooltip
          title={
            <div style={{ fontSize: '12px' }}>
              {_buttonMessage.length && _buttonMessage}
            </div>
          }
          aria-label='Apply Combos Globally'
        >
          <AddCircleOutlineIcon
            className={`settings-icon ${
              (_loading || _childAgentsNotLoaded) && 'bounce'
            }`}
            fontSize='large'
            style={{
              fill:
                (_newAgentAddress.length && !_isError) || _childAgentsNotLoaded
                  ? 'green'
                  : _isError
                  ? 'red'
                  : 'white',
            }}
          />
        </Tooltip>
      </IconButton>
    </NewAgentForm>
  );
}
