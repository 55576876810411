import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { transfersToCSV } from '../../../helpers/convert';
import { styleRefs as SR } from 'styles/styleRefs';

const DownloadCSVBtn = (props) => {
  const [transfersList, setTransfersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const reportName = props.reportName.split(' ').join('_');
  const exportFilename = `${reportName}_table.csv`;

  const getTransfers = (_event, done) => {
    if (!isLoading) {
      setIsLoading(true);
      const transfers = transfersToCSV(Object.values(props.transactions));
      setTransfersList(transfers);
      setIsLoading(false);
      done(true);
    }
  };

  const style = {
    height: '50%',
    width: '120px',
    margin: '8px',
    fontSize: SR.fontSize.sml,
  };

  const title = isLoading ? 'Loading Csv...' : 'Download Table';

  return (
    <CSVLink
      data={transfersList}
      asyncOnClick={true}
      onClick={getTransfers}
      filename={exportFilename}
      className='flux-btn flux-btn-bg random-btn pull-right'
      style={style}
      target='_blank'
    >
      {title}
    </CSVLink>
  );
};

export default DownloadCSVBtn;
