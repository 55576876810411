import React from 'react';
import Modal from 'react-modal';
import { ModalStyles } from '../Graph/styles';

import ReportsNew from './ReportsNew.js';
import ReportsNewAML from './ReportsNewAML.js';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default function NewReportModal(props) {
  return (
    <Modal
      isOpen={props.openModal}
      style={ModalStyles({ width: '650px' })}
      closeTimeoutMS={150}
      contentLabel='New Report'
    >
      {props.ifComplianceModal ? (
        <ReportsNewAML
          reloadList={props.reloadList}
          addNewReport={props.addNewReport}
          toggleModal={props.toggleModal}
          presetItems={props.presetItems}
        />
      ) : (
        <ReportsNew
          reloadList={props.reloadList}
          addNewReport={props.addNewReport}
          toggleModal={props.toggleModal}
          presetItems={props.presetItems}
        />
      )}
    </Modal>
  );
}
