import _ from 'lodash';

export const idIsComboNode = (id) => id && _.startsWith(id, '_combonode_');
export const idIsComboLink = (id) => id && _.startsWith(id, '_combolink_');
export const idIsCombo = (id) => idIsComboNode(id) || idIsComboLink(id);
export const idIsLink = (id) => id && _.includes(id, '|') && !idIsCombo(id);
export const idIsNode = (id) => id && !idIsLink(id) && !idIsCombo(id);

const zeroToOne = (scores) => {
  const minimum = _.min(_.values(scores));
  const maximum = _.max(_.values(scores));
  return _.mapValues(scores, (value) => {
    if (maximum === minimum) return minimum;
    return (value - minimum) / (maximum - minimum);
  });
};

export const normalize = (scores) => {
  if (!scores) return undefined;
  return zeroToOne(scores);
};
export const getNodes = (items) => {
  return _.reduce(
    items,
    (acc, item, id) => {
      const { data } = item;
      if (idIsNode(id)) acc[id] = data;
      return acc;
    },
    {},
  );
};
export const getSourceIds = (items) => {
  const ids = new Set();
  for (const id of _.keys(items)) {
    if (idIsNode(id) && items[id].data.is_source) {
      ids.add(id);
    }
  }
  return ids;
};

export const getSelectionBreakdown = (selection) => {
  const nodes = [];
  const links = [];
  const comboNodes = [];
  const comboLinks = [];
  let total = 0;
  _.forEach(selection, (selected, id) => {
    if (selected === true) {
      if (idIsLink(id)) links.push(id);
      else if (idIsNode(id)) nodes.push(id);
      else if (idIsComboLink(id)) comboLinks.push(id);
      else if (idIsComboNode(id)) comboNodes.push(id);
      total++;
    }
  });

  return { total, nodes, links, comboNodes, comboLinks };
};

export const getParentComboId = (item) =>
  item && _.values(item.data).length > 0
    ? _.join(_.concat(['_combonode'], _.values(item.data)), '_')
    : null;

export const getSelectedIds = (selected) => {
  const selectedSet = new Set();
  for (const id of _.keys(selected)) selectedSet.add(id);
  return selectedSet;
};

export const getTransfersByLinkItem = (item) => {
  if (item && 'data' in item && 'transfers' in item.data) {
    return [...item.data.transfers];
  }
  return [];
};

export const getTransfersByLinkId = (items, id) => {
  if (idIsLink(id) && items && id in items) {
    return getTransfersByLinkItem(items[id]);
  }
  return [];
};

export const addPresentSourceNodes = (items, viewableItems) => {
  // all source nodes should be shown in graph
  _.forEach(items, (item, id) => {
    if (idIsNode(id) && item.data.is_source) {
      viewableItems[id] = item;
    }
  });
  return viewableItems;
};

export const getLinkIdsFromComboLinkId = (comboLinks, id) => {
  if (
    idIsComboLink(id) &&
    comboLinks &&
    id in comboLinks &&
    'links' in comboLinks[id]
  ) {
    return [..._.keys(comboLinks[id].links)];
  }
  return [];
};

export const getAllTransfers = (items) => {
  const transfers = Object.entries(items).reduce((acc, [, val]) => {
    const trans = (val.data || {}).transfersLookup;
    if (_.isEmpty(trans)) return acc;
    Object.entries(trans).forEach(([, v]) => {
      if (v && v.from_cluster && v.to_cluster) {
        acc[`${v.from_cluster}|${v.to_cluster}`] = v;
      }
    });
    return acc;
  }, {});
  return Object.values(transfers);
};

export const getAssetFromTransfer = (t) => {
  if (_.isEmpty(t)) return 'btc';
  if (_.isEmpty(t.asset)) return 'btc';
  return t.asset;
};

export const getBaseDenominationFromAsset = (a) => {
  if (a === 'btc') return 100000000;
  if (a === 'eth') return 10000;
  // for assets
  return 10000;
};

export const getBaseDenominationFromTransfer = (t) => {
  const a = getAssetFromTransfer(t);
  return getBaseDenominationFromAsset(a);
};

export const getBaseDenominationFromTransferList = (tList) => {
  const a = getAssetFromTransferList(tList);
  return getBaseDenominationFromAsset(a);
};

const getAssetFromTransferList = (tList) => {
  if (_.isEmpty(tList)) return 'btc';
  return getAssetFromTransfer(tList[0]);
};

export const getNodesWithBalance = (items) => {
  const ids = new Set();
  for (const id of _.keys(items)) {
    if (
      idIsNode(id) &&
      id in items &&
      items[id] &&
      'data' in items[id] &&
      'has_balance' in items[id].data &&
      items[id].data.has_balance
    )
      ids.add(id);
  }
  return Array.from(ids);
};

export const getNodesWithBalanceCnt = (items) => {
  const nodes = getNodesWithBalance(items);
  return nodes.length;
};

//
export const getNodesActiveFromDays = (items) => {
  // The logic for this is different then previous.
  // Time data is in the links... So lets utilize that.
  const ids = new Set();
  const fourteendaysago = new Date() - 1209600000;
  for (const id in items) {
    if (idIsLink(id)) {
      // Base case checks.. They should never trigger;
      if (_.isEmpty(items[id].times)) continue;
      // If a time stamp on an edge is > then 14 days,
      // we return return a non empty array.
      if (items[id].times.filter((v) => v.time > fourteendaysago).length) {
        // Lets add the items the edge refers too.
        // A link connects two nodes, so we include both nodes per id.
        ids.add(items[id].id1);
        ids.add(items[id].id2);
      }
    }
  }
  return Array.from(ids);
};

export const calculateThroughput = (items, converted) => {
  // Get all transfers from items.
  const transfers = getAllTransfers(items);
  // handle tx with no amt_in_USD (happening before data is available)
  let convAndMissingTransferData = false;
  const sourceTainted = transfers.reduce((acc, tx) => {
    let txAmt = 0;
    if (converted) {
      if (!tx.amt_in_USD) {
        convAndMissingTransferData = true;
        return acc;
      }
      txAmt = parseFloat(tx.amt_in_USD);
    } else {
      txAmt = tx.amt;
    }

    acc =
      tx._from in items &&
      'data' in items[tx._from] &&
      items[tx._from].data.is_source
        ? acc + txAmt
        : acc;
    acc =
      tx._to in items && 'data' in items[tx._to] && items[tx._to].data.is_source
        ? acc - txAmt
        : acc;

    return acc;
  }, 0);

  if (converted && convAndMissingTransferData) {
    return null;
  } else {
    return sourceTainted;
  }
};

export const calculateNumOfNodes = (items) => {
  let cnt = 0;
  for (const id in items) if (idIsNode(id)) cnt++;
  return cnt;
};

export const calculateNumOfTrans = (items) => {
  const totalTransfers = getAllTransfers(items);
  return totalTransfers.length;
};
