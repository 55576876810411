import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'contexts/user';
import NavBar from 'components/NavBar';
import { ActiveAlerts } from './ActiveAlerts';
import { AddAlert } from './AddAlert';
import { Panel } from 'components/Common/Panel';
import { AlertWrapper } from './styles';
import { createAlertData } from './utils';
import { NotificationsList } from './NotificationsList';
import { LoadingBubbles } from 'components/Common/Loading';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export const AlertsComponent = () => {
  const { apiClient, user } = useContext(UserContext);
  const [alertList, setAlertList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  useEffect(() => {
    const fetchData = async () =>
      await apiClient.alerts
        .getAll()
        .then((data) => setAlertList(data))
        .catch((err) => console.error(err));

    fetchData();
  }, [apiClient]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      return apiClient.notifications
        .getAll()
        .then((data) => {
          setNotificationList(data);
        })
        .catch((err) => console.error(err))
        .then(() => setLoading(false));
    };

    fetchData();
  }, [apiClient]);

  const createAlert = async (data) => {
    setLoading(true);
    const userEmail = user._authUser.email;
    const alertData = createAlertData({ ...data, userEmail });

    return apiClient.alerts
      .create(alertData)
      .then((data) => {
        setAlertList([...alertList, data]);
        setSnackbarOpen(true);
      })
      .catch((err) => console.error(err))
      .then(() => setLoading(false));
  };

  const deleteAlert = async (id) => {
    if (!id || !id.length) return;

    setLoading(true);
    return apiClient.alerts
      .delete(id)
      .then(() => {
        const tmpAlerts = alertList.filter((item) => item._id !== id);
        setAlertList(tmpAlerts);
      })
      .catch((err) => console.error(err))
      .then(() => setLoading(false));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      {loading && <LoadingBubbles />}
      <NavBar view='list' />
      <AlertWrapper className={loading ? 'is-disabled' : ''}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity='success'>
            Alert created!
          </Alert>
        </Snackbar>
        <Panel>
          <Panel.Heading>
            <Panel.Title componentClass='h1'>Alerts</Panel.Title>
          </Panel.Heading>

          <Panel.Body className='main-body'>
            <AddAlert createAlert={createAlert}></AddAlert>
            <ActiveAlerts
              data={alertList}
              deleteAlert={deleteAlert}
            ></ActiveAlerts>
            <NotificationsList
              data={notificationList}
              deleteAlert={deleteAlert}
            ></NotificationsList>
          </Panel.Body>
        </Panel>
      </AlertWrapper>
    </>
  );
};
