import React, { useContext, useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import TransactionsTable from 'components/Report/Table';
import { Resizable } from 're-resizable';
import { Chart, TimeBar, FontLoader } from 'regraph';
import { store } from 'ReportData/ReportDataStore';
import { ReGraphFrame } from './styles';
import { Box, Grid } from 'grommet';
import UserInput from './UserInput';
import { FlowType, ReportType } from 'utils/enums';
import _ from 'lodash';

import Legend from './legend';
import Layouts from './Layouts';
import Combinations from './Combinations';

import { useGraph } from 'hooks/useGraph';

// TODO: Move to centralized stlyes section
import 'fonts/fontawesome-pro/css/all.css';
import 'fonts/mfglabs/mfglabs_iconset.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';

import ContextMenu from './ContextMenu';
import NewReportModal from '../NewReport/NewReportModal.js';
import CustomLabelModal from './CustomLabelModal';
import DownloadGraph from './GraphButtons/DownloadGraph';

const Graph = () => {
  const { state: globalState } = useContext(store);
  const {
    state,
    dispatch,
    chartRef,
    timeBarRef,
    onContextMenu,
    onCombineLinks,
    onCombineNodes,
    onHover,
    onTimeBarChange,
    onDoubleClick,
    onPointerDown,
    onChange,
    onClick,
    onKeyDown,
    onKeyUp,
  } = useGraph();

  const [openModal, setopenModal] = useState(false);

  const [presetNewReportItems, setPresetNewReportItems] = useState([]);
  const toggleModal = (presetItems = []) => {
    setopenModal(!openModal);
    setPresetNewReportItems(presetItems);
  };

  const limitPopover = (
    <Popover id='popover-info' title=''>
      This report is Manual expansion only due to a very large transfer network.
    </Popover>
  );

  const reformatLayout =
    state.histPointer === 0 ||
    (state.positions[state.histPointer] &&
      state.positions[state.histPointer].state &&
      !state.positions[state.histPointer].state.step &&
      state.positions[state.histPointer].state.combine);

  const showTable = globalState.ReportData.flowType !== FlowType.Entity;

  return (
    <>
      <Resizable
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: showTable ? 'solid 3px #e2deeb' : 'none',
        }}
        enable={
          showTable
            ? {
                top: false,
                right: false,
                bottom: true,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }
            : false
        }
        defaultSize={{ width: '100%', height: '75vh' }}
        maxHeight={'75vh'}
      >
        <div
          style={{ height: '100%', width: '100%' }}
          className='no-gutters d-flex'
          id='graphcontainer'
        >
          <ReGraphFrame>
            <FontLoader
              config={{ custom: { families: ['Font Awesome 5 Pro'] } }}
            >
              <Grid
                fill
                areas={[{ name: 'main', start: [0, 0], end: [0, 0] }]}
                columns={['flex']}
                rows={['100%']}
              >
                {showTable && <Legend graphData={state.viewableItems} />}
                <div
                  id='graph-bottom-right'
                  style={{
                    zIndex: 15,
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    right: '90px',
                    bottom: _.isEmpty(state.ReportData.transactions)
                      ? '15px'
                      : '74px',
                    backgroundColor: 'rgba(28,20,45,0)',
                  }}
                >
                  <Combinations />
                </div>

                <Box gridArea='main'>
                  <Chart
                    ref={chartRef}
                    items={state.viewableItems}
                    layout={state.layout}
                    combine={state.combine}
                    options={state.chartOptions}
                    animation={state.animation}
                    selection={Array.from(state.selection).reduce((acc, id) => {
                      acc[id] = true;
                      return acc;
                    }, {})}
                    positions={
                      reformatLayout ? {} : state.positions[state.histPointer]
                    }
                    // onDragMove={onDragMove}
                    onContextMenu={onContextMenu}
                    onPointerDown={onPointerDown}
                    // onPointerMove={onPointerMove}
                    // onPointerUp={onPointerUp}
                    onChange={onChange}
                    onCombineNodes={onCombineNodes}
                    onHover={onHover}
                    onCombineLinks={onCombineLinks}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                  />
                  <TimeBar
                    ref={timeBarRef}
                    items={state.items}
                    style={{
                      width: '100%',
                      height: '75px',
                      display: _.isEmpty(state.ReportData.transactions)
                        ? 'none'
                        : 'flex',
                    }}
                    options={state.timeBarOptions}
                    onTimeBarChange={onTimeBarChange}
                  />
                </Box>
                {isSupportedBrowser() && chartRef.current && (
                  <DownloadGraph
                    ReportData={state.ReportData}
                    chartRef={chartRef}
                  />
                )}

                {state.ReportData.meta &&
                state.ReportData.meta.is_transfers_limit_exceeded ? (
                  <OverlayTrigger
                    rootClose
                    trigger='click'
                    placement='bottom'
                    overlay={limitPopover}
                  >
                    <WarningIcon
                      className='warning-button'
                      style={{ fill: 'orange', marginRight: '15px' }}
                    />
                  </OverlayTrigger>
                ) : state.ReportData.reportType === ReportType.Compliance ? (
                  ''
                ) : (
                  <UserInput />
                )}

                {state.contextMenu.show && (
                  <ContextMenu
                    state={state}
                    dispatch={dispatch}
                    toggleNewReportModal={toggleModal}
                  />
                )}

                <NewReportModal
                  token={globalState.token}
                  reloadList={() => null}
                  toggleModal={toggleModal}
                  openModal={openModal}
                  presetItems={presetNewReportItems}
                />

                <CustomLabelModal />

                <Layouts dispatch={dispatch} graphState={state} />
              </Grid>
            </FontLoader>
          </ReGraphFrame>
        </div>
      </Resizable>
      {showTable ? <TransactionsTable /> : null}
    </>
  );
};
export default Graph;

const isSupportedBrowser = () => {
  // Safari 3.0+ "[object HTMLElementConstructor]"
  let safari;
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window['safari'] ||
        (typeof safari !== 'undefined' && safari.pushNotification),
    );

  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;

  return !isSafari && !isIE && !isEdge;
};
