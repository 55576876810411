import ChartData, { ChartDataType } from 'models/chartData';
class HistoricalData {
  constructor(apiData, metricId) {
    this._apiData = apiData;
    this._metricId = metricId;
  }

  // TODO: replace with something like 'timeSeries' to decouple
  // this from the chart implementation
  get chartData() {
    const { entities, indices } = this._apiData.selectorIds;
    const combined = [...Object.entries(entities), ...Object.entries(indices)];
    const series = combined.reduce((acc, entry) => {
      const [id, data] = entry;
      const values = data.map((d) => {
        return [d.timestamp * 1000, d.riskScore];
      });
      acc[id] = values;
      return acc;
    }, {});
    return new ChartData('', series, ChartDataType.TimeSeries);
  }
}

export default HistoricalData;
