import React, { useMemo, useState, useRef, useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import formatters from './formatters';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DownloadCSVBtn from './DownloadCSVBtn';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useGraph } from 'hooks/useGraph';
import useTransactions from 'hooks/useTransactions';
import { getBlockExplorerURL } from 'helpers/utils';
import { openUrl } from 'utils/browser';
import { TableContainer, TableHeader } from './styles';

const TransactionsTable = () => {
  const { state: graphState } = useGraph();
  const {
    setTransactionSearch,
    transactions,
    setSelectedClusterIds,
    setAttentionClusterIds,
    getClusterColor,
  } = useTransactions();
  const [lastSearchText, setLastSearchText] = useState('');
  const searchBarRef = useRef({ state: { value: '' } });
  const rowCache = useRef({});
  const isToken = !['eth', 'btc'].includes(
    graphState.ReportData.assetInfo?.asset,
  );

  const isCPDataAvailable = graphState.ReportData.isCPDataAvailable;

  const isolateClusterId = useCallback(
    (clusterId) => {
      setSelectedClusterIds([clusterId]);
    },
    [setSelectedClusterIds],
  );

  const openExplorer = (asset, address) => {
    const url = getBlockExplorerURL(asset, address);
    openUrl(url);
  };

  const onMouseEnterAddress = useCallback(
    (clusterId) => {
      setAttentionClusterIds([clusterId]);
    },
    [setAttentionClusterIds],
  );

  const onMouseLeaveAddress = useCallback(() => {
    setAttentionClusterIds(null);
  }, [setAttentionClusterIds]);

  const columns = useMemo(() => {
    const idExtraData = {
      eventHandlers: {
        onMouseEnterAddress,
        onMouseLeaveAddress,
        onAddressClick: openExplorer,
        onIsolateClick: isolateClusterId,
      },
    };

    const colsArr = [
      {
        dataField: 'timestamp',
        text: 'Timestamp (UTC)',
        sort: true,
        formatter: formatters.dateTime,
        headerStyle: () => ({ width: '6%' }),
      },
      {
        dataField: 'block',
        text: 'Block',
        sort: true,
        formatter: formatters.blockNB,
        headerStyle: () => ({ width: '5%' }),
      },
      {
        dataField: 'id',
        text: 'Txid',
        sort: true,
        formatter: formatters.txId,
        headerStyle: () => ({ width: '8%' }),
        sortFunc: (a, b, order) => {
          return order === 'asc'
            ? b.id.localeCompare(a.id)
            : a.id.localeCompare(b.id);
        },
      },
      {
        dataField: 'from',
        text: 'From',
        sort: true,
        sortValue: (cell) => cell.address,
        formatter: formatters.id,
        formatExtraData: idExtraData,
        headerStyle: () => ({
          color: isCPDataAvailable && '#EB4663',
        }),
      },
      ...(isCPDataAvailable
        ? [
            {
              dataField: 'fromAmounts',
              text: graphState.ReportData.assetInfo.symbol,
              sort: true,
              formatter: formatters.amounts,
              style: () => ({ color: '#EB8698' }),
              headerStyle: () => ({
                width: '6%',
                color: '#EB4663',
              }),
              headerFormatter: formatters.asset,
            },
            ...(isToken
              ? []
              : [
                  {
                    dataField: 'fromFiatAmounts',
                    text: 'USD',
                    sort: true,
                    formatter: formatters.fiatAmounts,
                    style: () => ({ color: '#EB8698' }),
                    headerStyle: () => ({
                      width: '6%',
                      color: '#EB4663',
                    }),
                  },
                ]),
          ]
        : []),

      {
        dataField: 'to',
        text: 'To',
        sort: true,
        sortValue: (cell) => cell.address,
        formatter: formatters.id,
        formatExtraData: idExtraData,
        headerStyle: () => ({ color: isCPDataAvailable && '#51ED6D' }),
      },

      {
        dataField: 'toAmounts',
        text: graphState.ReportData.assetInfo?.symbol ?? '-',
        sort: true,
        formatter: formatters.amounts,
        style: () => ({ color: isCPDataAvailable && '#9CECAA' }),
        headerStyle: () => ({
          width: '6%',
          color: isCPDataAvailable && '#51ED6D',
        }),
        headerFormatter: formatters.asset,
      },
      ...(isToken
        ? []
        : [
            {
              dataField: 'toFiatAmounts',
              text: 'USD',
              sort: true,
              formatter: formatters.fiatAmounts,
              style: () => ({ color: isCPDataAvailable && '#9CECAA' }),
              headerStyle: () => ({
                width: '6%',
                color: isCPDataAvailable && '#51ED6D',
              }),
            },
          ]),
      {
        dataField: 'notes',
        text: 'Notes',
        formatter: formatters.other,
        headerStyle: () => ({
          width: '4%',
          paddingBottom: '12px',
        }),
      },
    ];

    return colsArr;
  }, [
    isToken,
    isolateClusterId,
    onMouseEnterAddress,
    onMouseLeaveAddress,
    isCPDataAvailable,
    graphState.ReportData.assetInfo?.symbol,
  ]);

  const sizePerPageList = [
    {
      text: '10',
      value: 10,
    },
    {
      text: '25',
      value: 25,
    },
    {
      text: '50',
      value: 50,
    },
  ];

  const afterSearch = () => {
    let searchText = searchBarRef.current.state.value;
    searchText = searchText !== '' ? searchText : null;
    setLastSearchText(searchText);
    setTransactionSearch(searchText);
  };

  const createTag = useCallback(
    (entity, clusterId) => {
      const noEntityFound = !entity || entity.toLowerCase() === 'unknown';
      return {
        title: noEntityFound ? null : entity,
        color: getClusterColor(clusterId),
      };
    },
    [getClusterColor],
  );

  const createRow = (tx) => {
    const toItems = [];
    const toAmounts = [];
    const toFiatAmounts = [];
    const fromItems = [];
    const fromAmounts = [];
    const fromFiatAmounts = [];

    tx.counterpartiesTo.forEach((cp) => {
      toItems.push({
        address: cp.address,
        cluster: cp.cluster,
        tag: createTag(cp.entity, cp.cluster),
        asset: tx.asset,
      });
      toAmounts.push(cp.amount);
      toFiatAmounts.push(cp.amountUSD);
    });

    tx.counterpartiesFrom.forEach((cp) => {
      fromItems.push({
        address: cp.address,
        cluster: cp.cluster,
        tag: createTag(cp.entity, cp.cluster),
        asset: tx.asset,
      });
      fromAmounts.push(cp.amount);
      fromFiatAmounts.push(cp.amountUSD);
    });

    const showAllListeners = [];
    const addShowAllListener = (listener) => {
      showAllListeners.push(listener);
    };
    const changeShowAll = (showAll) => {
      showAllListeners.forEach((listener) => listener(showAll));
    };
    const row = {
      // keys used to provide formatters cell values
      key: tx.id,
      asset: tx.asset,
      timestamp: tx.timestamp,
      block: tx.block,
      totalSent: [tx._amountIn],
      totalSentFiat: [tx._amountInUsd],
      toAmounts: toAmounts,
      toFiatAmounts: toFiatAmounts,
      totalReceived: [tx._amountOut],
      totalReceivedFiat: [tx._amountOutUsd],
      fromAmounts: fromAmounts,
      fromFiatAmounts: fromFiatAmounts,
      id: {
        asset: tx.asset,
        id: tx.id,
      },
      from: fromItems,
      to: toItems,
      notes: tx.notes,
      // non-cell formatter data
      tx,
      addShowAllListener,
      changeShowAll,
    };
    return row;
  };

  const createRows = (transactions) => {
    const rows = Object.values(transactions).map((tx) => {
      let row = rowCache.current[tx.id];
      if (!row) {
        row = createRow(tx);
        rowCache.current[tx.id] = row;
      }
      return row;
    });
    return rows;
  };

  const rows = createRows(transactions);
  let reportName = '';
  if (
    graphState &&
    graphState.ReportData &&
    graphState.ReportData.meta &&
    rows.length !== 0
  ) {
    reportName = graphState.ReportData.meta.name;
  }

  return (
    <TableContainer>
      <ToolkitProvider
        keyField='id'
        data={rows ? rows : []}
        columns={columns}
        search={{
          defaultSearch: lastSearchText,
          onColumnMatch: () => true,
          afterSearch,
        }}
        exportCSV={{
          separator: ',',
          ignoreHeader: false,
          noAutoBOM: true,
          onlyExportFiltered: true,
        }}
      >
        {(props) => {
          return (
            <>
              <TableHeader>
                <h5>
                  History For <span> {transactions.length}</span> Total
                  Transactions
                </h5>
                <Search.SearchBar
                  {...props.searchProps}
                  className={'searchBar'}
                  delay={200}
                  placeholder='filter by address or transaction hash'
                  ref={searchBarRef}
                />
                <DownloadCSVBtn
                  reportName={reportName}
                  transactions={transactions}
                />
              </TableHeader>
              <BootstrapTable
                {...props.baseProps}
                headerClasses={`headerClass`}
                rowClasses={`rowClass`}
                keyField='key'
                bordered={true}
                rowStyle={{ fontSize: 12 }}
                pagination={paginationFactory({
                  page: 1,
                  sizePerPage: 25,
                })}
                sizePerPageList={sizePerPageList}
                defaultSorted={[
                  {
                    dataField: 'timestamp',
                    order: 'desc',
                  },
                ]}
              />
            </>
          );
        }}
      </ToolkitProvider>
    </TableContainer>
  );
};
export default TransactionsTable;
