import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { styleRefs as SR } from '../../styles/styleRefs';
import { UserContext } from '../../contexts/user';
import ElementusAPIService from '../../services/elementus_api.service';
import { IDashboard } from '../../typings/interfaces';
import { Spinner } from 'components/Common/Spinner';

export default function Redash() {
  const dashboards: IDashboard[] = [];
  const { apiClient } = useContext(UserContext);
  const [dashboardList, setDashboardList] = useState(dashboards);
  const [activeDashboard, setActiveDashboard] = useState(dashboardList[0]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!apiClient) return;
    const api: ElementusAPIService = apiClient;

    const fetchData = async () =>
      await api.dashboards
        .getAll()
        .then((data) => {
          setDashboardList(data);
          setActiveDashboard(data[0]);
          setIsLoading(false);
        })
        .catch((err) => console.error(err));

    fetchData();
  }, [apiClient]);

  const handleUrlChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(event.target.value);
    const dashboard = dashboardList.find((d) => d.id === id);
    if (dashboard) {
      setActiveDashboard(dashboard);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner color={SR.colors.nearWhite} />
      ) : (
        <DashboardContainer>
          <AvailableDashboards>
            <span>Dashboards</span>
            <select onChange={handleUrlChange}>
              {dashboardList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </AvailableDashboards>
          {activeDashboard && (
            <iframe title='redash' src={activeDashboard.public_url}></iframe>
          )}
        </DashboardContainer>
      )}
    </>
  );
}

const DashboardContainer = styled.div`
  width: calc(100% - 57px);
  height: 100vh;
  margin-left: 57px;
  iframe {
    position: static;
    width: 100%;
    height: calc(100% - 50px);
    border: none;
  }
`;

const AvailableDashboards = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  padding-left: 1em;
  span {
    color: ${SR.colors.nearWhite};
    font-size: 2em;
  }
  select {
    margin: 10px;
    margin-left: 1em;
    max-width: 300px;
  }
`;
