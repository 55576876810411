import React, { useState } from 'react';
import { ViewContainer } from '../../styles/';
import { InfoWindow } from '../Common/InfoWindow';

import markdown from './markdown';
import NavBar from '../NavBar';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';

import { SectionButton, FAQButtonSection } from '../FAQ/styles';

const FAQ = () => {
  const [sectionClicked, setSectionClicked] = useState('authentication_flow');

  return (
    <ViewContainer>
      <NavBar view='list' />
      <InfoWindow>
        <header className='post-header' style={{ position: 'fixed' }}>
          <h1 className='post-title'>Risk API </h1>
          <h1 className='post-title'>Specification</h1>
        </header>
        <FAQButtonSection
          style={{ position: 'fixed', top: '350px', height: '300px' }}
        >
          {Object.keys(markdown).map((section) => {
            return (
              <SectionButton
                key={section}
                onClick={() => setSectionClicked(section)}
                style={{
                  color: sectionClicked === section ? '#fff' : 'black',
                  backgroundColor:
                    sectionClicked === section ? '#753bf2' : '#F3F2F7',
                  opacity:
                    sectionClicked && sectionClicked !== section ? 0.5 : 1,
                }}
              >
                {section.split('_').join(' ')}
              </SectionButton>
            );
          })}
        </FAQButtonSection>
        <article className='post'>
          <section className='wiki-content'>
            <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}>
              {markdown[sectionClicked]}
            </ReactMarkdown>
          </section>
          <footer className='site-footer clearfix'>
            <section className='copyright'>
              <a href='https://elementus.io'>Elementus</a> ©{' '}
              {new Date().getFullYear()}
            </section>
          </footer>
        </article>
      </InfoWindow>
    </ViewContainer>
  );
};

export default FAQ;

// ## There are three API End points
// 1) **Address Risk Provenance** - *For a given Bitcoin/Ethereum address and crypto asset of interest (BTC, ETC, or ERC20 token), retrieves the risk profile based on the provenance of funds the address has received.*
// 2) **Address Risk Provenance Flag** - *A shorten version of __Address Risk Provenance__ endpoint.()*
// 3) **Address Risk Profile** - *Used to retrieve direct risk associations of a Bitcoin or Ethereum address.*
