import React, { useEffect } from 'react';
import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';
import { scrollTo } from 'utils/browser';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TimelineIcon from '@material-ui/icons/Timeline';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';

export const EntityFAB = () => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <FAB className='entity-report-fab'>
      <div
        id='fab-risk-gauge'
        onClick={(e) => scrollTo('risk-gauge', e)}
        className='active'
      >
        <ErrorOutlineIcon />
        Risk Gauge
      </div>
      <div
        id='fab-score-breakdown'
        onClick={() => scrollTo('score-breakdown')}
        className='active'
      >
        <EqualizerIcon />
        Score Breakdown
      </div>
      <div id='fab-kyc-aml' onClick={() => scrollTo('kyc-aml')}>
        <EmojiPeopleIcon />
        KYC/AML
      </div>
      <div id='fab-risk-sources' onClick={() => scrollTo('risk-sources')}>
        <ExitToAppIcon />
        Sources
      </div>
      <div id='fab-risk-score' onClick={() => scrollTo('risk-score')}>
        <TimelineIcon />
        Risk Score
      </div>
      <div id='fab-benchmark-table' onClick={() => scrollTo('benchmark-table')}>
        <ViewComfyIcon />
        Historical Entity Index
      </div>
    </FAB>
  );
};

const FAB = styled.div`
  position: fixed;
  left: 5em;
  top: 1em;
  background-color: ${SR.colors.nuetral_DarkPurple};
  border-radius: 10px;
  max-width: 105px;
  color: ${SR.colors.neutralWhite};
  box-shadow: -4px -2px 4px 0px hsl(256deg 37% 28%) inset,
    4px 2px 8px 0px hsl(248deg 33% 18%) inset;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1em;
    height: 60px;
    justify-content: center;

    svg {
      font-size: 1.5em;
    }

    &:hover {
      color: ${SR.colors.emphasis_lightPurple};
      transition: 0.5s;
      cursor: pointer;
    }
    &.active {
      color: ${SR.colors.emphasis_brightPurple};
      transition: 0.5s;
    }
  }
`;

const handleScroll = (status) => {
  const riskGauge = document.getElementById('risk-gauge');
  const kycAml = document.getElementById('kyc-aml');
  const riskScore = document.getElementById('risk-score');
  const benchmarkTable = document.getElementById('benchmark-table');

  const fabRiskGauge = document.getElementById('fab-risk-gauge');
  const fabScoreBreakdown = document.getElementById('fab-score-breakdown');
  const fabKycAml = document.getElementById('fab-kyc-aml');
  const fabRiskSources = document.getElementById('fab-risk-sources');
  const fabRiskScore = document.getElementById('fab-risk-score');
  const fabBenchmarkTable = document.getElementById('fab-benchmark-table');

  let active = false;
  if (riskGauge.getBoundingClientRect().top > 0) {
    fabRiskGauge.classList.add('active');
    fabScoreBreakdown.classList.add('active');
    active = true;
  } else {
    fabScoreBreakdown.classList.remove('active');
    fabRiskGauge.classList.remove('active');
  }

  if (!active && kycAml.getBoundingClientRect().top > 0) {
    fabKycAml.classList.add('active');
    fabRiskSources.classList.add('active');
    active = true;
  } else {
    fabKycAml.classList.remove('active');
    fabRiskSources.classList.remove('active');
  }

  // ensure riskScore is truthy as component loads async
  if (!active && riskScore && riskScore.getBoundingClientRect().top > 0) {
    fabRiskScore.classList.add('active');
    active = true;
  } else {
    fabRiskScore.classList.remove('active');
  }

  if (!active && benchmarkTable.getBoundingClientRect().top > 0) {
    fabBenchmarkTable.classList.add('active');
    active = true;
  } else {
    fabBenchmarkTable.classList.remove('active');
  }
};
