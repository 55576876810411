import React, { useState } from 'react';
import { CellText, IconButton } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';

const MAX_DEFAULT_CP_COUNT = 3;

const Badge = ({ text, backgroundColor }) => {
  return text ? (
    <p
      style={{
        background: backgroundColor,
        padding: '0px 5px 0px 5px',
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        marginBottom: '12px',
      }}
    >
      {text}
    </p>
  ) : null;
};

const ExpandButton = ({ count, onClick }) => {
  const text = `(view ${count} more)`;
  return (
    <CellText title={text} onClick={onClick} style={{ cursor: 'pointer' }}>
      {text}
    </CellText>
  );
};

const IsolateButton = ({ onClick }) => {
  return (
    <IconButton
      type='button'
      title='Isolate Address'
      onClick={(e) => {
        e.currentTarget.blur();
        onClick();
      }}
    >
      <FontAwesomeIcon
        className='TableAddressButtonIcon'
        icon={faCrosshairs}
        color='white'
      />
    </IconButton>
  );
};

export const RowContainer = ({ children }) => {
  return (
    <div
      style={{
        height: '30px',
        width: '100%',
        display: 'flex',
      }}
    >
      {children}
    </div>
  );
};

const CounterpartiesContainer = ({ items, eventHandlers, changeShowAll }) => {
  const [showAll, setShowAll] = useState(false);
  const moreDataFlag = 'more';
  const visibileItems =
    items.length <= MAX_DEFAULT_CP_COUNT || showAll
      ? [...items]
      : [...items.slice(0, MAX_DEFAULT_CP_COUNT), moreDataFlag];
  return (
    <>
      {visibileItems.map((item, i) => {
        return item !== moreDataFlag ? (
          <RowContainer key={i}>
            <CellText
              title='View address in block explorer'
              onMouseEnter={() =>
                eventHandlers.onMouseEnterAddress(item.cluster)
              }
              onMouseLeave={() => eventHandlers.onMouseLeaveAddress()}
              onClick={() =>
                eventHandlers.onAddressClick(item.asset, item.address)
              }
              style={{ cursor: 'pointer', flexGrow: 1 }}
            >
              {item.address}
            </CellText>
            {item.tag.title && (
              <Badge text={item.tag.title} backgroundColor={item.tag.color} />
            )}
            <IsolateButton
              onClick={() => {
                eventHandlers.onIsolateClick(item.cluster);
              }}
            />
          </RowContainer>
        ) : (
          <ExpandButton
            key={''}
            count={items.length - MAX_DEFAULT_CP_COUNT}
            onClick={() => {
              setShowAll(true);
              changeShowAll(true);
            }}
          />
        );
      })}
    </>
  );
};

const idFormatter = (items, row, _rowIdx, extraData = {}) => {
  return (
    <CounterpartiesContainer
      items={items}
      eventHandlers={extraData.eventHandlers}
      changeShowAll={row.changeShowAll}
    />
  );
};

export default idFormatter;
