import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import useTheme from '@material-ui/core/styles/useTheme';
import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChatIcon from '@material-ui/icons/Chat';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupportIcon from '@material-ui/icons/ContactSupport';
import SpeedIcon from '@material-ui/icons/Speed';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import WarningIcon from '@material-ui/icons/Warning';
import GrainIcon from '@material-ui/icons/Grain';
import { useStyles, NavBarContainer } from './styles';
import { Product } from '../../utils/enums';
import { Link } from 'react-router-dom';
import useIntercom from '@reclaim-ai/react-intercom-hook';
import { useAnalytics } from 'use-analytics';
import { styleRefs as SR } from 'styles/styleRefs';
import { UserContext } from 'contexts/user';
import { EXPERIMENTAL_FEATURES } from 'featureFlags';

const baseLogo = `${process.env.PUBLIC_URL}/img/base-logo.svg`;

export default function NavBar(props) {
  const { user } = useContext(UserContext);
  const isRiskApiEnabled = user.isAuthorized(Product.RISK_API);
  const isDashboardViewEnabled =
    EXPERIMENTAL_FEATURES.redash && user.isAuthorized(Product.DASHBOARDS);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [intercomSettings, setIntercomSettings] = useState({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    custom_launcher_selector: '#intercom',
    hide_default_launcher: true,
  });

  const { logout } = useContext(UserContext);
  const analytics = useAnalytics();

  useEffect(() => {
    if (!analytics) return;
    analytics.identify(user.id, { userid: user.id });
    analytics.track('dimension', { userid: user.id });
    setIntercomSettings({
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      custom_launcher_selector: '#intercom',
      hide_default_launcher: true,
      user_id: user.id,
      email: user.email,
      name: user.name,
      avatar: user.picture,
    });
  }, [user, analytics, props.report]);

  useIntercom(intercomSettings);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let containerClass = classes.root + ' minidrawer';

  if (props.useTheme && props.useTheme === 'light')
    containerClass = containerClass + ' light-col';
  if (props.useTheme && props.useTheme === 'dark')
    containerClass = containerClass + ' dark-col';

  return (
    <NavBarContainer className={containerClass}>
      <div className='openmenu'>
        <button
          className='MuiButtonBase-root MuiIconButton-root'
          tabIndex='0'
          type='button'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
        >
          <span className='MuiIconButton-label'>
            {open ? <MenuIcon style={{ display: 'none' }} /> : <MenuIcon />}
          </span>
        </button>
      </div>
      <img src={baseLogo} className='elemLogo' alt='elemLogo' />
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            Elementus
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {theme.direction === 'rtl' ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          ) : (
            <div>
              <div
                style={{
                  color: 'white',
                  fontSize: SR.fontSize.standard,
                  marginRight: '15px',
                  marginTop: '14px',
                  cssFloat: 'left',
                }}
              >
                {user.email.slice(0, 30) +
                  (user.email.length > 30 ? '...' : '')}
              </div>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          )}
        </div>
        <Divider />
        <List>
          <Link to='/reports' title='Report List' onClick={handleDrawerClose}>
            <ListItem button key='Report List'>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary='Report List' />
            </ListItem>
          </Link>
          {EXPERIMENTAL_FEATURES.riskMonitor ? (
            <Link
              to='/risk-monitor'
              title='Risk Monitor'
              onClick={handleDrawerClose}
            >
              <ListItem button key='Risk Monitor'>
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                <ListItemText primary='Risk Monitor' />
              </ListItem>
            </Link>
          ) : null}
          {EXPERIMENTAL_FEATURES.alertsFeature ? (
            <Link to='/alerts' title='Alerts' onClick={handleDrawerClose}>
              <ListItem button key='Alerts'>
                <ListItemIcon>
                  <NotificationsActiveIcon />
                </ListItemIcon>
                <ListItemText primary='Alerts' />
              </ListItem>
            </Link>
          ) : null}
          {isDashboardViewEnabled ? (
            <Link
              to='/dashboards'
              title='Dashboards'
              onClick={handleDrawerClose}
            >
              <ListItem button key='Dashboards'>
                <ListItemIcon>
                  <GrainIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboards' />
              </ListItem>
            </Link>
          ) : null}
        </List>

        <LowerList>
          <Link to='/info' title='FAQ' onClick={handleDrawerClose}>
            <ListItem button key='FAQ'>
              <ListItemIcon>
                <SupportIcon />
              </ListItemIcon>
              <ListItemText primary='FAQ' />
            </ListItem>
          </Link>
          {isRiskApiEnabled && (
            <Link to='/api' title='Risk API' onClick={handleDrawerClose}>
              <ListItem button key='API'>
                <ListItemIcon>
                  <SpeedIcon />
                </ListItemIcon>
                <ListItemText primary='Risk API' />
              </ListItem>
            </Link>
          )}
          {/* <div id='beamerButton'>
            <ListItem button key='Notifications' title='Notifications'>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary='Notifications' />
            </ListItem>
          </div> */}

          <div id='intercom'>
            <ListItem
              button
              key='Live Support'
              title='Live Support'
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary='Live Support' />
            </ListItem>
          </div>
          <Divider />
          <ListItem
            button
            key='Log Out'
            title='Log Out'
            onClick={() => logout()}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary='Log Out' />
          </ListItem>
          <Divider />
          <Link to='/termsofuse' title='privacy' onClick={handleDrawerClose}>
            <ListItem button key='Terms of Service' title='Terms of Service'>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary='Terms of Service' />
            </ListItem>
          </Link>

          <Divider />
          <Link to='/privacy' title='privacy' onClick={handleDrawerClose}>
            <ListItem button key='Privacy' title='Privacy'>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary='Privacy' />
            </ListItem>
          </Link>
        </LowerList>
      </Drawer>
    </NavBarContainer>
  );
}

const LowerList = styled.ul`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  margin-bottom: 220px;
  padding-left: 0px;
`;
