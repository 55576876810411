import React, { useReducer, createContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'models/user';
import ElementusAPIService from 'services/elementus_api.service';

const initialState = {
  user: null,
  apiClient: null,
  logout: null,
};

export const UserContext = createContext(initialState);

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_USER': {
      return {
        ...state,
        user: payload,
      };
    }
    case 'SET_LOGOUT': {
      return {
        ...state,
        logout: payload,
      };
    }
    case 'SET_API_CLIENT': {
      return {
        ...state,
        apiClient: payload,
      };
    }
    default:
      return state;
  }
};

export const UserProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [authToken, setAuthToken] = useState(null);
  const {
    user: authUser,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0();

  const getAuthToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAuthToken(token);
    } catch {
      loginWithRedirect();
    }
  };

  useEffect(() => {
    dispatch({ type: 'SET_LOGOUT', payload: logout });
  }, [logout]);

  useEffect(() => {
    const action = { type: 'SET_USER', payload: null };
    if (authUser && authToken) {
      const user = new User(authUser, authToken);
      action.payload = user;
    }
    dispatch(action);
  }, [authUser, authToken]);

  useEffect(() => {
    const action = { type: 'SET_API_CLIENT', payload: null };
    if (authToken) {
      const client = new ElementusAPIService(authToken);
      action.payload = client;
    }
    dispatch(action);
  }, [authToken]);

  useEffect(() => {
    getAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
  );
};
