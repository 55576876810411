import styled from 'styled-components';
import { Window } from '../../styles';
import { styleRefs as SR } from 'styles/styleRefs';

export const InfoWindow = styled(Window)`
  display: flex;
  background: none;
  height: 80%;
  width: 80%;
  color: white;

  article {
    margin-top: 0;
    border-bottom: none;
    padding-bottom: 0;
    position: relative;
    width: 80%;
    max-width: 710px;
    margin: 4rem auto;
    text-rendering: geometricPrecision;

    .post-title {
      font-size: 5rem;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      letter-spacing: -2px;
      text-indent: -3px;
    }

    h1 {
      font-size: 36px;
    }

    h1,
    h2,
    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: inherit;
      font-weight: 500;
      line-height: 1.1;
      color: inherit;
    }
    h1 {
      margin: 0.67em 0;
      font-size: 2em;
    }

    h2 {
      font-size: 3.6rem;
      letter-spacing: -1px;
      -webkit-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
      -moz-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
      -o-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
      line-height: 1.15em;
      margin: 0 0 0.4em 0;
      font-family: 'Lato', sans-serif;
    }
    h3 {
      font-size: 3rem;
      letter-spacing: -0.6px;
    }
    p {
      letter-spacing: 0.01rem;
      font-family: 'Open Sans', sans-serif;
      letter-spacing: 0.01rem;
      font-size: 1.8rem;
      line-height: 1.65em;
      margin: 0 0 1.75em 0;
      color: whitesmoke;
      font-weight: lighter;
    }
  }
  footer {
    height: 125px;
    display: flex;
    align-items: center;
  }
  a {
    color: ${SR.colors.emphasis_lightPurple};
  }
`;
