import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { format } from 'date-fns';

// Formatters

export const toolbarFormatter = (cell, row, rowIndex, deleteAlert) => {
  let address = 'any';
  if (row.params.address != null) {
    address = row.params.address;
  }

  return (
    <div className='toolbar'>
      {address}
      <IconButton
        className='btn-delete'
        component='span'
        onClick={() => deleteAlert(row._id)}
      >
        <DeleteOutlineRoundedIcon />
      </IconButton>
    </div>
  );
};

export const amountFormatter = (cell, row) => {
  if (row.params.amount == null) {
    return 'any';
  }

  let label;
  const alertType = row.type.toLowerCase();
  if (alertType === 'transaction') {
    if (row.params.amount === 0) {
      label = 'Any';
    } else {
      label = `${row.params.amount} btc`;
    }
  } else if (alertType === 'risk') {
    if (row.params.amount === 0) {
      label = 'Any';
    } else {
      label = `${row.params.amount} %`;
    }
  }

  return <div className='toolbar'>{label}</div>;
};

export const alertTypeFormatter = (cell, row) => {
  let alertTypeLabel;
  const alertType = cell.toLowerCase();

  switch (alertType) {
    case 'risk':
      alertTypeLabel = 'Risk';
      break;

    case 'transaction':
    default:
      alertTypeLabel = 'Transaction';
  }

  return <div>{alertTypeLabel}</div>;
};

export const dateFormatter = (cell) => {
  const dateLabel = format(new Date(cell), 'd LLLL y, h:mm a');

  return <div>{dateLabel}</div>;
};

export const notificationTypeFormatter = (cell, row, i) => {
  const userEmail = row.notificationConfig.params.emailAddress;
  const notificationType = row.notificationConfig.type.toLowerCase();

  return (
    <div key={i}>
      <span>{userEmail}&nbsp;</span>
      <span className='capitalize'> ({notificationType})</span>
    </div>
  );
};

// React Boostrap Table Next: configuration objects

export const ActiveAlertsTableConfig = (deleteAlert) => {
  return [
    {
      dataField: 'title',
      text: 'Alert title',
      sort: true,
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      formatter: toolbarFormatter,
      formatExtraData: deleteAlert,
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      formatter: alertTypeFormatter,
    },
    {
      dataField: 'notificationConfig.type',
      text: 'Notify',
      sort: true,
      formatter: notificationTypeFormatter,
    },
    {
      dataField: '',
      text: 'Amount',
      sort: true,
      formatter: amountFormatter,
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
      formatter: dateFormatter,
    },
  ];
};

export const NotificationsTableConfig = [
  {
    dataField: 'title',
    text: 'Alert title',
    sort: true,
  },
  {
    dataField: 'createdAt',
    text: 'Date',
    sort: true,
    formatter: dateFormatter,
  },
];
