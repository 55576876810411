import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { styleRefs as SR } from 'styles/styleRefs';

export const AlertWrapper = styled(Container)`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 100vh;
  padding: 2em;
  padding-left: calc(57px + 2em) !important;

  h1,
  h3 {
    font-family: Verlag A, Verlag B;
  }

  .notifications {
    table {
      table-layout: auto;
    }
  }
  .alerts-panel {
    margin-bottom: 2em;
  }
  .btn-delete {
    margin-left: auto;
    color: #f00;
  }
  .react-bootstrap-table td div {
    display: flex;
    align-items: center;
    height: 20px;
  }
  .MuiInputBase-input {
    font-size: 1.3em;
  }
  .MuiAlert-root {
    font-size: 1em;
  }
`;

export const AddAlertComponent = styled.div`
  .title {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    justify-content: center;

    svg {
      font-size: 2rem;
      margin-right: 0.5rem;
    }
  }

  .alert-seq {
    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: ${SR.margin.std};
    }

    .textbox-address {
      width: 270px;
    }
    .textbox-amount {
      width: 70px;
    }
  }

  .btn-set-alert {
    margin-right: 0;
    margin-left: auto;
    margin-top: 0.5em;
    height: 35px;
  }
`;
