import React from 'react';

const ExpandIcon = () => {
  return (
    <div className='context-icons'>
      <svg id='Layer_1' x='0px' y='0px' height='12px' viewBox='0 0 36 36'>
        <path
          className='st0'
          d='M14.6,13l-5.7,7.2c-0.5-0.3-1.2-0.5-1.9-0.5c-1.9,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6
	c0-1-0.4-1.9-1.1-2.5l5.7-7.3c0.7,0.4,1.5,0.7,2.3,0.8v10.1c-1.8,0.2-3.2,1.7-3.2,3.5c0,1.9,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6
	c0-1.8-1.4-3.3-3.2-3.5V14.3c0.8-0.1,1.6-0.3,2.3-0.8l5.7,7.3c-0.6,0.6-1,1.5-1,2.5c0,1.9,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6
	c0-2-1.6-3.6-3.6-3.6c-0.7,0-1.4,0.2-1.9,0.6L21.3,13c1-0.9,1.6-2.2,1.6-3.6c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9
	C13.1,10.8,13.7,12,14.6,13z M9.8,23.2c0,1.5-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7S9.8,21.7,9.8,23.2z M28.9,20.5
	c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7S27.4,20.5,28.9,20.5z M20.7,27.9c0,1.5-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7
	s1.2-2.7,2.7-2.7l0,0l0,0C19.5,25.2,20.7,26.4,20.7,27.9z M18,5.3c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1
	c-2.3,0-4.1-1.8-4.1-4.1C13.9,7.1,15.7,5.3,18,5.3z'
        />
      </svg>
    </div>
  );
};
export default ExpandIcon;
