import React, { useContext } from 'react';
import EgressChart from './EgressChart/egressChart';
import { Resizable } from 're-resizable';
import SummaryStats from './SummaryStats/summaryStats';
import { SummaryStatsBody, SummaryContainer } from './styles';
import PageTitle from './PageTitle/pageTitle';
import verge from 'verge';
import { store } from 'ReportData/ReportDataStore';

let viewportHeight = verge.viewportH();
let bottomSectionHeight = viewportHeight - 470; // Hack to bring the lower section up to the bottom of the higher section
if (!bottomSectionHeight || bottomSectionHeight < 100)
  bottomSectionHeight = 350;

const SummaryPanel = () => {
  const { state: globalState } = useContext(store);
  const reportType = globalState.ReportData.reportType;

  return (
    <SummaryContainer>
      <PageTitle />
      <SummaryStatsBody type={reportType}>
        <SummaryStats />
      </SummaryStatsBody>
      {reportType !== 'Compliance' && (
        <Resizable
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            flexGrow: 1,
            alignSelf: 'flex-end',
            flexDirection: 'column',
            padding: '0 10px 10px 10px',
          }}
          enable={{
            top: true,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          defaultSize={{
            width: '100%',
            height: '25vh',
          }}
          maxHeight='90vh'
          minHeight='10vh'
          className='egressouter'
        >
          <EgressChart />
        </Resizable>
      )}
    </SummaryContainer>
  );
};

export default SummaryPanel;
