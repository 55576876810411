export const createAlertData = (data) => {
  const { asset, address, amount, userEmail, title, notificationType, type } =
    data;
  return {
    title,
    type,
    params: {
      address,
      asset,
      amount: parseFloat(amount),
    },
    notificationConfig: {
      type: notificationType,
      params: {
        emailAddress: userEmail,
      },
    },
  };
};
