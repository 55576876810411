import React from 'react';
import 'zingchart/es6';
import ZingChart from 'zingchart-react';
import 'zingchart/modules-es6/zingchart-treemap.min.js';
import { lineChartStyles } from '../../utils/zingCharts';
import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

export const ChartComponent = ({ chartData }) => {
  const zingChartData = chartData.createZingChartData();
  const dashedTitle = chartData.title.replace(/\s+/g, '-');

  return (
    <ChartComponentWrapper>
      <ZingChart
        id={`${dashedTitle}-chart-component`}
        data={zingChartData}
        width='100%'
        height='100%'
        defaults={lineChartStyles}
        className='zing-chart'
      />
    </ChartComponentWrapper>
  );
};

const ChartComponentWrapper = styled.div`
  height: 600px;
  background-color: ${SR.colors.nuetral_DarkPurple};
  box-shadow: -4px -2px 4px 0px hsl(256deg 37% 28%) inset,
    4px 2px 8px 0px hsl(248deg 33% 18%) inset;
  border-radius: 10px;

  #-chart-component {
    height: calc(100% - 1em) !important;
    width: calc(100% - 1em) !important;
    overflow: hidden !important;
    padding: 1em;
  }
`;
