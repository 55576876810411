const content = {
  'User Guide': {
    'What is Pulse?': {
      text: [
        'Pulse automatically follows the flow of funds originating from source addresses to their ultimate destination, unabated by number of “hops” in between. Enter one or more starting addresses and Pulse will prepare a report showing where the bitcoin went (named entities) and the path it took to get there.',
      ],
      gifs: [],
    },
    'How do I quickly see where the funds ultimately went?': {
      text: [
        'First, press the Select All button in the Destination Summary section. This will select all the known “exit transactions” in the graph and in the Transaction History. Next, press the Download CSV button in the Transaction History section. In the file that is exported, each row corresponds to an “exit transaction” -- a transfer of crypto to a known service (e.g. exchange, gambling site, darknet market, etc), which will be identified in the file.',
      ],
      gifs: [],
    },
    'How far back in time can I go?': {
      text: [
        'We have dedicated nodes that gather the entire record of transactions across the Bitcoin network. You can trace an analysis from block 0.',
      ],
      gifs: [],
    },
    'What is the Destination summary for?': {
      text: [
        'The Destination Summary quantifies where the funds ultimately went. It aggregates every outgoing transaction to a known blockchain entity such as an exchange, gambling site, or a payment gateway, and summarizes  the results within structured square tiles. The size of each tile corresponds to the amount of bitcoin that was sent to the respective entity. The chart is organized in a hierarchical way, with entities of the same type grouped together (e.g. exchanges grouped with other exchanges, gambling sites grouped with other gambling sites). To drill down deeper into a particular group, click on the [+] sign next to the group’s name.',
      ],
      gifs: [],
    },
    'How can I export the information from my reports?': {
      text: [
        'Pulse aggregates the entire transaction history of the source address(es) within the transaction table below the network visual. There, the data can be filtered and exported into a csv. Screenshots of the network visual are also permitted. The user also has the ability to export a snapshot of the graph as a .png file.',
      ],
      gifs: [],
    },
    'Which addresses can I use?': {
      text: [
        'During the initial release, Elementus Pulse can search up addresses within the Bitcoin blockchain. All Bitcoin types of addresses are usable, with the exception of exchange deposit addresses. Up to 100 addresses can be processed at a time. Pulse can process up to 8,000 nodes.',
      ],
      gifs: [],
    },
    'Which browsers are supported ?': {
      text: [
        'Chrome:',
        '79.0.3945.130 - 83.0.4103.116 - 84.0.4147.125 - 84.0.4147.89 - 85.0.4183.102 - 85.0.4183.121 - 86.0.4240.111 - 86.0.4240.183 - 86.0.4240.193 - 86.0.4240.198 - 86.0.4240.30 - 86.0.4240.75 - 86.0.4240.80 - 86.0.4240.99 - 87.0.4270.0 - 87.0.4278.0 - 87.0.4280.101 - 87.0.4280.11 - 87.0.4280.141 - 87.0.4280.66 - 87.0.4280.67 - 87.0.4280.88 - 88.0.4324.104 - 88.0.4324.146 - 88.0.4324.150 - 88.0.4324.152 - 88.0.4324.96 - 89.0.4389.40 and later versions',
        ' ',
        'Edge:',
        '15.15063 - 18.17763 - 18.19041 - 79.0.309.65 - 86.0.622.68 - 88.0.705.53 - 88.0.705.56 - 88.0.705.62 and later versions',
        ' ',
        'Firefox:',
        ' 78.0 - 82.0 - 83.0 - 84.0 and later versions',
        ' ',
        'Safari:',
        '14.0.1 - 14.0.2 - 14.0.3 and later versions',
        ' ',
      ],
      gifs: [],
    },
    'What if I require a custom analysis?': {
      text: [
        'There may be instances where a group of source addresses generates a large network, one that would benefit from a bespoke analysis by a member of the Elementus Support Team. If you are notified of a large network created by your source address(es), click the link in the message and one of our team members can offer further assistance.',
      ],
      gifs: [],
    },
  },
  Algorithm: {
    'What is the basis for entity attribution?': {
      text: [
        'Our method for mapping addresses to entities involves two distinct pieces: clustering (determining which addresses have the same owner) and attribution (assigning a real-world name to a wallet). Our attributions come from various sources: manual on-chain interactions, message boards, social media, dark-net scrapers and other public sources. Our clustering is done via a wide range of proprietary methods, including our patent pending SourceFlow algorithm.',
      ],
      gifs: [],
    },
    'What is a wallet?': {
      text: [
        `A wallet is a group of addresses that collectively function like a single account. Technically, it is a collection of addresses that are linked by a  <a href="https://en.bitcoin.it/wiki/Common-input-ownership_heuristic">co-spend relationship</a> . That is to say, if two addresses have been inputs to the same transaction, those addresses will be assigned to the same wallet. An entity may, and typically does, have multiple different wallets attributed to them.`,
      ],
      gifs: [],
    },
    'How does SourceFlow work?': {
      text: [
        'SourceFlow is our patent pending algorithm that intelligently traces the flow of funds from a source address to its ultimate destination, unabated by number of transactions, or “hops,” in between. As SourceFlow crawls through our database of transactions on the blockchain, it makes smart determinations on when to stop, and which paths to follow based on a variety of on-chain indicators.',
      ],
      gifs: [],
    },
    'Do you support other digital currencies besides Bitcoin?': {
      text: [
        'Our methodology can be utilized for multiple types of blockchain networks. Though the initial launch of Elementus Pulse is limited to Bitcoin, we fully support Ethereum and all of the ERC-20 tokens in our data pipeline. We are working hard to bring our market leading Ethereum attribution to Elementus Pulse in a future update. In the meantime, if you require an analysis of the Ethereum blockchain or one of its 2000+ ERC-20 tokens, please contact our Elementus Support Team at support@elementus.io or reach out to one of our support members using the live chat feature in Elementus Pulse.',
      ],
      gifs: [],
    },
  },
};

export default content;
