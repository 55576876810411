/* eslint-disable no-dupe-class-members */
import rp from 'request-promise';

export class NotificationsService {
  _hostUrl;
  _rpOptions;

  constructor(headers, hostUrl) {
    this._hostUrl = hostUrl;

    // set default request options
    this._rpOptions = {
      headers: { ...headers },
      json: true,
    };
  }

  _getAll() {
    const options = {
      ...this._rpOptions,
      method: 'GET',
      uri: `${this._hostUrl}/notifications`,
    };
    return rp(options);
  }

  getServices() {
    return { getAll: this._getAll.bind(this) };
  }
}
