import React from 'react';
import { ViewContainer } from '../../styles';
import { InfoWindow } from '../Common/InfoWindow';
import NavBar from '../NavBar';

const Terms = () => {
  return (
    <ViewContainer>
      <NavBar view='list' />
      <InfoWindow>
        <article className='post'>
          <header className='post-header'>
            <h1 className='post-title'>Terms of Use</h1>
          </header>

          <section className='post-content'>
            <h2>Introduction</h2>
            <p>
              Site Terms of Service, an Enforceable Legal Agreement.
              <br />
              As of December 1, 2017
            </p>
            <p>
              These Terms of Service and our privacy policy (together the
              “Terms”) govern all use of{' '}
              <a href='https://elementus.io'>https://elementus.io</a> and that
              site’s services (together the “Site” or “Services”). The Site is
              owned by Max Galka, a individual.
            </p>
            <p>
              The owners and contributors to the Site will be referred to as
              “we,” “us,” or “our” in these Terms. By using the Site or its
              Services, and/or by clicking anywhere on this Site to agree to
              these Terms, you are deemed to be a “user” for purposes of the
              Terms. You and every other user (“you” or “User” as applicable)
              are bound by these Terms. You and each user also agree to the
              Terms by using the Services. If any User does not agree to the
              Terms or the Privacy Policy, such User may not access the Site or
              use the Services. In these Terms, the word “Site” includes the
              site referenced above, its owner(s), contributors, suppliers,
              licensors, and other related parties.
            </p>
            <h2>User Prohibited From Illegal Uses</h2>
            <p>
              User shall not use, and shall not allow any person to use, the
              Site or Services in any way that violates a federal, state, or
              local law, regulation, or ordinance, or for any disruptive,
              tortious, or illegal purpose, including but not limited to
              harassment, slander, defamation, data theft or inappropriate
              dissemination, or improper surveillance of any person.
            </p>
            <h2>User represents and warrants that:</h2>
            <ul>
              <li>
                User will use the Services only as provided in these Terms;
              </li>
              <li>
                User is at least 18 years old and has all right, authority, and
                capacity to agree to these Terms;
              </li>
              <li>
                User will provide accurate, complete, and current information to
                the Site and its owner(s);
              </li>
              <li>
                User will notify the Site and its owner(s) regarding any
                material change to information User provides, either by updating
                and correcting the information, or by alerting the Site and its
                owner(s) via the functions of the Site or the email address
                provided below.
              </li>
            </ul>
            <h2>Disclaimer of Warranties</h2>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SITE PROVIDES THE
              SERVICES “AS IS,” WITH ALL FAULTS. THE SITE DOES NOT WARRANT
              UNINTERRUPTED USE OR OPERATION OF THE SERVICES, OR THAT ANY DATA
              WILL BE TRANSMITTED IN A MANNER THAT IS TIMELY, UNCORRUPTED, FREE
              OF INTERFERENCE, OR SECURE. THE SITE DISCLAIMS REPRESENTATIONS,
              WARRANTIES, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED,
              WRITTEN, ORAL, CONTRACTUAL, COMMON LAW, OR STATUTORY, INCLUDING
              BUT NOT LIMITED TO ANY WARRANTIES, DUTIES, OR CONDITIONS OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
              NON-INFRINGEMENT, OR THAT MAY ARISE FROM A COURSE OF DEALING OR
              USAGE OF TRADE.
            </p>
            <h3>Liability Is Limited</h3>
            <p>
              THE SITE SHALL NOT BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL,
              CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND,
              INCLUDING BUT NOT LIMITED TO LOST PROFITS (REGARDLESS OF WHETHER
              WE HAVE BEEN NOTIFIED THAT SUCH LOSS MAY OCCUR) OR EXPOSURE TO ANY
              THIRD PARTY CLAIMS BY REASON OF ANY ACT OR OMISSION. THE SITE
              SHALL NOT BE LIABLE FOR ANY ACT OR OMISSION OF ANY THIRD PARTY
              INVOLVED WITH THE SERVICES, SITE OFFERS, OR ANY ACT BY SITE USERS.
              THE SITE SHALL NOT BE LIABLE FOR ANY DAMAGES THAT RESULT FROM ANY
              SERVICE PROVIDED BY, OR PRODUCT OR DEVICE MANUFACTURED BY, THIRD
              PARTIES.
            </p>
            <p>
              NOTWITHSTANDING ANY DAMAGES THAT USER MAY SUFFER FOR ANY REASON,
              THE ENTIRE LIABILITY OF THE SITE IN CONNECTION WITH THE SITE OR
              SERVICES, AND ANY PARTY’S EXCLUSIVE REMEDY, SHALL BE LIMITED TO
              THE AMOUNT, IF ANY, ACTUALLY PAID BY USER TO THE SITE OWNER DURING
              THE 12 MONTHS PRIOR TO THE EVENT THAT USER CLAIMS CAUSED THE
              DAMAGES.
            </p>
            <p>
              The Site shall not be liable for any damages incurred as a result
              of any loss, disclosure, or third party use of information,
              regardless of whether such disclosure or use is with or without
              User’s knowledge or consent. The Site shall have no liability for
              any damages related to: User’s actions or failures to act, the
              acts or omissions of any third party, including but not limited to
              any telecommunications service provider, or events or causes
              beyond the Site’s reasonable control. The Site has no obligations
              whatever, and shall have no liability to, any third party who is
              not a User bound by these Terms. Limitations, exclusions, and
              disclaimers in these Terms shall apply to the maximum extent
              permitted by applicable law, even if any remedy fails its
              essential purpose.
            </p>
            <h2>Third party products, links, and actions</h2>
            <p>
              The Site may include or offer third party products or services.
              The Site may also have other users or members who interact with
              each other, through the Site, elsewhere online, or in person.
              These third party products and any linked sites have separate and
              independent terms of service and privacy policies. We have no
              control or responsibility for the content and activities of these
              linked sites, sellers, and third parties in general, regardless of
              whether you first were introduced or interacted with such
              businesses, services, products, and people through the Site, and
              therefore you agree that we are not liable for any of them. We do,
              however, welcome any feedback about these sites, sellers, other
              users or members, and third parties.
            </p>
            <h2>Changes to the Site and the Services</h2>
            <p>
              The owners and contributors to the Site will work to improve the
              Site for our users, and to further our business interests in the
              Site. We reserve the right to add, change, and remove features,
              content, and data, including the right to add or change any
              pricing terms. You agree that we will not be liable for any such
              changes. Neither your use of the Site nor these terms give you any
              right, title, or protectable legal interest in the Site or its
              content.
            </p>
            <h2>Indemnity</h2>
            <p>
              If your activity or any activity on your behalf creates potential
              or actual liability for us, or for any of our users, partners, or
              contributors, you agree to indemnify and hold us and any such
              user, partner, contributor, or any agent harmless from and against
              all claims, costs of defense and judgment, liabilities, legal
              fees, damages, losses, and other expenses in relation to any
              claims or actions arising out of or relating to your use of the
              Site, or any breach by you of these Terms of Use.
            </p>
            <h2>Intellectual Property</h2>
            <p>
              Elementus and/or its suppliers, as applicable, retain ownership of
              all proprietary rights in the Service and in all trade names,
              trademarks and service marks associated or displayed with the
              Service. You will not remove, deface or obscure any of
              Trello&apos;s or its suppliers&apos; copyright or trademark
              notices and/or legends or other proprietary notices on,
              incorporated therein, or associated with the Service. You may not
              reverse engineer, reverse compile or otherwise reduce to human
              readable form any software associated with the Service.
            </p>
            <p>
              The Site grants User a revocable, non-transferable, and
              non-exclusive license to use the Site solely in connection with
              the Site and the Services, under these Terms.
            </p>
            <p>
              Copyright in all content and works of authorship included in the
              Site are the property of the Site or its licensors. Apart from
              links which lead to the Site, accurately attributed social media
              references, and de minimus text excerpts with links returning to
              the Site, no text, images, video or audio recording, or any other
              content from the Site shall be copied without explicit and
              detailed, written permission from the Site’s owner. User shall not
              sublicense or otherwise transfer any rights or access to the Site
              or related Services to any other person.
            </p>
            <p>
              The names and logos used by the Site, and all other trademarks,
              service marks, and trade names used in connection with the
              Services are owned by the Site or its licensors and may not be
              used by User without written consent of the rights owners. Use of
              the Site does not in itself give any user any license, consent, or
              permission, unless and then only to the extent granted explicitly
              in these Terms.
            </p>
            <p>
              All rights not expressly granted in these Terms are reserved by
              the Site.
            </p>
            <h2>Privacy</h2>
            <p>
              Any information that you provide to the Site is subject to the
              Site’s Privacy Policy, which governs our collection and use of
              User information. User understands that through his or her use of
              the Site and its Services, User consents to the collection and use
              (as set forth in the Privacy Policy) of the information, including
              the transfer of this information to the United States and/or other
              countries for storage, processing and use by the Site. The Site
              may make certain communications to some or all Users, such as
              service announcements and administrative messages. These
              communications are considered part of the Services and a User’s
              account with the Site, and Users are not able to opt out of all of
              them.
            </p>
            <h3>Usernames, Passwords, and Profiles</h3>
            <p>
              If prompted, Users must provide a valid email address to the Site,
              at which email address the User can receive messages. User must
              also update the Site if that email address changes. The Site
              reserves the right to terminate any User account and/or User
              access to the Site if a valid email is requested but is not
              provided by the User.
            </p>
            <p>
              If the Site prompts or allows a User to create a username or
              profile, Users agree not to pick a username or provide any profile
              information that would impersonate someone else or that is likely
              to cause confusion with any other person or entity. The Site
              reserves the right to cancel a User account or to change a
              username or profile data at any time. Similarly, if the Site
              allows comments or user input, or prompts or allows a User to
              create an avatar or upload a picture, User agrees not to use any
              image that impersonates some other person or entity, or that is
              otherwise likely to cause confusion.
            </p>
            <p>
              You are responsible for protecting your username and password for
              the Site, and you agree not to disclose it to any third party. We
              recommend that you use a password that is more than eight
              characters long. You are responsible for all activity on your
              account, whether or not you authorized it. You agree to inform us
              of unauthorized use of your account, by email to
              admin@elementus.io. You acknowledge that if you wish to protect
              your interactions with the Site, it is your responsibility to use
              a secure encrypted connection, virtual private network, or other
              appropriate measures. The Site’s own security measures are
              reasonable in terms of their level of protection, but are not
              helpful if the interactions of you or any other User with Site are
              not secure or private.
            </p>
            <h2>Disputes</h2>
            <p>
              We are based in New York, NY and you are contracting to use our
              Site. These Terms and all matters arising from your use of the
              Site are governed by and will be construed according to the laws
              of New York, NY, without regard to any choice of laws rules of any
              jurisdiction. The federal courts and state courts that have
              geographical jurisdiction over disputes arising at our office
              location in the New York, NY will be the only permissible venues
              for any and all disputes arising out of or in connection with
              these Terms or the Site and Service.
            </p>
            <h2>General</h2>
            <p>
              These Terms, including the incorporated Privacy Policy, supersede
              all oral or written communications and understandings between User
              and the Site.
            </p>
            <p>
              Any cause of action User may have relating to the Site or the
              Services must be commenced within one (1) year after the claim or
              cause of action arises.
            </p>
            <p>
              Both parties waive the right to a jury trial in any dispute
              relating to the Terms, the Site, or the Services.
            </p>
            <p>
              If for any reason a court of competent jurisdiction finds any
              aspect of the Terms to be unenforceable, the Terms shall be
              enforced to the maximum extent permissible, to give effect to the
              intent of the Terms, and the remainder of the Terms shall continue
              in full force and effect.
            </p>
            <p>
              User may not assign his or her rights or delegate his or her
              responsibilities under these Terms or otherwise relating to the
              Site or its Services.
            </p>
            <p>
              There shall be no third party beneficiaries under these Terms,
              except for the Site’s affiliates, suppliers, and licensors, or as
              required by law.
            </p>
            <p>
              Use of the Site and its Services is unauthorized in any
              jurisdiction that does not give effect to all provisions of these
              Terms, including without limitation this paragraph.
            </p>
            <p>
              The failure of the Site to exercise or enforce any right or
              provision of these Terms shall not constitute a waiver of that
              right or provision.
            </p>
            <h2>Terms Contact</h2>
            <p>
              If you have any questions about these Terms, please address them
              to admin@elementus.io.
            </p>
            <h2>Last Updated</h2>
            <p>
              These terms were last updated on <em>December 1, 2017</em>
            </p>
          </section>
        </article>
        <footer className='site-footer clearfix'>
          <section className='copyright'>
            <a href='https://elementus.io'>Elementus</a> © 2017
          </section>
        </footer>
      </InfoWindow>
    </ViewContainer>
  );
};

export default Terms;
