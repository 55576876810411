import React, { useState, useEffect } from 'react';
import RiskMonitorAdmin from './RiskMonitorAdmin';
import RiskMonitorTable from './RiskMonitorTable';
import { AppCenterWindow } from './styles';
import ElementusAPIService from 'services/elementus_api.service';
import useRiskMonitor from 'hooks/useRiskMonitor';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'components/Common/Spinner';
import { ReportContainer } from '../styles';
import { Panel } from 'components/Common/Panel';

const RiskMonitor = () => {
  const [apiClient, setApiClient] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { riskSummaries, addAddress, removeAddress, isUpdating } =
    useRiskMonitor(apiClient);

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setApiClient(new ElementusAPIService(token));
      })
      .catch((error) => {
        console.error('failed to get create api client. error: ', error);
      });
  }, [getAccessTokenSilently]);

  return (
    <ReportContainer className={isUpdating ? 'is-disabled' : ''}>
      {isUpdating && <Spinner></Spinner>}
      <AppCenterWindow width='85vh'>
        <Panel>
          <Panel.Heading>
            <Panel.Title componentClass='h1'>Risk Monitor</Panel.Title>
          </Panel.Heading>

          <Panel.Body className='main-body'>
            <RiskMonitorAdmin addAddress={addAddress} />
            <RiskMonitorTable
              removeAddress={removeAddress}
              riskSummaries={riskSummaries}
            />
          </Panel.Body>
        </Panel>
      </AppCenterWindow>
    </ReportContainer>
  );
};

export default RiskMonitor;
