import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

export const ViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  align-items: center;
  display: flex;
  align-self: start;
  vertical-align: middle;
  justify-content: center;
  font-family: 'Verlag A', 'Verlag B';
  color: white;
  .wiki-content {
    h1 {
      text-align: center;
      letter-spacing: 5px;
      font-size: 3em;
    }
    th {
      font-size: 1.4em;
    }
    td,
    th {
      padding: 5px;
    }
    code {
      font-size: 83%;
    }
  }
`;

export const Window = styled.div`
  width: 70%;
  height: 70%;
  background: #e2deeb;
  border-color: #337ab7;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
`;

export const TitleText = styled.h1`
  color: black;
  font-family: 'Verlag A', 'Verlag B';
  font-size: 24px;
  opacity: 1;
  font-weight: 500;
  line-height: 1;
`;

export const PanelHead = styled.div`
  padding: 10px 30px 0px 30px;
`;

export const PanelBody = styled.div`
  padding: 30px 0px 0px 0px;
`;

export const PanelContainer = styled.div`
  height: 100%;
`;

export const EmphasisButton = styled.button`
  align-items: center;
  font-size: 12px;
  padding: 1px 16px 0 16px;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border: 0;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 10px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: #fff;
  background-color: #753bf2;
  line-height: 3em;
  background-color: #e0e0e0;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: none;
`;

export const PurpleActiveButton = styled.button`
  outline: none !important;
  outline-offset: none !important;
  -webkit-font-smoothing: antialiased;
  overflow: visible;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 1px 16px 0 16px;
  font-size: ${SR.fontSize.sml};
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  /*line-height: 1.75;*/
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: white;
  background-color: ${SR.colors.emphasis_lightPurple};

  line-height: 3em;

  :hover,
  :focus {
    background-color: ${SR.colors.mutedGreyPurple} !important;
    color: white !important;
    text-decoration: none !important;
  }

  p {
    margin: 0;
    padding: 5px;
  }
`;
