import React, { useEffect } from 'react';
import SliderBar from '../../Common/SliderBar';
import InfoIcon from 'components/Common/InfoIcon';
import { ParameterContainer } from './styles';

export default function Parameters(props) {
  useEffect(() => {
    props.set_reportParams((p) => {
      p['hops'].current = props.hops;
      p['dilution'].current = props.dilution;
      return p;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dilution, props.hops]);

  return (
    <div>
      {/* <Col sm={6} className='text-field-container'> */}
      <ParameterContainer>
        <div className='field-title'>
          Min Concentration{' '}
          <InfoIcon
            infoText={
              'Concentration: The minimum percentage relationship to the source that a given node must have in order to be included in the graph. Concentration must be a number between 0.0 and 1.0.'
            }
          />
          <SliderBar
            value={props.dilution}
            onChange={(event, val) => props.set_dilution(val)}
            min={0}
            max={100}
            isRight={true}
            isDilution={true}
          />
        </div>
        <div className='field-title'>
          Max # Hops{' '}
          <InfoIcon
            infoText={
              'Max # Hops: The maximum number of hops from the source that a given node must have in order to be included in the graph.'
            }
          />
          <SliderBar
            value={props.hops}
            onChange={(event, val) => props.set_hops(val.toString())}
            steps={1}
            min={0}
            max={10}
            isRight={true}
          />
        </div>
      </ParameterContainer>
      {/* </Col> */}
    </div>
  );
}
