import * as ZingChartUtils from '../../utils/zingCharts';

export const ChartDataType = {
  TimeSeries: 'timeseries',
};

export default class ChartData {
  constructor(title, data, type) {
    this._title = title;
    this._data = data;
    this._type = type;
    this._featured = null;
  }

  get title() {
    return this._title;
  }

  setFeatured(value) {
    this._featured = value;
  }

  createZingChartData() {
    let zingChartData;
    switch (this._type) {
      case ChartDataType.TimeSeries: {
        zingChartData = ZingChartUtils.createTimeSeriesData(
          this._title,
          this._data,
          this._featured,
        );
        break;
      }
      default:
        throw new Error(`chart type "${this._type}" unsupported`);
    }
    return zingChartData;
  }
}
