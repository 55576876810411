import React from 'react';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Panel } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ClearIcon from '@material-ui/icons/Clear';
import ReactLoading from 'react-loading';

export const MyReports = ({
  columns,
  ownedReports,
  ownedRows,
  beforeSaveCell,
}) => {
  const { SearchBar } = Search;

  return (
    <>
      <div style={{ minHeight: '500px' }}>
        <Panel.Title componentClass='h1' className='position-absolute'>
          My Reports
        </Panel.Title>
        {ownedReports ? (
          <ToolkitProvider
            keyField='id'
            data={ownedRows}
            columns={columns()}
            search
          >
            {(props) => (
              <>
                <div className='searchbar-controls'>
                  <SearchBar
                    {...props.searchProps}
                    placeholder='Search reports'
                    className='table-searchbar'
                  />
                  <ClearIcon
                    className='btn-clear-searchbar'
                    onClick={() => props.searchProps.onClear()}
                  />
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory({
                    page: 1,
                    sizePerPage: 8,
                  })}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    beforeSaveCell,
                  })}
                />
              </>
            )}
          </ToolkitProvider>
        ) : (
          <ReactLoading
            type='bubbles'
            color='gray'
            height='10%'
            width='10%'
            id='loading-animation-small'
          />
        )}
      </div>
    </>
  );
};
