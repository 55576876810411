import React from 'react';
import { Tooltip } from 'react-bootstrap';
import parse from 'html-react-parser';

// Show up to DEFAULT_MAX_LIST_SIZE suggestions for autocomplete
const DEFAULT_MAX_LIST_SIZE = 6;

export const AddressAutocompleteTooltip = ({
  props,
  autocompletelist = [],
  onClickHandler,
  query,
  noMatchingResultsLabel = 'No matches found',
}) => {
  if (query == null || autocompletelist == null) return null;

  const showEmptyStateLabel =
    query.length >= 4 && autocompletelist.length === 0;

  // truncate list to 6 items
  autocompletelist = autocompletelist.slice(0, DEFAULT_MAX_LIST_SIZE);

  return (
    <Tooltip id='tooltip-autocomplete' {...props}>
      <div className='autocomplete-items'>
        {autocompletelist.map((address, key) => (
          <span
            className='autocomplete-item'
            key={key}
            onClick={() => onClickHandler(address)}
          >
            {makeQueryPortionBold(query, address)}
          </span>
        ))}

        {showEmptyStateLabel && (
          <span className='autocomplete-item'>{noMatchingResultsLabel}</span>
        )}
      </div>
    </Tooltip>
  );
};

// make the query/prefix portion of the string <bold>
const makeQueryPortionBold = (prefix, address) => {
  if (!address || !prefix) return;

  let prefixCounter = 0;
  let result = '';

  for (let i = 0; i < address.length; i++) {
    if (address[i].toLowerCase() === prefix[prefixCounter].toLowerCase()) {
      // optimization: if following letter is also bold, include here instead of adding new strong element next iteration
      result = result.concat(`<strong>${address[i]}</strong>`);
      prefixCounter++;
    } else {
      result = result.concat(`${address[i]}`);
    }

    // stop condition: our query is fully matched
    if (prefixCounter === prefix.length) {
      // add remaining letters if available
      if (i < address.length) {
        result = result.concat(address.substring(i + 1));
      }
      break;
    }
  }

  return parse(result);
};
