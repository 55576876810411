import React from 'react';
import { BeatLoader } from 'react-spinners';
import { styleRefs as SR } from 'styles/styleRefs';

const size = 20;
const styles = {
  position: 'absolute;',
  top: `calc(50% - ${size / 2}px);`,
  left: 'calc(50% + 32px);',
};
export const Spinner = (props) => {
  let color = SR.colors.black;
  if (props?.color) {
    color = props.color;
  }

  return <BeatLoader color={color} css={styles} size={size}></BeatLoader>;
};
