import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Panel } from 'components/Common/Panel';
import { ActiveAlertsTableConfig } from './table.config';

export const ActiveAlerts = ({ data, deleteAlert }) => {
  return (
    <Panel className='active-alerts'>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>Active Alerts</Panel.Title>
      </Panel.Heading>
      <Panel.Body className='notifications'>
        <BootstrapTable
          keyField='_id'
          data={data}
          columns={ActiveAlertsTableConfig(deleteAlert)}
          bordered={false}
          defaultSorted={[{ dataField: 'data', order: 'asc' }]}
          noDataIndication='No current active alerts.'
        />
      </Panel.Body>
    </Panel>
  );
};
