import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Popover } from 'react-bootstrap';
import Chip from '@material-ui/core/Chip';
import { styleRefs as SR } from 'styles/styleRefs';

export const EditIconS = styled(EditIcon)`
  fill: ${SR.colors.offWhite} !important;
  margin-right: 5px;
`;

export const FailNote = styled.small`
  color: ${(props) => props.color};
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const IntercomText = styled.span`
  font-weight: bold;
  color: ${SR.colors.emphasis_lightPurple};
`;

export const RemoveButtonStyles = {
  DelContainer: styled.div`
    display: flex;
    justify-content: flex-end;
    text-align: right;
  `,

  DelButton: styled(Chip)`
    color: ${SR.colors.contrasting_Orange};
    font-size: ${SR.fontSize.med};
  `,

  RemoveCircleOutlineIconS: styled(RemoveCircleOutlineIcon)`
    transition: 'transform 1s ease-in-out 0s'; // this won't work because it isn't in a proper hook.
  `,
};

export const PopoverS = styled(Popover)`
  max-width: 400px;
`;

export const CenteredFormatterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const AddressListWrapper = styled.div`
  display: flex;

  div {
    .addressdataicon {
      margin: auto;
      color: ${SR.colors.emphasis_lightPurple};
      fill: ${SR.colors.emphasis_lightPurple};
      position: relative;
      top: 3px;
      font-size: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
