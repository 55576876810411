export const openUrl = (url: string) => {
  window.open(url, '_blank');
};

export const scrollTo = (elementId: string) => {
  const element = document.getElementById(elementId);

  if (element == null) return;

  element.scrollIntoView({ behavior: 'smooth', block: 'start' });
};
