import React from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

const CloseButton = ({ onClose }) => {
  return (
    <StyledCloseButton
      fontSize='large'
      className='close-button'
      onClick={onClose}
    />
  );
};

export default CloseButton;

const StyledCloseButton = styled(CancelOutlinedIcon)`
  position: absolute;
  right: 11px;
  top: 11px;
  padding: 0px;
  height: 30px;
  min-width: 30px;
  line-height: 100%;
  fill: '#000';

  :hover,
  :focus {
    fill: ${SR.colors.emphasis_lightPurple} !important;
    cursor: pointer;
  }
`;
