import React from 'react';
import { styleRefs as SR } from 'styles/styleRefs';
import { PurpleActiveButton } from 'styles/';

export const CreatePulseReportButton = ({
  show,
  toggleModal,
  set_ifComplianceModal,
}) => {
  if (!show) return null;

  return (
    <PurpleActiveButton
      style={{ fontSize: SR.fontSize.standard, marginBottom: '0.5em' }}
      onClick={(e) => {
        e.target.blur();
        toggleModal();
        set_ifComplianceModal(false);
      }}
    >
      Create Pulse Report
    </PurpleActiveButton>
  );
};

export const CreateRadarReportButton = ({
  show,
  toggleModal,
  set_ifComplianceModal,
}) => {
  if (!show) return null;

  return (
    <PurpleActiveButton
      style={{ fontSize: SR.fontSize.standard }}
      onClick={(e) => {
        e.target.blur();
        toggleModal();
        set_ifComplianceModal(true);
      }}
    >
      Create Radar Report
    </PurpleActiveButton>
  );
};
