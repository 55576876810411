import { isInternalOnlyEntity } from 'utils/elementusData';
import DenominatedBalance from 'models/other/balance';

class Counterparty {
  constructor(apiData) {
    this._apiData = apiData;
  }

  get address() {
    return this._apiData.addr;
  }

  get amount() {
    return this._apiData.amt;
  }

  get amountWithDenom() {
    const bal = new DenominatedBalance(``, this._apiData.amt);
    return bal.amt;
  }

  get amountUSD() {
    return this._apiData.amt_USD;
  }

  get amountUSDWithDenom() {
    const bal = new DenominatedBalance(`$`, this._apiData.amt_USD);
    return bal.amt;
  }

  get entity() {
    const entity = this._apiData.entity;
    return isInternalOnlyEntity(entity) ? undefined : entity;
  }

  get beneficialOwner() {
    return this._apiData.beneficial_owner
      ? this._apiData.beneficial_owner
      : undefined;
  }

  get cluster() {
    return this._apiData.cluster;
  }
}

export default Counterparty;
