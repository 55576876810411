import React from 'react';
import { parse } from 'json2csv';
import JSZip from 'jszip';
import * as d3 from 'd3';
import { styleRefs as SR } from 'styles/styleRefs';
import { PurpleActiveButton } from 'styles/';

export default function DownloadGraph({ ReportData, chartRef }) {
  /* thehref: DataURL form of a png file. */

  const fetchData = () => {
    const buildCsvOutputs = () => {
      let reportSummary = [];
      // save csv
      if (ReportData.reportType !== 'compliance') {
        reportSummary = [
          {
            REPORT_RUN_TYPE: ReportData.reportType,
            SOURCE_IDS: ReportData.sourceIds,
            NODES: ReportData.nCount,
            TRANSACTIONS: ReportData.txCount,
            THROUGHPUT_BTC: ReportData.throughputCrypto,
            THROUGHPUT_USD: ReportData.throughputUSD,
            INPUT_BTC: ReportData.inputCrypto,
            INPUT_USD: ReportData.inputUSD,
          },
        ];
      }

      const addresses = [];
      let longestIdRange = 0;
      const entityTypesByCluster = { ...ReportData.entityTypesByAddress };

      Object.entries(ReportData.entityTypesByAddress).forEach(
        ([entity, ids]) => {
          const idsToUse = Array.from(
            new Set(ids.map((id) => id.split('_')[0])),
          );
          entityTypesByCluster[entity] = Array.from(idsToUse);
          if (idsToUse.length > longestIdRange)
            longestIdRange = idsToUse.length;
        },
      );

      for (let i = 0; i <= longestIdRange; i++) {
        let row = {};
        Object.entries(entityTypesByCluster).forEach(([entity, ids]) => {
          if (ids[i]) {
            row[entity] = ids[i];
          }
        });

        addresses.push(row);
      }
      const summary_CSV = parse(reportSummary, {
        fields: Object.keys(reportSummary[0]),
      });

      const entity_addresses_CSV = parse(addresses, {
        fields: Object.keys(ReportData.entityTypesByAddress),
      });

      return { summary_CSV, entity_addresses_CSV };
    };

    const zipFiles = (summary_CSV, entity_addresses_CSV) => {
      var zip = new JSZip();
      zip.file('summary.csv', summary_CSV);
      zip.file('entity_addresses.csv', entity_addresses_CSV);
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        window.saveAs(
          content,
          `${ReportData.meta.name.split(' ').join('_')}_SummaryData.zip`,
        );
      });
    };

    if (chartRef && chartRef.current && chartRef.current.image) {
      let resScale = d3.scaleSqrt().domain([1, 3000]).range([5000, 20000]);

      const res = resScale(ReportData.initialGraph.nodes.length);

      chartRef.current
        .export({
          fitTo: {
            height: res,
            width: res,
          },
          type: 'png',
        })
        .then((image) => {
          if (image) {
            image.download(
              `${ReportData.meta.name.split(' ').join('_')}_graph`,
            );
            const { summary_CSV, entity_addresses_CSV } = buildCsvOutputs();
            zipFiles(summary_CSV, entity_addresses_CSV);
          }
        });
    }
  };

  return (
    <PurpleActiveButton
      id='download-graph-btn'
      onClick={() => fetchData()}
      style={{
        fontSize: SR.fontSize.sml,
      }}
    >
      <p>Download Graph</p>
    </PurpleActiveButton>
  );
}
