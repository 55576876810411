import React, { useContext, useState } from 'react';
import * as R from 'ramda';
import {
  Col,
  Form,
  FormGroup,
  Panel,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { PurpleActiveButton } from 'styles/';
import { store } from 'ReportData/ReportDataStore';
import SliderBar from '../../../Common/SliderBar';
import IconButton from '@material-ui/core/IconButton';
import Settings from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import NewAgent from './NewAgent';

import {
  UserInputModal,
  UserInputContainer,
  ModalWithNoBackground,
} from './styles';
import Config from 'utils/config';

const UserInput = () => {
  const { state: globalState, dispatch: globalDispatch } = useContext(store);
  const [modalIsOpen, setOpenModal] = useState(false);

  const [stagingUserParameters, setStagingUserParameters] = useState(
    globalState.ReportData.meta.reportParams,
  );

  const concentrationMin = globalState.ReportData.meta.flow_type
    ? Config.reportParams.incomingConcentrationMin
    : Config.reportParams.outgoingConcentrationMin;

  const updateGraphParams = () => {
    globalDispatch({ type: 'LOADING', payload: true });
    globalDispatch({ type: 'USER_PARAMETERS', payload: stagingUserParameters });
    setOpenModal(false);
  };

  const handleSliderChange = (event, value, name) => {
    const newUserParameters = R.clone(stagingUserParameters);
    newUserParameters[name].current = parseFloat(value);
    setStagingUserParameters(newUserParameters);
  };
  const popover = (
    <Popover id='popover-info' title=''>
      Adjust parameters to configure the graph to your specifications: <br />
      <br />
      Dilution: The minimum percentage relationship to the source that a given
      node must have in order to be included in the graph. Dilution must be a
      number between 0.0 and 1.0. <br />
      <br />
      Hops: The maximum number of hops from the source that a given node must
      have in order to be included in the graph. <br />
      <br />
      Minimum Transfer Size: The minimum aggregate transfer value that a given
      node must have received in order to be included in the graph.{' '}
      {`Transfer size for this graph must be <= ${globalState.ReportData.meta.reportParams['minTransferSize'].max} USD $`}
    </Popover>
  );

  return (
    <>
      <UserInputContainer>
        <div className='filterDiv'>
          <IconButton
            className='user-input-button'
            onClick={() => setOpenModal(!modalIsOpen)}
          >
            <Settings
              className='settings-icon'
              id='filter'
              fontSize='large'
              style={{ fill: 'white' }}
            />
          </IconButton>
        </div>

        <NewAgent />
      </UserInputContainer>

      {/*  TODO: replace modal with in graph features */}
      <ModalWithNoBackground
        isOpen={modalIsOpen}
        style={customStyles}
        closeTimeoutMS={2000}
        contentLabel='Graph Parameters'
      >
        <UserInputModal>
          <Panel className='user-input-panel'>
            <Panel.Heading>
              <Panel.Title className='modal-title'>
                Graph Parameters
              </Panel.Title>

              <OverlayTrigger
                rootClose
                trigger='click'
                placement='bottom'
                overlay={popover}
              >
                <InfoIcon title='info' className={'info-icon-new-report'} />
              </OverlayTrigger>
            </Panel.Heading>
            <Panel.Body>
              <Form>
                <FormGroup controlId='formBasicText'>
                  <div className='user-input-boxes'>
                    <Col>
                      <div className='text-field-container'>
                        <div className='field-title'> Min Concentration </div>
                        <SliderBar
                          ifRight={true}
                          value={
                            parseFloat(stagingUserParameters.dilution.current) *
                            100
                          }
                          onChange={(event, val) =>
                            handleSliderChange(event, val / 100, 'dilution')
                          }
                          min={concentrationMin * 100}
                          max={1.0 * 100}
                          isDilution={true}
                          allIs100={
                            parseFloat(stagingUserParameters.dilution.min) === 1
                          }
                        />
                      </div>
                      <div className='text-field-container'>
                        <div className='field-title'>Max # Hops</div>
                        <SliderBar
                          ifRight={true}
                          value={stagingUserParameters['hops'].current}
                          onChange={(event, val) =>
                            handleSliderChange(event, val, 'hops')
                          }
                          steps={1}
                          min={0}
                          max={10}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className='text-field-container'>
                        <div className='field-title'>
                          Min Transfer Amount
                          {globalState.ReportData.assetInfo.symbol}
                        </div>
                        <SliderBar
                          value={stagingUserParameters.minTransferSize.current}
                          onChange={(event, val, scaledVal) =>
                            handleSliderChange(
                              event,
                              scaledVal,
                              'minTransferSize',
                            )
                          }
                          max={stagingUserParameters.minTransferSize.max}
                          min={0}
                          isMinTrx={true}
                        />
                      </div>
                    </Col>
                  </div>

                  <div className='user-input-button-row'>
                    <PurpleActiveButton
                      className='cancel-update-btn'
                      onClick={(event) => {
                        event.preventDefault();
                        setOpenModal(false);
                        setStagingUserParameters(
                          globalState.ReportData.meta.reportParams,
                        );
                      }}
                    >
                      Cancel
                    </PurpleActiveButton>

                    <PurpleActiveButton
                      className='update-graph-btn'
                      onClick={updateGraphParams}
                    >
                      Update Graph
                    </PurpleActiveButton>
                  </div>
                </FormGroup>
              </Form>
            </Panel.Body>
          </Panel>
        </UserInputModal>
      </ModalWithNoBackground>
    </>
  );
};

export default UserInput;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    width: '600px',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(255, 255, 255, 0.75)',
    opacity: 1,
    border: 'none',
    marginTop: '15px',
    overflow: 'visible',
  },
  overlay: {
    height: '100%',
    backgroundColor: 'none',
  },
};
