import * as WAValidator from 'wallet-address-validator';
import { isValidArray } from './typeChecking';

const EthHashRegex = /^0x[a-f0-9]{64}$/im;
const BtcHashRegex = /^[a-f0-9]{64}$/im;

export const EBlockchain = {
  Bitcoin: 'bitcoin',
  Ethereum: 'ethereum',
};

export const EAsset = {
  BTC: 'btc',
  ETH: 'eth',
};

export const getBlockchainFromAddress = (address) => {
  if (isBtcAddress(address)) return EBlockchain.Bitcoin;
  if (isEthAddress(address)) return EBlockchain.Ethereum;
};

export const getBlockchainFromHash = (hash) => {
  if (isValidBtcHash(hash)) return EBlockchain.Bitcoin;
  if (isValidEthHash(hash)) return EBlockchain.Ethereum;
};

export const getBlockchainAsset = (blockchain) => {
  switch (blockchain) {
    case EBlockchain.Bitcoin:
      return EAsset.BTC;

    case EBlockchain.Ethereum:
      return EAsset.ETH;

    default:
  }
  return null;
};

export const AssetOption = {
  Btc: { value: EAsset.BTC, label: EAsset.BTC },
  Eth: { value: EAsset.ETH, label: EAsset.ETH },
};

export const DefaultAssetTypes = [AssetOption.Btc, AssetOption.Eth];

export const guessAssetFromAddress = (address) => {
  if (!address || !address.length) return null;

  // first try to see if address is valid
  const addressBlockchain = getBlockchainFromAddress(address);
  if (addressBlockchain) return getBlockchainAsset(addressBlockchain);

  // try to see if valid partial addresses
  if (isValidPartialBitcoinAddress(address)) return EAsset.BTC;
  if (isValidPartialEthereumAddress(address)) return EAsset.ETH;

  return null;
};

// ----------------------------------------
// Address utils

export const isBtcAddress = (address) => WAValidator.validate(address, 'BTC');

export const isEthAddress = (address) => WAValidator.validate(address, 'ETH');

export const isValidPartialBitcoinAddress = (partialAddress) => {
  if (!partialAddress?.length) {
    return false;
  }

  if (isBtcAddress(partialAddress)) return true;

  if (partialAddress[0] === '1' || partialAddress[0] === '3') return true;

  const partialAddressClean = partialAddress.toLowerCase();
  const b = partialAddressClean.substring(0, 1);
  const bc = partialAddressClean.substring(0, 2);
  const bc1 = partialAddressClean.substring(0, 3);
  if (b === 'b' && partialAddressClean.length === 1) return true;
  if (bc === 'bc' && partialAddressClean.length === 2) return true;
  if (bc1 === 'bc1' && partialAddressClean.length >= 3) return true;

  return false;
};

export const isValidPartialEthereumAddress = (partialAddress) => {
  if (!partialAddress?.length) return false;

  if (isEthAddress(partialAddress)) return true;

  if (partialAddress.substring(0, 2).toLowerCase() === '0x') {
    return true;
  }

  return false;
};

/** Validates an address, if asset is provided, returns if address is valid for that asset */
export const isValidAddress = (address, asset) => {
  switch (asset) {
    case EAsset.ETH:
      return isEthAddress(address);
    case EAsset.BTC:
      return isBtcAddress(address);
    default:
  }

  return isBtcAddress(address) || isEthAddress(address);
};

export const cleanAddrBTC = (addresses) => {
  if (!isValidArray(addresses)) {
    return [];
  }

  return addresses.filter(isBtcAddress);
};

export const cleanAddrETH = (addresses) => {
  if (!isValidArray(addresses)) {
    return [];
  }

  return addresses.filter(isEthAddress);
};

// ----------------------------------------
// Transaction hash utils

export const guessAssetFromInput = (input) => {
  let asset = guessAssetFromAddress(input);
  if (asset != null) return asset;

  asset = guessAssetFromHash(input);
  // default to BTC
  return asset || EAsset.BTC;
};

export const guessAssetFromHash = (hash) => {
  if (!hash || !hash.length) return null;

  // first try to see if hash is valid
  const hashBlockchain = getBlockchainFromHash(hash);
  if (hashBlockchain) return getBlockchainAsset(hashBlockchain);

  // try to see if valid partial hash
  if (isValidPartialBitcoinAddress(hash)) return EAsset.BTC;
  if (isValidPartialEthereumAddress(hash)) return EAsset.ETH;

  return null;
};

export const cleanTxBTC = (txs) => {
  if (!isValidArray(txs)) {
    return [];
  }
  // TODO address these hacks for tx id. Need to validate more than just length
  return txs.filter(isValidBtcHash);
};

export const cleanTxETH = (txs) => {
  if (!isValidArray(txs)) {
    return [];
  }

  return txs.filter(isValidEthHash);
};

/** Validates an tx hash, if asset is provided, returns if hash is valid only for that asset */
export const isValidTXHash = (hash, asset) => {
  switch (asset) {
    case EAsset.ETH:
      return isValidEthHash(hash);
    case EAsset.BTC:
      return isValidBtcHash(hash);
    default:
  }

  return isValidBtcHash(hash) || isValidEthHash(hash);
};

export const isValidBtcHash = (hash) => BtcHashRegex.test(hash);

export const isValidEthHash = (hash) => EthHashRegex.test(hash);

export const isValidPartialBtcHash = (partialHash) =>
  /^[A-Fa-f0-9]{0,64}$/.test(partialHash);

export const isValidPartialEthHash = (partialHash) =>
  /^0x[A-Fa-f0-9]{0,64}$/.test(partialHash);
