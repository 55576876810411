import React from 'react';
import { ModalPortal } from '@weahead/react-customizable-modal';
import Share from 'components/Share';

export const ShareReportModal = ({
  show,
  setIsShareOpen,
  ownedReports,
  setOwnedReports,
  selectedReport,
  setSelectedReport,
  sharedReports,
  setSharedReports,
}) => {
  if (!show) return null;

  const reportIndex = (id, list) =>
    (list || []).findIndex((item) => item && item._id === id);

  const handleShareSettingsChange = (viewers, changed, unfollow) => {
    setIsShareOpen(false);
    const reportId = selectedReport._id;
    const ownedReportsIndex = reportIndex(reportId, ownedReports);
    const sharedReportsIndex = reportIndex(reportId, sharedReports);

    // report sharing settings were updated
    if (changed && ownedReportsIndex > -1) {
      const reportList = [...ownedReports];
      reportList[ownedReportsIndex]['share_users'] = viewers;
      setOwnedReports(reportList);
    }

    // user deleted a shared report
    if (unfollow && sharedReportsIndex > -1) {
      // remove report from list
      const reportList = [
        ...sharedReports.filter((report) => report._id !== reportId),
      ];
      setSharedReports(reportList);
    }

    setSelectedReport({});
  };

  return (
    <>
      <ModalPortal id={'modal-root'}>
        <Share
          onClose={handleShareSettingsChange}
          report={selectedReport}
        ></Share>
      </ModalPortal>
    </>
  );
};
