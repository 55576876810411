import React, { useState, useEffect } from 'react';
import {
  removeCustomLabels,
  addOrEditCustomLabels,
  hideContextMenu,
  set_showCustomLabelEdit,
} from 'hooks/useGraph/actions';
import { Form, FormGroup, Button } from 'react-bootstrap';
import 'rc-menu/assets/index.css';
import Modal from 'react-modal';
import CloseButton from 'components/Common/CloseButton';
import TextField from '@material-ui/core/TextField';
import { idIsCombo } from 'helpers/itemsHelper';
import { useGraph } from 'hooks/useGraph';

import { ModalStyles } from '../styles';
import { CustomLabelModalContainer } from './styles';
import { PurpleActiveButton } from 'styles/';

/**
 * CustomLabelModal
 *
 * Defines the modal that appears upon adding/editing a custom label for nodes and/or edges via the context menu.
 * */

const CustomLabelModal = () => {
  const { state, dispatch } = useGraph();
  const [customLabel, setCustomLabel] = useState(' ');

  const closeModal = () => {
    dispatch(set_showCustomLabelEdit(false));
    dispatch(hideContextMenu());
    setCustomLabel('');
  };

  useEffect(() => {
    const existingLabels = [];
    let inComboMode = false;
    state.selection.forEach((s) => {
      if (idIsCombo(s)) {
        inComboMode = true;
      }
      if (inComboMode && s in state.customLabelsMap) {
        existingLabels.push(state.customLabelsMap[s]);
      } else if (s in state.customLabelsMap)
        existingLabels.push(state.customLabelsMap[s]);
    });

    if (
      state.selection.size === existingLabels.length &&
      existingLabels.every((l) => l === existingLabels[0])
    )
      setCustomLabel(existingLabels[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selection]);

  const handleCustomLabelChange = (event) => {
    const target = event.target;
    setCustomLabel(target.value);
  };

  const saveCustomLabel = (event) => {
    // stops full reload
    event.preventDefault();

    if (!customLabel) {
      dispatch(removeCustomLabels());
    } else {
      dispatch(addOrEditCustomLabels(customLabel));
    }
    closeModal();
  };

  const uniqueLabels = new Set(Object.values(state.customLabelsMap));

  return (
    <Modal
      isOpen={state.showCustomLabelEdit}
      closeTimeoutMS={2000}
      contentLabel='Custom Label'
      style={ModalStyles({ width: '375px' })}
    >
      <CustomLabelModalContainer>
        <section className='modal-heading'>
          <h1 className='modal-title'>Custom Label</h1>

          <CloseButton onClose={() => closeModal()} />
        </section>
        <Form onSubmit={(e) => saveCustomLabel(e)} className='customLabelform'>
          <FormGroup controlId='formBasicText'>
            <div className='text-field-container'>
              <TextField
                autoFocus
                className='custom-input'
                inputProps={{ maxLength: 48, spellCheck: false }}
                name='customLabel'
                value={customLabel}
                onChange={handleCustomLabelChange}
                variant='filled'
                multiline
                placeholder={'Enter a custom label.'}
              />
            </div>

            <div className='custom-label-buttons'>
              <div className='hash-div'>
                <PurpleActiveButton type='submit'>
                  <p>Save</p>
                </PurpleActiveButton>
                <br />
                <br />
                {[...uniqueLabels].map((lab) => {
                  if (!lab) return null;
                  return (
                    <Button
                      key={lab}
                      className='fill-lbl'
                      onClick={() => setCustomLabel(lab)}
                    >
                      #{lab}
                    </Button>
                  );
                })}
              </div>
            </div>
          </FormGroup>
        </Form>
      </CustomLabelModalContainer>
    </Modal>
  );
};
export default CustomLabelModal;
