import React from 'react';
import { store } from 'ReportData/ReportDataStore';
import { useGraph } from 'hooks/useGraph';

import * as d3 from 'd3';
import { RiskBreakDown, IndentedSection, RiskCatRow } from '../styles';
import { KYCSummary } from './KYCSummary';
import { colors } from 'ReportData/colors';
import * as R from 'ramda';
import Gauge from './gauge';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { addSelection, clearSelection } from 'hooks/useGraph/actions';
import EntityStatistics from '../../../ExperimentalFeatures/EntityStatistics';

const formatPer = d3.format(',.02%');
const MIN_RISK = 0.0000001;

// this file should be broken up and separated into separate files

const SummaryAML = () => {
  const { state: globalState } = React.useContext(store);

  const ReportData = globalState.ReportData;
  const riskScore = ReportData.meta.scores.riskScore;
  let riskSourcesTotal = 0;
  const riskSources = ReportData.meta.scores.riskSources.filter((rS) =>
    ReportData.riskSourcesAddresses.includes(rS['rootAddress']),
  );

  const riskSourcesSummedByCat = {
    cybercrime: MIN_RISK,
    'darknet markets': MIN_RISK,
    mining: MIN_RISK,
    obfuscation: MIN_RISK,
    sanctions: MIN_RISK,
    gambling: MIN_RISK,
    terrorism: MIN_RISK,
    'high risk jurisdiction': MIN_RISK,
  };

  riskSources.forEach((rS) => {
    let riskContribution = rS['riskContribution'];
    if (typeof riskContribution === 'string') {
      riskContribution = parseFloat(riskContribution);
    }

    // entity reports data uses the category 'darknet_markets' instead
    // of 'darknet markets'. This can be removed if that changes
    if (
      rS['riskCategory'] === 'darknet_markets' ||
      rS['riskCategory'] === 'darknet commerce'
    ) {
      rS['riskCategory'] = 'darknet markets';
    }

    if (
      rS['riskCategory'] === 'terrorism_finance' ||
      rS['riskCategory'] === 'terrorism finance'
    ) {
      rS['riskCategory'] = 'terrorism';
    }

    const category = (rS['riskCategory'] || '').toLowerCase();
    if (category && riskSourcesSummedByCat[category]) {
      riskSourcesSummedByCat[category] = Math.min(
        riskSourcesSummedByCat[category] + rS['riskContribution'],
        1,
      );
      riskSourcesTotal = Math.min(riskSourcesTotal + rS['riskContribution'], 1);
    } else {
      if (category !== 'Uncategorized') {
        console.error('unsupported risk category in risk score: ', rS);
      }
    }
  });

  // if a risk score is provided use it instead of the computed value
  if (riskScore !== undefined) {
    riskSourcesTotal = Math.min(riskScore, 1);
  }

  return (
    <div className='single-col-layout'>
      <RiskBreakDown className='risk-breakdown'>
        <Gauge value={riskSourcesTotal.toFixed(4)} min='0' max='100' />
        <ScoreBreakdown
          riskSourcesTotal={riskSourcesTotal}
          riskSourcesSummedByCat={riskSourcesSummedByCat}
        />
        <KYCSummary kycData={ReportData} />
        <EntityStatistics reportData={ReportData} />
        <RiskSources riskSources={riskSources} />
      </RiskBreakDown>
    </div>
  );
};

const ScoreBreakdown = ({
  ReportData,
  riskSourcesTotal,
  riskSourcesSummedByCat,
}) => {
  const riskScoreCategoriesTuples = Object.entries(riskSourcesSummedByCat).map(
    ([cat, scr]) => [scr, cat],
  );
  const sortByFirstItem = R.sortBy(R.prop(0));
  const riskBreakDownData_ordered = sortByFirstItem(riskScoreCategoriesTuples);

  return (
    <div id='score-breakdown' className='collapsable-item'>
      <div className='collapsable-header'>
        <span>SCORE BREAKDOWN</span>
      </div>
      <IndentedSection>
        <table>
          <thead>
            <tr>
              <th className='iconCell'></th>
              <th className='catCell'>Category</th>
              <th className='expCell' colSpan='2'>
                Exposure
              </th>
            </tr>
          </thead>
          <tbody>
            {riskBreakDownData_ordered
              .reverse()
              .map(([categoryTotal, riskScoreCategory]) => (
                <RiskProfile
                  key={riskScoreCategory}
                  riskSourcesTotal={riskSourcesTotal}
                  categoryTotal={categoryTotal}
                  riskScoreCategory={riskScoreCategory}
                />
              ))}
          </tbody>
        </table>
      </IndentedSection>
    </div>
  );
};

const RiskProfile = (props) => {
  return (
    <RiskCatRow highlight={props.categoryTotal > 0.0000001}>
      <td className='iconCell'>
        <i
          className={colors[props.riskScoreCategory.toLowerCase()].icon}
          style={{
            color:
              props.categoryTotal > 0.0000001 &&
              colors[props.riskScoreCategory.toLowerCase()].color,
          }}
        ></i>
      </td>
      <td className='catCell'>{props.riskScoreCategory}</td>
      <td className='scrCell'>
        {props.categoryTotal < 0.0001 && props.categoryTotal > 0.0000001
          ? '< .01%'
          : formatPer(props.categoryTotal.toFixed(4))}
      </td>
      <td className='riskBar'>
        <svg
          height='10'
          width={(props.categoryTotal / props.riskSourcesTotal) * 50}
        />
      </td>
    </RiskCatRow>
  );
};

const RiskSources = ({ riskSources }) => {
  const { state: globalState } = React.useContext(store);
  const { dispatch } = useGraph(store);

  if (riskSources == null || riskSources.length === 0) {
    return null;
  }

  // *data format*
  // entity: 'dwarfpool';
  // entityType: 'miner';
  // riskCategory: 'mining';
  // riskContribution: 3.9759722818371924;
  // e - 7;
  // riskDesc: ''; /tool tip
  // rootAddress: '0x2a65aca4d5fc5b5c859090a6c34d164135398226';
  // source: '';

  const riskSourcesArray = riskSources.map((rS) => [
    rS['riskContribution'],
    rS,
  ]);
  const sortByFirstItem = R.sortBy(R.prop(0));
  const riskSources_ordered = sortByFirstItem(riskSourcesArray);

  return (
    <div id='risk-sources' className='collapsable-item scrollable-wrapper'>
      <div className='collapsable-header'>
        <span>SOURCES</span>
      </div>
      <IndentedSection>
        <table>
          <tbody className='table-body'>
            {riskSources_ordered.reverse().map((riskSource, idx) => (
              <Tooltip
                key={riskSource[1]['rootAddress'] + idx}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={
                  riskSource[1]['riskDesc'].length === 0 &&
                  riskSource[1]['riskDesc']
                }
                placement='left'
                arrow
              >
                <tr
                  className='addrRow'
                  onClick={() => {
                    // ensures there is an address to be selected
                    const lookup = globalState.ReportData.clusterLookUp;
                    if (lookup[riskSource[1]['rootAddress']]) {
                      dispatch(clearSelection());
                      dispatch(
                        addSelection(
                          globalState.ReportData.clusterLookUp[
                            riskSource[1]['rootAddress']
                          ].unqIds,
                        ),
                      );
                    }
                  }}
                >
                  <td className='iconCell' colSpan='1'>
                    {colors[riskSource[1]['riskCategory'].toLowerCase()] && (
                      <i
                        className={
                          colors[riskSource[1]['riskCategory'].toLowerCase()]
                            .icon
                        }
                        style={{
                          color:
                            colors[riskSource[1]['riskCategory'].toLowerCase()]
                              .color,
                        }}
                      />
                    )}
                  </td>
                  <td className='entityCell' colSpan='2'>
                    <span>{riskSource[1]['entity']}</span>
                    <span style={{ color: 'grey' }}>
                      {riskSource[1]['entityType'].replaceAll('_', ' ')}
                    </span>
                  </td>
                  <td
                    className='scrCell'
                    colSpan='1'
                    style={{ verticalAlign: 'top' }}
                  >
                    {riskSource[1]['riskContribution'] < 0.0001
                      ? '< .01%'
                      : formatPer(
                          Math.min(riskSource[1]['riskContribution'], 1),
                        )}
                  </td>
                </tr>
              </Tooltip>
            ))}
          </tbody>
        </table>
      </IndentedSection>
    </div>
  );
};

export default SummaryAML;
