import React, { useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseButton from 'components/Common/CloseButton';
import { PurpleActiveButton } from 'styles/';
import {
  useTrapFocus,
  useBodyScrollLock,
  useCloseOnEsc,
} from '@weahead/react-customizable-modal';
import { UserContext } from 'contexts/user';
import { ModalStyle } from './Share';
import { styleRefs as SR } from 'styles/styleRefs';

export default function Delete({ onClose, report }) {
  const [viewers] = useState(report.share_users);
  const { apiClient } = useContext(UserContext);

  useBodyScrollLock();
  useCloseOnEsc(() => onClose(false));

  const modalRef = useTrapFocus();

  const overlayProps = useSpring({
    backgroundColor: 'rgba(0,0,0,0.6)',
    from: { backgroundColor: 'rgba(0,0,0,0)' },
  });

  const modalProps = useSpring({
    transform: 'scale(1)',
    from: { transform: 'scale(0)' },
    config: { mass: 1, tension: 280, friction: 25 },
  });

  return (
    <ModalStyle>
      <animated.div
        style={{
          ...overlayProps,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <animated.div
          ref={modalRef}
          style={{
            ...modalProps,
            width: 500,
            backgroundColor: '#e2deeb',
            padding: 20,
            position: 'relative',
            borderRadius: '10px',
          }}
        >
          <CloseButton onClose={() => onClose(false)} />

          <center>
            <Typography variant='h4'>Delete Report</Typography>
          </center>

          <div style={{ padding: '30px 0px 30px 0px' }}>
            <Typography variant='h5'>
              The following users will no longer have access to this report:
            </Typography>
            <Chip
              icon={<FaceIcon />}
              label={report.user_email}
              className='chip-report-owner'
            />
            {viewers &&
              viewers.map((v) => (
                <Chip
                  key={v}
                  icon={<FaceIcon />}
                  id={v}
                  label={v}
                  className='chip-report-owner'
                />
              ))}
          </div>

          {/** If I am the owner of this report. */}
          {!report.is_shared_with_me && (
            <center style={{ padding: 30 }}>
              <PurpleActiveButton
                onClick={() => {
                  apiClient.deleteForensicAnalysis(report._id);
                  return onClose(true);
                }}
              >
                <DeleteForeverIcon />
                <p style={{ fontSize: SR.fontSize.med }}>Confirm Delete</p>
              </PurpleActiveButton>
            </center>
          )}
        </animated.div>
      </animated.div>
    </ModalStyle>
  );
}
