import React, { useContext, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import { store } from 'ReportData/ReportDataStore';
import { FlowType } from 'utils/enums';

const displayDate = function (dt) {
  return dt.toLocaleDateString();
};

const PageTitle = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { state } = useContext(store);
  const ReportData = state.ReportData;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const targetAddresses = ReportData.meta.target_addresses;
  const isTransactionReport = !targetAddresses || targetAddresses.length === 0;
  const ids = isTransactionReport
    ? ReportData.meta.target_txs
    : targetAddresses;
  const isEntityReport = ReportData.meta.flow_type === FlowType.Entity;

  const createAddTxDisplay = (isTransactionReport) => {
    return (
      <div>
        <div className='metakey'>
          {isTransactionReport ? 'Transaction Hashes:' : 'Source Addresses:'}
        </div>
        <div id='metaaddresses'>
          {ids.map((id) => (
            <div key={id}>{id}</div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div id='titlesection'>
      <div className='sectiontitle'>
        <h5>{ReportData.meta.name}</h5>
        <InfoIcon
          onClick={handleClick}
          title='View metadata'
          className='info-icon-result'
        >
          <div className='iconbehind'></div>
        </InfoIcon>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className='addresspopover'>
            <table>
              <tbody>
                <tr>
                  <td className='metakey'>Name:</td>
                  <td className='metaval'>{ReportData.meta.name}</td>
                </tr>
                <tr>
                  <td className='metakey'>Created on:</td>
                  <td className='metaval'>
                    {displayDate(ReportData.meta.created_at)}
                  </td>
                </tr>
                {isEntityReport ? (
                  <tr>
                    <td className='metakey'>Entity:</td>
                    <td className='metaval'>{ReportData.entityName}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            {isEntityReport ? null : createAddTxDisplay(isTransactionReport)}
          </div>
        </Popover>
      </div>
      <div className='title-hr'></div>
    </div>
  );
};

export default PageTitle;
