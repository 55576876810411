import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum, faBtc } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

// TODO add icons for common tokens
// name: icon
const tokensInfo = {
  // COMP: null,
};

const TableAsset = styled.div`
  justify-content: center;
  display: flex;
`;

const formatter = (data) => {
  // this formatter is being passed arguments of different types
  const assetSymbol = data.text ?? data;
  const asset = (assetSymbol ?? '-').toLowerCase();
  if (asset === 'btc') {
    return (
      <TableAsset>
        <FontAwesomeIcon icon={faBtc} />
      </TableAsset>
    );
  }
  if (asset === 'eth') {
    return (
      <TableAsset>
        <FontAwesomeIcon icon={faEthereum} />
      </TableAsset>
    );
  } else {
    const tokenInfo = tokensInfo[asset.toUpperCase()];
    return (
      <TableAsset>
        {tokenInfo ? (
          <FontAwesomeIcon icon={tokenInfo} />
        ) : asset ? (
          asset.toUpperCase()
        ) : (
          '-'
        )}
      </TableAsset>
    );
  }
};

export default formatter;
