import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

// import { Windo;, PanelBod;, EmphasisButto;, TitleText } from '../../styles/';
export const SummaryStatsBody = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: ${(props) =>
    props.type === 'Compliance' ? '0' : `solid 3px ${SR.colors.offWhite}`};
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  color: white;
`;

export const SummaryContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  justify-content: flex-end;
  .sectiontitle {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    padding-left: 4px;
    width: 100%;
    font-family: 'Verlag A', 'Verlag B';
    h5 {
      font-size: 20px;
      opacity: 1;
      font-weight: 500;
      display: inline;
      overflow-wrap: anywhere;
      margin-left: 5px;
    }
    svg {
      margin: 5px;
    }
  }
  .single-col-layout {
    display: flex;
    flex-direction: row;
    height: 100%;
    > div {
      width: 50%;
    }
  }

  #egresstitle {
    display: inline-block;
    margin: 12px 0 12px 0;
    color: white;
  }

  #treemapcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
  }
  #chart1-top text,
  #chart1-top text > tspan {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }

  #ShowLabelsCheckBox_title,
  #ShowLabelsCheckBox_title:focus {
    font-family: 'Verlag A', 'Verlag B';
    font-size: 15px;
    opacity: 1;
    font-weight: 500;
    outline-width: 0;
  }
  #ShowLabelsCheckBox_checkbox {
    position: relative;
    top: 2px;
    font-size: 16px;
  }

  #activitychartcontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  #activitychart {
    position: relative;
    left: 2px;
    // width: 80%;
    // top: -165px;
  }
  #activityaxis {
    // width: 80%;
    overflow: visible;
    position: relative;
    right: 18px;
    // top: -6px;
  }
  .activityticks {
    font-family: 'roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 8.5px;
  }
  .activityaxis {
    // width: 80%;

    path {
      stroke: ${SR.colors.offWhite};
      // width: 80%;
    }
  }

  #summarystatscontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  #statsblock {
    height: 100%;
    width: 100%;
  }

  .statcontainer {
    width: 100%;
    text-align: center;
    padding: 0px 0 0px 0;
    margin: 0;
  }
  .statnumber {
    color: ${SR.colors.offWhite};
    font-size: 24px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    opacity: 1;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 16px;
  }
  .stattoptext {
    color: ${SR.colors.offWhite};
    font-size: 24px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    opacity: 1;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .stattext {
    color: ${SR.colors.offWhite};
    font-size: 10px;
    font-family: 'Verlag A', 'Verlag B';
    opacity: 0.7;
    font-weight: 200;
    max-width: 200px;
    margin: auto;
  }
  .stdetail > .stattext {
    height: 20px;
  }

  #h5activitytimeline {
    margin-bottom: 4px;
    margin-top: 4px;
  }

  #titlesection {
    margin-top: 4px;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  #titlesection > .sectiontitle {
    text-align: center;
    padding: 8px 0;
  }
  #titlesection > .sectiontitle > h5 {
    font-size: 24px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .sectiontitle > span.metadataicon {
    cursor: pointer;
    color: ${SR.colors.emphasis_lightPurple};
    position: relative;
    top: 2px;
    font-size: 20px;
    z-index: 0;
  }

  #titlesection {
    margin-top: 4px;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  #titlesection {
    .sectiontitle {
      text-align: center;
      padding: 8px 0;
      display: flex;
      justify-content: center;
      color: white;
      h5 {
        font-size: 24px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }

  #titlesection > .sectiontitle > .sectiontitle > span.metadataicon {
    cursor: pointer;
    color: ${SR.colors.emphasis_lightPurple};
    position: relative;
    top: 2px;
    font-size: 20px;
    z-index: 0;
  }

  .metadataicon:hover,
  .metadataicon:active,
  .addressdataicon:hover,
  .addressdataicon:active {
    opacity: 0.8;
  }

  .iconbehind {
    width: 14px;
    height: 14px;
    background-color: white;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 7px;
    z-index: -1;
  }

  .title-hr {
    background-color: ${SR.colors.offWhite};
    /* border: white; */
    height: 2px;
    width: 100%;
    margin-bottom: 2px;
  }
`;

export const RiskCatRow = styled.tr`
  opacity: ${(props) => (props.highlight ? '1' : '.5')};
  font-weight: ${(props) => (props.highlight ? 'bolder' : 'initial')};
  .riskBar {
    border-left: 0.5px solid white;
    padding-left: 0px;
    svg {
      background: white;
      margin-right: 10px;
    }
  }
`;

export const RiskBreakDown = styled.section`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: ${SR.fontSize.med};
  }

  table {
    width: 100%;
    table-layout: fixed;
    thead {
      th {
        padding-left: 10px;
        &:first-child {
          padding-left: 0px;
        }
      }
      .iconCell {
        width: 28px;
        max-width: 28px;
      }
      .catCell {
        width: 125px;
      }
      .expCell {
        padding-right: 10px;
        text-align: center;
      }
    }
    tbody {
      font-size: 14px;
      height: 100%;
      text-align: left;
      line-height: 1;

      td {
        white-space: nowrap;
        padding-left: 10px;
        text-align: left;
        height: 37px;
        overflow-x: hidden;
        &:first-child {
          padding-left: 0px;
        }
      }
      .scrCell {
        padding-right: 4px;
        text-align: right;
      }
      .iconCell {
        width: 20px;
        max-width: 20px;
      }
      .statusCell {
        max-width: 90px;
      }

      .addrcell {
        width: calc(100% + 12px);
      }
      .catCell {
        white-space: normal;
      }

      .addrCell,
      .entityCell {
        display: flex;
        width: 175px;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        #type {
          font-weight: bold;
          opacity: 0.3;
        }
      }
    }
  }
`;

export const IndentedSection = styled.div`
  background: #2a2146;
  border-radius: 10px;
  box-shadow: -4px -2px 4px 0px hsl(256deg 37% 28%) inset,
    4px 2px 8px 0px hsl(248deg 33% 18%) inset;
  transition: box-shadow 5s cubic-bezier(0.6, -0.5, 0.27, 1.55),
    transform 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  display: inline-block;
  position: relative;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  max-height: calc(100% - 52px);
  overflow-y: auto;
  overflow-x: hidden;

  .dropdowntable {
    border-right: 0;

    .addrRow {
      /* height: ${(props) => props.height}; */
      display: flex;
      td {
        display: flex;
        align-items: center;
        p {
          display: inline;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }
      }
    }
    #riskBar0 {
      border-image: linear-gradient(
          to top,
          hsl(0deg 0% 70%),
          hsl(252deg 35% 29%)
        )
        1 90%;
    }
    .riskBar {
      border-left: 0.5px solid hsl(0deg 0% 70%);
      padding-left: 0px;
      svg {
        background: white;
        margin-right: 10px;
      }
    }
  }
`;

export const GaugeContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .info {
    display: flex;
    width: 100%;
    justify-content: space-around;
    figure {
      width: 140%;
    }
    .riskLabels {
      margin-left: 10px;
      width: 100%;
      margin-top: 0.6em;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .labelContainer {
        display: flex;
        .labelBox {
          width: 12px;
          height: 10px;
          border-radius: 10px;
        }
        p {
          margin: 0 6px 2px;
          font-size: xx-small;
        }
      }
    }
  }
  .valueTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }
  .value {
    width: 100%;
    align-self: center;
    margin-top: 0.4em;
    font-size: 2em;
    line-height: 1em;
    font-weight: 900;
    font-feature-settings: 'zero', 'tnum' 1;
  }
  svg {
    overflow: visible !important; // Fixes clipped circle PRD-620
  }
`;

export const RaisedButton = styled.div`
  display: inline-block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  align-items: center;
  background: linear-gradient(135deg, hsl(256deg 37% 15%), hsl(256deg 37% 30%));
  box-shadow: -8px -4px 8px 0px hsl(256deg 37% 30%),
    8px 4px 12px 0px hsl(256deg 37% 15%);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%;
    border-radius: 50%;
    box-shadow: ${(props) =>
      props.isExpanded
        ? '-4px -2px 4px 0px hsl(256deg 37% 15%), 4px 2px 8px 0px hsl(256deg 37% 30%)'
        : '-4px -2px 4px 0px hsl(256deg 37% 30%), 4px 2px 8px 0px hsl(256deg 37% 15%)'};
    transform: scale3d(1, 1, 1);
    transition: opacity 5s ease-in-out, transform 5s ease-in-out;
  }

  h3 {
    font-size: 10px;
    margin: 10px;
    background: linear-gradient(
      135deg,
      hsl(256deg 10% 40%),
      hsl(256deg 10% 88%)
    );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    &:before {
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#000),
        to(#ccc)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  i:before {
    display: initial;
  }
`;
