class RiskMonitorConfig {
  constructor(apiData) {
    this._apiData = apiData;
  }

  get id() {
    return this._apiData._id;
  }

  get monitoredItems() {
    return this._apiData.monitored_items;
  }
}

export default RiskMonitorConfig;
