import styled from 'styled-components';
import Menu from 'rc-menu';
import { styleRefs as SR } from 'styles/styleRefs';

export const ContextMenuContainer = styled(Menu)`
  position: absolute;
  background-color: rgba(28, 20, 45, 1);
  z-index: 100;
  left: ${(props) => props.x + 5}px;
  top: ${(props) => props.y + 5}px;
  border: none;
  width: 200px;
  padding: 0;
  font-size: 11px;

  .graph-context-menu-item {
    color: white;
    justify-content: flex-start;
    align-items: center;
    padding: 15;
    display: flex;
    height: 20px;
    cursor: pointer;
  }
  .rc-menu-item-active {
    background: ${SR.colors.emphasis_lightPurple};
    color: white;
    font-weight: bold;
  }

  .context-icons {
    padding-right: 6px;
    padding-left: 6px;
    display: flex;
    svg {
      fill: white;
    }
  }
`;
