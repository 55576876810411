const Config = {
  reportParams: {
    incomingConcentrationMin: 0.01,
    outgoingConcentrationMin: 0.2,
    incomingConcentrationDefault: 0.01,
    outgoingConcentrationDefault: 0.8,
  },
};

export default Config;
