import React from 'react';
import { EXPERIMENTAL_FEATURES } from 'featureFlags';
import { FlowType } from 'utils/enums';
import { IndentedSection } from '../Report/SummaryPanel/styles';

// Note: Experimental feature
const EntityStatistics = ({ entityStats, flowType }) => {
  if (!EXPERIMENTAL_FEATURES.entityReports.entityStats) {
    return null;
  }

  if (entityStats == null || flowType == null || flowType !== FlowType.Entity) {
    return null;
  }

  const {
    entityName = 'N/A',
    addressCount = 'N/A',
    incomingTxCount = 'N/A',
    incomingVolume = 'N/A',
    outgoingTxCount = 'N/A',
    outgoingVolume = 'N/A',
    firstOnChainTx = 'N/A',
  } = entityStats;

  const stats = [
    { label: 'Exchange', value: entityName },
    { label: '# of addresses', value: addressCount },
    { label: '# of transactions inbound', value: incomingTxCount },
    { label: '# of transactions outbound', value: outgoingTxCount },
    { label: 'Total BTC inbound', value: incomingVolume },
    { label: 'Total BTC outbound', value: outgoingVolume },
    { label: 'First seen', value: firstOnChainTx },
  ];

  return (
    <>
      <div className='collapsable-item'>
        <div className='collapsable-header'>
          <span>ENTITY STATISTICS</span>
        </div>
        <IndentedSection>
          <table>
            <tbody>
              {stats.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.label}</td>
                  <td>{entry.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </IndentedSection>
      </div>
    </>
  );
};

export default EntityStatistics;
