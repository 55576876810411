import * as R from 'ramda';

const convertDateToExcelFormat = (dt) => {
  let record_date = Date.parse(dt);
  const days = Math.round((record_date - new Date(1899, 11, 30)) / 8.64e7) - 1;
  const mins = dt.getMinutes();
  const minRatio = mins / 60;
  const hours = dt.getHours();
  const hoursAndMinRatio = (hours + minRatio) / 24;
  record_date = days + hoursAndMinRatio;

  return record_date;
};

export const transfersToCSV = (transactions) => {
  const header = [
    'time_stamp',
    'block',
    'tx_hash',
    'asset',
    'from',
    'from_cluster',
    'from_entity',
    'to',
    'to_cluster',
    'to_entity',
    'amt',
    'amt_in_USD',
    'date_time',
  ];

  let transRows = [];
  R.forEach((tx) => {
    const trs = tx.getTransfers() || [];
    if (R.isEmpty(trs)) {
      return;
    }

    const transfersVals = trs.map((tr) => {
      tr.date_time = convertDateToExcelFormat(tr.timestamp);
      return Object.values(tr);
    });

    transRows = R.concat(transRows, transfersVals);
  })(transactions);

  return [header, ...transRows];
};
