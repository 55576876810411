module.exports.colors = {
  ' dpp_': { color: '#adcfff' },
  '': { color: '#c0c0c0', icon: '' },
  '-': { color: '#c0c0c0', icon: '' },
  'cloud mining': { color: '#00e0e0', icon: 'fas fa-cloud', code: '\uf0c2' },

  intermediary: {
    color: '#bbafa2',
    icon: 'fas fa-cloud',
    code: '\uf0c2',
  },

  'darknet commerce': {
    color: '#ded681',
    icon: 'fas fa-dungeon',
    code: '\uf6d9',
  },
  'darknet market': {
    color: '#ded681',
    icon: 'fas fa-dungeon',
    code: '\uf6d9',
  },
  'darknet markets': {
    color: '#ded681',
    icon: 'fas fa-dungeon',
    code: '\uf6d9',
  },
  'terrorism finance': {
    color: '#ded681',
    icon: 'fas fa-bomb',
    code: '\uf1e2',
  },
  'risky service': {
    color: '#ded681',
    icon: 'fas fa-exclamation-triangle',
    code: '\uf1e2',
  },
  'high risk jurisdiction': {
    color: '#ded681',
    icon: 'fas fa-exclamation-triangle',
    code: '\uf1e2',
  },
  'mining hardware': {
    color: '#00e0e0',
    icon: 'fas fa-cog',
    code: '\uf085',
  },
  mining: {
    color: '#00e0e0',
    icon: 'fas fa-cog',
    code: '\uf085',
  },
  'mining pool': {
    color: '#0a7800',
    icon: 'fas fa-cog',
    code: '\uf085',
  },
  'ofac sanctions': { color: '#800000', icon: 'fas fa-ban', code: '\uf05e' },
  sanctions: { color: '#800000', icon: 'fas fa-ban', code: '\uf05e' },

  'otc desk': { color: '#ded681', icon: 'fas fa-exchange', code: '\uf362' },
  'p2p exchange': { color: '#DA70D6', icon: 'fas fa-exchange', code: '\uf362' },
  'payment gateway': {
    color: '#7D6608',
    icon: 'fas fa-exchange',
    code: '\uf362',
  },
  'payments gateway': {
    color: '#7D6608',
    icon: 'fas fa-exchange',
    code: '\uf362',
  },
  'plexcoin ico (via shapeshift)': {
    color: '#dcdc00',
    icon: 'fas fa-seedling',
    code: '\uf4d8',
  },
  'plexcoin ico': { color: '#ff0008', icon: 'fas fa-seedling', code: '\uf4d8' },
  'record validation': {
    color: '#adcfff',
    icon: 'fas fa-book',
    code: '\uf02d',
  },
  'token project': {
    color: '#e876e5',
    icon: 'fas fa-seedling',
    code: '\uf4d8',
  },
  uncategorized: { color: '#646464', icon: 'fas fa-question', code: '\uf128' },
  'uncategorized/unknown': { color: '#c0c0c0' },
  'unknown entity': { color: '#c0c0c0' },
  'upbit hack': {
    color: '#ff0000',
    icon: 'fas fa-user-secret',
    code: '\uf21b',
  },
  atm: { color: '#b784a7', icon: 'fas fa-money-bill-wave-alt', code: '\uf53b' },
  changer: {
    color: '#edb6bc',
    icon: 'fas fa-money-bill-wave-alt',
    code: '\uf53b',
  },
  ransomware: { color: '#46e8b8', icon: 'fas fa-lock', code: '\uf023' },
  csam: { color: '#f7ebed', icon: 'fas fa-child', code: '\uf1ae' },
  custody: { color: '#89cff0', icon: 'fas fa-hand-holding', code: '\uf4bd' },
  cybercrime: { color: '#FFA500', icon: 'fas fa-user-ninja', code: '\uf504' },
  dex: { color: '#e45f85', icon: 'fas fa-exchange', code: '\uf362' },
  donation: { color: '#ce7082', icon: 'fas fa-gift', code: '\uf06b' },
  exchange: { color: '#578efa', icon: 'fas fa-exchange', code: '\uf362' },
  faucet: { color: '#9c9bdd', icon: 'fas fa-faucet', code: '\ue005' },
  gambling: { color: '#81d756', icon: 'fas fa-dice', code: '\uf522' },
  'investment scheme': {
    color: '#81d756',
    icon: 'fas fa-dice',
    code: '\uf522',
  },
  gaming: { color: '#f4c7ee', icon: 'fas fa-gamepad', code: '\uf11b' },
  government: { color: '#167c80', icon: 'fas fa-landmark', code: '\uf66f' },
  hacker: { color: '#34c8ee', icon: 'fas fa-user-secret', code: '\uf21b' },
  hyip: { color: '#a06ef5', icon: 'fas fa-signal', code: '\uf012' },
  ico: { color: '#ADFF2F', icon: 'fas fa-seedling', code: '\uf4d8' },
  investor: { color: '#b2fceb', icon: 'fas fa-chart-line', code: '\uf201' },
  lending: { color: '#17c37b', icon: 'fas fa-credit-card', code: '\uf09d' },
  marketplace: { color: '#46e8b8', icon: 'fas fa-cart-plus', code: '\uf217' },
  merchant: { color: '#f1c2b8', icon: 'fas fa-cart-plus', code: '\uf217' },
  miner: { color: '#00e0e0', icon: 'fas fa-cog', code: '\uf085' },
  misc: { color: '#3a745e', icon: 'fas fa-question', code: '\uf128' },
  mixer: { color: '#ff4d4d', icon: 'fas fa-blender', code: '\uf517' },
  coinjoin: { color: '#ff4d4d', icon: 'fas fa-coins', code: '\uf51e' },
  obfuscation: { color: '#ff4d4d', icon: 'fas fa-blender', code: '\uf517' },
  other: { color: '#646464', icon: 'fas fa-question', code: '\uf128' },
  person: { color: '#ffefe5', icon: 'fas fa-user', code: '\uf007' },
  profile: { color: '#edb6bc', icon: 'fas fa-user', code: '\uf007' },
  proxy: { color: '#ff7045', icon: 'fas fa-arrows-alt-h', code: '\uf337' },
  source: { color: '#ff0000', icon: 'fas fa-play', code: '\uf04b' },
  'flagged nodes': {
    color: '#ff0000',
    icon: 'fas fa-chevron-right',
    code: '\uf054',
  },
  spam: { color: '#776ea7', icon: 'fas fa-mail-bulk', code: '\uf674' },
  spammer: { color: '#ded681', icon: 'fas fa-trash', code: '\uf1f8' },
  terrorism: { color: '#ded681', icon: 'fas fa-bomb', code: '\uf1e2' },
  tomatocage: { color: '#32b67a', icon: 'fas fa-user-secret', code: '\uf21b' },
  tuxavant: { color: '#adcfff', icon: 'fas fa-user-secret', code: '\uf21b' },
  unknown: { color: '#c0c0c0', icon: '' },
  'wallet manager': {
    color: '#ff7045',
    icon: 'fas fa-window-maximize',
    code: '\uf2d0',
  },
};
