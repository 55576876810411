class RiskScore {
  constructor(apiData) {
    this._address = apiData.address;
    this._timestamp = apiData.date;
    this._score = apiData.score;
    this._breakdown = apiData.breakdown;
    this._asset = apiData.asset;
    this._id = apiData._id;
  }

  get asset() {
    return this._asset;
  }

  get address() {
    return this._address;
  }

  get breakdown() {
    return this._breakdown;
  }

  get score() {
    return this._score;
  }

  get timestamp() {
    return this._timestamp;
  }
}

export default RiskScore;
