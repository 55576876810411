import React, { useState, useEffect, useRef, useContext } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import {
  Col,
  Form,
  FormGroup,
  Panel,
  Overlay,
  ButtonGroup,
} from 'react-bootstrap';
import { validate } from 'wallet-address-validator';
import BarLoader from 'react-spinners/BarLoader';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import InfoIcon from 'components/Common/InfoIcon';
import CloseButton from 'components/Common/CloseButton';
import Parameters from './parameters';
import { styleRefs as SR } from 'styles/styleRefs';
import { PurpleActiveButton } from 'styles/';

import { trimLeadingTrailingWhitespace } from 'helpers/utils';
import { PurpleRadio, NewAppContainer, customSelectStyles } from './styles';

import {
  cleanAddrETH,
  cleanAddrBTC,
  cleanTxBTC,
  cleanTxETH,
  isValidAddress,
  isValidTXHash,
  guessAssetFromInput,
  AssetOption,
  DefaultAssetTypes,
  isValidEthHash,
  isEthAddress,
} from 'utils/crypto';
import Config from 'utils/config';
import { UserContext } from 'contexts/user';
import { AddressAutocompleteTooltip } from 'components/Common/AutoComplete';
import ForensicAnalysis from 'models/forensicAnalysis';

const allBtcAddresses = R.pipe(R.map(R.trim), R.all(validate));

const ReportsNew = (props) => {
  const { apiClient } = useContext(UserContext);
  const addressInputRef = useRef(null);
  const [report_name, set_report_name] = useState('');
  const [report_addresses_list, set_report_addresses_list] = useState(
    props.presetItems || [],
  );
  const [createError, setcreateError] = useState(null);
  const [is_requesting, set_is_requesting] = useState(false);
  const [reportParams, set_reportParams] = useState({
    dilution: {
      current: Config.reportParams.outgoingConcentrationDefault * 100,
      min: Config.reportParams.outgoingConcentrationMin * 100,
      max: 100,
    },
    hops: { current: '10+' },
    minTransferSize: { current: 0 }, //in base denomination
    crypto: 'btc',
    maxNode: 10000,
  });

  const [hops, set_hops] = useState(reportParams['hops'].current);
  const [dilution, set_dilution] = useState(reportParams['dilution'].current);
  const [isIntelligent, setIsIntelligent] = useState(true);

  const [autocompleteObj, setAutocompleteObj] = useState({});
  const [curAddressPrefix, setCurAddressPrefix] = useState(null);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [numDupes, setNumDupes] = useState(0);
  const [isSourceflow, setIsSourceflow] = useState(true);
  const [isFullCluster, set_isFullCluster] = useState(true);
  const [assetsList, setAssetsList] = useState(DefaultAssetTypes);
  const [selectedAsset, setSelectedAsset] = useState(AssetOption.Btc);
  const [randAddrIdx, setRandAddrIdx] = useState(0);
  const [reportNameErr, setReportNameErr] = useState('');
  const [addressListErr, setAddressListErr] = useState('');

  // ensures cluster option doesn't show up since transactions always run as clusters
  const isBtcAndHasNoTxs =
    selectedAsset &&
    selectedAsset.value &&
    selectedAsset.value.toLowerCase() === 'btc' &&
    allBtcAddresses(report_addresses_list.filter((rA) => rA !== ' '));

  const clearErrors = () => {
    setReportNameErr('');
    setAddressListErr('');
    setcreateError(null);
    setNumDupes(0);
  };

  useEffect(() => {
    const getAutocompleteList = async (addressPrefix) => {
      // skip API call if memoized;
      // only make API call if user has input >= 4 characters
      if (curAddressPrefix && !(curAddressPrefix in autocompleteObj)) {
        const newAutocompleteObj = await apiClient
          .getAddressAutocompletion(addressPrefix, isSourceflow)
          .then((autocompletes) => {
            // maybe if there is only one result, no dropdown

            autocompletes.sort();
            const ac = R.clone(autocompleteObj);
            ac[addressPrefix] = autocompletes;
            setAutocompleteObj(ac);
            return ac;
          });

        // call was successful and there's a match in the DB
        // and addressPrefix matches the only address in the returned list
        // then autoselect
        const addresses = report_addresses_list.filter(
          (addr) => addr?.trim()?.length,
        );
        const address = addresses.length ? addresses[addresses.length - 1] : '';
        if (
          curAddressPrefix in newAutocompleteObj &&
          newAutocompleteObj[curAddressPrefix].length === 1 &&
          curAddressPrefix === newAutocompleteObj[curAddressPrefix][0] &&
          curAddressPrefix !== address
        ) {
          handleAutocompleteAddressClick(curAddressPrefix, false);
        }
      }
    };

    // fetch tokens that addresses have interacted with
    const getEthAssetsList = async (addressesList) => {
      const ethAddrs = addressesList.filter((addrOrHash) => {
        const addrOrHashClean = addrOrHash.trim();
        return isValidEthHash(addrOrHashClean) || isEthAddress(addrOrHashClean);
      });
      let assets = [];
      if (ethAddrs.length) {
        assets = await apiClient
          .getEthAssets(ethAddrs, isSourceflow)
          .then((assets) => {
            return assets.map((a) => ({
              value: a.address.toLowerCase(),
              label: a.symbol.toLowerCase(),
            }));
          })
          .catch((err) => {
            console.error('[getEthAssets] fail to fetch user eth assets', err);
            return [];
          });
      }

      // always include native asset
      const ETH = AssetOption.Eth.value;
      const addEth = assets.find((asset) => asset.label === ETH) == null;
      if (addEth) {
        assets.push(AssetOption.Eth);
      }

      // ensure previously selected asset is still available in new list
      const currentAsset = selectedAsset.label;
      const setEth =
        assets.find((asset) => asset.label === currentAsset) == null;
      if (setEth) {
        setSelectedAsset(AssetOption.Eth);
      }

      setAssetsList(assets);
    };

    getAutocompleteList(curAddressPrefix);

    const addresses = report_addresses_list.filter(
      (address) => address.trim().length > 1,
    );

    // base case - show both BTC and Ethereum
    if (!addresses.length) {
      setAssetsList(DefaultAssetTypes);
      return;
    }

    // first address or hash is Ethereum
    const firstAddressAsset = guessAssetFromInput(report_addresses_list[0]);
    if (firstAddressAsset === AssetOption.Eth.value) {
      const asset = selectedAsset.value;
      if (asset === AssetOption.Btc.value) {
        setSelectedAsset(AssetOption.Eth);
      }
      getEthAssetsList(report_addresses_list);
      return;
    }

    // first address or hash is BTC
    setAssetsList([AssetOption.Btc]);
    setSelectedAsset(AssetOption.Btc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_addresses_list, isSourceflow]);

  useEffect(() => {
    setShowAutocomplete(!!(curAddressPrefix && curAddressPrefix.length));
  }, [curAddressPrefix]);

  const handleAutocompleteAddressClick = (address) => {
    const truncatedList = report_addresses_list.slice(0, -1);
    truncatedList.push(address);
    set_report_addresses_list(truncatedList);
    setCurAddressPrefix(null);
    // clear memoization once selected
    setAutocompleteObj({});
    addressInputRef.current.focus();
  };

  const handleAddressListChange = (event) => {
    clearErrors();

    // handle direct call with a list of addresses
    const newAddresses = event.target ? event.target.value : event;

    // handle comma and space-delimited
    const newAddressListCommaSplit = newAddresses.split(',');
    const newAddressListNewlineSplit = newAddressListCommaSplit.reduce(
      (acc, elem) => {
        const splt = elem.split('\n');
        return [...acc, ...splt];
      },
      [],
    );

    const newAddressListUntrimmed = newAddressListNewlineSplit.reduce(
      (acc, elem) => {
        const splt = elem.split(' ');
        return [...acc, ...splt];
      },
      [],
    );

    // handle adding comma and space between elements
    let newAddressList = newAddressListUntrimmed
      .filter(
        (x, idx) => !(x === '' && idx !== newAddressListUntrimmed.length - 1),
      )
      .map((x, idx) => (idx === 0 || x === '' ? x : ' ' + x))
      .map((x) => x.replace(/['"]+/g, ''));

    // handle adding comma and space between elements
    const blankIdx = newAddressList.indexOf('');
    if (blankIdx >= 0) {
      newAddressList = newAddressList.slice(0, blankIdx + 1);
      newAddressList[blankIdx] = ' ';
    }

    set_report_addresses_list(newAddressList);

    let addressPrefix;
    if (newAddressList.length) {
      addressPrefix = newAddressList[newAddressList.length - 1];
      addressPrefix = trimLeadingTrailingWhitespace(addressPrefix);
    } else {
      addressPrefix = '';
    }

    if (addressPrefix.length && addressPrefix.length >= 4) {
      setCurAddressPrefix(addressPrefix);
    } else {
      // get rid of dropdown if input is not long enough
      setCurAddressPrefix(null);
    }
  };

  const handleReportNameChange = (event) => {
    const target = event.target;
    set_report_name(target.value);
    clearErrors();
  };

  const chooseRandomAddress = () => {
    handleAddressListChange(EXAMPLE_ADDRESSES[randAddrIdx]);
    setRandAddrIdx((randAddrIdx + 1) % EXAMPLE_ADDRESSES.length);
  };

  const handleSubmit = (event) => {
    // stops full reload
    event.preventDefault();

    if (!report_name || !report_name.trim().length) {
      setReportNameErr('Please enter a name for this report.');
      return;
    }

    const noAddresses =
      report_addresses_list == null ||
      report_addresses_list.length === 0 ||
      report_addresses_list[0].trim() === '';

    if (noAddresses) {
      setAddressListErr('Please enter an address or transaction hash.');
      return;
    }

    // failsafe - valid asset type
    if (!selectedAsset?.value?.length) {
      setAddressListErr('Please select a valid asset type.');
      return;
    }

    // handle invalid addresses and duplicate addresses
    const noBlankItems = report_addresses_list
      .map((x) => trimLeadingTrailingWhitespace(x))
      .filter((a) => a.length);

    // check if any of the inputs is invalid
    for (let i = 0; i < noBlankItems.length; i++) {
      const input = noBlankItems[i];
      if (!isValidAddress(input) && !isValidTXHash(input)) {
        setAddressListErr('Found an invalid address or transaction hash.');
        return;
      }
    }

    const btcAddresses = cleanAddrBTC(noBlankItems);
    const ethAddresses = cleanAddrETH(noBlankItems);
    const btcTxs = cleanTxBTC(noBlankItems);
    const ethTxs = cleanTxETH(noBlankItems);

    // this is what is left after checking each entry is valid address/tx hash
    const emptyInput =
      btcAddresses.length + ethAddresses.length + btcTxs.length + ethTxs.length;
    if (emptyInput === 0) {
      setAddressListErr('No valid addresses or transaction hashes detected.');
      set_is_requesting(false);
      return null;
    }

    // only allow single address/tx hash type (ie: disallow mixing assets and/or types)
    if (
      (btcAddresses.length > 0) +
        (ethAddresses.length > 0) +
        (btcTxs.length > 0) +
        (ethTxs.length > 0) >
      1
    ) {
      setAddressListErr(
        'All items must be of the same asset type (BTC, ETH, token) and format (address, transaction hash).',
      );
      set_is_requesting(false);
      return null;
    }

    // this works because only one can have length > 0
    const cleanItems = btcAddresses
      .concat(ethAddresses)
      .concat(btcTxs)
      .concat(ethTxs);

    // remove duplicates
    const dedupedSet = new Set(cleanItems);
    const deduped = [...dedupedSet];

    // show number of duplicate addresses
    const numDupes = cleanItems.length - deduped.length;
    setNumDupes(numDupes);

    // check to ensure values are being input correctly
    reportParams.hops.current = hops.toString();
    reportParams.dilution = {
      current: (reportParams.dilution.current / 100).toString(),
    };

    set_is_requesting(true);
    clearErrors();

    apiClient
      .createForensicAnalysis(
        report_name,
        deduped,
        isFullCluster,
        reportParams,
        isSourceflow ? -1 : 1,
        selectedAsset.value,
      )
      .then((res) => {
        res = ForensicAnalysis.normalizeReportMeta(res);
        set_is_requesting(false);
        props.toggleModal();

        // add pending report to report list immediately before reloading with results
        // conditioned on whether this is a report coming from the report list page (and not the graph page)
        if (props.addNewReport) {
          props.addNewReport(res);
          props.reloadList();
        }

        return null;
      })
      .catch((error_res) => {
        const errorMsg = error_res.error.message
          .toLowerCase()
          .includes('failed to fetch')
          ? 'Network too large. Please contact us for more info.'
          : error_res.error.message;
        setcreateError(errorMsg);
        set_is_requesting(false);
      });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      const parsed = reader.result
        .split('\n')
        .map((val) => val.replace(/['"]+/g, ''));

      handleAddressListChange(parsed.toString());
    };
    reader.readAsText(file);

    clearErrors();
  };

  const AddressAutocomplete = (props) => {
    const autocompleteOptions = autocompleteObj[curAddressPrefix];

    return (
      <AddressAutocompleteTooltip
        props={props}
        autocompletelist={autocompleteOptions}
        onClickHandler={handleAutocompleteAddressClick}
        query={curAddressPrefix}
        noMatchingResultsLabel='No addresses matching this prefix...'
      ></AddressAutocompleteTooltip>
    );
  };

  return (
    <NewAppContainer>
      <section className='modal-heading'>
        <h1 className='modal-title'>New Pulse Report</h1>
        <InfoIcon
          infoText={`
                  Elementus Pulse follows the crypto inflows from, and the outflows to,
                  specified addresses of interest across various assets. Input any number of 'source'
                  addresses or transaction hashes below to get started. You can expand manually starting only with
                  the source address(es), or you can do an 'intelligent' expansion--filtered by
                  the association with the source address(es) (dilution) and the number of hops out from the origin source.`}
        />
        <CloseButton onClose={() => props.toggleModal()} />
      </section>
      <Panel.Body>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormGroup controlId='formBasicText'>
            <div className='text-field-container'>
              <div className='field-title'>
                Report Name
                <div className='button-toggle'>
                  <ButtonGroup aria-label='sourceflow-sinkflow-btn-group'>
                    <PurpleActiveButton
                      type='button'
                      onClick={() => {
                        setIsSourceflow(true);
                        set_dilution(
                          Config.reportParams.outgoingConcentrationDefault *
                            100,
                        );
                      }}
                      className={
                        isSourceflow ? 'flux-btn left' : 'flux-btn passive left'
                      }
                    >
                      Outgoing
                    </PurpleActiveButton>
                    <PurpleActiveButton
                      type='button'
                      onClick={() => {
                        setIsSourceflow(false);
                        set_dilution(
                          Config.reportParams.incomingConcentrationDefault *
                            100,
                        );
                      }}
                      className={
                        !isSourceflow
                          ? 'flux-btn right'
                          : 'flux-btn passive right'
                      }
                    >
                      Incoming
                    </PurpleActiveButton>
                  </ButtonGroup>
                  <ButtonGroup aria-label='sourceflow-sinkflow-btn-group'>
                    <PurpleActiveButton
                      type='button'
                      onClick={() => {
                        set_hops(10);
                        setIsIntelligent(true);
                      }}
                      className={
                        hops !== 0 ? 'flux-btn left' : 'flux-btn passive left'
                      }
                    >
                      Intelligent
                    </PurpleActiveButton>
                    <PurpleActiveButton
                      type='button'
                      onClick={() => {
                        set_hops(0);
                        setIsIntelligent(false);
                      }}
                      className={
                        hops === 0
                          ? 'flux-btn right '
                          : 'flux-btn passive right '
                      }
                    >
                      Manual
                    </PurpleActiveButton>
                  </ButtonGroup>
                </div>
              </div>

              <TextField
                autoFocus
                error={!!reportNameErr}
                helperText={reportNameErr}
                inputProps={{ maxLength: 255, spellCheck: false }}
                name='report_name'
                value={report_name}
                onChange={handleReportNameChange}
                variant='filled'
                placeholder='Enter a name for your report.'
              />
            </div>
            <div className='text-field-container address-input'>
              <div className='field-title'>
                Source Addresses or Transaction Hashes
              </div>

              <TextField
                error={!!addressListErr}
                helperText={addressListErr}
                style={{ height: '150px' }}
                maxRows={4}
                inputRef={addressInputRef}
                inputProps={{
                  spellCheck: false,
                }}
                InputLabelProps={{ style: { fontSize: 12, height: '150px' } }}
                id='addresslist'
                name='report_addresses_list'
                value={report_addresses_list}
                onChange={handleAddressListChange}
                variant='filled'
                placeholder='Enter one or more addresses or transaction hashes to trace the flow of funds. Autocomplete starts after 4 characters.'
                multiline
              />

              {curAddressPrefix &&
              autocompleteObj[curAddressPrefix] &&
              autocompleteObj[curAddressPrefix].length ? (
                <Overlay
                  placement='bottom'
                  target={addressInputRef.current}
                  show={showAutocomplete}
                  rootClose={true}
                  onHide={() => setShowAutocomplete(false)}
                >
                  <AddressAutocomplete />
                </Overlay>
              ) : (
                <> </>
              )}
            </div>
            <div className='load-buttons'>
              <div>
                <label htmlFor='file-upload' className='custom-file-upload'>
                  <i className='fa fa-cloud-upload'></i> Upload CSV
                </label>
                <input
                  type='file'
                  name='file'
                  id='file-upload'
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />
              </div>

              {isBtcAndHasNoTxs && (
                <div className='Cluster_Optionality_Radio'>
                  <div>
                    <PurpleRadio
                      checked={isFullCluster}
                      onChange={() => set_isFullCluster(true)}
                      value='fullCluster'
                      name='radio-button-demo'
                    />
                    <p>Run as Wallets</p>
                  </div>
                  <div>
                    <PurpleRadio
                      checked={!isFullCluster}
                      onChange={() => set_isFullCluster(false)}
                      value='singleAddress'
                      color='primary'
                      name='radio-button-demo'
                    />
                    <p>Run as Addresses</p>
                  </div>
                </div>
              )}

              <Col>
                {selectedAsset ? (
                  <Select
                    value={selectedAsset}
                    onChange={(x) => {
                      setSelectedAsset(x);
                      clearErrors();
                    }}
                    options={assetsList}
                    styles={customSelectStyles}
                  />
                ) : (
                  <></>
                )}

                <PurpleActiveButton
                  onClick={() => chooseRandomAddress()}
                  className='random-btn'
                  type='button'
                >
                  Example Address
                </PurpleActiveButton>
              </Col>
            </div>
          </FormGroup>

          {isIntelligent ? (
            <Parameters
              isSourceflow={isSourceflow}
              set_reportParams={set_reportParams}
              reportParams={reportParams}
              hops={hops}
              set_hops={set_hops}
              dilution={dilution}
              set_dilution={set_dilution}
            />
          ) : (
            <></>
          )}

          <RenderActions
            is_requesting={is_requesting}
            createError={createError}
            numDupes={numDupes}
            report_addresses_list={report_addresses_list}
          />
        </Form>
      </Panel.Body>
    </NewAppContainer>
  );
};

const RenderActions = ({ is_requesting, createError, numDupes }) => {
  const errorPresent = !RA.isNilOrEmpty(createError);

  return (
    <>
      {(errorPresent || numDupes > 0) && (
        <div className='alert-container'>
          {errorPresent ? (
            <div>{createError}</div>
          ) : (
            <div>Addresses excluded as duplicates: {numDupes}</div>
          )}
        </div>
      )}

      {is_requesting ? (
        <BarLoader
          css='
          width: 540px;
          margin-top: 50px;
          '
          size={20}
          color={SR.colors.nuetral_DarkPurple}
        />
      ) : (
        <PurpleActiveButton
          title='submit'
          type='submit'
          className='submit-btn'
          style={{ fontSize: SR.fontSize.med }}
        >
          Submit
        </PurpleActiveButton>
      )}
    </>
  );
};

ReportsNew.propTypes = {};

// NOTE: Placeholder list
// ideally instead of hardcoding, we can make some API call to get a random address
// using filter criteria that ensures that the address will work on the platform.
// TODO make api call to retrieve random address from mongo. write endpoint in api server.
const EXAMPLE_ADDRESSES = [
  '3QxkF8PWg9H93pATr4jU6pRkrimW2Vyxhj',
  '3Au8ZodNHPei7MQiSVAWb7NB2yqsb48GW4',
  '13sCVM5JLGMdCReom6g22wmGRr5BoHMFrJ',
  '15MMk9CQFb9fWRdayaLLpdJkx3g7ape7yD',
];

export default ReportsNew;
