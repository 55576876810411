import React, { useState, useEffect } from 'react';
import { ViewContainer } from '../../styles/';
import {
  FAQWindow,
  SectionButton,
  FAQButtonSection,
  ExplanatoryTextSection,
  ExplanatoryTextQuestion,
  ExplanatoryTextResponse,
  FAQPanelBody,
} from './styles';
import content from './content.js';
import NavBar from '../NavBar';
import ReactHtmlParser from 'react-html-parser';
import { styleRefs as SR } from 'styles/styleRefs';

import FunctionsIcon from '@material-ui/icons/Functions';

import { BsArrowBarRight } from 'react-icons/bs';
import { BsChevronBarRight } from 'react-icons/bs';

const FAQ = () => {
  const [sectionClicked, setSectionClicked] = useState(false);
  const [questionClicked, setQuestionClicked] = useState(false);

  useEffect(() => {
    setSectionClicked(Object.keys(content)[0]);
  }, []);

  const toggleQuestion = (question) => {
    if (question === questionClicked) setQuestionClicked(false);
    else setQuestionClicked(question);
  };

  const handleSectionClicked = (e) => {
    if (sectionClicked === e.currentTarget.textContent)
      setSectionClicked(false);
    else setSectionClicked(e.currentTarget.textContent);
  };

  const IconLookUp = (key, color) => {
    switch (key) {
      case 'Algorithm':
        return <FunctionsIcon style={{ fill: color }} />;
      case 'Current Features':
        return <BsChevronBarRight style={{ fill: color }} fontSize='large' />;
      case 'Future Features':
        return <BsArrowBarRight style={{ fill: color }} fontSize='large' />;
      default:
        return '';
    }
  };

  return (
    <ViewContainer>
      <NavBar view='list' />
      <FAQWindow>
        <FAQPanelBody>
          <FAQButtonSection>
            {Object.keys(content).map((FAQsectionName) => {
              return (
                <SectionButton
                  key={FAQsectionName}
                  onClick={(e) => handleSectionClicked(e)}
                  style={{
                    color: sectionClicked === FAQsectionName ? '#fff' : 'black',
                    backgroundColor:
                      sectionClicked === FAQsectionName
                        ? SR.colors.emphasis_lightPurple
                        : SR.colors.offWhite,
                    opacity:
                      sectionClicked && sectionClicked !== FAQsectionName
                        ? 0.5
                        : 1,
                  }}
                >
                  {IconLookUp(
                    FAQsectionName,
                    sectionClicked === FAQsectionName ? '#fff' : 'black',
                  )}
                  {FAQsectionName}
                </SectionButton>
              );
            })}
          </FAQButtonSection>
          <ExplanatoryTextSection
            style={{
              opacity: sectionClicked ? 1 : 0,
            }}
          >
            {sectionClicked
              ? Object.keys(content[sectionClicked]).map((question) => {
                  return (
                    <div key={question}>
                      <ExplanatoryTextQuestion
                        onClick={() => toggleQuestion(question)}
                      >
                        {question}
                      </ExplanatoryTextQuestion>
                      <ExplanatoryTextResponse
                        style={{
                          display: questionClicked === question ? '' : 'none',
                        }}
                      >
                        {content[sectionClicked][question]['text'].map(
                          (text, i) => (
                            <span key={question + i}>
                              {ReactHtmlParser(text)}
                              <br />
                            </span>
                          ),
                        )}
                        <br />
                        {content[sectionClicked][question]['gifs'].map(
                          (gifLink) => (
                            <img
                              key={gifLink}
                              height='360px'
                              width='425px'
                              src={gifLink}
                              alt={question}
                            />
                          ),
                        )}
                      </ExplanatoryTextResponse>
                    </div>
                  );
                })
              : ''}
          </ExplanatoryTextSection>
        </FAQPanelBody>
      </FAQWindow>
    </ViewContainer>
  );
};

export default FAQ;
