import React from 'react';
import AddressList from './address_list';
import {
  EditIconS,
  FailNote,
  PopoverS,
  CenteredFormatterContainer,
} from './styles';
import { OverlayTrigger } from 'react-bootstrap';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { styleRefs as SR } from 'styles/styleRefs';
import assetFormatter from 'components/Report/Table/formatters/asset';

// maps statusType to summary color
const SummaryColor = {
  success: '#5bc0de',
  error: '#d9534e',
  initialized: 'green',
};

// maps statusType to info icon color
const InfoColorClass = {
  error: 'info-icon-fail',
  initialized: 'info-icon-success',
  warning: 'info-icon-warn',
};

const nameFormatter = (name, row) => {
  if (!name || name.length === 0) {
    return <div className='ellipsis-cell'>-</div>;
  }
  return (
    <div className='ellipsis-cell report-name'>
      {row.isEditable && <EditIconS className='info-icon-edit' />}
      {name}
    </div>
  );
};

const targetFormatter = (targets) => {
  if (targets.length === 0) {
    targets.push('-');
  }
  return (
    <div className='ellipsis-cell' title={targets.join(', ')}>
      {targets.join(', ')}
    </div>
  );
};

const targetsListFormatter = (targets, row) => {
  return (
    <div>
      {targets.length ? (
        <AddressList
          items={targets}
          isAddresses={row.targetTypes.includes('address')}
        />
      ) : (
        '-'
      )}
    </div>
  );
};

const timestampFormatter = (timestamp) => {
  let formattedTimestamp = '-';
  if (timestamp) {
    formattedTimestamp = dayjs(timestamp).format('YYYY-MM-DD HH:mm');
  }
  return <div className='created-cell'>{formattedTimestamp}</div>;
};

const resultFormatter = (result) => {
  const noteColor = SummaryColor[result.statusType];
  const infoColorClass = InfoColorClass[result.popContentType];
  const popOverlay = !result.popContent ? (
    <></>
  ) : (
    <PopoverS id={'report-note'} title={result.popContentTitle}>
      {result.popContent}
    </PopoverS>
  );

  // can't show link if report is building, or an error occured
  const reportLink =
    result.link &&
    result.statusType !== 'error' &&
    result.statusType !== 'initialized' &&
    result.summary !== '0% Risk Score' ? (
      <Link to={result.link} className='flux-btn'>
        View
      </Link>
    ) : null;

  return (
    <CenteredFormatterContainer>
      <OverlayTrigger
        rootClose
        trigger='click'
        placement='bottom'
        overlay={popOverlay}
      >
        <FailNote color={noteColor} style={{ textAlign: 'center' }}>
          {!!result.popContent && (
            <InfoIcon
              title='info'
              className={`info-icon-report ${infoColorClass}`}
            >
              <div className='iconbehind' />
            </InfoIcon>
          )}
          {result.summary ? result.summary : null}
        </FailNote>
      </OverlayTrigger>
      {reportLink}
    </CenteredFormatterContainer>
  );
};

const shareReportFormatter = ({ row, setIsShareOpen, setSelectedReport }) => {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <IconButton
        style={{ margin: 'auto ' }}
        variant='contained'
        size='small'
        onClick={() => {
          setIsShareOpen(true);
          setSelectedReport(row.report);
        }}
      >
        <GroupIcon style={{ fill: SR.colors.emphasis_lightPurple }} />
      </IconButton>
    </div>
  );
};

const deleteReportFormatter = ({ row, setIsDeleteOpen, setSelectedReport }) => {
  const disabled = !row.isDeleteAllowed;
  const iconColor = disabled
    ? SR.colors.mutedWhitePurple
    : SR.colors.emphasis_lightPurple;
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <IconButton
        style={{ margin: 'auto ' }}
        variant='contained'
        size='small'
        disabled={disabled}
        onClick={() => {
          setIsDeleteOpen(true);
          setSelectedReport(row.report);
        }}
      >
        <DeleteForeverIcon style={{ fill: iconColor }} />
      </IconButton>
    </div>
  );
};

const formatters = {
  deleteReportFormatter,
  nameFormatter,
  resultFormatter,
  targetFormatter,
  targetsListFormatter,
  timestampFormatter,
  shareReportFormatter,
  assetFormatter,
};

export default formatters;
