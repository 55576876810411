import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { styleRefs as SR } from 'styles/styleRefs';

const drawerWidth = 240;

export const NavBarContainer = styled.div`
  svg.MuiSvgIcon-root {
    fill: white;
    cursor: pointer;
    width: 1.3em;
    height: 1.3em;
  }

  div.makeStyles-drawerClose-8,
  div.jss8 {
    width: 57px;
  }
  div.MuiPaper-root {
    background: ${SR.colors.nuetral_DarkPurple};
    overflow: hidden;
    border-right: 0;
  }
  svg.MuiSvgIcon-root {
    // fill: white;
    cursor: pointer;
    width: 1.3em;
    height: 1.3em;
  }

  .menubutton:hover {
    color: darkgray;
  }

  .menubutton2:hover {
    color: darkgray;
  }

  .minidrawer.light-col div.MuiPaper-root {
    background: ${SR.colors.offWhite};
    color: ${SR.colors.nuetral_DarkPurple};
  }
  .minidrawer.light-col svg.MuiSvgIcon-root {
    fill: ${SR.colors.nuetral_DarkPurple};
  }
  .minidrawer.light-col .openmenu {
    background: ${SR.colors.offWhite};
  }
  .minidrawer.light-col span.MuiTypography-root {
    color: ${SR.colors.nuetral_DarkPurple};
  }

  .elemLogo {
    position: fixed;
    bottom: 34px;
    width: 24px;
    height: auto;
    left: 12px;
    z-index: 2000;
  }

  .openmenu {
    background: ${SR.colors.nuetral_DarkPurple};
    /*background: yellow;*/
    width: 57px;
    position: fixed;
    height: 64px;
    z-index: 1600;
    left: 0px;
    top: 0;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 0.01071em;
    box-sizing: border-box;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    justify-content: flex-end;
  }
  .makeStyles-drawerOpen-7 {
    z-index: 1800;
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRight: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '57px !important',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
