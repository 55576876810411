import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { MonitoredAddressesContainer } from './styles';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { isValidAddress } from 'utils/crypto';

const RiskMonitorAdmin = ({ addAddress }) => {
  const [inputAddress, setInputAddress] = useState('');
  const [validatedAddress, setValidatedAddress] = useState(null);

  useEffect(() => {
    if (isValidAddress(inputAddress)) {
      setValidatedAddress(inputAddress);
    } else {
      setValidatedAddress(null);
    }
  }, [inputAddress]);

  const onAddressInputChange = (e) => {
    setInputAddress(e.target.value);
  };

  const onAddAddressClick = () => {
    addAddress(inputAddress);
    setInputAddress('');
  };

  return (
    <MonitoredAddressesContainer>
      <div className='ma-wrapper'>
        <form className='ma-form'>
          <TextField
            id='risk-monitor-admin-input'
            className='rma-input'
            inputProps={{ maxLength: 48, spellCheck: false }}
            name='riskMonitorAdminAddressInput'
            value={inputAddress}
            onChange={onAddressInputChange}
            placeholder={'Enter an address'}
            variant='outlined'
          />
          <Button
            className='btn-ma'
            onClick={onAddAddressClick}
            disabled={validatedAddress === null}
          >
            Monitor address
          </Button>
        </form>
      </div>
    </MonitoredAddressesContainer>
  );
};

export default RiskMonitorAdmin;
