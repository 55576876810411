import styled from 'styled-components';
import { Panel as BootstrapPanel } from 'react-bootstrap';
import { styleRefs as SR } from 'styles/styleRefs';

export const Panel = styled(BootstrapPanel)`
  .panel-default > .panel-heading {
  }
  .panel-heading {
    background-color: ${SR.colors.offWhite} !important;
    h1 {
      font-size: 2em;
    }
    h3 {
      font-size: 1.5em;
    }
  }
  .panel-body {
    background-color: white;
    border-radius: 4px;
    &.main-body {
      background-color: ${SR.colors.offWhite};
    }
  }
`;
