import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { styleRefs as SR } from 'styles/styleRefs';

export const ModButton = withStyles({
  root: {
    backgroundColor: SR.colors.emphasis_lightPurple,
    borderColor: SR.colors.emphasis_lightPurple,
    fontSize: SR.fontSize.sml,
    position: 'absolute',
    right: '16px',
    top: '13px',
    height: '24.5px',
    '&:hover': {
      backgroundColor: '#6231cc',
      borderColor: SR.colors.emphasis_lightPurple,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: SR.colors.emphasis_lightPurple,
      borderColor: '#25124d',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    '.MuiButton-label': {
      position: 'relative',
      top: '-1px',
    },
  },
})(Button);
