import styled from 'styled-components';

export const AddressColumnStyle = styled.div`
  .address {
    margin-bottom: 0.5em;
  }
  .btn-copy {
    color: #fff;
  }
  .btn-delete {
    color: #f00;
  }
  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
