import React from 'react';

const UnhideIcon = () => {
  return (
    <div className='context-icons'>
      <svg id='Layer_1' x='0px' y='0px' height='12px' viewBox='0 0 36 36'>
        <g>
          <g>
            <path
              d='M33,16.7l-2.9-2.1c-7.2-5.2-16.9-5.2-24-0.1l-2.9,2.1l0,0c-1,0.7-1.6,1.8-1.6,3s0.6,2.3,1.6,2.9L6,24.6
			c3.6,2.5,7.8,3.9,12,3.9s8.4-1.3,12-3.9l2.9-2.1c1-0.7,1.6-1.8,1.6-2.9S33.9,17.3,33,16.7z M31.5,20.6l-2.9,2.1
			c-6.3,4.5-14.8,4.5-21.1,0l-2.9-2.1c-0.4-0.2-0.5-0.6-0.5-1c0-0.4,0.2-0.7,0.5-1l2.9-2.1c6.3-4.5,14.8-4.5,21.1,0l2.9,2.1
			c0.4,0.2,0.5,0.6,0.5,1C32,20,31.9,20.3,31.5,20.6z'
            />
            <rect x='16.8' y='2.6' width='2.4' height='4.9' />
            <rect
              x='25.8'
              y='8.1'
              transform='matrix(0.7071 -0.7071 0.7071 0.7071 1.7291 22.7797)'
              width='5.1'
              height='2.4'
            />
            <rect
              x='6.5'
              y='6.8'
              transform='matrix(0.7071 -0.7071 0.7071 0.7071 -4.3105 8.1986)'
              width='2.4'
              height='5.1'
            />
            <path
              d='M18.1,14.8c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S20.7,14.8,18.1,14.8z M18.1,22
			c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4S19.4,22,18.1,22z'
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
export default UnhideIcon;
