import React, { useContext } from 'react';
import { store } from 'ReportData/ReportDataStore';
import SummaryPanel from './SummaryPanel/summaryPanel';
import { useParams, Redirect } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { GraphProvider } from 'hooks/useGraph';
import { useAuth0 } from '@auth0/auth0-react';
import NavBar from '../NavBar';
import { ReportType } from 'utils/enums';
import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';
import { EntityBenchmarkSection } from 'components/ExperimentalFeatures/EntityBenchmarkSection';
import { EntityFAB } from './EntityFAB';

export const EntityReport = () => {
  const { id } = useParams();
  const { state: globalState } = useContext(store);
  const { getAccessTokenSilently } = useAuth0();

  return (
    <>
      {!globalState.loading && !globalState.error && (
        <NavBar view={ReportType.Compliance} />
      )}
      <EntityFAB />
      <EntityReportFrame className='container'>
        {!globalState.loading && !globalState.error && (
          <GraphProvider
            id={id}
            token={getAccessTokenSilently}
            ReportData={globalState.ReportData}
          >
            <SummaryPanel />
          </GraphProvider>
        )}
        <EntityBenchmarkSection />
        {globalState.loading && !globalState.error && (
          <ReactLoading
            type='bubbles'
            color='gray'
            height={'10%'}
            width={'10%'}
            id='loading-animation'
          />
        )}
        {globalState.error && <Redirect to='/not_found'>404</Redirect>}
      </EntityReportFrame>
    </>
  );
};

const EntityReportFrame = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - ${SR.components.navbar.width});
  left: ${SR.components.navbar.width};
  height: auto;
  overflow-y: auto;
  max-width: 970px;

  .risk-breakdown {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;

    .risk-gauge,
    #risk-sources,
    #kyc-aml,
    #score-breakdown {
      width: 50%;
    }
  }
`;
