export const FlowType = {
  Source: -1,
  Sink: 1,
  Radix: 2,
  Entity: 3,
};

export const ReportType = {
  Compliance: 'Compliance',
  Incoming: 'Incoming',
  Outgoing: 'Outgoing',
};

export const Product = {
  DASHBOARDS: 'DASHBOARDS',
  RADIX: 'RADIX',
  RISK_API: 'RISK_API',
  ENTITY_REPORTS: 'ENTITY_REPORTS',
  FLUX: 'FLUX',
};
