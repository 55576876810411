import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

export const ReportContainer = styled.div`
  height: 100vh;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  display: flex;
  justify-content: center;

  .report-creation-btn-wrapper {
    display: flex;
    flex-direction: row;
  }

  .created-cell {
    text-align: center;
  }

  .ellipsis-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  #report-frame-new,
  #report-frame-list {
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    vertical-align: middle;
    justify-content: center;
  }

  #report-frame-new {
    background-color: transparent;
    opacity: 1;
  }

  #report-frame-list {
    height: 100vh;
    width: 100vw;
    background-color: #1c142d;
    padding-top: 40px;

    div {
      .progress {
        margin-bottom: 0;
      }
    }
    tbody > tr > td {
      vertical-align: middle;
      border: 0.5px solid #fff;
      background-color: rgba(255, 255, 255, 0.6);
    }

    .report-name {
      display: flex;
      svg {
        margin: auto 8px auto 4px;
        &:hover {
          cursor: pointer;
          path {
            fill: ${SR.colors.nuetral_DarkPurple};
          }
        }
      }
    }
  }

  #report-frame-list .react-bs-table-container table td svg:hover,
  #report-frame-list .react-bs-table-container table thead th svg:hover {
    cursor: pointer;
  }

  #report-frame-new-flex {
    align-self: start;
    width: 100%;
    height: 100%;
    display: flex;
    vertical-align: middle;
    justify-content: center;
  }

  #report-frame-new .panel-container {
    width: 600px;
    align-self: center;
  }
  #report-frame-list .panel-container {
    max-width: 1350px;
    width: 92vw;
    align-self: center;
    max-height: calc(100vh - 80px);
  }
  .report-frame-new-list .panel-container .panel-primary {
    position: relative;
    left: 28px;
    background-color: #2c2249;
  }
  .report-frame-new-list.light-col .panel-container .panel-primary {
    background-color: #e2deeb;
    margin: 0px;
  }

  .report-frame-new-list .text-field-container > .MuiFormControl-root {
    width: 100%;
    max-height: 140px;
    overflow-y: auto;
    height: auto;
  }

  .report-frame-new-list #addresslist {
    width: 100%;
    height: auto;
  }

  .report-frame-new-list div.MuiFilledInput-root {
    font-size: 1.5rem;
  }
  #report-frame-new.light-col div.MuiFilledInput-root {
    background-color: rgba(255, 255, 255, 0.6);
  }

  #report-frame-new .MuiFormLabel-root {
    font-size: 1.5rem;
  }

  .report-frame-new-list .panel-heading > .panel-title {
    color: black;
    font-family: 'Verlag A', 'Verlag B';
    font-size: 24px;
    opacity: 1;
    font-weight: 500;
    line-height: 1;
  }

  #report-frame-list .panel-body {
    padding: 10px 30px 10px 30px;
  }

  #report-frame-list table.table > tbody > tr > td:nth-child(2) {
    border-right: none;
    padding-right: 0;
  }
  #report-frame-list table.table > tbody > tr > td:nth-child(3) {
    border-left: none;
    padding-left: 0;
  }

  .searchbar-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .search-label {
      margin: 0;
    }
    .table-searchbar {
      width: 200px;
    }
    .btn-clear-searchbar {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0.5em;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
