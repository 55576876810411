/**
 * Stateless functions that help transform report data
 * from the API into data used by the app
 */

export const FlowType = {
  Source: -1,
  Sink: 1,
  Radix: 2,
  Entity: 3,
};

export const ReportType = {
  Compliance: 'Compliance',
  Incoming: 'Incoming',
  Outgoing: 'Outgoing',
};

const AssetConversion = { btc: 100000000, eth: 10000 };

export const getAssetConversion = (asset: string): number => {
  return AssetConversion[asset];
};

export const clusterOf = (name: string): string => {
  return name.split('_')[0];
};

export const getReportSourceType = (metadata, asset): string => {
  const targetAddresses = metadata.target_addresses;
  let toShow = '-';
  if (targetAddresses && targetAddresses.length) {
    if (asset === 'btc') {
      if (metadata.is_from_clusters) {
        toShow = 'Wallet';
      } else {
        toShow = 'Address';
      }
    } else {
      toShow = 'Address';
    }
  } else if (metadata.flow_type === FlowType.Entity) {
    toShow = 'Entity';
  } else {
    toShow = 'Transaction';
  }
  return toShow;
};

export const getReportType = (metadata): string => {
  return metadata.flow_type === FlowType.Sink
    ? ReportType.Incoming
    : metadata.flow_type === FlowType.Source
    ? ReportType.Outgoing
    : ReportType.Compliance;
};

export const isSourceflow = (reportMetadata): boolean => {
  return reportMetadata.flow_type !== undefined
    ? parseInt(reportMetadata.flow_type) === FlowType.Source
    : true;
};
