import React, { useContext } from 'react';
import './styles/verlag2.css';
import { Switch, Route } from 'react-router-dom';
import ReportsList from './components/Report/List';
import FAQ from './components/FAQ/';
import API from './components/API/';
import NavBar from 'components/NavBar';

import Use from './components/legal/use';
import Privacy from './components/legal/privacy';
import NotFound from './components/NotFound';
import Unauthorized from './components/Unauthorized';
import initSettings from './helpers/initSettings';
import ProtectedRoute from 'components/ProtectedRoute';
import Report from 'components/Report/Report';
import { isMobile } from 'react-device-detect';
import MobileDisclaimer from './components/MobileDisclaimer';
import { enableMapSet } from 'immer';
import RiskMonitor from 'components/RiskMonitor';
import { UserContext } from 'contexts/user';
import { AlertsComponent } from 'components/Alerts/AlertsComponent';
import Dashboard from 'components/Dashboard';

enableMapSet();

const App = () => {
  initSettings();
  const { user } = useContext(UserContext);
  if (!user) return null;
  return (
    <div>
      <NavBar view='list' />

      {isMobile && <MobileDisclaimer />}
      <Switch>
        <ProtectedRoute
          exact
          path={['/', '/reports']}
          component={ReportsList}
        />
        <ProtectedRoute exact path={'/report/:id'} component={Report} />
        <ProtectedRoute exact path={'/risk-monitor'} component={RiskMonitor} />
        <ProtectedRoute exact path={['/info']} component={FAQ} />
        <ProtectedRoute exact path={['/api']} component={API} />
        <ProtectedRoute exact path={'/alerts'} component={AlertsComponent} />
        <ProtectedRoute exact path={'/dashboards'} component={Dashboard} />

        <ProtectedRoute exact path='/not_found' component={NotFound} />
        <ProtectedRoute exact path={['/termsofuse']} component={Use} />
        <ProtectedRoute exact path={['/privacy']} component={Privacy} />
        <Route exact path='/unathorized' component={Unauthorized} />
      </Switch>
    </div>
  );
};

export default App;
