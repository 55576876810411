import RiskMonitorConfig from 'models/riskMonitorConfig';
import { useReducer, useEffect, useCallback } from 'react';
import RiskScore from 'models/riskScore';
import RiskScoresSummary from 'models/riskScoresSummary';

// actions
// action types
const SET_RISK_MONITOR_CONFIG = 'SET_RISK_MONITOR_CONFIG';
const SET_RISK_SCORES = 'SET_RISK_SCORES';
const REMOVE_RISK_SCORE = 'REMOVE_RISK_SCORE';
const ADD_PENDING_REQUEST_ID = 'ADD_PENDING_REQUEST_ID';
const REMOVE_PENDING_REQUEST_ID = 'REMOVE_PENDING_REQUEST_ID';

// action factories
const addPendingRequestId = (pendingRequestId) => ({
  type: ADD_PENDING_REQUEST_ID,
  payload: pendingRequestId,
});

// const removeRiskScore = (address) => ({
//   type: REMOVE_RISK_SCORE,
//   payload: address,
// });

const removePendingRequestId = (pendingRequestId) => ({
  type: REMOVE_PENDING_REQUEST_ID,
  payload: pendingRequestId,
});

const setRiskMonitorConfig = (riskMonitorConfig) => ({
  type: SET_RISK_MONITOR_CONFIG,
  payload: riskMonitorConfig,
});

const setRiskScores = (riskScores) => ({
  type: SET_RISK_SCORES,
  payload: riskScores,
});

const initialState = {
  riskScoresSummaries: [],
  riskMonitorConfig: null,
  riskScores: [],
  pendingRequestIds: [],
};

// reducer functions
const createRiskScoresSummaries = (riskScores) => {
  const addressRiskScores = riskScores.reduce((acc, rs) => {
    if (!acc[rs.address]) {
      acc[rs.address] = [];
    }
    acc[rs.address].push(rs);
    return acc;
  }, {});

  return Object.values(addressRiskScores).map((rss) => {
    return new RiskScoresSummary(rss);
  });
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_RISK_MONITOR_CONFIG: {
      return {
        ...state,
        riskMonitorConfig: action.payload,
      };
    }
    case SET_RISK_SCORES: {
      const riskScores = action.payload;
      const riskScoresSummaries = createRiskScoresSummaries(riskScores);
      return {
        ...state,
        riskScores,
        riskScoresSummaries,
      };
    }
    case ADD_PENDING_REQUEST_ID: {
      return {
        ...state,
        pendingRequestIds: [...state.pendingRequestIds, action.payload],
      };
    }
    case REMOVE_PENDING_REQUEST_ID: {
      return {
        ...state,
        pendingRequestIds: state.pendingRequestIds.filter(
          (id) => id !== action.payload,
        ),
      };
    }
    case REMOVE_RISK_SCORE: {
      return {
        ...state,
        rawRiskScores: state.rawRiskScores.filter(
          (entry) => entry.address !== action.payload,
        ),
        riskScoresSummaries: state.riskScoresSummaries.filter(
          (entry) => entry.address !== action.payload,
        ),
      };
    }
    default: {
      throw new Error('unsupported risk monitor reducer action');
    }
  }
};

const useRiskMonitor = (apiClient) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!apiClient) {
      return;
    }
    const grmcRequestId = 'grmcRequestId';
    dispatch(addPendingRequestId(grmcRequestId));
    apiClient
      .getRiskMonitorConfig()
      .then((data) => {
        const riskMonitorConfig = new RiskMonitorConfig(data);
        dispatch(setRiskMonitorConfig(riskMonitorConfig));
      })
      .catch((error) => {
        // TODO: larger initiative -> interpret status codes in API client
        if (error.statusCode === 404) {
          const crmcRequestId = 'crmcRequestId';
          dispatch(addPendingRequestId(crmcRequestId));
          apiClient
            .createRiskMonitorConfig()
            .then((data) => {
              const riskMonitorConfig = new RiskMonitorConfig(data);
              dispatch(setRiskMonitorConfig(riskMonitorConfig));
            })
            .catch((error) => {
              console.error(
                'failed to create risk monitor config. error: ',
                error,
              );
            })
            .finally(() => dispatch(removePendingRequestId(crmcRequestId)));
        } else {
          console.error('failed to get monitored addresses. error: ', error);
        }
      })
      .finally(() => dispatch(removePendingRequestId(grmcRequestId)));
  }, [dispatch, apiClient]);

  useEffect(() => {
    if (!apiClient) {
      return;
    }
    apiClient
      .getRiskScores()
      .then((res) => {
        const riskScores = res.map(
          (apiRiskScore) => new RiskScore(apiRiskScore),
        );
        dispatch(setRiskScores(riskScores));
      })
      .catch((error) => {
        console.error('failed to get risk scores. error: ', error);
      });
  }, [dispatch, apiClient]);

  const addAddress = useCallback(
    (address) => {
      const riskMonitorConfig = state.riskMonitorConfig;
      const currentMonitoredAddresses = riskMonitorConfig.monitoredItems.reduce(
        (addrs, item) => {
          addrs.push(item.address);
          return addrs;
        },
        [],
      );
      if (currentMonitoredAddresses.includes(address)) {
        return;
      }
      const monitoredItems = [...riskMonitorConfig.monitoredItems, { address }];
      const rmcRequestId = 'rmcRequestId';
      dispatch(addPendingRequestId(rmcRequestId));
      apiClient
        .updateRiskMonitorConfig({
          id: riskMonitorConfig.id,
          monitoredItems,
        })
        .then((apiRiskMonitorConfig) => {
          const updatedRiskMonitorConfig = new RiskMonitorConfig(
            apiRiskMonitorConfig,
          );
          dispatch(setRiskMonitorConfig(updatedRiskMonitorConfig));

          const crsRequestId = 'crsRequestId';
          dispatch(addPendingRequestId(crsRequestId));
          return apiClient
            .createRiskScore(address)
            .then((res) => {
              const riskScore = new RiskScore(res);
              dispatch(setRiskScores([...state.riskScores, riskScore]));
            })
            .catch((error) => {
              console.error('failed to create risk score. error: ', error);
            })
            .finally(() => dispatch(removePendingRequestId(crsRequestId)));
        })
        .catch((error) =>
          console.error('failed to add address. error: ', error),
        )
        .finally(() => dispatch(removePendingRequestId(rmcRequestId)));
    },
    [apiClient, state.riskMonitorConfig, state.riskScores],
  );

  const removeAddress = useCallback(
    (address) => {
      const riskMonitorConfig = state.riskMonitorConfig;
      const monitoredItems = riskMonitorConfig.monitoredItems.filter(
        (item) => item.address !== address,
      );
      const raRequestId = 'raRequestId';
      dispatch(addPendingRequestId(raRequestId));
      apiClient
        .updateRiskMonitorConfig({
          id: riskMonitorConfig.id,
          monitoredItems,
        })
        .then((apiRiskMonitorConfig) => {
          const updatedRiskMonitorConfig = new RiskMonitorConfig(
            apiRiskMonitorConfig,
          );
          dispatch(setRiskMonitorConfig(updatedRiskMonitorConfig));
        })
        .catch((error) =>
          console.error('failed to add address. error: ', error),
        )
        .finally(() => dispatch(removePendingRequestId(raRequestId)));
    },
    [apiClient, state.riskMonitorConfig],
  );

  return {
    addAddress,
    removeAddress,
    riskMonitorConfig: state.riskMonitorConfig,
    riskSummaries: state.riskScoresSummaries,
    isUpdating: state.pendingRequestIds.length !== 0,
  };
};

export default useRiskMonitor;
