import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

export const AppCenterWindow = styled.div`
  margin-left: 57px; // offset sidebar width to center
  font-family: 'Verlag A', 'Verlag B';
  height: 100%;

  .panel-default {
    margin: ${SR.margin.double};
  }
`;

export const AppViewTableContainer = styled.div`
  background: ${SR.colors.nearWhite};
`;
