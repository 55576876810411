import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ReportDataWithRegraph from './reportDataWithRegraph';
import ElementusAPIService from 'services/elementus_api.service';
import ForensicAnalysis from 'models/forensicAnalysis';

export const store = React.createContext({});

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'INIT': {
      // set Report Data as an object with the address as key
      const ReportData = new ReportDataWithRegraph(payload);
      return {
        ...state,
        ...payload,
        report: payload.report,
        ReportData,
        loading: false,
      };
    }
    case 'LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'USER_PARAMETERS': {
      return { ...state, userParameters: payload };
    }
    case 'FILTER':
      state.ReportData.filterGraphBasedOnTime(
        action.payload.min,
        action.payload.max,
      );
      return {
        ...state,
      };
    case 'ERROR':
      //TODO: Save error to a LOG
      return { ...state, error: true };

    default:
      return state;
  }
};
const initialState = {
  ReportData: {},
  SelectedNode: false,
  HoveredNode: false,
  newReportName: {},
  userParameters: { reset: false },
  report: {},
  loading: true,
  error: false,
  // meta, graph,transfers,reportsList,reportParams,token, id will also be set here.
};

export const StoreProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { getAccessTokenSilently } = useAuth0();
  // First thing we do is load the report from the given Id.

  useEffect(() => {
    (async () => {
      if (!props.id) return;
      try {
        // Lets do the API call here, because we have access to the useAuth0 hook.
        // This will also provide a nice check to see if the user has access to the report.
        const token = await getAccessTokenSilently();
        const api = new ElementusAPIService(token);

        const forensicAnalysisResponse = await api.getForensicAnalysis(
          props.id,
          state.userParameters,
        );
        if (forensicAnalysisResponse.status === 500) {
          console.log(
            `Failed to Fetch parentAgent from API /forensic-analysis/${props.id}`,
          );
        }

        const forensicAnalysis = new ForensicAnalysis(forensicAnalysisResponse);
        const rawReportData = forensicAnalysis.rawReportData;
        rawReportData.token = token;

        dispatch({
          type: 'INIT',
          payload: rawReportData,
        });
      } catch (e) {
        console.log('e', e);
        dispatch({
          type: 'ERROR',
          payload: e,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, state.userParameters, getAccessTokenSilently]);

  return (
    <store.Provider value={{ state, dispatch }}>
      {props.children}
    </store.Provider>
  );
};
