import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import styled from 'styled-components';
import { EXPERIMENTAL_FEATURES } from 'featureFlags';
import { bigNumNotationToFloat } from 'utils/format';
import { comparePercentageStrings } from 'utils/displayData';
import { styleRefs as SR } from 'styles/styleRefs';

const createBenchmarkRow = (obj) => {
  return Object.entries(obj).reduce((acc, curr) => {
    let [key, value] = curr;
    if (!value) value = '-';
    acc[key] = value;
    return acc;
  }, {});
};

const BenchmarkComponent = ({ reportData }) => {
  let benchmarkData = reportData.getBenchmarkData();

  if (!EXPERIMENTAL_FEATURES.entityReports.exchangeBenchmarks) {
    return null;
  }

  benchmarkData = benchmarkData.map(createBenchmarkRow);

  const activeEntityId =
    reportData.meta.entity && reportData.meta.entity.id
      ? reportData.meta.entity.id
      : '<Unknown Entity>';

  if (benchmarkData.length) {
    // Add current entity to exchange list if not already there
    const found = benchmarkData.find(
      (exchange) => exchange.entityName === activeEntityId,
    );
    if (!found) {
      const entityStatistics = reportData.getEntityStatistics();
      const benchmarkRow = createBenchmarkRow(entityStatistics);
      benchmarkData.push(benchmarkRow);
    }
  }

  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (row.entityName === activeEntityId) {
      classes = 'active-entity';
    }

    return classes;
  };

  return (
    <BenchmarkTable id='benchmark-table'>
      <BootstrapTable
        keyField='entityName'
        data={benchmarkData}
        columns={BootstrapTableColumnConfig}
        bordered={false}
        rowClasses={rowClasses}
        defaultSorted={[{ dataField: 'entityName', order: 'asc' }]}
        noDataIndication='Failed to load data for this entity.'
      ></BootstrapTable>
    </BenchmarkTable>
  );
};

const BenchmarkTable = styled.div`
  background-color: ${SR.colors.nuetral_DarkPurple};
  color: #fff;
  opacity: 0.8;
  height: 100%;
  .react-bootstrap-table {
    overflow-y: auto;
    height: 100%;
    thead {
      position: sticky;
      top: 0px;
      background-color: #292146;
    }
    .active-entity {
      background-color: ${SR.colors.emphasis_lightPurple};
    }
  }
`;

// Utils ----------------------------------------

// Custom sort function for Bootstrap Table Next - Sorts number fields
export const bootstrapTableSortNumberType = (a, b, order, dataField) => {
  // remove % to sort percentage fields
  let cleanA = a.replace('%', '');
  let cleanB = b.replace('%', '');

  // revert big notation conversion (to float) to compare
  cleanA = bigNumNotationToFloat(cleanA);
  cleanB = bigNumNotationToFloat(cleanB);

  if (order === 'asc') {
    return Number(cleanA) < Number(cleanB) ? -1 : 1;
  }

  return Number(cleanA) > Number(cleanB) ? -1 : 1;
};

const scrollActiveEntityIntoView = () => {
  setTimeout(() => {
    const elements = document.getElementsByClassName('active-entity');
    if (elements == null || elements.length === 0) return;

    const element = elements[0];
    if (element.scrollIntoView) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, 250);
};

// Config ----------------------------------------

const BootstrapTableColumnConfig = [
  {
    dataField: 'entityName',
    text: 'Exchange',
    sort: true,
  },
  {
    dataField: 'riskScore',
    text: 'Risk score',
    sort: true,
    sortFunc: (a, b, order) => {
      const [first, second] = order === 'asc' ? [a, b] : [b, a];
      return comparePercentageStrings(first, second);
    },
    onSort: scrollActiveEntityIntoView,
  },
  {
    dataField: 'addressCount',
    text: '# of addresses',
    sort: true,
    sortFunc: bootstrapTableSortNumberType,
    onSort: scrollActiveEntityIntoView,
  },
  {
    dataField: 'incomingTxCount',
    text: '# of inbound txs',
    sort: true,
    sortFunc: bootstrapTableSortNumberType,
    onSort: scrollActiveEntityIntoView,
  },
  // {
  //   dataField: 'outgoingTxCount',
  //   text: '# of outbound txs',
  //   sort: true,
  //   sortFunc: bootstrapTableSortNumberType,
  //   onSort: scrollActiveEntityIntoView,
  // },
  {
    dataField: 'incomingVolume',
    text: 'Total BTC inbound',
    sort: true,
    sortFunc: bootstrapTableSortNumberType,
    onSort: scrollActiveEntityIntoView,
  },
  // {
  //   dataField: 'outgoingVolume',
  //   text: 'Total BTC outbound',
  //   sort: true,
  //   sortFunc: bootstrapTableSortNumberType,
  //   onSort: scrollActiveEntityIntoView,
  // },
  {
    dataField: 'firstOnChainTx',
    text: 'First seen',
    sort: true,
    sortFunc: bootstrapTableSortNumberType,
    onSort: scrollActiveEntityIntoView,
  },
];

export default BenchmarkComponent;
