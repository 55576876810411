import React from 'react';
import { IndentedSection } from '../styles';
import styled from 'styled-components';
import { FlowType } from 'utils/enums';

export const KYCSummary = ({ kycData }) => {
  const data = kycData.meta.scores.extra || {};
  const verificationItems = [
    {
      id: 'id',
      label: 'Id required',
      value: data.id_required,
    },
    {
      id: 'address',
      label: 'Proof of address',
      value: data.address_proof_required,
    },
    {
      id: 'phone',
      label: 'Phone verification',
      value: data.phone_verified_required,
    },
    {
      id: 'selfie',
      label: 'Selfie required',
      value: data.selfie_required,
    },
    {
      id: 'withdrawal',
      label: 'Has withdrawal limits',
      value: data.withdrawal_limits == null ? null : data.withdrawal_limits > 0,
    },
    {
      id: 'high-risk',
      label: 'High risk jurisdiction',
      value: data.risky_location_required,
    },
  ];

  if (kycData.flowType !== FlowType.Entity) {
    return null;
  }

  return (
    <div id='kyc-aml' className='collapsable-item scrollable-wrapper'>
      <div className='collapsable-header'>
        <span>KYC/AML</span>
      </div>
      <IndentedSection className='scrollable-div'>
        <KYCTable>
          <tbody className='table-body'>
            {verificationItems.map((item) => (
              <tr key={item.label}>
                <td className='iconCell'>
                  {getIconForVerificationItem(item.id)}
                </td>
                <td>{item.label}</td>
                <td className='statusCell'>
                  {getVerificationItemValue(item.value)}
                  {getVerificationItemStatusIcon(item.id, item.value)}
                </td>
              </tr>
            ))}
          </tbody>
        </KYCTable>
      </IndentedSection>
    </div>
  );
};

const KYCTable = styled.table`
  i {
    margin-left: auto;
    &.green {
      color: #0f0;
    }
    &.red {
      color: #f00;
    }
    &.yellow {
      color: #d9c908;
    }
  }
  td:last-child {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const getVerificationItemValue = (value) => {
  if (value == null) return 'Unknown';

  return value ? 'Yes' : 'No';
};

const getIconForVerificationItem = (label) => {
  let className = 'fas fa-exclamation-triangle';
  switch (label) {
    case 'id':
      className = 'fal fa-id-card';
      break;
    case 'address':
      className = 'fas fa-map-marker-alt';
      break;
    case 'phone':
      className = 'fas fa-phone-square-alt';
      break;
    case 'selfie':
      className = 'fas fa-portrait';
      break;
    case 'withdrawal':
      className = 'fas fa-funnel-dollar';
      break;
    case 'high-risk':
    default:
      break;
  }
  return <i className={className}></i>;
};

// Returns icon indicating whether entity passes the given verification
const getVerificationItemStatusIcon = (verificationItemId, value) => {
  if (value == null) return questionMark;

  switch (verificationItemId) {
    case 'id':
      return value ? checkMark(value, true) : cross(value, false);
    case 'address':
      return value ? checkMark(value, true) : cross(value, false);
    case 'phone':
      return value ? checkMark(value, true) : cross(value, false);
    case 'selfie':
      return value ? checkMark(value, true) : cross(value, false);
    case 'withdrawal':
      return value > 0 ? checkMark(value, true) : cross(value, false);
    case 'high-risk':
    default:
      return value ? cross(value, false) : checkMark(value, true);
  }
};

const checkMark = (value, setGreen) => {
  let className = 'fas fa-check';

  if (value != null) {
    className += setGreen ? ' green' : ' red';
  }

  return <i className={className}></i>;
};

const questionMark = <i className='far fa-question-circle yellow'></i>;

const cross = (value, setGreen) => {
  let className = 'fas fa-times';

  if (value != null) {
    className += setGreen ? ' green' : ' red';
  }

  return <i className={className}></i>;
};
