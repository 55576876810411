import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { styleRefs as SR } from 'styles/styleRefs';

export const GraphContainerCanvas = styled.div`
  box-shadow: -8px -4px 8px 0px hsl(259deg 38% 20%) inset,
    8px 4px 12px 0px hsl(259deg 38% 10%) inset;

  canvas {
    cursor: ${(props) => props.cursor};
    &:active {
      cursor: grabbing;
    }
  }
`;

export const ReGraphFrame = styled(Box)`
  height: 100%;
  background-color: ${SR.colors.nuetral_DarkPurple};

  .linelegend {
    display: none;
  }

  .statunits {
    color: #e2deeb;
    font-size: 24px;
  }

  #graphlegendcontainer {
    position: relative;
    left: 10px;
    min-height: 10px;
    min-width: 10px;
    z-index: 10;
    pointer-events: none;
  }

  #legendsvg {
    margin-left: 15px;
    overflow: visible;
    fill: white;
    z-index: 10;
    cursor: pointer;
  }

  #legendsvg > g {
    transform: translate(0, -200%) scale(0.8);
  }

  .warning-button {
    top: 8%;
    right: 0%;
    position: absolute;
    z-index: 10;
    /* Firefox */
    -moz-transition: 0.8s ease-in-out;
    /* WebKit */
    -webkit-transition: 0.8s ease-in-out;
    /* Opera */
    -o-transition: 0.8s ease-in-out;
    /* Standard */
    transition: 0.8s ease-in-out;
  }

  .warning-button:hover {
    /* Firefox */
    -moz-transform: rotate(360deg);
    /* WebKit */
    -webkit-transform: rotate(360deg);
    /* Opera */
    -o-transform: rotate(360deg);
    /* Standard */
    transform: rotate(360deg);
  }

  .top-left-settings-box {
    position: absolute;
    top: 1%;
    left: 0%;
    margin-left: -45px;
  }

  .panel-container {
    height: 100%;
    border-color: none;
  }

  #graphcontainer {
    height: 100%;
    width: 100%;
    position: absolute;
    .report-box {
      border-radius: 0;
    }
  }

  #download-graph-btn {
    top: 1%;
    right: 1%;
    position: absolute;
    z-index: 10;
  }
`;

export const ActivePurpleButton = styled.button`
  height: ${(props) => props.height};
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  width: 120px;
  margin: 8px;
  -webkit-font-smoothing: antialiased;
  overflow: visible;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 1px 16px 0 16px;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  background-color: ${SR.colors.emphasis_lightPurple};
  line-height: 3em;
`;

export const LayoutButtonContainer = styled.div`
  font-size: ${SR.fontSize.sml};
  justify-content: left;
  padding: 10;
  bottom: 60;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-radius: 3px;
  top: 1%;
  left: 1%;

  .layout-option {
    background: darken(${SR.colors.nuetral_DarkPurple}, 15%) !important;
    line-height: 1em;
    margin-left: 2px !important;
    margin-right: 2px !important;
    margin-top: 4px !important;
  }

  .layout-option:hover {
    background-color: darken(${SR.colors.nuetral_DarkPurple}, 5%) !important;
    border-color: ${SR.colors.nuetral_DarkPurple};
    box-shadow: none;
  }

  .layout-button {
    margin-right: 5px !important;
  }
`;

export const LayoutButton = withStyles({
  root: {
    backgroundColor: SR.colors.emphasis_lightPurple,
    borderColor: SR.colors.emphasis_lightPurple,
    fontSize: SR.fontSize.sml,
    '&:hover': {
      backgroundColor: '#6231cc',
      borderColor: SR.colors.emphasis_lightPurple,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: SR.colors.emphasis_lightPurple,
      borderColor: '#25124d',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

export const ModalStyles = (props) => ({
  content: {
    top: '50%',
    left: '50%',
    height: 'max-content',
    position: 'absolute',
    width: props.width ? props.width : '500px',
    transform: 'translate(-50%, -50%)',
    opacity: 1,
    border: 'none',
    marginTop: '15px',
    overflow: 'visible',
  },
  overlay: {
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.5)',
  },
});

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 0px 9px 3px hsl(0 0% 100% / 0.1);
  background: ${SR.colors.nuetral_DarkPurple};
  border-radius: 20px;
  color: white;

  .modal-heading {
    position: relative;
    padding: 30px 30px 14px 30px;
    background: ${SR.colors.nuetral_DarkPurple};
    height: 20%;
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;

    .modal-title {
      font-family: 'Verlag A', 'Verlag B';
      font-size: 36px;
      opacity: 1;
      font-weight: 500;
      line-height: 1;
      color: white;
      margin-left: auto;
    }
    .info-icon-new-report {
      margin-right: auto;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .MuiSlider-root {
    color: ${SR.colors.emphasis_lightPurple};
  }

  .MuiFormControl-root {
    .MuiFilledInput-root {
      background: #1c142c;
    }

    label {
      color: ${SR.colors.emphasis_lightPurple};
    }
    input {
      font-size: large;
      color: white;
    }
    .MuiFilledInput-underline:after {
      border-bottom: 4px solid ${SR.colors.emphasis_lightPurple};
    }
  }
`;
