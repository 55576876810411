import React from 'react';
import MobileOff from '@material-ui/icons/MobileOff';
import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

const elementusLogo = `${process.env.PUBLIC_URL}/img/elementus-logo.png`;
const elementusLogo2 = `${process.env.PUBLIC_URL}/img/elementus.png`;

export default function MobileDisclaimer() {
  return (
    <main role='main' className='site-content' id='login-screen-main'>
      <div id='background-layer'>
        <div id='Loginform'>
          <div className='login-inner'>
            <div id='login-logo-box'>
              <div id='login-circle-logo'>
                <img
                  id='login-elementus-logo'
                  src={elementusLogo}
                  alt={'elementus-logo'}
                />
              </div>
            </div>
            <div
              id='login-form-box'
              className='row col-md-offset-2 col-md-8 login-form'
            >
              <div className='logintitlecontainer'>
                <img
                  id='loginFormTitle'
                  className='navbar-icon'
                  src={elementusLogo2}
                  alt={'logo'}
                  width='200'
                  height='35'
                />
              </div>
              <MobileOff
                style={{ fill: SR.colors.emphasis_lightPurple, margin: 15 }}
                fontSize='large'
              />
              <DisclaimerTxt> not optimized for mobile usage</DisclaimerTxt>
              <DisclaimerTxt> please use a desktop computer</DisclaimerTxt>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export const DisclaimerTxt = styled.p`
  color: grey;
`;
