const createSummary = (riskScores) => {
  if (riskScores.length === 0) {
    throw new Error('cannot create summary without risk scores');
  }
  const sortedRiskScores = riskScores.sort((rsA, rsB) =>
    rsA.timestamp > rsB.timestamp ? -1 : 1,
  );
  const riskScore = sortedRiskScores[0];
  const previousRiskScore =
    sortedRiskScores.length >= 2 ? sortedRiskScores[1] : null;
  const initialRiskScore =
    sortedRiskScores.length >= 2
      ? sortedRiskScores[sortedRiskScores.length - 1]
      : null;
  const summary = {
    address: riskScore.address,
    asset: riskScore.asset,
    riskScore: riskScore.score,
    riskScoreDate: riskScore.timestamp,
    breakdown: riskScore.breakdown,
    previousRiskScore: previousRiskScore ? previousRiskScore.score : null,
    previousRiskScoreDate: previousRiskScore
      ? previousRiskScore.timestamp
      : null,
    initialRiskScore: initialRiskScore ? initialRiskScore.score : null,
    initialRiskScoreDate: initialRiskScore ? initialRiskScore.timestamp : null,
  };
  return summary;
};
/**
 * Summarizes risk scores for a single address
 */
class RiskScoresSummary {
  constructor(riskScores) {
    this._summary = createSummary(riskScores);
  }

  get address() {
    return this._summary.address;
  }

  get asset() {
    return this._summary.asset;
  }

  get riskScore() {
    return this._summary.riskScore;
  }

  get riskScoreDate() {
    return this._summary.riskScoreDate;
  }

  get previousRiskScore() {
    return this._summary.previousRiskScore;
  }

  get previousRiskScoreDate() {
    return this._summary.previousRiskScoreDate;
  }

  get initialRiskScore() {
    return this._summary.initialRiskScore;
  }

  get initialRiskScoreDate() {
    return this._summary.initialRiskScoreDate;
  }

  get breakdown() {
    return this._summary.breakdown;
  }
}

export default RiskScoresSummary;
