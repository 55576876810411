/*
  #e7674e -- orange
  #e2deeb -- light
  #753bf2  rgb(117,59,242) -- med purple
  #2c2249 -- dark
  #1c142d -- darkest
*/

export const styleRefs = {
  colors: {
    black: '#000',
    emphasis_lightPurple: '#753bf2',
    emphasis_brightPurple: '#802cfb',
    mutedWhitePurple: '#a7a1c4',
    mutedGreyPurple: '#6231cc',
    nearWhite: '#f3f1f7',
    offWhite: '#E2DDEB',
    mutedWhite: '#d3d3d3',
    nuetral_DarkPurple: '#2c2249',
    nearBlackPurple: '#1c142d',
    contrasting_YellowOrange: '#fdbb00',
    contrasting_RedishOrange: '#e4735f',
    brightRed: '#FF0108',
    neutralWhite: '#cdc9c9',
    mediumPurple: '#3d2874',
    grey: '#333',
  },
  fontSize: {
    sml: '8.75px',
    standard: '12px',
    med: '14px',
    lrg: '20px',
  },
  margin: {
    std: '1em',
    double: '2em',
    half: '0.5em',
  },
  padding: {
    half: '0.5em',
    std: '1em',
    double: '2em',
  },
  components: {
    navbar: {
      width: '57px',
    },
  },
};
