import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import {
  breakdownFormatter,
  changeFormatter,
  riskScoreFormatter,
  addressColumnFormatter,
} from '../formatters';
import filterFactory from 'react-bootstrap-table2-filter';

import { AppViewTableContainer } from '../styles';
import { TableStyling } from './styles';
import { numberSortFn, stringSortFn } from 'utils/sort';

const KeyField = 'id';
const Column = {
  Address: 'Address',
  Asset: 'Asset',
  RiskScore: 'Risk Score',
  RiskScoreDate: 'Risk Score Date',
  PreviousRiskScore: 'Previous Risk Score',
  PreviousRiskScoreDate: 'Previous Risk Score Date',
  PreviousRiskScoreChange: 'Previous Risk Score Change',
  InitialRiskScore: 'Initial Risk Score',
  InitialRiskScoreDate: 'Initial Risk Score Date',
  InitialRiskScoreChange: 'Initial Risk Score Change',
  Breakdown: 'Breakdown',
};

const Name = {
  Address: 'Address',
  Asset: 'Asset',
  RiskScore: 'Score',
  RiskScoreDate: 'Score Date',
  PreviousRiskScore: 'Previous Score',
  PreviousRiskScoreDate: 'Previous Score Date',
  PreviousRiskScoreChange: 'Previous Score Change',
  InitialRiskScore: 'Initial Score',
  InitialRiskScoreDate: 'Initial Score Date',
  InitialRiskScoreChange: 'Initial Score Change',
  Breakdown: 'Breakdown',
};

const RiskMonitorTable = ({ riskSummaries, removeAddress }) => {
  const rows = riskSummaries.map(createRow);

  const BootstrapTableColumnConfig = [
    {
      dataField: Column.Address,
      text: Name.Address,
      formatter: addressColumnFormatter,
      formatExtraData: removeAddress,
      headerStyle: () => ({ width: '15%' }),
    },
    {
      dataField: Column.Asset,
      text: Name.Asset,
      sort: true,
      sortFunc: stringSortFn,
      formatter: (cell, row) => cell.toUpperCase(),
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: Column.RiskScore,
      text: Name.RiskScore,
      formatter: riskScoreFormatter,
      sort: true,
      sortFunc: (a, b, order) => numberSortFn(a.score, b.score, order),
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: Column.Breakdown,
      text: Name.Breakdown,
      formatter: breakdownFormatter,
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: Column.PreviousRiskScore,
      text: Name.PreviousRiskScore,
      formatter: riskScoreFormatter,
    },
    {
      dataField: Column.PreviousRiskScoreChange,
      text: Name.PreviousRiskScoreChange,
      formatter: changeFormatter,
    },
    {
      dataField: Column.InitialRiskScore,
      text: Name.InitialRiskScore,
      formatter: riskScoreFormatter,
    },
    {
      dataField: Column.InitialRiskScoreChange,
      text: Name.InitialRiskScoreChange,
      formatter: changeFormatter,
    },
  ];

  return (
    <AppViewTableContainer>
      <ToolkitProvider
        keyField={KeyField}
        data={rows}
        columns={BootstrapTableColumnConfig}
      >
        {(props) => {
          return (
            <TableStyling>
              <BootstrapTable
                bordered
                {...props.baseProps}
                keyField={KeyField}
                filter={filterFactory()}
                noDataIndication='No accounts currently being monitored'
              />
            </TableStyling>
          );
        }}
      </ToolkitProvider>
    </AppViewTableContainer>
  );
};

const createRow = (riskSummary) => {
  return {
    [KeyField]: riskSummary.address,
    [Column.Address]: riskSummary.address,
    [Column.Asset]: riskSummary.asset,
    [Column.RiskScore]: {
      score: riskSummary.riskScore,
      date: riskSummary.riskScoreDate,
    },
    [Column.Breakdown]: riskSummary.breakdown,
    [Column.PreviousRiskScore]: {
      score: riskSummary.previousRiskScore,
      date: riskSummary.previousRiskScoreDate,
    },
    [Column.PreviousRiskScoreChange]:
      riskSummary.riskScore - riskSummary.previousRiskScore,
    [Column.InitialRiskScore]: {
      score: riskSummary.initialRiskScore,
      date: riskSummary.initialRiskScoreDate,
    },
    [Column.InitialRiskScoreChange]:
      riskSummary.riskScore - riskSummary.initialRiskScore,
  };
};

export default RiskMonitorTable;
