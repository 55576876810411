import styled from 'styled-components';
import { ModalContainer } from '../styles';
import { styleRefs as SR } from 'styles/styleRefs';

export const CustomLabelModalContainer = styled(ModalContainer)`
  .form-group {
    text-align: center;
  }
  .text-field-container {
    .custom-input {
      textarea {
        color: white;
        font-size: 15px;
        height: 36px;
        line-height: 16px;
      }
    }
  }
  .custom-label-buttons {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    .hash-div {
      width: 100%;

      .fill-lbl {
        color: ${SR.colors.offWhite};
        border-color: ${SR.colors.offWhite};
        background: ${SR.colors.nuetral_DarkPurple};
        max-width: 38%;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
          font-weight: bold;
          color: ${SR.colors.emphasis_lightPurple};
          border-color: ${SR.colors.emphasis_lightPurple};
          background: white;
        }
      }
    }

    .left-btn {
      margin-right: 5px;
    }
  }
`;
