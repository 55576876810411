import React from 'react';
import AccountTree from '@material-ui/icons/AccountTree';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { styleRefs as SR } from 'styles/styleRefs';

// Template
// export const [Name]Button = ({ onClick }) => {
//   return <Icons.[MaterialUIIcon] fontSize='large' className='close-button' onClick={onClick} />;
// };

export const Rotate = ({ onClick, tooltip, orientation, loading }) => {
  // Default Down
  let style = { fill: '#f3f2f7', tranRorm: 'rotate(90deg)' };
  if (orientation !== 'down') style.fill = SR.colors.emphasis_lightPurple;
  if (orientation === 'right') style.transform = `rotate(${0}deg)`;
  if (orientation === 'up') style.transform = `rotate(${270}deg)`;
  if (orientation === 'left') style.transform = `rotate(${180}deg)`;
  return (
    <IconButton
      aria-label='rotate'
      component='span'
      onClick={onClick}
      disabled={loading}
    >
      <Tooltip
        title={<div style={{ fontSize: SR.fontSize.standard }}>{tooltip}</div>}
        aria-label='rotate'
      >
        <AccountTree style={style} fontSize='large' className='icon-buttons' />
      </Tooltip>
    </IconButton>
  );
};
