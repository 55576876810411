import React from 'react';

const ClearIcon = () => {
  return (
    <div className='context-icons'>
      <svg id='Layer_1' x='0px' y='0px' height='12px' viewBox='0 0 36 36'>
        <path
          className='st0'
          d='M14.8,24l9.3-9.3c0.9-0.9,0.9-0.9,0-1.8L23.1,12c-0.9-0.9-0.9-0.9-1.8,0L12,21.3c-0.9,0.9-0.9,0.9,0,1.8
		l0.9,0.9C13.9,25,13.9,25,14.8,24z M24.1,21.3L14.8,12c-0.9-0.9-0.9-0.9-1.8,0L12,12.9c-0.9,0.9-0.9,0.9,0,1.8l9.3,9.3
		c0.9,0.9,0.9,0.9,1.8,0l0.9-0.9C25,22.2,25,22.2,24.1,21.3z M25.8,32.9l1.1-0.7c1.1-0.7,1.1-0.7,0.5-1.8l-0.7-1.1
		c-0.7-1.1-0.7-1.1-1.8-0.5l-1.1,0.7c-1.1,0.7-1.1,0.7-0.5,1.8l0.7,1.1C24.7,33.5,24.7,33.5,25.8,32.9z M11,7.2l1.1-0.7
		c1.1-0.7,1.1-0.7,0.5-1.8l-0.7-1.1c-0.7-1.1-0.7-1.1-1.8-0.5L9,3.8C7.9,4.4,7.9,4.4,8.6,5.6l0.7,1.1C9.9,7.9,9.9,7.9,11,7.2z
		M32.9,10.2L32.2,9c-0.7-1.1-0.7-1.1-1.8-0.5l-1.1,0.7c-1.1,0.7-1.1,0.7-0.5,1.8l0.7,1.1c0.7,1.1,0.7,1.1,1.8,0.5l1.1-0.7
		C33.5,11.3,33.5,11.3,32.9,10.2z M7.2,25l-0.7-1.1c-0.7-1.1-0.7-1.1-1.8-0.5l-1.1,0.7c-1.1,0.7-1.1,0.7-0.5,1.8L3.8,27
		c0.7,1.1,0.7,1.1,1.8,0.5l1.1-0.7C7.9,26.1,7.9,26.1,7.2,25z M32.2,27l0.7-1.1c0.7-1.1,0.7-1.1-0.5-1.8l-1.1-0.7
		c-1.1-0.7-1.1-0.7-1.8,0.5L28.8,25c-0.7,1.1-0.7,1.1,0.5,1.8l1.1,0.7C31.6,28.1,31.6,28.1,32.2,27z M6.6,12.2L7.2,11
		c0.7-1.1,0.7-1.1-0.5-1.8L5.6,8.6C4.5,7.9,4.5,7.9,3.8,9l-0.7,1.1c-0.7,1.1-0.7,1.1,0.5,1.8l1.1,0.7C5.9,13.3,5.9,13.3,6.6,12.2z
		M27,3.8l-1.1-0.7c-1.1-0.7-1.1-0.7-1.8,0.5l-0.7,1.1c-0.7,1.1-0.7,1.1,0.5,1.8L25,7.2c1.1,0.7,1.1,0.7,1.8-0.5l0.7-1.1
		C28.1,4.4,28.1,4.4,27,3.8z M12.2,29.5L11,28.8c-1.1-0.7-1.1-0.7-1.8,0.5l-0.7,1.1c-0.7,1.1-0.7,1.1,0.5,1.8l1.1,0.7
		c1.1,0.7,1.1,0.7,1.8-0.5l0.7-1.1C13.3,30.1,13.3,30.1,12.2,29.5z M34.8,18.7v-1.3c0-1.3,0-1.3-1.3-1.3h-1.3c-1.3,0-1.3,0-1.3,1.3
		v1.3c0,1.3,0,1.3,1.3,1.3h1.3C34.8,20,34.8,20,34.8,18.7z M5.2,18.7v-1.3c0-1.3,0-1.3-1.3-1.3H2.5c-1.3,0-1.3,0-1.3,1.3v1.3
		c0,1.3,0,1.3,1.3,1.3h1.3C5.2,20,5.2,20,5.2,18.7z M18.7,1.2h-1.3c-1.3,0-1.3,0-1.3,1.3v1.3c0,1.3,0,1.3,1.3,1.3h1.3
		c1.3,0,1.3,0,1.3-1.3V2.5C20,1.2,20,1.2,18.7,1.2z M18.7,30.9h-1.3c-1.3,0-1.3,0-1.3,1.3v1.3c0,1.3,0,1.3,1.3,1.3h1.3
		c1.3,0,1.3,0,1.3-1.3v-1.3C20,30.9,20,30.9,18.7,30.9z'
        />
      </svg>
    </div>
  );
};
export default ClearIcon;
