import React, { useContext } from 'react';
import * as d3 from 'd3';
import { store } from 'ReportData/ReportDataStore';
import SummaryFLUX from './summary_FLUX';
import SummaryAML from './summary_AML';
import { ReportType } from 'utils/enums';

window.d3 = d3;

const SummaryStats = () => {
  const { state: globalState } = useContext(store);
  const ReportData = globalState.ReportData;

  return (
    <div id='summarystatscontainer'>
      <div id='statsblock'>
        {ReportData.reportType === ReportType.Compliance ? (
          <SummaryAML />
        ) : (
          <SummaryFLUX ReportData={ReportData} />
        )}
      </div>
    </div>
  );
};

export default SummaryStats;
