import { degrees } from 'regraph/analysis';
import { idIsNode, idIsComboLink } from 'helpers/itemsHelper';

// Actions
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const CHART_HOVER = 'CHART_HOVER';

export const ADD_ITEMS = 'ADD_ITEMS';

export const KEY_DOWN = 'KEY_DOWN';
export const KEY_UP = 'KEY_UP';

export const EXPANSION_REQUESTED = 'EXPANSION_REQUESTED';
export const APPLY_SEARCH = 'APPLY_SEARCH';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const UPDATE_POSITIONS = 'UPDATE_POSITIONS';
export const REVERT_POSITIONS = 'REVERT_POSITIONS';

export const ROTATE = 'ROTATE';
export const INITIALIZE = 'INITIALIZE';

export const ADD_SELECTION = 'ADD_SELECTION';
export const REMOVE_SELECTION = 'REMOVE_SELECTION';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';
export const TOGGLE_GLOBAL_COMBOS = 'TOGGLE_GLOBAL_COMBOS';

export const ADD_OR_EDIT_CUSTOM_LABELS = 'ADD_OR_EDIT_CUSTOM_LABELS';
export const REMOVE_CUSTOM_LABELS = 'REMOVE_CUSTOM_LABELS';
export const SET_SHOW_CUSTOM_LABEL_EDIT = 'SET_SHOW_CUSTOM_LABEL_EDIT';

export const SET_SHOW_RISK_BRK_DWN = 'SET_SHOW_RISK_BRK_DWN';

export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';

export const ADD_COMBINATION = 'ADD_COMBINATION';

export const DEAL_WITH_DUPES = 'DEAL_WITH_DUPES';

export const CLEAR_COMBINATION = 'CLEAR_COMBINATION';

export const HIDE_ITEMS = 'HIDE_ITEMS';
export const UNHIDE_ITEMS = 'UNHIDE_ITEMS';

export const REMOVE_COMBINATION_P1 = 'REMOVE_COMBINATION_P1';
export const REMOVE_COMBINATION_P2 = 'REMOVE_COMBINATION_P2';

export const ADD_CUSTOM_COMBINATION = 'ADD_CUSTOM_COMBINATION';
export const REMOVE_CUSTOM_COMBINATION = 'REMOVE_CUSTOM_COMBINATION';

export const TRIGGER_LAYOUT = 'TRIGGER_LAYOUT';
export const TOGGLE_LABELS = 'TOGGLE_LABELS';
export const TOGGLE_EXPOSURE = 'TOGGLE_EXPOSURE';

export const TOGGLE_COMBO = 'TOGGLE_COMBO';
export const TOGGLE_FLOW = 'TOGGLE_FLOW';

export const TIME_FILTER = 'TIME_FILTER';
export const SHOW_LAYOUTS = 'SHOW_LAYOUTS';
export const CHANGE_BASELAYOUT = 'CHANGE_BASELAYOUT';
export const RERENDER = 'RERENDER';

/**
 * We handle the right click logic here.
 * Based on the current state, we will make decisions on what to do.
 *
 * Currently we are just making a decision on whether or not to show or hide
 * the context menu.
 *
 * Notice how we do not define a 'RIGHT_CLICK' 'type'
 */

export const rightClick = (id, x, y, subItem, currState) =>
  currState.contextMenu.show === true
    ? hideContextMenu()
    : showContextMenu(id, x, y, subItem);

export const initialize = (ReportData, api) => ({
  type: INITIALIZE,
  ReportData,
  api,
});

export const addItems = (id, color, newItems, t_min, t_max, direction) => ({
  type: ADD_ITEMS,
  id,
  color,
  newItems,
  t_min,
  t_max,
  direction,
});

export const showMessage = (id, subItem) => ({
  type: SHOW_MESSAGE,
  id,
  subItem,
});

export const expansionRequested = (id, glyphType, direction) => ({
  type: EXPANSION_REQUESTED,
  id,
  glyphType,
  direction,
});

export const applySearch = (idsToPing, chartRef) => ({
  type: APPLY_SEARCH,
  idsToPing,
  chartRef,
});

export const addSelection = (
  selection,
  comboNodes,
  comboLinks,
  chartRef,
  fromTableSearch,
) => ({
  type: ADD_SELECTION,
  selection,
  comboNodes,
  comboLinks,
  chartRef,
  fromTableSearch,
});

export const clearSelection = () => ({ type: CLEAR_SELECTION });

export const toggleLocalCombinations = () => ({ type: TOGGLE_GLOBAL_COMBOS });

export const removeSelection = (
  selection,
  comboNodes,
  comboLinks,
  items,
  chartRef,
) => ({
  type: REMOVE_SELECTION,
  selection,
  comboNodes,
  comboLinks,
  items,
  chartRef,
});

export const addOrEditCustomLabels = (newLabel) => ({
  type: ADD_OR_EDIT_CUSTOM_LABELS,
  newLabel,
});

export const removeCustomLabels = () => ({
  type: REMOVE_CUSTOM_LABELS,
});

export const hideItems = (selection, api) => ({
  type: HIDE_ITEMS,
  selection,
  api,
});
export const unhideItems = (api, items = false) => ({
  type: UNHIDE_ITEMS,
  api,
  items,
});

export const updatePositions = (positions) => ({
  type: UPDATE_POSITIONS,
  positions,
});
export const revertPositions = (direction) => ({
  type: REVERT_POSITIONS,
  direction,
});

export const showContextMenu = (id, x, y, subItem) => {
  return { type: SHOW_CONTEXT_MENU, id, x, y, subItem };
};
export const hideContextMenu = () => {
  return { type: HIDE_CONTEXT_MENU };
};

export const set_showCustomLabelEdit = (isOpen) => {
  return { type: SET_SHOW_CUSTOM_LABEL_EDIT, isOpen };
};

export const set_showRickBrkDwn = (isOpen) => {
  return { type: SET_SHOW_RISK_BRK_DWN, isOpen };
};

export const rotate = () => ({ type: ROTATE });
export const addCombination = (value) => ({ type: ADD_COMBINATION, value });
export const dealWithDupes = (addORremove) => ({
  type: DEAL_WITH_DUPES,
  addORremove,
});
export const clearCombination = () => ({ type: CLEAR_COMBINATION });
export const removeCombinationP1 = () => {
  return { type: REMOVE_COMBINATION_P1 };
};
export const removeCombinationP2 = (value) => {
  return { type: REMOVE_COMBINATION_P2, value };
};
export const addCustomCombination = (kind, ids) => {
  return { type: ADD_CUSTOM_COMBINATION, kind, ids };
};
export const triggerLayout = () => {
  return { type: TRIGGER_LAYOUT };
};
export const toggleCombo = () => {
  return { type: TOGGLE_COMBO };
};
export const toggleLabels = () => {
  return { type: TOGGLE_LABELS };
};
export const toggleExposure = () => {
  return { type: TOGGLE_EXPOSURE };
};

export const toggleFlow = (comboLinks, comboNodes) => {
  return { type: TOGGLE_FLOW, comboLinks, comboNodes };
};

export const changeBaselayout = (name) => {
  return { type: CHANGE_BASELAYOUT, name };
};

// If a 'timeFilter' event is not dispatched then no nodes
// appear in the graph. It is necessary to be able to bypass
// filtering while still dispatching the action.
export const timeFilter = ({ items, range }, state, bypass) => {
  let itemsToView = {};
  // bypass = true

  if (bypass) {
    itemsToView = state.items;
  } else {
    Object.entries(items).forEach(([id, link]) => {
      if (!link.data.hidden) {
        const { id1, id2 } = link;

        const dupeEdges = state.localCombinations
          ? [].concat(...Object.values(state.ReportData.dupeEdges.byAgent))
          : state.ReportData.dupeEdges['global'];

        if (
          !link.data.hidden &&
          !(state.combine.properties.length && dupeEdges.includes(id))
        )
          itemsToView[id] = link;

        if (id && id in state.items && idIsComboLink(id))
          itemsToView[id] = state.items[id];

        if (idIsNode(id1) && id1 && id1 in state.items)
          itemsToView[id1] = state.items[id1];

        if (idIsNode(id2) && id2 && id2 in state.items)
          itemsToView[id2] = state.items[id2];
      }
    });
  }
  return { type: TIME_FILTER, range, itemsToView };
};
export const rerender = () => {
  return { type: RERENDER };
};

export const getDegrees = (items) => {
  return degrees(items, { direction: 'from' }).then(
    (values) => {},
    (err) => console.error(err),
  );
};

export const keyDown = (key) => {
  return { type: KEY_DOWN, key };
};
export const keyUp = (key) => {
  return { type: KEY_UP, key };
};
