import { OverlayTrigger, Popover } from 'react-bootstrap';
import InfoIconBase from '@material-ui/icons/Info';
import React from 'react';

export default function InfoIcon(props) {
  const hopsPopover = (
    <Popover id='popover-info' title=''>
      {props.infoText}
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        rootClose
        trigger='click'
        placement='bottom'
        overlay={hopsPopover}
      >
        <InfoIconBase
          title='info'
          className='info-icon-new-report'
          style={{ cursor: 'pointer' }}
        ></InfoIconBase>
      </OverlayTrigger>
    </>
  );
}
