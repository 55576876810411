import styled from 'styled-components';
import { styleRefs as SR } from 'styles/styleRefs';

export const ReportFrame = styled.div`
  height: 100vh;
  width: calc(100vw - 57px);
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-left: 57px;

  #report-frame-left {
    justify-content: space-between;
    height: 100vh;
    width: ${(props) => props.widthLeft};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #report-frame-right {
    height: 100vh;
    width: ${(props) => props.widthRight};
    max-width: ${(props) => props.maxWidthRight};
    background-color: ${SR.colors.nuetral_DarkPurple};
    z-index: 1;
    opacity: 0.9;
  }

  #loading-animation {
    position: absolute;
    left: 47.1825%; // measured manually looking at navbar percentage of width
    top: 40%;
  }

  #loading-animation-small {
    margin-left: 45%;
    margin-top: 10%;
  }
`;
