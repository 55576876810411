import React from 'react';
import { ModalPortal } from '@weahead/react-customizable-modal';
import Delete from 'components/Delete';

export const DeleteReportModal = ({
  show,
  setIsDeleteOpen,
  ownedReports,
  setOwnedReports,
  selectedReport,
  setSelectedReport,
}) => {
  if (!show) return null;

  return (
    <ModalPortal id={'modal-root'}>
      <Delete
        onClose={(confirmed) => {
          setIsDeleteOpen(false);
          if (confirmed) {
            const toDelete = ownedReports.findIndex(
              (x) => x && x._id === selectedReport._id,
            );
            const copied = [...ownedReports];
            copied.splice(toDelete, 1);
            setOwnedReports(copied);
          }
          setSelectedReport({});
        }}
        report={selectedReport}
      ></Delete>
    </ModalPortal>
  );
};
