import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { AddAlertComponent } from './styles';
import { Panel } from 'components/Common/Panel';
import { ActivePurpleButton } from 'components/Report/Graph/styles';
import { validate } from 'wallet-address-validator';
import { Tabs, Tab } from 'react-bootstrap';
import { guessAssetFromAddress } from 'utils/crypto';

export const AddAlert = ({ createAlert }) => {
  const [type, setType] = useState('transaction');
  const [asset, setAsset] = useState('BTC');
  const [amount, setAmount] = useState(0);
  const [notificationType, setNotificationType] = useState('Email');
  const [address, setAddress] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const handleCurrencyChange = (asset) => {
    setAsset(asset);
  };

  const handleCreateAlert = () => {
    if (!title.length) {
      return setTitleError(true);
    }

    const validAddress = checkAddress(address);
    if (!validAddress) {
      return setAddressError(true);
    }

    const alert = {
      address,
      amount,
      title,
      asset: asset.toUpperCase(),
      type: type.toLowerCase(),
      notificationType: notificationType.toLowerCase(),
    };

    createAlert(alert).then(() => clearInputs());
  };

  const handleAddressChange = (address) => {
    const asset = guessAssetFromAddress(address);
    setAsset(asset);
  };

  const clearInputs = () => {
    setTitle('');
    setAddress('');
    setAmount(0);
  };

  return (
    <AddAlertComponent>
      <Panel className='alerts-panel'>
        <Panel.Heading>
          <Panel.Title componentClass='h3'>Create Alert</Panel.Title>
        </Panel.Heading>
        <Panel.Body className='flex-col'>
          <Tabs defaultActiveKey={1} id='uncontrolled-tab-example'>
            {/* Transaction tab*/}
            <Tab
              eventKey={1}
              title='Transaction Alerts'
              onClick={() => setType('transaction')}
            >
              <div className='alert-seq'>
                <div className='row'>
                  <span className='margin-right'>Create alert</span>
                  <TextField
                    className='textbox-address'
                    size='small'
                    id='alert-title'
                    label='alert title'
                    variant='outlined'
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setTitleError(false);
                    }}
                    value={title}
                    error={titleError}
                  />
                  <span className='margin-right'>&nbsp;, watch</span>
                  <TextField
                    error={addressError}
                    className='textbox-address'
                    size='small'
                    id='alert-address'
                    label='address'
                    variant='outlined'
                    onChange={(e) => {
                      setAddress(e.target.value);
                      handleAddressChange(e.target.value);
                      setAddressError(false);
                    }}
                    value={address}
                  />
                  <span>&nbsp;,</span>
                </div>
                <div className='row'>
                  <span className='margin-right'>
                    send an alert for activity over
                  </span>

                  <TextField
                    className='textbox-amount margin-right'
                    size='small'
                    id='alert-amount'
                    label='amount'
                    variant='outlined'
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                  />

                  <DropdownButton
                    title={asset}
                    id={'asset-dropdown'}
                    className='margin-right'
                  >
                    <MenuItem eventKey='BTC' onSelect={handleCurrencyChange}>
                      BTC
                    </MenuItem>
                    <MenuItem eventKey='ETH' onSelect={handleCurrencyChange}>
                      ETH
                    </MenuItem>
                  </DropdownButton>

                  <span className='margin-right'>and notify me via </span>

                  <DropdownButton
                    title={notificationType}
                    id={'notification-type-dropdown'}
                  >
                    <MenuItem eventKey='Email' onSelect={setNotificationType}>
                      Email
                    </MenuItem>
                    <MenuItem eventKey='Slack' onSelect={setNotificationType}>
                      Slack
                    </MenuItem>
                  </DropdownButton>
                </div>
              </div>
            </Tab>
            {/* Risk tab*/}
            <Tab
              eventKey={2}
              title='Risk Alerts'
              onClick={() => setType('risk')}
            >
              <div className='alert-seq'>
                <div className='row'>
                  <span className='margin-right'>Create alert</span>
                  <TextField
                    className='textbox-address'
                    size='small'
                    id='alert-title'
                    label='alert title'
                    variant='outlined'
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setTitleError(false);
                    }}
                    value={title}
                    error={titleError}
                  />
                  <span className='margin-right'>&nbsp;, watch</span>
                  <TextField
                    error={addressError}
                    className='textbox-address'
                    size='small'
                    id='alert-address'
                    label='address'
                    variant='outlined'
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setAddressError(false);
                    }}
                    value={address}
                  />
                  <span>&nbsp;,</span>
                </div>
                <div className='row'>
                  <span className='margin-right'>
                    send an alert if risk score exceeds
                  </span>

                  <TextField
                    className='textbox-amount margin-right'
                    size='small'
                    id='alert-amount'
                    label='amount'
                    variant='outlined'
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                  />

                  <span className='margin-right'>
                    percent and notify me via
                  </span>

                  <DropdownButton
                    title={notificationType}
                    id={'notification-type-dropdown'}
                  >
                    <MenuItem eventKey='Email' onSelect={setNotificationType}>
                      Email
                    </MenuItem>
                    <MenuItem eventKey='Slack' onSelect={setNotificationType}>
                      Slack
                    </MenuItem>
                  </DropdownButton>
                </div>
              </div>
            </Tab>
          </Tabs>

          <ActivePurpleButton
            size='large'
            variant='contained'
            color='primary'
            onClick={() => {
              handleCreateAlert();
            }}
            className='btn-set-alert'
          >
            SET ALERT
          </ActivePurpleButton>
        </Panel.Body>
      </Panel>
    </AddAlertComponent>
  );
};

const checkAddress = (address) => {
  let isValid = validate(address, 'BTC');
  if (isValid) return true;

  isValid = validate(address, 'ETH');
  if (isValid) return true;

  return false;
};
