export const stringSortFn = (a, b, order) => {
  if (order === 'asc') {
    return a.localeCompare(b);
  }

  return b.localeCompare(a);
};

export const numberSortFn = (a, b, order) => {
  if (order === 'asc') {
    return a < b ? -1 : 1;
  }

  return a < b ? 1 : -1;
};
