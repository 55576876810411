import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BenchmarkComponent from './BenchmarkComponent';
import { ChartComponent } from './ChartComponent';
import { store } from 'ReportData/ReportDataStore';
import ElementusAPIService from 'services/elementus_api.service';
import HistoricalData from 'models/historicalData';
import { IndentedSection } from 'components/Report/SummaryPanel/styles';
import styled from 'styled-components';
import { Spinner } from 'components/Common/Spinner';

export const EntityBenchmarkSection = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [chartData, setChartData] = useState(null);
  const [chartLoadingError, setChartLoadingError] = useState(false);
  const { state: globalState } = useContext(store);

  // 1) the data fetching code should be moved out of the component,
  // but because the parent is the overburdened Graph component
  // we can keep it here for now.
  // 2) `getChartData` should be called once per instantiation

  const getChartData = useCallback(
    async (exchange, indices) => {
      try {
        const token = await getAccessTokenSilently();
        const api = new ElementusAPIService(token);
        const metricId = 'riskScore';
        const selectorIds = { entities: [exchange], indices };
        const apiData = await api.getHistoricalData(metricId, selectorIds);
        const historicalData = new HistoricalData(apiData, 'Risk Score');
        const chartData = historicalData.chartData;
        chartData.setFeatured(exchange);
        setChartData(chartData);
        setChartLoadingError(false);
      } catch (e) {
        setChartLoadingError(true);
        console.error(e);
      }
    },
    [getAccessTokenSilently],
  );

  useEffect(() => {
    const exchange = globalState.ReportData.entityName;
    const indices = ['top_10_exchanges_by_volume'];
    getChartData(exchange, indices);
  }, [getChartData, globalState.ReportData.entityName]);

  return (
    <>
      <ChartComponentWrapper
        id='risk-score'
        className='collapsable-item full-width'
      >
        {chartLoadingError ? null : chartData ? (
          <>
            <div className='collapsable-header'>
              <span>RISK SCORE</span>
            </div>
            <ChartComponent className='chart-component' chartData={chartData} />
          </>
        ) : (
          <Spinner />
        )}
      </ChartComponentWrapper>

      <HistoricalIndexWrappper className='collapsable-item scrollable-wrapper full-width'>
        <div className='collapsable-header'>
          <span>HISTORICAL ENTITY INDEX</span>
        </div>

        <IndentedSection className='indented-section'>
          <BenchmarkComponent reportData={globalState.ReportData} />
        </IndentedSection>
      </HistoricalIndexWrappper>
    </>
  );
};

const ChartComponentWrapper = styled.div`
  margin-top: 4em;
  margin-bottom: 4em;
  height: 700px !important;
  max-height: unset !important;
`;

const HistoricalIndexWrappper = styled.div`
  margin-bottom: 4em;
  max-height: unset !important;

  .indented-section {
    max-height: 630px;
  }

  .react-bootstrap-table {
    height: 600px;
    table.table {
      margin-bottom: 0;
    }
  }
`;
